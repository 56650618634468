import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  LinearProgress,
  TextField,
  MenuItem,
  IconButton,
  Box,
  CardActions,
  Button,
  AlertColor,
} from "@mui/material";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import { ReactElement, FC, useState, useEffect } from "react";
import { Class, ScheduleClasses } from "../../Interfaces/ClassInterface";
import { DayType, Period } from "../../Interfaces/ScheduleInterface";
import { Room } from "../../Interfaces/SchoolInterface";
import { LightModal } from "../LightModal/LightModal";

import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useFormik,
  Formik,
  Form,
  FieldArray,
  FormikProvider,
  FormikProps,
  FormikErrors,
} from "formik";
import React from "react";
import { fetchPeriodsByDayTypeIdRequest } from "../../Services/ScheduleService";
import { RootState } from "../../store";
import {
  createMultipleClass,
  fetchAllBySchoolIdAndTeacherUsername,
} from "../../Services/ClassService";
import {
  AlertState,
  setCustomAlertDetails,
  setShowCustomAlert,
} from "../../store/slices/main";
import { Semester } from "../../Interfaces/SemesterInterface";
import { setCurrentScheduleClass } from "../../store/slices/class.slice";

interface ListProps {
  title: string;
  school_id: number;
  openModal: boolean;
  handleOpenModal: (state: boolean, origin: string) => void;
  reload: () => void;
  dayTypes: DayType[];
  periods?: Period[];
  rooms: Room[];
  classes: Class[];
  selectedClass?: Class;
  selectedSemester?: Semester;
  selectedScheduleClass?: ScheduleClasses;
  styles: any;
}

const ScheduleClassesModal: FC<ListProps> = ({
  styles,
  title,
  school_id,
  openModal,
  handleOpenModal,
  reload,
  dayTypes,
  periods,
  rooms,
  classes,
  selectedSemester,
  selectedScheduleClass,
  selectedClass,
}): ReactElement => {
  //
  const dispatch = useDispatch();

  const [mapPeriods, setMapPeriods] = useState<Map<number, Period[]>>(
    new Map()
  );
  const [generalErrors, setGeneralErrors] = useState<string[]>([]);
  const [showGeneralError, setShowGeneralError] = useState<boolean>(false);

  const [generateForm, setGenerateForm] = useState<boolean>(false);
  const [initValue, setInitValue] = useState<NewClass>({
    className: "",
    description: "",
    classForms: [
      {
        day_type: "",
        period: "",
        loadingPeriods: false,
        room: "",
      },
    ],
  });

  const [savingIndicator, setSavingIndicator] = useState<boolean>(false);

  interface ClassForm {
    day_type: string;
    period: string;
    room: string;
    loadingPeriods: boolean;
    class_id?: string;
    schedule_id?: string;
  }

  interface NewClass {
    className: string;
    description: string;
    classForms: ClassForm[];
  }

  useEffect(() => {
    setGenerateForm(false);
    getInitialValues()
      .then((result) => {
        setInitValue(result);
        setGenerateForm(true);
      })
      .catch((err) => {
        setGenerateForm(false);
        console.log(err);
      });
  }, [selectedScheduleClass]);

  async function getInitialValues(): Promise<NewClass> {
    console.log("init values");
    if (
      selectedScheduleClass &&
      selectedScheduleClass.classesByScheduleId?.length > 0
    ) {
      const newClasses: ClassForm[] = [];

      for (
        let index = 0;
        index < selectedScheduleClass.classesByScheduleId.length;
        index++
      ) {
        const c = selectedScheduleClass.classesByScheduleId[index];
        if (c.day_type_id && c.period_id && c.room_id) {
          // await loadDayType(index, c.day_type_id.toString());

          if (c.day_type_id.toString() && c.day_type_id.toString() !== "") {
            let periods: Period[] = [];
            periods = await fetchPeriodsByDayTypeIdRequest(
              parseInt(c.day_type_id.toString())
            );
            setMapPeriods(mapPeriods.set(index, periods));
          }

          newClasses.push({
            day_type: c.day_type_id.toString(),
            period: c.period_id.toString(),
            room: c.room_id.toString(),
            schedule_id: c.schedule_id?.toString(),
            class_id: c.id?.toString(),
            loadingPeriods: false,
          });
        }
      }

      const initialValues: NewClass = {
        className: selectedScheduleClass.classesByScheduleId[0].name,
        description: selectedScheduleClass.classesByScheduleId[0].description,
        classForms: [...newClasses],
      };
      console.log(initialValues);

      return initialValues;
    } else {
      const initialValues: NewClass = {
        className: "",
        description: "",
        classForms: [
          {
            day_type: "",
            period: "",
            loadingPeriods: false,
            room: "",
          },
        ],
      };

      return initialValues;
    }
  }

  function resetForm() {
    formik.resetForm();
    handleOpenModal(false, "");
    setGeneralErrors([]);
    setShowGeneralError(false);
    dispatch(
      setCurrentScheduleClass({
        className: "",
        description: "",
        classForms: [
          {
            day_type: "",
            period: "",
            loadingPeriods: false,
            room: "",
          },
        ],
      })
    );
  }

  const validateSchema = Yup.object().shape({
    className: Yup.string()
      .required("Class name is required")
      .max(30, "The current value field must not exceed 30 characters"),
    classForms: Yup.array()
      .of(
        Yup.object().shape({
          day_type: Yup.lazy((value) => {
            console.log(value);
            if (value && value !== "") {
              return Yup.string().required("A Day Type is required");
            } else {
              return Yup.mixed().notRequired();
            }
          }),
          period: Yup.string().when("day_type", {
            is: (val: string | ((arg0: boolean) => any) | undefined) =>
              val != undefined && val !== "",
            then: Yup.string().required("A period is required"),
            otherwise: Yup.string().notRequired(),
          }),
          room: Yup.string().when("day_type", {
            is: (val: string | ((arg0: boolean) => any) | undefined) =>
              val != undefined && val !== "",
            then: Yup.string().required("A room is required"),
            otherwise: Yup.string().notRequired(),
          }),
          // room: Yup.string().required("A Room is required"),
        })
      )
      .min(1, "Need at least a Day Type"),
  });

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValue,
    validationSchema: validateSchema,
    onSubmit: (values) => {
      console.log(values);

      let validation = [];
      setGeneralErrors([]);
      setShowGeneralError(false);
      setSavingIndicator(true);

      if (values.classForms.length == 0) {
        setGeneralErrors([
          ...generalErrors,
          "Need at least a daytype, period and room",
        ]);
      }
      for (let index = 0; index < values.classForms.length; index++) {
        const classform = values.classForms[index];
        for (
          let indexaux = 0;
          indexaux < values.classForms.length;
          indexaux++
        ) {
          const classformaux = values.classForms[indexaux];
          if (index !== indexaux) {
            if (
              classform.period + "" === classformaux.period + "" &&
              classform.day_type + "" === classformaux.day_type + ""
            ) {
              validation.push(
                "Overlapping on " + (index + 1) + " and " + (indexaux + 1)
              );
            }
          }
        }
      }
      if (validation && validation.length > 0) {
        setShowGeneralError(true);
        setGeneralErrors([...validation]);
        setSavingIndicator(false);
      } else {
        const classesToCreate: Class[] = [];

        values.classForms.forEach((classForm) => {
          if (
            (classForm.day_type != null && classForm.day_type !== "") ||
            (classForm.period != null && classForm.period !== "") ||
            (classForm.room != null && classForm.room !== "")
          ) {
            let schedule_id: number | undefined;
            if (classForm?.schedule_id) {
              schedule_id = +classForm?.schedule_id;
            } else {
              schedule_id = undefined;
            }
            let class_id: number | undefined;
            if (classForm?.class_id) {
              class_id = +classForm?.class_id;
            } else {
              class_id = undefined;
            }

            classesToCreate.push({
              name: values.className,
              description: values.description,
              day_type_id: +classForm.day_type,
              period_id: +classForm.period,
              room_id: +classForm.room,
              semester_id: selectedSemester?.id,
              school_id: school_id,
              schedule_id: schedule_id,
              id: class_id,
            });
          }
        });
        // post to service
        const createClasses = async () => {
          return await createMultipleClass(classesToCreate);
        };

        createClasses()
          .then((result: Class[]) => {
            console.log(result);
            formik.resetForm();
            handleOpenModal(false, "");
            reload();
            resetForm();
            showAlert("Created classes successfully", "success");
            setSavingIndicator(false);
          })
          .catch((err) => {
            console.log(err);
            setShowGeneralError(true);
            setGeneralErrors([...JSON.parse(err.message)]);
            setSavingIndicator(false);
          });
      }
    },
  });

  async function loadDayType(index: number, value: string) {
    if (value && value !== "") {
      setMapPeriods(
        mapPeriods.set(
          index,
          await fetchPeriodsByDayTypeIdRequest(parseInt(value))
        )
      );
    }
  }
  function changedDayType(
    index: number,
    value: string,
    field: string,
    fieldLoading: string
  ) {
    if (value && value !== "") {
      const fetchData = async () => {
        return await fetchPeriodsByDayTypeIdRequest(parseInt(value));
      };
      formik.setFieldValue(fieldLoading, true);
      fetchData()
        .then((result: Period[]) => {
          // console.log(result);
          formik.setFieldValue(field, "");
          setMapPeriods(mapPeriods.set(index, result));
          formik.setFieldValue(fieldLoading, false);
        })
        .catch((err) => {
          periods = [];
          formik.setFieldValue(fieldLoading, false);
        });
    }
  }

  function changedPeriod(
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) {
    if (event.target.value && event.target.value !== "") {
      let currentClassForm = formik.values.classForms[index];
      formik.values.classForms.map((classform, i) => {
        if (
          index !== i &&
          currentClassForm.day_type === classform.day_type &&
          event.target.value === classform.period
        ) {
          console.log("error en " + index + " y " + i);

          // if (formik?.errors?.classForms) {
          //   (formik?.errors?.classForms[index] as FormikErrors<ClassForm>)
          //     .day_type;
          // }
        }
      });
    }
  }

  const renderFieldArray = () => (arrayHelpers: any) => {
    return formik.values.classForms.map((classform, index) => {
      return (
        <div key={index}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={1}
              sm={1}
              style={{
                textAlign: "center",
                marginTop: 5,
                color: "gray",
              }}
            >
              <div style={{ paddingTop: 15, paddingBottom: 0 }}>
                {index + 1}
              </div>
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                id={`classForms.${index}.day_type`}
                name={`classForms.${index}.day_type`}
                select
                SelectProps={{
                  multiple: false,
                }}
                defaultValue={classform.day_type}
                label="Day Type"
                variant="standard"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  changedDayType(
                    index,
                    e.target.value,
                    `classForms.${index}.period`,
                    `classForms.${index}.loadingPeriods`
                  );
                }}
                error={Boolean(
                  formik.touched.classForms &&
                    formik.touched.classForms?.[index]?.day_type &&
                    formik.errors.classForms &&
                    formik.errors.classForms?.[index] &&
                    (formik.errors.classForms[index] as FormikErrors<ClassForm>)
                      .day_type
                )}
                helperText={
                  formik.touched.classForms &&
                  formik.touched.classForms?.[index]?.day_type &&
                  formik.errors.classForms &&
                  formik.errors.classForms?.[index] &&
                  (formik.errors.classForms[index] as FormikErrors<ClassForm>)
                    .day_type
                    ? (
                        formik.errors.classForms[
                          index
                        ] as FormikErrors<ClassForm>
                      ).day_type
                    : ""
                }
              >
                {dayTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                id={`classForms.${index}.period`}
                name={`classForms.${index}.period`}
                select
                SelectProps={{
                  multiple: false,
                }}
                defaultValue={classform.period}
                label="Period"
                variant="standard"
                fullWidth
                disabled={classform.loadingPeriods}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  changedPeriod(index, e, `classForms.${index}.period`);
                }}
                error={Boolean(
                  formik.touched.classForms &&
                    formik.touched.classForms?.[index]?.period &&
                    formik.errors.classForms &&
                    formik.errors.classForms?.[index] &&
                    (formik.errors.classForms[index] as FormikErrors<ClassForm>)
                      .period
                )}
                helperText={
                  formik.touched.classForms &&
                  formik.touched.classForms?.[index]?.period &&
                  formik.errors.classForms &&
                  formik.errors.classForms?.[index] &&
                  (formik.errors.classForms[index] as FormikErrors<ClassForm>)
                    .period
                    ? (
                        formik.errors.classForms[
                          index
                        ] as FormikErrors<ClassForm>
                      ).period
                    : ""
                }
              >
                {mapPeriods?.get(index)?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {classform.loadingPeriods ? <LinearProgress /> : <></>}
            </Grid>
            <Grid item xs={3} sm={3}>
              <TextField
                id={`classForms.${index}.room`}
                name={`classForms.${index}.room`}
                label="Room"
                select
                SelectProps={{
                  multiple: false,
                }}
                defaultValue={classform.room}
                variant="standard"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
                error={Boolean(
                  formik.touched.classForms &&
                    formik.touched.classForms?.[index]?.room &&
                    formik.errors.classForms &&
                    formik.errors.classForms?.[index] &&
                    (formik.errors.classForms[index] as FormikErrors<ClassForm>)
                      .room
                )}
                helperText={
                  formik.touched.classForms &&
                  formik.touched.classForms?.[index]?.room &&
                  formik.errors.classForms &&
                  formik.errors.classForms?.[index] &&
                  (formik.errors.classForms[index] as FormikErrors<ClassForm>)
                    .room
                    ? (
                        formik.errors.classForms[
                          index
                        ] as FormikErrors<ClassForm>
                      ).room
                    : ""
                }
              >
                {rooms.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </div>
      );
    });
  };

  return (
    <>
      <LightModal
        width="650px"
        isOpenModal={openModal}
        handleOpenModal={handleOpenModal}
      >
        <Card>
          {generateForm ? (
            <FormikProvider value={formik}>
              <Form>
                <CardHeader title={title} sx={{ mx: 1 }} />
                <CardContent sx={{ mx: 1 }}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      style={{ marginBottom: "5px" }}
                      id={`className`}
                      name={`className`}
                      label="Enter Class Name"
                      variant="standard"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.className}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.className && formik.errors.className
                      )}
                      helperText={
                        formik.touched.className && formik.errors.className
                          ? formik.errors.className
                          : null
                      }
                    />
                  </Grid>
                  <div style={{ maxHeight: 400, overflow: "auto" }}>
                    <FieldArray
                      name="classForms"
                      render={renderFieldArray()}
                    ></FieldArray>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        paddingTop: 20,
                        paddingBottom: 10,
                        marginLeft: -12,
                      }}
                    >
                      <IconButton
                        color="primary"
                        component="span"
                        size="large"
                        onClick={() => {
                          if (formik.values.classForms.length > 0) {
                            const lastClassForm =
                              formik.values.classForms[
                                formik.values.classForms.length - 1
                              ];

                            if (
                              (lastClassForm.day_type != null &&
                                lastClassForm.day_type === "") ||
                              (lastClassForm.period != null &&
                                lastClassForm.period === "") ||
                              (lastClassForm.room != null &&
                                lastClassForm.room === "")
                            ) {
                              return;
                            }
                          }
                          const newList = [
                            ...formik.values.classForms,
                            {
                              name: "",
                              day_type: "",
                              period: "",
                              room: "",
                              description: "",
                              id: formik.values.classForms.length + 1,
                            },
                          ];
                          formik.setFieldValue("classForms", newList);
                        }}
                      >
                        <AddCircleSharpIcon />
                      </IconButton>
                      Add Day Type
                    </Grid>
                  </div>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      type="text"
                      id={`description`}
                      name={`description`}
                      label="Enter Description"
                      variant="standard"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.description && formik.errors.description
                      )}
                      helperText={
                        formik.touched.description && formik.errors.description
                          ? formik.errors.description
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div
                      style={{
                        maxHeight: "100px",
                        overflow: "scroll",
                        color: "red",
                      }}
                    >
                      {showGeneralError ? (
                        generalErrors.map((error, index) => (
                          <p
                            key={index}
                            style={{
                              marginTop: "2px",
                              marginBottom: "2px",
                              fontSize: 14,
                            }}
                          >
                            {" "}
                            * {error}
                          </p>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </Grid>
                </CardContent>
                <CardActions style={{ padding: 0, margin: 0 }}>
                  <Grid
                    container
                    spacing="0"
                    alignItems="center"
                    justifyContent="center"
                    style={{ marginTop: "1rem" }}
                  >
                    <Grid item xs={6}>
                      <Button
                        onClick={resetForm}
                        disabled={savingIndicator}
                        style={styles.formButton}
                        size="large"
                        color="secondary"
                        variant="contained"
                        fullWidth
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={styles.formButton}
                        fullWidth
                        size="large"
                      >
                        <div>{title}</div>
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
                {savingIndicator && (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
              </Form>
            </FormikProvider>
          ) : (
            <LinearProgress />
          )}
        </Card>
      </LightModal>
    </>
  );
};

export default ScheduleClassesModal;
