import { Search } from "@mui/icons-material";
import {
  AlertColor,
  Box,
  Button,
  CssBaseline,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  List,
  TextField,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddFloatButton } from "../Components";
import CustomAlert from "../Components/CustomAlert/CustomAlert";
import SchoolAdminModal from "../Components/User/SchoolAdminModal";
import UserListItem from "../Components/User/UserListItem";
import { LayoutContainer } from "../Layout/layout.container";
import { RootState } from "../store";
import {
  AlertState,
  setCustomAlertDetails,
  setPageTitle,
  setShowCustomAlert,
} from "../store/slices/main";
import {
  fetchSchoolAdminUsers,
  filterSchoolAdmins,
  setErrorMessage,
  setSuccessMessage,
} from "../store/slices/user.slice";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = {
  containerBox: {
    borderRadius: "5px 5px 0 0",
  } as CSSProperties,
  header: {
    height: 55,
    zIndex: 3,
  } as CSSProperties,
  listContainer: {
    overflow: "auto",
    minHeight: "70vh",
    maxHeight: "70vh",
    textAlign: "center",
    background: "#3C404E",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  divider: {
    borderColor: "#3C404E",
    background: "#3C404E",
    borderBottomWidth: 2,
  },
  searchContainer: {
    padding: "5px 10px",
    height: 40,
    width: "100%",
    borderRadius: "5px",
  } as CSSProperties,
};

const SchoolAdmins = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  styles.containerBox = {
    ...styles.containerBox,
    background: isMobile ? "#3C404E" : "",
  };
  styles.header = {
    ...styles.header,
    marginTop: isMobile ? -50 : -25,
    marginBottom: isMobile ? 0 : 5,
    padding: isMobile ? 10 : 0,
  };
  styles.searchContainer = {
    ...styles.searchContainer,
    background: !isMobile ? "rgb(70, 75, 91)" : "",
  };
  //store
  const { schoolAdmins, loadingIndicator, errorMessage, successMessage } =
    useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(setPageTitle("School Admins"));
    dispatch(fetchSchoolAdminUsers());
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showAlert(errorMessage, "error");
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      showAlert(successMessage, "success");
    }
  }, [successMessage]);

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function filter(e: React.ChangeEvent<HTMLInputElement>) {
    const keyword = e.target.value.trim();
    dispatch(filterSchoolAdmins(keyword));
  }

  function handleOpenModal(value: boolean) {
    setOpenModal(value);
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  return (
    <>
      <CssBaseline />
      <LayoutContainer isRaised={true}>
        <Box style={styles.containerBox}>
          <Box style={styles.header}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <Box style={styles.searchContainer}>
                  <TextField
                    placeholder="Search by name"
                    variant="standard"
                    fullWidth
                    onChange={filter}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              {!isMobile && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      color: "white",
                      height: 48,
                      width: 150,
                    }}
                    onClick={() => handleOpenModal(true)}
                  >
                    + Add new Admin
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box style={styles.listContainer}>
            {loadingIndicator ? (
              <Box style={styles.loading}>
                <LinearProgress />
              </Box>
            ) : (
              <List sx={{ padding: 0 }}>
                {schoolAdmins?.map((item, index) => (
                  <div key={index.toString()}>
                    <UserListItem user={item} isMobile={isMobile} />
                    <Divider style={styles.divider} />
                  </div>
                ))}
              </List>
            )}
          </Box>
        </Box>
        {isMobile && <AddFloatButton handleOpenModal={handleOpenModal} />}
      </LayoutContainer>
      <SchoolAdminModal
        handleOpenModal={handleOpenModal}
        openModal={openModal}
      />
      <CustomAlert handleClose={handleCloseAlert} />
    </>
  );
};

export default SchoolAdmins;
