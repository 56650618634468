import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";

function OnEnterRouteQR() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(false);
  const { isSessionValid } = useCognitoAwsV2();

  useEffect(() => {
    if (isLoading == true) {
      validateUserSession();
    }
  }, []);

  async function validateUserSession(): Promise<void> {
    const isValid = await isSessionValid();
    console.log(isValid);
    setIsValid(isValid);
    setIsLoading(false);
  }
  return <>{!isLoading && (isValid ? <Outlet /> : <Outlet />)}</>;
}

export default OnEnterRouteQR;
