import {
  Box, Button, Divider, Grid, LinearProgress, List, ListItem, ListItemText, TextField, Typography,
} from "@mui/material";
import { FC, ReactElement, useMemo } from "react";
import { CSSProperties } from "@mui/styles";
import { Class } from '../../Interfaces/ClassInterface';
import React from "react";
import { PopButtonMenu, ConfirmationDialog } from "../../Components";
import { getTimeDateformat } from '../../Helpers/Utils';
import useState from 'react';
import DateRangePicker from "@mui/lab/DateRangePicker";
import moment from 'moment';
interface ListProps {
  styles: any;
  isMobile: boolean;
  classes: Class[];
  loadingClasses: boolean;
}
/*
const styles = {
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "56px",
  } as CSSProperties,
  divider: {
    borderColor: "#3C404E",
    background: "#3C404E",
    borderBottomWidth: 2,
  },
};*/
const TodayList: FC<ListProps> = ({
  loadingClasses,
  classes,
  styles,
  isMobile,
}): ReactElement => {
  styles.listContainer = {
    ...styles.listContainer,
    maxHeight: isMobile ? "60vh" : "50vh",
    minHeight: isMobile ? "60vh" : "50vh",
  };
  const [startHour, setStartHour] = React.useState<string | number>();
  const [startMinute, setStartMinute] = React.useState<string | number>();
  const [endHour, setEndHour] = React.useState<string | number>();
  const [endMinute, setEndMinute] = React.useState<string | number>();
  const ClassListItems = useMemo(
    () =>

      classes?.map((item: Class, index) => (
        <React.Fragment key={item.id}>
          <ListItem style={styles.listItem}>
            <ListItemText
              primaryTypographyProps={styles.primaryTextList}
              primary={<React.Fragment>
                <Grid container spacing={1} mt={0} >
                  <Grid item md={2} pt={0} sx={styles.listGridContainer}>
                    <Grid sx={styles.listGridText}>{moment(new Date(item.period?.start_time)).format("HH:mm")}</Grid>
                  </Grid>
                  <Grid item md={2} pt={0} sx={styles.listGridContainer}>
                    <Grid sx={styles.listGridText}>{moment(new Date(item.period?.end_time)).format("HH:mm")}</Grid>
                  </Grid>
                </Grid>
                <Box sx={styles.classNameText}>
                  {item.name}
                </Box>
              </React.Fragment>}
              secondary={
                moment().format("D MMM, ddd")
              }
              secondaryTypographyProps={styles.secondaryTextList}
            />
          </ListItem >
          <Divider key={`${item.id}${index}`} style={styles.divider} />
        </React.Fragment >
      )),
    [classes]
  )

  return (
    <>
      <Box style={styles.listContainer}>
        {loadingClasses ? (
          <Box style={styles.loading}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <List style={styles.list}>{ClassListItems}
            </List>
          </>
        )}
      </Box>
    </>
  );
};

export default TodayList;
