import { CSSProperties, FC, ReactElement, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Grid,
  Button,
  CardActions,
  InputAdornment,
  Box,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  LinearProgress,
} from "@mui/material";
import { LightModal } from "../LightModal/LightModal";
import { Search } from "@mui/icons-material";
import { User } from "../../Interfaces/UserInterface";
import { useDispatch } from "react-redux";
import { Class } from "../../Interfaces/ClassInterface";
import { AppDispatch } from "../../store";
import { addStudentsToClass, addStudentsToClassSchedule } from "../../Services/UsersService";

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
    lineHeight: 1,
  } as CSSProperties,
};

interface ListProps {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
  selectedClass: Class;
  students: User[];
  studentsInClass: User[];
  isSaving: boolean;
}

export const EnrollStudentModal: FC<ListProps> = ({
  openModal,
  handleOpenModal,
  selectedClass,
  students,
  studentsInClass,
  isSaving,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [studentFilter, setStudentFilter] = useState<string>("");
  const [filteredStudents, setFilteredStudents] = useState<User[]>([]);
  const [notEnrolledStudents, setNotEnrolledStudents] = useState<User[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<User[]>([]);

  useEffect(() => {
    const studentsInClassIds = studentsInClass.map((student) => student.id);
    const notEnrolled = students.filter(
      (student) => !studentsInClassIds.includes(student.id)
    );

    setNotEnrolledStudents(notEnrolled);
  }, [students, studentsInClass]);

  // Apply filter
  useEffect(() => {
    if (studentFilter === "") {
      setFilteredStudents(selectedStudents);
      return;
    }
    const filtered = notEnrolledStudents.filter((student) =>
      getFullName(student).toLowerCase().includes(studentFilter.toLowerCase())
    );
    setFilteredStudents(filtered);
  }, [studentFilter]);

  const getFullName = (student: User) =>
    `${student.first_name} ${student.last_name}`;

  // Handle events
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStudentFilter(event.target.value);
  };

  const handleStudentChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const selectedStudent = filteredStudents.find(
      (student) => student.id === Number(value)
    );
    if (!selectedStudent) return;

    let newSelectedStudents = selectedStudents;

    if (checked) {
      newSelectedStudents.push(selectedStudent);
    } else {
      const index = selectedStudents.indexOf(selectedStudent);
      index > -1 && newSelectedStudents.splice(index, 1);
    }
    setSelectedStudents([...newSelectedStudents]);
  };

  const addStudentsToRoster = async (studentIds: number[]) => {
    if (!selectedClass?.schedule_id) return;

    dispatch(
      addStudentsToClassSchedule({ studentIds, classSchedule_id: selectedClass.schedule_id })
    ).then(resetAndClose);
  };

  const handleSubmit = () => {
    const selectedStudentsIds = [...selectedStudents, ...studentsInClass].map(
      (student) => Number(student.id)
    );

    addStudentsToRoster(selectedStudentsIds);
  };

  const resetAndClose = () => {
    setSelectedStudents([]);
    setFilteredStudents([]);
    setStudentFilter("");
    handleOpenModal(false);
  };

  return (
    <>
      <LightModal isOpenModal={openModal} handleOpenModal={handleOpenModal}>
        <Card>
          <CardHeader
            title="Add students to class"
            sx={{ mx: 1, fontWeight: 500 }}
          />
          <CardContent sx={{ mx: 1 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  marginBottom: 10,
                  paddingTop: 0,
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    id="student"
                    type="text"
                    placeholder="Search for student"
                    variant="standard"
                    disabled={false}
                    value={studentFilter}
                    onChange={handleFilterChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                  {filteredStudents.length > 0 && (
                    <FormGroup>
                      {filteredStudents.map((student) => (
                        <FormControlLabel
                          key={student.id}
                          control={
                            <Checkbox
                              value={student.id}
                              name="student-add-roster"
                              onChange={handleStudentChecked}
                              checked={selectedStudents.includes(student)}
                            />
                          }
                          label={getFullName(student)}
                        />
                      ))}
                    </FormGroup>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ padding: 0, margin: 0, marginTop: 10 }}>
            <Grid
              container
              spacing="0"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "1rem" }}
            >
              {isSaving && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              )}
              <Grid item xs={6}>
                <Button
                  onClick={resetAndClose}
                  style={styles.formButton}
                  size="large"
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  style={styles.formButton}
                  size="large"
                  variant="contained"
                  type="submit"
                  fullWidth
                  onClick={handleSubmit}
                >
                  Add Students
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </LightModal>
    </>
  );
};
