import { FC, ReactElement, useEffect, useState } from "react";
import { getAttendanceByClass } from "../../Services/AttendanceService";

import {
  Box,
  LinearProgress,
  Alert,
  Grid,
  TextField,
  InputAdornment,
  AlertColor,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import "./AttendanceListClassComponent.css";

import moment from "moment";

import { Class } from "../../Interfaces/ClassInterface";
import { DayType, Period } from "../../Interfaces/ScheduleInterface";
import { Attendance } from "../../Interfaces/AttendanceInterface";

interface IAttendanceListClass {
  lastReload: Date | null;
  classId: number | null | undefined;
}

const styles = {
  searchContainer: {
    background: "#3C404E",
    padding: "10px",
    height: 50,
    width: "100%",
    borderRadius: "8px 8px 0px 0px ",
  },
};

/**
 *This component is used to display the list
 * of students which that are registered in a certain class (Kiosk Mode)
 * @param param0
 * @returns
 */
export const AttendanceListClassComponent: FC<IAttendanceListClass> = ({
  classId,
  lastReload,
}): ReactElement => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("sm"));

  const [data, setData] = useState<ReportAttendanceByCurrentClass[]>([]);
  const [dataFiltered, setDataFiltered] = useState<
    ReportAttendanceByCurrentClass[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [studentFilter, setStudentFilter] = useState<string>("");

  useEffect(() => {
    if (classId) loadData(classId);
  }, [classId, lastReload]);

  function filter(e: React.ChangeEvent<HTMLInputElement>) {
    const keyword = e.target.value.trim().toLowerCase();
    setStudentFilter(keyword);
    filterStudents(keyword);
  }

  function filterStudents(filterTerm: string) {
    const filtered = data.filter(({ first_name, last_name }) =>
      `${first_name} ${last_name}`.toLowerCase().includes(filterTerm)
    );
    setDataFiltered(filtered);
  }

  function loadData(classId: number | null) {
    console.log(classId);
    setLoading(true);

    const fetchData = async () => {
      return await getAttendanceByClass(classId);
    };

    fetchData()
      .then((result: ReportAttendanceByCurrentClass[]) => {
        //console.log(result);
        const attendanceOrdered = result.sort((s1, s2) => {
          const s1Moment = moment(s1.attendance_scan_date);
          const s2Moment = moment(s2.attendance_scan_date);

          if (s1Moment.isAfter(s2Moment)) {
            return -1;
          } else if (s1Moment.isBefore(s2Moment)) {
            return 1;
          } else {
            return 0;
          }
        });
        setData(attendanceOrdered);
        console.log("kiosk class id", classId)
        console.log("attendance kiosk", attendanceOrdered)
        setDataFiltered(result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
        setDataFiltered([]);
        setLoading(false);
      });
  }
  return (
    <>
      {/* search by name */}
      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={12}>
          <Box style={styles.searchContainer}>
            <TextField
              placeholder="Search by name"
              variant="standard"
              fullWidth
              onChange={filter}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {loading ? (
            <LinearProgress />
          ) : (
            <table className="attendance-list-studentclass">
              <tbody>
                {dataFiltered
                  .filter((x) => {
                    return x != null;
                  })
                  .map((x) => (
                    <tr key={x.id}>
                      <td className="studentName">
                        {x.first_name} {x.last_name}
                      </td>
                      <td className="class-status">
                        {x.statusAttendance == "Present" ? (
                          <div className="button-present">Present</div>
                        ) : (
                          <></>
                        )}
                        {x.statusAttendance == "Absent" ? (
                          <div className="button-absent">Absent</div>
                        ) : (
                          <></>
                        )}
                        {x.statusAttendance == "Tardy" ? (
                          <div className="button-tardy">Tardy</div>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export interface ReportAttendanceByCurrentClass {
  id: number;
  first_name: string;
  last_name: string;
  user_name: string;
  attendance_date: string;
  attendance_scan_date: string;
  statusAttendance: string;
}
