import { FC, ReactElement } from "react";
import { Box, LinearProgress, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";

import "./AdminSettingsComponent.css";

import ChangePasswordModal from "../AdminSettings/ChangePasswordModal";
import { RootState } from "../../store";
import { getUserByUsername } from "../../Services/UsersService";

import CustomAlert from "../CustomAlert/CustomAlert";

import {
  setPageTitle,
  setShowCustomAlert,
  setCustomAlertDetails,
} from "../../store/slices/main";

const AdminSettingsComponent: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const { currentUser } = useSelector((state: RootState) => state.main);
  const { user, loadingIndicator } = useSelector(
    (state: RootState) => state.user
  );

  React.useEffect(() => {
    dispatch(setPageTitle("Edit Profile"));
    // dispatch(setPageSubtitle("Subtitle"));
    dispatch(getUserByUsername(currentUser.user_name));
  }, []);

  async function handleChangePasswordModal() {
    setOpenChangePasswordModal((v) => !v);
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setCustomAlertDetails({}));
  }

  return (
    <>
      <Box>
        <Box className="default-loading">
          {loadingIndicator ? (
            <LinearProgress />
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <table className="table-photo-profile">
                  <tbody>
                    <tr>
                      <td className="td-photo-profile">
                        <Avatar
                          src={user?.profile_picture_encoded}
                          alt={user?.first_name}
                        ></Avatar>
                      </td>
                      <td className="td-button">
                        <Button
                          className="button-photo-profile"
                          variant="contained"
                        >
                          Change profile image
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid sx={{ padding: "10px" }} item xs={12} md={6}>
                <TextField
                  variant="standard"
                  fullWidth
                  id="email"
                  label="Email"
                  value={user?.email}
                />
              </Grid>
              <Grid sx={{ padding: "10px" }} item xs={12} md={6}>
                <TextField
                  variant="standard"
                  fullWidth
                  id="firstName"
                  label="First Name"
                  value={user?.first_name}
                />
              </Grid>
              <Grid sx={{ padding: "10px" }} item xs={12} md={6}>
                <TextField
                  variant="standard"
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  value={user?.last_name}
                />
              </Grid>
              <Grid sx={{ padding: "10px", textAlign: "left" }} item xs={12}>
                <div className="account-management">
                  <div className="title">Account Management</div>
                </div>
                <div className="button-change-pass-container">
                  <Button
                    onClick={handleChangePasswordModal}
                    className="button-change-pass"
                    variant="contained"
                  >
                    Change password
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      <ChangePasswordModal
        openModal={openChangePasswordModal}
        handleOpenModal={handleChangePasswordModal}
      ></ChangePasswordModal>
      <CustomAlert handleClose={handleCloseAlert} />
    </>
  );
};

export default AdminSettingsComponent;
