import { ReactElement, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { LayoutContainer } from "../Layout/layout.container";
import {
  AlertColor,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {
  createHallPassLocationRequest,
  fetchHallPasses,
  sentStudentToNextClass,
  sentStudentToToHallPass,
} from "../Services/HallPassService";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import { useWebSocket } from "../Hooks/useWebSocket";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import {
  getStudentsOnLocation,
  setErrorMessage,
  setSuccessMessage,
} from "../store/slices/hallPass.slice";
import {
  CreateStudentOnLocationDto,
  HallPassLocation,
  StudentOnLocation,
} from "../Interfaces/SchoolInterface";
import { CSSProperties } from "@mui/styles";
import { LightConfirmationDialog } from "../Components/ConfirmationDialog/LightConfirmationDialog";
import {
  AlertState,
  setCustomAlertDetails,
  setSelectedUser,
  setShowCustomAlert,
} from "../store/slices/main";
import { CustomAlert } from "../Components";
import { createQueueManagerByUserName, validateQueueManager } from "../Services/UsersService";

const styles = {
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    minHeight: "79vh",
    maxHeight: "79vh",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  createPassItem: {
    background: "#464B5B",
    borderRadius: "5px 5px 0px 0px",
  } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "auto",
    margin: 0,
  } as CSSProperties,
  divider: { borderColor: "#3C404E", background: "#3C404E" },
};

const TeacherHallPassLocations = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentAuthenticatedUser } = useCognitoAwsV2();
  const { currentSchool, selectedUser } = useSelector(
    (state: RootState) => state.main
  );
  // console.log('*** selected user***', selectedUser)
  const { attendanceCurrentClass } = useSelector(
    (state: RootState) => state.class_
  );
  const { hallPassLocations, loadingIndicator } = useSelector(
    (state: RootState) => state.hallPass
  );

  const { user } = useSelector((state: RootState) => state.user);

  const [openNextClassConfirmationModal, setOpenNextClassConfirmationModal] =
    useState(false);
  const [
    openPassRequestConfirmationModal,
    setOpenPassRequestConfirmationModal,
  ] = useState(false);
  const [savingIndicator, setSavingIndicator] = useState(false);
  const [hallPassLocation, setHallPassLocation] = useState<HallPassLocation>();

  useEffect(() => {
    // console.log("*current school*", currentSchool);
    // console.log('*hallPassLocations*', hallPassLocations)
    // console.log('*current class', attendanceCurrentClass)
    if (currentSchool.id) {
      dispatch(fetchHallPasses(currentSchool.id));
      
    }
  }, [currentSchool]);

  //WebSocket
  useWebSocket([
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        currentSchool.id && dispatch(fetchHallPasses(Number(currentSchool.id)));
      },
    },
  ]);

  
  

  function StudentsInLine(studentsOnLocations: StudentOnLocation[]) {
    const total = getStudentsOnLocation(studentsOnLocations).length;
    const color =
      total <= 1
        ? "rgba(122, 193, 66, 1)"
        : total <= 4
        ? "rgba(248, 147, 28, 1)"
        : "rgba(255, 78, 50, 1)";
    return (
      <>
        {total > 0 ? (
          <>
            <span>Students in Line:</span>
            <span
              style={{
                color: color,
                marginLeft: 5,
              }}
            >
              {total}
            </span>
          </>
        ) : (
          <span>No Students in Line</span>
        )}
      </>
    );
  }

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  

  async function hallPassRequest() {
    try {
      setSavingIndicator(true);
      console.log("selectedUser", selectedUser);

      // if (!attendanceCurrentClass || !attendanceCurrentClass?.id) {
      if (!attendanceCurrentClass) {
        throw new Error(
          "There is not a current class in progress at this time."
        );
      }

      const request: CreateStudentOnLocationDto = {
        location_id: Number(hallPassLocation?.id),
        class_id: Number(attendanceCurrentClass?.id),
        user_name: `${selectedUser.user_name}`,
      };
      const studentQueue = await validateQueueManager(selectedUser?.user_name?.toString() || "0")
      if(!studentQueue) {
      await createQueueManagerByUserName({studentId : selectedUser?.user_name?.toString() || "0", locationId : hallPassLocation?.id?.toString() || "0"})
      await dispatch(createHallPassLocationRequest(request));
      await sentStudentToToHallPass(Number(selectedUser?.id));
        let student = { ...selectedUser };
        student.sentToHallPass = true;
        dispatch(setSelectedUser(student));

        setSavingIndicator(false);
        setOpenPassRequestConfirmationModal(false);
        navigate(-1);
      } else {
        showAlert("The pass is not currently available, please check back again soon", "error");
        setSavingIndicator(false);
        setOpenPassRequestConfirmationModal(false);
      }
    } catch (error: any) {
      showAlert(error?.message, "error");
      setSavingIndicator(false);
      setOpenPassRequestConfirmationModal(false);
    }
  }

  async function sendToNextClass() {
    try {
      setSavingIndicator(true);
      await sentStudentToNextClass(Number(selectedUser?.id));
      let student = { ...selectedUser };
      student.sentToNextClass = true;
      dispatch(setSelectedUser(student));
    } catch (error) {
      console.error(error);
    } finally {
      setSavingIndicator(false);
      setOpenNextClassConfirmationModal(false);
      navigate(-1);
    }
  }

  function Locations(): ReactElement {
    return (
      // <>
      //   <ListItem
      //     style={{ background: "#7AC142" }}
      //     onClick={() => {
      //       setOpenNextClassConfirmationModal(true);
      //     }}
      //   >
      //     <ListItemButton sx={{ padding: 0, margin: 0 }}>
      //       <ListItemText primary="Send student to next class" />
      //       <IconButton edge="end" aria-label="menu">
      //         <ArrowForwardIos fontSize="small" />
      //       </IconButton>
      //     </ListItemButton>
      //   </ListItem>
      <>
      {user.group === "Staff" ? (
        <ListItem
          style={{ background: "#7AC142" }}
          onClick={() => {
            setOpenNextClassConfirmationModal(true);
          }}
        >
          <ListItemButton sx={{ padding: 0, margin: 0 }}>
            <ListItemText primary="Return student to class" />
            <IconButton edge="end" aria-label="menu">
              <ArrowForwardIos fontSize="small" />
            </IconButton>
          </ListItemButton>
        </ListItem>
      ) : (
        <ListItem
          style={{ background: "#7AC142" }}
          onClick={() => {
            setOpenNextClassConfirmationModal(true);
          }}
        >
          <ListItemButton sx={{ padding: 0, margin: 0 }}>
            <ListItemText primary="Send student to next class" />
            <IconButton edge="end" aria-label="menu">
              <ArrowForwardIos fontSize="small" />
            </IconButton>
          </ListItemButton>
        </ListItem>
      )}
        {hallPassLocations?.map((item, index) => (
          <div key={index.toString()}>
            <ListItem
              style={styles.listItem}
              onClick={() => {
                setHallPassLocation(item);
                setOpenPassRequestConfirmationModal(true);
              }}
            >
              <ListItemButton sx={{ padding: 0, margin: 0 }}>
                <ListItemText
                  primary={item.name}
                  secondary={StudentsInLine(
                    item.StudentsOnLocations as StudentOnLocation[]
                  )}
                />
                <IconButton edge="end" aria-label="menu">
                  <ArrowForwardIos fontSize="small" />
                </IconButton>
              </ListItemButton>
            </ListItem>
            <Divider style={styles.divider} />
          </div>
        ))}
      </>
    );
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  return (
    <>
      <LayoutContainer isRaised={true}>
        <List style={styles.list}>
          <ListItem style={styles.createPassItem}>
            <ListItemText primary="Request a Hall Pass" />
          </ListItem>
          {/* {selectedUser.sentToNextClass || selectedUser.sentToHallPass ? ( */}
            {/* {selectedUser.sentToNextClass  ? ( */}
            {/* <Box style={{ marginTop: 20 }}> */}
              {/* The student is already in another location */}
            {/* </Box> */}
          {/* ) : ( */}
            {/* <Locations /> */}
          {/* )} */}
          <Locations />
        </List>
        {/* Send to next class confirmation modal */}
        <LightConfirmationDialog
          open={openNextClassConfirmationModal}
          onCancel={() => {
            setOpenNextClassConfirmationModal(false);
            navigate(-1);
          }}
          onConfirm={sendToNextClass}
          title="Are you sure you want to create a pass?"
          message="Confirm you want to create a pass for this student"
          savingIndicator={savingIndicator}
          okText="Yes"
          cancelText="No"
        />

        {/* Create Student Pass confirmation modal */}
        <LightConfirmationDialog
          open={openPassRequestConfirmationModal}
          onCancel={() => {
            setOpenPassRequestConfirmationModal(false);
            navigate(-1);
          }}
          onConfirm={hallPassRequest}
          title="Are you sure you want to create a pass?"
          message="Confirm you want to create a pass for this student"
          savingIndicator={savingIndicator}
          okText="Yes"
          cancelText="No"
        />
        <CustomAlert handleClose={handleCloseAlert} />
      </LayoutContainer>
    </>
  );
};

export default TeacherHallPassLocations;
