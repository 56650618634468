import { FrecuencyType } from "./ScheduleInterface";

export interface CalendarFormError {
  name?: string;
}

export enum DateTypeEnum {
  Holiday = "Holiday",
  Schoolday = "Schoolday",
}

export type Frequency = {
  id?: number;
  start_time: Date;
  end_time: Date;
  frecuency_type: FrecuencyType;
  details: string;
};

export type Calendar = {
  id?: number;
  selected_date: Date;
  day_type_enum: DateTypeEnum;
  school_id: number;
  day_type_id?: number;
  frequency : Frequency
};
