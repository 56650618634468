import { createTheme, ThemeProvider } from "@mui/material/styles";
import RouterApp from "./Routes";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Layout } from "./Layout/layout.component";
import { Provider } from "react-redux";
import { store } from "./store";

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "Gotham",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#7AC142",
    },
    background: {
      default: "rgba(42, 45, 56, 1)",
    },
    secondary: {
      main: "#FF4E32",
    },
  },
});

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Layout>
            <RouterApp />
          </Layout>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
