import { FC, ReactElement } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Logo } from "../../Components";

import { Grid } from "@mui/material";

import "./layout-sign.css";

interface LayaoutProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export const LayoutSign: FC<LayaoutProps> = (props): ReactElement => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {/* principal grid */}
      <Grid container component="main" sx={{ height: "100vh" }} spacing={0}>
        {/* item grid for from */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className="sign-container">
            {downMd ? (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Logo></Logo>
              </Grid>
            ) : (
              ""
            )}
            <Grid
              sx={{ height: "100vh" }}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <div className="sign-container-form">{props.children}</div>
            </Grid>
          </div>
        </Grid>
        {/* item grid for logo on desktop devices */}
        {!downMd ? (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Logo></Logo>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};
