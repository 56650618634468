import { createAsyncThunk } from "@reduxjs/toolkit";
import { Class } from "../Interfaces/ClassInterface";
import ApiService from "./apiService";

import moment from "moment";

const baseURL = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE === "local"
    ? true
    : false
  : true;
const classURL = `${baseURL ? "classes" : ""}/classes/classes/`;

export const fetchClasses = createAsyncThunk(
  "class/fetchClasses",
  async (school_id: number) => {
    const result: any = await ApiService.get<Class[]>(
      `${classURL}${school_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const fetchAllBySchoolIdAndTeacherUsername = createAsyncThunk(
  "class/fetchAllBySchoolIdAndTeacherUsername",
  async ({ school_id, user_name }: any) => {
    const result: any = await ApiService.get<Class>(
      `${classURL}school/${school_id}/teacher/${user_name}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const getClassById = createAsyncThunk(
  "class/classById",
  async (class_id: number) => {
    const result: any = await ApiService.get<Class>(
      `${classURL}class/${class_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const createClass = createAsyncThunk(
  "class/createClass",
  async (class_: Class) => {
    const result: any = await ApiService.post<Class>(classURL, class_);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export async function createMultipleClass(classes: Class[]) {
  const result: any = await ApiService.post<Class[]>(
    `${classURL}multipleclasses/user`,
    classes
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result.response?.response?.data?.error);
}

// classes/userteacher/:id/:school_id/:s_date

export async function getClassesByTeacherAndDate(
  user_id: number,
  school_id: number,
  date: Date
) {
  const selectedDate = moment(date).format("YYYY-MM-DD");
  const result: any = await ApiService.get<Class[]>(
    `${classURL}userteacher/${user_id}/${school_id}/${selectedDate}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export const createClassWithUser = createAsyncThunk(
  "class/createClassWithUser",
  async (class_: Class) => {
    const result: any = await ApiService.post<Class>(`${classURL}user`, class_);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const updateClass = createAsyncThunk(
  "class/updateClass",
  async (class_: Class) => {
    const result: any = await ApiService.put<Class>(
      `${classURL}${class_.id}`,
      class_
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const deleteClass = createAsyncThunk(
  "class/deleteClass",
  async (class_id: number) => {
    const result: any = await ApiService.delete<Class>(
      `${classURL}${class_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const fetchAttendanceClassByPeriodAndDayType = createAsyncThunk(
  "class/fetchAttendanceClassByPeriodAndDayType",
  async ({ period_id, day_type_id }: any) => {
    const result: any = await ApiService.get<Class[]>(
      classURL + "classesdata/" + period_id + "/" + day_type_id
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export const fetchCurrentClassByRoomId = createAsyncThunk(
  "class/getCurrentClassByRoomId",
  async (room_id: number) => {
    const result: any = await ApiService.get<Class>(
      `${classURL}class/room/${room_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export async function getCurrentClassByTeacherId(
  user_id?: number,
  school_id?: number
): Promise<any> {
  const result: any = await ApiService.get<any>(
    `${classURL}userteacher/${user_id}/${school_id}`
  );
  if (result?.success) {
    console.log(result.data);
    return result.data;
  }
  console.log(result?.message);
  throw new Error(result?.message);
}

export async function getCurrentClassByStudent(
  user_name: string
): Promise<Class> {
  const result: any = await ApiService.get<Class>(
    `${classURL}class/student/${user_name}`
  );
  if (result?.success) {
    return result.data;
  }
  console.log(result?.message);
  throw new Error(result?.message);
}

export async function removeClassSchedule(class_id: number) {
  const result: any = await ApiService.delete(
    `${classURL}schedule/class/${class_id}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result.response?.response?.data?.error);
}

export async function getAttendanceClassByPeriodAndDayType(
  period_id: any, day_type_id: any
  ): Promise<Class> {
    const result: any = await ApiService.get<Class[]>(
      classURL + "classesdata/" + period_id + "/" + day_type_id
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
}

export async function getClassId(
  class_id: number
  ): Promise<Class> {
    const result: any = await ApiService.get<Class[]>(
      `${classURL}class/${class_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
}