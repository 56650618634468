import axios from "axios";
import appConfig from "../Config/appConfig";
import { Auth } from "aws-amplify";

const ApiServiceResponse = (response: any) => ({
  success: Boolean(response),
  data: response.data,
  response: response,
});

const ApiServiceErrorResponse = (response: any) => {
  return {
    success: false,
    message: response.response.data.message,
    response: response,
    statusCode: response.response.data.statusCode
  };
};

const ApiService = axios.create({
  baseURL: `${appConfig.api.ENDPOINT}`,
});

ApiService.interceptors.request.use(
  async (config) => {
    const token = await getJwtToken();
    if (config && token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

async function getJwtToken(): Promise<string> {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const jwtToken = currentUser?.signInUserSession?.accessToken?.jwtToken;
    return jwtToken;
  } catch (err) {
    console.log(err);
    return "";
  }
}

ApiService.interceptors.response.use(
  (response) => ApiServiceResponse(response),
  (error) => {
    console.warn("api: ", error);
    return ApiServiceErrorResponse(error);
  }
);

export default ApiService;
