import { ReactElement, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { LayoutContainer } from "../Layout/layout.container";
import { createQueueManagerByUserName, validateQueueManager } from "../Services/UsersService";
import {
  AlertColor,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  createHallPassLocationRequest,
  createHallPassLocationRequestSkipLine,
  fetchHallPasses,
  sentStudentToNextClass,
  sentStudentToToHallPass,
} from "../Services/HallPassService";
import { useWebSocket } from "../Hooks/useWebSocket";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import {
  getStudentsOnLocation,
  setErrorMessage,
  setSuccessMessage,
} from "../store/slices/hallPass.slice";
import {
  CreateStudentOnLocationDto,
  HallPassLocation,
  StudentOnLocation,
} from "../Interfaces/SchoolInterface";
import { CSSProperties } from "@mui/styles";
import { LightConfirmationDialog } from "../Components/ConfirmationDialog/LightConfirmationDialog";
import {
  AlertState,
  setCustomAlertDetails,
  setSelectedUser,
  setShowCustomAlert,
} from "../store/slices/main";
import { CustomAlert } from "../Components";

const styles = {
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    minHeight: "79vh",
    maxHeight: "79vh",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  createPassItem: {
    background: "#3C404E",
    borderRadius: "5px 5px 0px 0px",
   
  } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "auto",
    margin: 0,
  } as CSSProperties,
  divider: { borderColor: "#3C404E", background: "#3C404E" },
};

const KioskStudentHallPassLocations = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentSchool, selectedUser } = useSelector(
    (state: RootState) => state.main
  );
  // console.log('*** selected user***', selectedUser)
  const { attendanceCurrentClass } = useSelector(
    (state: RootState) => state.class_
  );
  const { hallPassLocations, loadingIndicator } = useSelector(
    (state: RootState) => state.hallPass
  );

  const [openNextClassConfirmationModal, setOpenNextClassConfirmationModal] =
    useState(false);
  const [
    openPassRequestConfirmationModal,
    setOpenPassRequestConfirmationModal,
  ] = useState(false);
  const [savingIndicator, setSavingIndicator] = useState(false);
  const [hallPassLocation, setHallPassLocation] = useState<HallPassLocation>();

  useEffect(() => {
    if (currentSchool.id) {
      dispatch(fetchHallPasses(currentSchool.id));
    }
  }, [currentSchool]);

  //WebSocket
  useWebSocket([
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        currentSchool.id && dispatch(fetchHallPasses(Number(currentSchool.id)));
      },
    },
  ]);

  

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  async function hallPassRequest() {
    try {
      setSavingIndicator(true);
      console.log('*** selected user***', selectedUser)
      if (!attendanceCurrentClass) {
        throw new Error(
          "There is not a current class in progress at this time."
        );
      }

      const request: CreateStudentOnLocationDto = {
        location_id: Number(hallPassLocation?.id),
        class_id: Number(attendanceCurrentClass?.id),
        user_name: `${selectedUser.user_name}`,
      };
      const studentQueue = await validateQueueManager(selectedUser?.user_name.toString() || "0")
      if(!studentQueue) {
        await createQueueManagerByUserName({studentId : selectedUser?.user_name?.toString() || "0", locationId : hallPassLocation?.id?.toString() || "0"})
        await dispatch(createHallPassLocationRequestSkipLine(request));
        await sentStudentToToHallPass(Number(selectedUser?.id));
        let student = { ...selectedUser };
        student.sentToHallPass = true;
        dispatch(setSelectedUser(student));

        setSavingIndicator(false);
        setOpenPassRequestConfirmationModal(false);
        showAlert("Hall pass created successfully", "success");
        setTimeout(() => {
          handleCloseAlert();
        }, 3000);
        navigate(-1);
      }else {
        showAlert("The pass is not currently available, please check back again soon", "error");
        setSavingIndicator(false);
        setOpenPassRequestConfirmationModal(false);
      }
    } catch (error: any) {
      showAlert(error?.message, "error");
      setSavingIndicator(false);
      setOpenPassRequestConfirmationModal(false);
    }
  }

  

  function Locations(): ReactElement {
    return (
      <>
        <Divider style={styles.divider} />
        {hallPassLocations?.map((item, index) => (
          <div key={index.toString()}>
            <ListItem
              style={styles.listItem}
              onClick={() => {
                setHallPassLocation(item);
                setOpenPassRequestConfirmationModal(true);
              }}
            >
              <ListItemButton sx={{ padding: 0, margin: 0 }}>
                <ListItemText
                  primary={item.name}
                />
                <IconButton edge="end" aria-label="menu">
                  <ArrowForwardIos fontSize="small" />
                </IconButton>
              </ListItemButton>
            </ListItem>
            <Divider style={styles.divider} />
          </div>
        ))}
      </>
    );
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  return (
    <>
      <LayoutContainer isRaised={true}>
        <List style={styles.list}>
          <ListItem style={styles.createPassItem}>
            <Typography
              component="div"
              align="left"
              variant="subtitle2"
              style={{ fontSize: "13px", lineHeight: "40px", fontWeight: 500, color: "#F9F9F9" }}
              >
              Request a Hall Pass
            </Typography>
          </ListItem>
          <Locations />
        </List>
        {/* Send to next class confirmation modal */}
        {/* <LightConfirmationDialog
          open={openNextClassConfirmationModal}
          onCancel={() => {
            setOpenNextClassConfirmationModal(false);
            navigate(-1);
          }}
          onConfirm={sendToNextClass}
          title="Are you sure you want to create a pass?"
          message="Confirm you want to create a pass for this student"
          savingIndicator={savingIndicator}
          okText="Yes"
          cancelText="No"
        /> */}

        {/* Create Student Pass confirmation modal */}
        <LightConfirmationDialog
          open={openPassRequestConfirmationModal}
          onCancel={() => {
            setOpenPassRequestConfirmationModal(false);
            navigate(-1);
          }}
          onConfirm={hallPassRequest}
          title="Are you sure you want to create a pass?"
          message="Confirm you want to create a pass for this student"
          savingIndicator={savingIndicator}
          okText="Yes"
          cancelText="No"
        />
        <CustomAlert handleClose={handleCloseAlert} />
      </LayoutContainer>
    </>
  );
};

export default KioskStudentHallPassLocations;