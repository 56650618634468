import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
    },
};

const PageLoading = (props: any): any => {

    const { classes } = props;

    return (
        <div className={classes.root}>
            <CircularProgress color="secondary" style={{background: "rgba(0,0,0,0.75)",}} />
        </div>
    );
};

export default withStyles(styles)(PageLoading);