import { Container } from '@mui/material'
import { FC, ReactElement, ReactNode } from 'react';

const styles = {
    container: {
        background: '#2A2D38',
        padding: 0,
        marginTop: 0,
        height: '100%',
    },
}

interface LayoutProps {
    children: ReactNode | ReactNode[]
}

export const LayoutMobile: FC<LayoutProps> = (props): ReactElement => {
    return (
        <Container component="main" maxWidth={false}  style={styles.container}>
            {props.children}
        </Container>
    )
}
