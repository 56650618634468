export enum FrecuencyType{
  EveryXDays = "EveryXDays",
  DaysOFWeek = "DaysOFWeek",
  NonRecurring = "NonRecurring"
}

export interface DayTypeFormError {
  name?: string;
  frecuency_type?: FrecuencyType;
  details?: string;
}

export interface PeriodFormError {
  name?: string;
  start_time?: string;
  end_time?: string;
}

export interface DayType {
  id?: number;
  name: string;
  // frecuency_type: FrecuencyType;
  school_id: number
  // details: string;
  Periods?: Period[];
}

export interface Period {
  id?: number;
  name: string;
  day_type_id: number;
  start_time: Date | string;
  end_time: Date | string;
}
