import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { useState } from "react";
import { useCognitoAwsV2 } from "../../Hooks/useCognitoAwsv2";
import MenuItem from "../../Interfaces/MenuItem";

import { Link } from "react-router-dom";

interface SideMenuOptionsProps {
  itemList?: MenuItem[];
  onOptionSelected: () => void;
}

export const SideMenuOptions = (props: SideMenuOptionsProps) => {
  const { itemList, onOptionSelected } = props;
  const { getSessionGroups, isSessionValid } = useCognitoAwsV2();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userGroups, setUserGroups] = useState<string[]>([]);

  React.useEffect(() => {
    if (isLoading == true) {
      checkAuthorizedUser();
    }
  }, [isLoading]);

  async function checkAuthorizedUser(): Promise<void> {
    const isValid = await isSessionValid();
    if (isValid) {
      const groups = await getSessionGroups();
      if (groups && groups.length >= 0) {
        setUserGroups(groups);
      }
    } else {
      console.log("No tiene grupos asociados");
    }
    setIsLoading(false);
  }

  function handleLinkItem({ authGroups }: MenuItem): boolean {
    return (
      userGroups &&
      userGroups.length > 0 &&
      userGroups.some((el) => authGroups.includes(el))
    );
  }
  return (
    <Box role="presentation">
      {!isLoading ? (
        <List>
          {itemList?.map((menuItem: MenuItem, index: number) => (
            <Box key={index}>
              {handleLinkItem(menuItem) && (
                <Link
                  style={{ color: "white", textDecoration: "none" }}
                  to={menuItem.route}
                  onClick={onOptionSelected}
                >
                  <ListItem button key={menuItem.id}>
                    <ListItemIcon>{menuItem.icon}</ListItemIcon>
                    <ListItemText primary={menuItem.name} />
                  </ListItem>
                </Link>
              )}
            </Box>
          ))}
        </List>
      ) : (
        <div>Loading</div>
      )}
    </Box>
  );
};
