import {
  Box,
  CssBaseline,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutContainer } from "../Layout/layout.container";
import { RootState } from "../store";
import { CSSProperties } from "@mui/styles";
import { PrintOutlined } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Class } from "../Interfaces/ClassInterface";
import appConfig from "../Config/appConfig";
import { setPageSubtitle, setPageTitle } from "../store/slices/main";
import { fetchAllBySchoolIdAndTeacherUsername } from "../Services/ClassService";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const pdfMake = require("pdfmake");

const styles = {
  listContainer: {
    borderRadius: "5px 5px 0 0",
    overflow: "auto",
    marginTop: "15px",
    textAlign: "center",
    zIndex: 3,
  } as CSSProperties,
  loading: {
    width: "100%",
    bottom: 0,
    position: "fixed",
    left: 0,
    right: 0,
  } as CSSProperties,
  list: { padding: 0 } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "67px",
    borderRadius: "5px",
    marginBottom: "5px",
  } as CSSProperties,
  roomTitle: {
    color: "rgba(255, 255, 255, 0.7)",
    fontWeight: 400,
    fontSize: 13,
    marginRight: 3,
  },
  roomValue: {
    fontWeight: 400,
    fontSize: 13,
  },
  classValue: {
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: 15,
  },
};

pdfMake.fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
  },
};

const RoomManagementPage: FC = (): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const { currentSchool, currentUser } = useSelector((state: RootState) => state.main);
  const { classes, loadingIndicator } = useSelector(
    (state: RootState) => state.class_
  );

  useEffect(() => {
    dispatch(setPageTitle(" "));
    dispatch(setPageSubtitle(" "));
  }, []);

  useEffect(() => {
    if (currentSchool.id) {
      dispatch(fetchAllBySchoolIdAndTeacherUsername({school_id: currentSchool.id, user_name: currentUser.user_name} as any));
    }
  }, [currentSchool]);

  styles.listContainer = {
    ...styles.listContainer,
    maxHeight: isMobile ? "60vh" : "50vh",
    minHeight: isMobile ? "60vh" : "50vh",
  };

  function colDefinition(url: string, room: string, className: string) {
    return [{ qr: url, fit: 80 }, { text: `Room: ${room}` }];
  }

  function docDefinition(url: string, room: string, className: string) {
    return {
      pageSize: "A4",
      pageMargins: [40, 40, 10, 10],
      alignment: "justify",
      content: [
        [...Array(8)].map(() => ({
          alignment: "left",
          margin: [0, 10],
          columnGap: 15,
          columns: [
            colDefinition(url, room, className),
            colDefinition(url, room, className),
            colDefinition(url, room, className),
            colDefinition(url, room, className),
            colDefinition(url, room, className),
            colDefinition(url, room, className),
          ],
        })),
      ],
    };
  }

  function handleClickItem(item: Class) {
    const url = `${appConfig.api.WEB}/school/${currentSchool.id}/room/${item.room_id}`;
    const title = `Room: ${item.room?.name}`;

    pdfMake
      .createPdf(docDefinition(url, `${item.room?.name}`, item.name))
      .download(`${title}.pdf`);
  }

  // const uniqueRooms = Object.values(
  //   classes.reduce((acc, curr) => {
  //     return acc[curr.room?.name]
  //       ? acc
  //       : { ...acc, [curr.room?.name]: curr };
  //   }, {})
  // );

  // const uniqueRooms = Object.values(
  //   classes.reduce((acc, curr) => {
  //     const roomName = curr.room?.name;
  //     if (roomName) {
  //       return acc[roomName]
  //         ? acc
  //         : { ...acc, [roomName]: curr };
  //     } else {
  //       return acc;
  //     }
  //   }, {})
  // );

  return (
    <>
      {!isMobile ? <div style={{ marginTop: "-80px" }}></div> : <div></div>}
      <CssBaseline />
      <LayoutContainer isRaised={true}>
        <Box>
          <Typography>Print QR Code</Typography>
        </Box>
        <Box style={styles.listContainer}>
          {loadingIndicator ? (
            <Box style={styles.loading}>
              <LinearProgress />
            </Box>
          ) : (
            // <List style={styles.list}>
            //   {classes?.map((item, index) => (
            //     <div key={index.toString()}>
            //       <ListItem style={styles.listItem}>
            //         <ListItemText
            //           primary={
            //             <React.Fragment>
            //               <span style={styles.roomTitle}>Room</span>
            //               <span style={styles.roomValue}>
            //                 {item.room?.name}
            //               </span>
            //             </React.Fragment>
            //           }
            //           secondary={
            //             <React.Fragment>
            //               <span style={styles.classValue}>{item.name}</span>
            //             </React.Fragment>
            //           }
            //         />
            //         <IconButton
            //           edge="end"
            //           aria-label="print"
            //           color="primary"
            //           size="small"
            //           style={{ backgroundColor: "#2A2D38" }}
            //           onClick={() => handleClickItem(item)}
            //         >
            //           <PrintOutlined />
            //         </IconButton>
            //       </ListItem>
            //     </div>
            //   ))}
            // </List>
              <List style={styles.list}>
                {classes
                  .filter((item, index, self) =>
                    index === self.findIndex((t) => t.room?.name === item.room?.name)
                  )
                  .map((item, index) => (
                    <div key={index.toString()}>
                      <ListItem style={styles.listItem}>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <span style={styles.roomTitle}>Room</span>
                              <span style={styles.roomValue}>{item.room?.name}</span>
                            </React.Fragment>
                          }
                          // secondary={
                          //   <React.Fragment>
                          //     <span style={styles.classValue}>{item.name}</span>
                          //   </React.Fragment>
                          // }
                        />
                        <IconButton
                          edge="end"
                          aria-label="print"
                          color="primary"
                          size="small"
                          style={{ backgroundColor: "#2A2D38" }}
                          onClick={() => handleClickItem(item)}
                        >
                          <PrintOutlined />
                        </IconButton>
                      </ListItem>
                    </div>
                  ))}
              </List>
          )}
        </Box>
      </LayoutContainer>
    </>
  );
};

export default RoomManagementPage;
