import { FC, ReactElement, useEffect, useState } from "react";
import { LayoutSign } from "../Layout/layout-sign/layout-sign";
import { LayoutContainer } from "../Layout/layout.container";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { createStudentUser } from "../Services/UsersService";
import { GroupEnum, User } from "../Interfaces/UserInterface";
import {
  EmailOutlined,
  LockOutlined,
  PersonOutlineOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import BadgeIcon from "@mui/icons-material/Badge";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import {
  Alert,
  Box,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { CustomButton } from "../Components";

import * as yup from "yup";

import { Auth } from "aws-amplify";
import { yupResolver } from "@hookform/resolvers/yup";
import { School } from "../Interfaces/SchoolInterface";
import { RootState } from "../store";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import { CURRENT_SCHOOL, emailRegex } from "../Helpers/Constants";

const styles = {
  boxStyle: {
    display: "flex",
    alignItems: "flex-end",
    mt: 3,
  },
  iconStyle: {
    color: "rgba(185,187,190,255)",
    mr: 1,
    my: 0.5,
  },
};

interface IFormInputs {
  firstName: string;
  lastName: string;
  email: string;
  studentIdNumber: string;
  grade: string;
  pass: string;
  confirm: string;
}

export const StudentRegistration: FC<any> = (): ReactElement => {
  const dispatch = useDispatch();
  const { error, signIn } = useCognitoAwsV2();
  const [loading, setLoading] = useState<boolean>(false);
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const [studentEmail, setStudentEmail] = useState<string>("");
  const [studentPass, setStudentPass] = useState<string>("");
  const [showPasswordNew, setShowPasswordNew] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] =
    useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IFormInputs>({
    firstName: "",
    lastName: "",
    email: "",
    studentIdNumber: "",
    grade: "",
    pass: "",
    confirm: "",
  });

  const [schoolId, setSchoolId] = useState<string | null>("");

  useEffect(() => {
    setSchoolId(window.localStorage.getItem(CURRENT_SCHOOL));
  }, []);

  //store
  const { savingIndicator, errorMessage, successMessage } = useSelector(
    (state: RootState) => state.user
  );

  const schema = yup.object().shape({
    firstName: yup.string().required("This field is required"),
    lastName: yup.string().required("This field is required"),
    studentIdNumber: yup.string().required("This field is required"),
    grade: yup.string().required("This field is required").max(4),
    email: yup
      .string()
      .matches(emailRegex, "Invalid email format")
      .required("This field is required"),
    // currentPass: yup.string().required("This field is required"),
    pass: yup
      .string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/,
        "The password must be 8 digits, some capital letters and a special character"
      )
      .required("This field is required"),
    confirm: yup
      .string()
      .oneOf([yup.ref("pass"), null], "Passwords must match")
      .required("This field is required"),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const dispach = useDispatch();

  const formSubmitHandler: SubmitHandler<any> = async (data: IFormInputs) => {
    try {
      setLoading(true);

      if (schoolId) {
        const school: School = {
          id: Number(schoolId),
          name: "",
          district_id: NaN,
        };
        const user: User = {
          email: data.email,
          first_name: data.firstName,
          last_name: data.lastName,
          user_name: "",
          student_id_number: data.studentIdNumber,
          grade: data.grade,
          group: GroupEnum.Student,
          schools: [school],
          password: data.pass,
        };
        setStudentEmail(data.email);
        setStudentPass(data.pass);
        await dispach(createStudentUser(user));
      } else {
        //TODO
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (successMessage && successMessage !== "") {
      singInStudent();
    }
    if (errorMessage && errorMessage !== "") {
      setLoading(false);
    }
  }, [savingIndicator]);

  async function singInStudent() {
    Auth.configure({
      storage: window.sessionStorage,
    });
    await signIn(studentEmail, studentPass);
  }

  useEffect(() => {
    const formNewData = {
      firstName: "",
      lastName: "",
      email: "",
      studentIdNumber: "",
      grade: "",
      pass: "",
      confirm: "",
    };

    setInitialValues(formNewData);
    reset(formNewData);
  }, []);

  return (
    <>
      <LayoutSign
        children={
          <div>
            <LayoutContainer>
              <Typography component="h2" variant="h5" sx={{ mt: -10 }}>
                Student
              </Typography>
              <Typography component="h2" variant="h5">
                Registration
              </Typography>
              <form
                style={{ marginTop: 60 }}
                onSubmit={handleSubmit(formSubmitHandler)}
              >
                <Box sx={styles.boxStyle}>
                  <PersonOutlineOutlined sx={styles.iconStyle} />
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue={initialValues.firstName}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="firstName"
                        label="First Name"
                        variant="standard"
                        error={!!errors.firstName}
                        helperText={
                          errors.firstName ? errors.firstName?.message : ""
                        }
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box sx={styles.boxStyle}>
                  <PersonOutlineOutlined sx={styles.iconStyle} />
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue={initialValues.lastName}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="lastName"
                        label="Last Name"
                        variant="standard"
                        error={!!errors.lastName}
                        helperText={
                          errors.lastName ? errors.lastName?.message : ""
                        }
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box sx={styles.boxStyle}>
                  <EmailOutlined sx={styles.iconStyle} />
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={initialValues.email}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="email"
                        type="email"
                        label="Email"
                        variant="standard"
                        error={!!errors.email}
                        helperText={errors.email ? errors.email?.message : ""}
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box sx={styles.boxStyle}>
                  <BadgeIcon sx={styles.iconStyle} />
                  <Controller
                    name="studentIdNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="studentIdNumber"
                        type={"text"}
                        label="Student ID Number"
                        variant="standard"
                        error={!!errors.studentIdNumber}
                        helperText={
                          errors.studentIdNumber
                            ? errors.studentIdNumber?.message
                            : ""
                        }
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box sx={styles.boxStyle}>
                  <LinearScaleIcon sx={styles.iconStyle} />
                  <Controller
                    name="grade"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="grade"
                        type={"number"}
                        label="Grade"
                        variant="standard"
                        error={!!errors.grade}
                        helperText={errors.grade ? errors.grade?.message : ""}
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box sx={styles.boxStyle}>
                  <LockOutlined sx={styles.iconStyle} />
                  <Controller
                    name="pass"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="pass"
                        type={showPasswordNew ? "text" : "password"}
                        label="New Password"
                        variant="standard"
                        error={!!errors.pass}
                        helperText={errors.pass ? errors.pass?.message : ""}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={() =>
                                setShowPasswordNew(!showPasswordNew)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {showPasswordNew ? (
                                <VisibilityOffOutlined color="disabled" />
                              ) : (
                                <VisibilityOutlined color="disabled" />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
                <Box sx={styles.boxStyle}>
                  <LockOutlined sx={styles.iconStyle} />
                  <Controller
                    name="confirm"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="confirm"
                        label="Confirm New Password"
                        type={showPasswordConfirm ? "text" : "password"}
                        variant="standard"
                        error={!!errors.confirm}
                        helperText={
                          errors.confirm ? errors.confirm?.message : ""
                        }
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={() =>
                                setShowPasswordConfirm(!showPasswordConfirm)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {showPasswordConfirm ? (
                                <VisibilityOffOutlined color="disabled" />
                              ) : (
                                <VisibilityOutlined color="disabled" />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
                <Stack sx={{ width: "100%", marginTop: 3 }} spacing={2}>
                  {errorMessage && (
                    <Alert severity="error">{errorMessage}</Alert>
                  )}
                </Stack>
                <CustomButton
                  disabled={loading}
                  text="Register"
                  type="submit"
                  sx={{ mt: 5, mb: 1 }}
                />
                {loading && (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
              </form>
            </LayoutContainer>
          </div>
        }
      ></LayoutSign>
    </>
  );
};
export default StudentRegistration;
