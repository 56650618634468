import { CircularProgress, Typography } from "@mui/material";
import { FC, ReactElement, useState, useEffect } from "react";
import { LayoutSign } from "../Layout/layout-sign/layout-sign";
import { LayoutContainer } from "../Layout/layout.container";
import { accessClassLink, accessClassLinkOauth2 } from "../Services/UsersService";
import { Auth } from "aws-amplify";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import { useNavigate } from "react-router-dom";

export const ProcessClassLink: FC<any> = (): ReactElement => {
  const { signIn } = useCognitoAwsV2();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const code = urlSearchParams.get("code");
      const responseType = urlSearchParams.get("response_type");
  
      if (code && responseType) {
        try {
          const userClassLink: any = await accessClassLinkOauth2(code);
          const codeClassLink = await accessClassLink(userClassLink);
          Auth.configure({
            storage: window.localStorage,
          });
          await signIn(userClassLink.Email, codeClassLink);
        } catch (error) {
          console.error("Error fetching user info:", error);
          setMessage("Error: user not found");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
      }
    };
    fetchData();
  }, []); 
  

  return (
    <>
      <LayoutSign
        children={
          <LayoutContainer sx={{ marginTop: "7rem" }}>
            {message ? (
              <div>
                <Typography component="h2" variant="h5">
                  {message}
                </Typography>
              </div>
            ) : (<>
              <Typography component="h2" variant="h5">
                Authenticating
              </Typography>
              <CircularProgress />
              </>
            )}
          </LayoutContainer>
        }
      ></LayoutSign>
    </>
  );
};

export default ProcessClassLink;
