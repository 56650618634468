import { AlertColor, Box, CssBaseline } from "@mui/material";
import { useEffect, useMemo, useState, useCallback } from "react";
import { CSSProperties } from "@mui/styles";
import { LayoutContainer } from "../Layout/layout.container";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertState,
  setCustomAlertDetails,
  setPageHeaderChildren,
  setShowCustomAlert,
} from "../store/slices/main";
import {
  AttendanceList,
  RadioSelector,
  CalendarSelector,
  CustomAlert,
} from "../Components";
import { RootState } from "../store";
import { fetchPeriodsBySelectedDateChar } from "../Services/ScheduleService";
import {
  fetchAttendanceByClassID,
  findAttendanceByDateAndPeriod,
} from "../Services/AttendanceService";
import { useLocation } from "react-router-dom";
import { Class } from "../Interfaces/ClassInterface";
import moment from "moment";
import { useWebSocket } from "../Hooks/useWebSocket";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { clearAttendance } from "../store/slices/attendance.slice";
import { fetchAttendanceClassByPeriodAndDayType } from "../Services/ClassService";

const styles = {
  containerBox: {
    borderRadius: "5px 5px 0 0",
  } as CSSProperties,
  header: {
    height: "auto",
    marginBottom: 12,
    zIndex: 3,
  } as CSSProperties,
  listContainer: {
    overflow: "auto",
    minHeight: "60vh",
    maxHeight: "60vh",
    textAlign: "center",
    background: "#3C404E",
    zIndex: 3,
    borderRadius: "8px 8px 0px 0px ",
  } as CSSProperties,
  tabsContainer: {
    background: "rgb(70, 75, 91)",
    padding: "5px 10px",
    maxWidth: "89vw",
    height: 55,
    width: "100%",
    borderRadius: 5,
  } as CSSProperties,
  tab: {
    textTransform: "none",
  } as CSSProperties,
  tabPanel: {
    padding: 0,
  } as CSSProperties,
  filterContainer: {
    textAlign: "center",
    zIndex: 3,
    display: "flex",
    margin: 10,
  } as CSSProperties,
};

interface LocationState {
  navigationClass: Class | null;
}

function AttendanceListPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const location = useLocation();
  const state = location.state as LocationState;
  const { navigationClass } = state || { navigationClass: null };

  const [selectedAttendanceDate, setSelectedAttendanceDate] = useState<Date>(
    new Date()
  );
  const [selectedPeriodID, setSelectedPeriodID] = useState<any>();

  // // STORE
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { user } = useSelector((state: RootState) => state.user);
  const {
    attendances,
    loadingIndicator,
    errorMessage: attendanceErrorMessage,
  } = useSelector((state: RootState) => state.attendance);
  const {
    attendanceCurrentClass,
    loadingIndicator: classInfoLoadingIndicator,
  } = useSelector((state: RootState) => state.class_);
  const {
    periodsForAttendance,
    periodsLoadingIndicator,
    errorMessage: scheduleErrorMessage,
  } = useSelector((state: RootState) => state.schedule);

  const options = useMemo(() => {
    return (
      periodsForAttendance?.map((period) => {
        return {
          value: period.name,
          label: period.name,
          periodID: period.id,
        };
      }) ?? []
    );
  }, [periodsForAttendance]);

  useEffect(() => {
    dispatch(clearAttendance());
  }, []);

  useEffect(() => {
    if (currentSchool?.id) {
      const attendance_date = moment(selectedAttendanceDate).format(
        "YYYY-MM-DD"
      );
      dispatch(
        fetchPeriodsBySelectedDateChar({
          attendance_date,
          school_id: currentSchool?.id,
        })
      );
    }
  }, [currentSchool]);

  useEffect(() => {
    if (scheduleErrorMessage) {
      showAlert(scheduleErrorMessage, "error");
    }
  }, [scheduleErrorMessage]);

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = { message, severity };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  useEffect(() => {
    if (periodsForAttendance.length && !selectedPeriodID) {
      const currentPeriod = periodsForAttendance.find((s) => {
        const hourFormat = "HH:mm";
        const currentTime = moment(selectedAttendanceDate, hourFormat);
        const startTime = moment(s.start_time).format(hourFormat);
        const endTime = moment(s.end_time).format(hourFormat);
        const checkHourStart = moment(startTime, hourFormat);
        const checkHourEnd = moment(endTime, hourFormat);

        const currentPeriodFound =
          checkHourStart.isBefore(currentTime) &&
          checkHourEnd.isAfter(currentTime);

        return currentPeriodFound;
      });
      if (currentPeriod) {
        setSelectedPeriodID(currentPeriod.id);
      }
    }
  }, [periodsForAttendance]);

  //WebSocket
  useWebSocket([
    {
      entity: "attendance",
      method: "UPDATE",
      onMessageReceived: () => {
        reloadList();
        if (!navigationClass?.id) return;
        dispatch(fetchAttendanceByClassID(navigationClass.id));
      },
    },
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        if (!navigationClass?.id) return;
        dispatch(fetchAttendanceByClassID(navigationClass.id));
      },
    },
  ]);

  const headerTitleAsString = useCallback(
    (classObject: Class) => {
      if (!classObject || !classObject?.id) {
        return `<p class="title2">
                </p>
                <p class="subtitle2">
                <strong></strong>
                <strong></strong>
                <strong></strong>
                </p>`;
      }

      return `<p class="title2">
                ${classObject?.name || ""}
            </p>
            <p class="subtitle2">
              Room:<strong> ${classObject?.room?.name || ""} &bull; </strong>
              Day Type:<strong> ${
                classObject?.day_type?.name || ""
              } &bull; </strong>
              Period:<strong> ${classObject?.period?.name || ""}</strong>
            </p>`;
    },
    [navigationClass]
  );

  useEffect(() => {
    if (navigationClass?.id) {
      console.log(navigationClass);
      setSelectedPeriodID(navigationClass?.period_id);
    }
  }, [navigationClass]);

  useEffect(() => {
    reloadList();
  }, [periodsForAttendance, selectedPeriodID]);

  function reloadList() {
    if (periodsForAttendance.length && selectedPeriodID > 0) {
      const selectedDateFormat = moment(selectedAttendanceDate).format(
        "YYYY-MM-DD"
      );
      const periodSelected = periodsForAttendance?.find(
        (period) => period?.id === selectedPeriodID
      );

      dispatch(
        findAttendanceByDateAndPeriod({
          school_id: currentSchool.id,
          attendance_date: selectedDateFormat,
          period_id: selectedPeriodID,
          day_type_id: Number(periodSelected?.day_type_id),
        })
      );

      dispatch(
        fetchAttendanceClassByPeriodAndDayType({
          school_id: currentSchool.id,
          period_id: selectedPeriodID,
          day_type_id: Number(periodSelected?.day_type_id),
        })
      );
    }
  }

  useEffect(() => {
    dispatch(
      setPageHeaderChildren(headerTitleAsString(attendanceCurrentClass))
    );
  }, [attendanceCurrentClass]);

  const handleCalendarChange = async (date: Date) => {
    dispatch(clearAttendance());
    setSelectedPeriodID(0);
    setSelectedAttendanceDate(date);
    const attendance_date = moment(date).format("YYYY-MM-DD");

    await dispatch(
      fetchPeriodsBySelectedDateChar({
        attendance_date,
        school_id: currentSchool?.id,
      })
    );
  };

  const handlePeriodChange = (value: string) => {
    setSelectedPeriodID(Number(value));
  };

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
  }

  console.log("attendances", attendances )

  return (
    <>
      {!isMobile ? <div style={{ marginTop: "-80px" }}></div> : <div></div>}
      <CssBaseline />
      <LayoutContainer isRaised={true}>
        <Box style={styles.listContainer}>
          <Box style={styles.filterContainer}>
            <CalendarSelector
              style={{ marginRight: 10 }}
              selectedDate={selectedAttendanceDate}
              onChange={handleCalendarChange}
            />
            <RadioSelector
              options={options}
              isLoading={periodsLoadingIndicator || classInfoLoadingIndicator}
              onChange={handlePeriodChange}
              defaultPeriodId={selectedPeriodID}
            />
          </Box>
          <AttendanceList
            attendanceArray={attendances}
            selectedAttendanceDate={selectedAttendanceDate}
            loadingIndicator={loadingIndicator}
          />
        </Box>
        <CustomAlert handleClose={handleCloseAlert} />
      </LayoutContainer>
    </>
  );
}

export default AttendanceListPage;
