import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react'
import { Box, Button, Divider, Grid, LinearProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Absent } from '../../Interfaces/AttendanceInterface';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { CalendarSelector } from '../Calendar/CalendarSelector';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../store';
import { fetchAbsentsBySchoolID } from '../../Services/AttendanceService';
import moment from 'moment';
import { setSelectedUser } from "../../store/slices/main";
import { getUserByStudentIdNumber } from '../../Services/UsersService';
import { User } from '../../Interfaces/UserInterface';
import { get } from 'http';

interface ListProps {
    loadingAbsents: boolean;
    absents: any[];
    isMobile: boolean;
}

const styles = {
    listContainer: {
        background: "#3C404E",
        borderRadius: "5px 5px 0 0",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
        overflow: "auto",
        marginTop: "-50px",
        textAlign: "center",
        minHeight: "79vh",
        maxHeight: "79vh",
        zIndex: 3,
        width: "90%",
    } as CSSProperties,
    loading: { width: "100%" } as CSSProperties,
    list: { borderRadius: "5px", padding: 0 } as CSSProperties,
    listItem: {
        background: "#464B5B",
        height: "auto",
    } as CSSProperties,
    divider: { borderColor: "#3C404E", background: "#3C404E" },
    clickableText: {
        cursor: "pointer",
        color: "#7AC142",
        fontSize: "15px",
        lineHeight: "50px",
    } as CSSProperties,
};

export const AbsentList: FC<ListProps> = ({ loadingAbsents, absents, isMobile }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentSchool } = useSelector((state: RootState) => state.main);
    const [singleStudentId, setSingleStudentId] = useState("");

    const [selectedAbsentDate, setSelectedAbsentDate] = useState<Date>(new Date());
    const [studentUser, setStudentUser] = useState<User | null>(null);

    const { user } = useSelector((state: RootState) => state.user);

   

    function getUserByStudentId(singleStudentId: string) {
        const fetchSelectedUser = async () => {
            try {
                const user = await getUserByStudentIdNumber(singleStudentId);
                console.log('***result***', user);
                if (user) {
                    dispatch(setSelectedUser(user));
                    navigate("/AdminStudentProfile");
                } else {
                    setStudentUser(null);
                }
            } catch (err) {
                console.error(err);
                setStudentUser(null);
            }
        }

        fetchSelectedUser();
    }

    
    
    

    const AbsentsListItems = useMemo(
        () =>
            absents?.map((item: Absent, index) => (
                <React.Fragment key={item.user_id}>
                    {console.log("item", item)}
                    <ListItem style={styles.listItem}>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: "subtitle2",
                                fontWeight: "500",
                                fontSize: "1rem",
                                marginBottom: "0",
                                color: "textPrimary",
                            }}
                            primary={
                                
                                <React.Fragment>
                                    <Grid container spacing={0} mt={0}
                                        pt={0} pb={0}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item xs={5} md={5} pt={0}>
                                            <Typography
                                                onClick={() => getUserByStudentId(item.student_id_number)}
                                                component="div"
                                                align="left"
                                                // variant="subtitle2"
                                                style={styles.clickableText}
                                                >
                                                {item.first_name + " " + item.last_name}
                                            </Typography>
                                            
                                        </Grid>
                                        <Grid item xs={3} md={3} pt={0}>
                                            <Typography
                                                component="div"
                                                align="left"
                                                variant="subtitle2"
                                                style={{ fontSize: "15px", marginLeft: "15px" }}>
                                                {item.grade || '6'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} md={4} pt={0}
                                            sx={{
                                                paddingTop: "0",
                                            }}>
                                            <Box
                                                component="div"
                                                ml={3}
                                                pt={0}
                                                sx={{
                                                    display: 'flex',
                                                    pt: 0.5,
                                                    pb: 0.5,
                                                    width: '35px',
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                    verticalAlign: 'middle',
                                                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#F8931C' : '#F8931C'),
                                                    color: '#fff',
                                                    border: '1px solid',
                                                    borderColor: (theme) =>
                                                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                                    borderRadius: '5px',
                                                    fontSize: '0.875rem',
                                                    fontWeight: '700',
                                                }}
                                            >
                                                {item.absents_count || '0'}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider key={`${item.user_id}${index}`} style={styles.divider} />
                </React.Fragment>
            )),
        [absents]
    );

    const handleCalendarChange = (date: Date) => {
        console.log(date);
        
        setSelectedAbsentDate(date);
        
        if (currentSchool && currentSchool?.id) {
            dispatch(fetchAbsentsBySchoolID({
                school_id: currentSchool.id,
                absent_date: moment(date).format("YYYY-MM-DD")
            }));
        }
    };

    return (
        <>
            <Box style={styles.listContainer}>
                <Box pl={2}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={12} mt={2}>
                            {isMobile && (
                                <IconButton
                                    component="span"
                                    size="small"
                                    sx={{
                                        background: (theme) => theme.palette.primary.main,
                                        color: "white",
                                        width: '30px', height: '30px',
                                        display: "flex", justifyContent: "center", alignItems: "center",
                                        paddingLeft: '13px'
                                    }}
                                    onClick={() => navigate(-1)}
                                    disableRipple={true}
                                >
                                    <ArrowBackIosIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                component="div"
                                align="left"
                                variant="subtitle2"
                                style={{ fontSize: "18px", lineHeight: "48px" }}
                            >
                                Absent Students
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {loadingAbsents ? (
                    <Box style={styles.loading}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <>
                        <Box pl={2}>
                            <CalendarSelector
                                style={{ marginRight: 10 }}
                                selectedDate={selectedAbsentDate}
                                onChange={handleCalendarChange}
                            />
                        </Box>
                        <Box pl={2}>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={5} sm={5}>
                                    <Typography
                                        component="div"
                                        align="left"
                                        variant="subtitle2"
                                        style={{ fontSize: "15px", lineHeight: "50px" }}
                                    >
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <Typography
                                        component="div"
                                        align="left"
                                        variant="subtitle2"
                                        style={{ fontSize: "15px", lineHeight: "50px" }}
                                    >
                                        Grade
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <Typography
                                        component="div"
                                        align="left"
                                        variant="subtitle2"
                                        style={{ fontSize: "15px", lineHeight: "50px" }}
                                    >
                                        Absences
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <List sx={{ padding: 0 }} style={styles.list}>{AbsentsListItems}</List>
                    </>
                )}
            </Box>
        </>
    )
}
