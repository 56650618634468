import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "./apiService";

const baseURL = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE === "local"
    ? true
    : false
  : true;
const notificationsURL = `${baseURL ? "users" : ""}/users/notifications`;

export const fetchAllNotificationsByUserName = createAsyncThunk(
  "notifications/fetchAllNotificationsByUserName",
  async (user_name: string) => {
    const result: any = await ApiService.get<Notification[]>(
      `${notificationsURL}/user/${user_name}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const updateReadNotificationById = createAsyncThunk(
  "notifications/updateReadNotificationById",
  async (notification_id: number) => {
    const result: any = await ApiService.put<Notification>(
      `${notificationsURL}/${notification_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);
