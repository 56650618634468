import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { FC, ReactElement } from "react";
import { WarningAmber } from "@mui/icons-material";
import { ToggleSchoolOptionEnum } from "../../Interfaces/SchoolInterface";
import { LightModal } from "../LightModal/LightModal";

interface ListProps {
  openModal: boolean;
  handleOpenModal: (state: ToggleSchoolOptionEnum) => void;
}

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

const AdvertisementShoolModal: FC<ListProps> = ({
  openModal,
  handleOpenModal,
}): ReactElement => {
  return (
    <LightModal
      width="400px"
      isOpenModal={openModal}
      handleOpenModal={handleOpenModal}
    >
      <Card>
        <CardHeader title="Add a School" sx={{ mx: 1 }} />
        <CardContent sx={{ mx: 1 }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "nowrap",
            }}
          >
            <WarningAmber color="error" />
            <span style={{ marginLeft: 15, marginTop: -5 }}>
              In order to create a new school please add a district first.
            </span>
          </div>
        </CardContent>
        <CardActions style={{ padding: 0, margin: 0 }}>
          <Grid
            container
            spacing="0"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "1rem" }}
          >
            <Grid item xs={6}>
              <Button
                onClick={() => handleOpenModal(ToggleSchoolOptionEnum.Schools)}
                disabled={false}
                style={styles.formButton}
                size="large"
                color="secondary"
                variant="contained"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={() =>
                  handleOpenModal(ToggleSchoolOptionEnum.Districts)
                }
                style={styles.formButton}
                fullWidth
                size="large"
                disabled={false}
              >
                Create a district
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </LightModal>
  );
};

export default AdvertisementShoolModal;
