import GridLoader from 'react-spinners/PropagateLoader';

export const corporateColors: any = {
    seedColor: '#7AC142',
};

export const LoadingIndicator = ({ state = true }) => {
    const styleLoading: any = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    return (
        <div style={styleLoading}>
            <GridLoader size={30} color={corporateColors.seedColor} loading={state} />
        </div>
    );
};
