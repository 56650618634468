import moment from "moment";
import { GroupEnum } from "../Interfaces/UserInterface";

export function getTimeStringFormat(date: Date): string {
  return moment(date, [moment.ISO_8601, "HH:mm"]).format("h:mm a");
}

export function getTimeDateformat(date: Date): Date {
  return moment(date, [moment.ISO_8601, "HH:mm"]).toDate();
}

export function getGroupName(group: GroupEnum){
  return group.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ");
}
