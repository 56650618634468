export interface IUserRolesTabOptions {
  id: number;
  name: string;
  index: number;
  isRolSelectionDisabled: boolean;
}

export const UserRolesTabOptions: IUserRolesTabOptions[] = [
  {
    id: 1,
    name: "Teacher",
    index: 1,
    isRolSelectionDisabled: false,
  },
  {
    id: 2,
    name: "School Admin",
    index: 2,
    isRolSelectionDisabled: false,
  },
  {
    id: 3,
    name: "Student",
    index: 3,
    isRolSelectionDisabled: false,
  },
  {
    id: 4,
    name: "Staff",
    index: 4,
    isRolSelectionDisabled: false,
  },
];
