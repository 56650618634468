import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Button, MenuItem, Radio, alpha, styled as muStyled } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import Menu, { MenuProps } from "@mui/material/Menu";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel, {
    FormControlLabelProps
} from "@mui/material/FormControlLabel";
import { withDarkTheme } from '../../Hocs/withDarkTheme';

interface StyledFormControlLabelProps extends FormControlLabelProps {
    bgColor: string;
}

interface StyledFormControlLabelPanelProps extends FormControlLabelProps {
    checked: boolean;
    bgColor: string;
}

const StyledFormControlLabel = muStyled((props: StyledFormControlLabelPanelProps) => (
    <FormControlLabel {...props} />
))(({ theme, checked, bgColor }) => ({
    "&.MuiFormControlLabel-root .MuiFormControlLabel-label": {
        fontSize: '14px',
        padding: '8px',
        textAlign: 'center',
        minWidth: '68px',
        marginLeft: '8px',
        fontWeight: 500,
        backgroundColor: alpha(
            bgColor == 'primary' ? theme.palette.primary.main : bgColor,
            theme.palette.action.selectedOpacity
        ),
        borderRadius: '8px',
        color: bgColor == 'primary' ? theme.palette.primary.main : bgColor,
    },
    ".MuiFormControlLabel-label": checked && {
        color: bgColor == 'primary' ? theme.palette.primary.main : bgColor,
    }
}));

function MyFormControlLabel(props: StyledFormControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}

const StyledButtonMultiColor = muStyled(Button, { shouldForwardProp: (prop: any) => prop !== 'bgColor' && prop !== 'defaultWidth' }
)<{ bgColor?: string; defaultWidth?: number}>(({ bgColor = 'primary', defaultWidth = 0 }) => (({ theme }) => ({
    backgroundColor: alpha(
        bgColor === 'primary' ? theme.palette.primary.main : bgColor,
        theme.palette.action.selectedOpacity
    ),
    width: defaultWidth && defaultWidth !== 0 ? defaultWidth : 'auto',
    color: bgColor === 'primary' ? theme.palette.primary.main : bgColor,
    textTransform: 'capitalize',
    '&:hover, &:focus': {
        backgroundColor: alpha(bgColor === 'primary' ? theme.palette.primary.main : bgColor,
            theme.palette.action.selectedOpacity
        ),
    },
    '&.Mui-selected': {
        backgroundColor: alpha(bgColor === 'primary' ? theme.palette.primary.main : bgColor,
            theme.palette.action.selectedOpacity
        ),
        color: bgColor === 'primary' ? theme.palette.primary.main : bgColor,
    },
    '&.Mui-selected:hover, &.Mui-selected:focus': {
        backgroundColor: alpha(bgColor === 'primary' ? theme.palette.primary.main : bgColor,
            theme.palette.action.selectedOpacity
        ),
        color: bgColor === 'primary' ? theme.palette.primary.main : bgColor,
    },
    '&.MuiPickersDay-root:not(.Mui-selected)': {
        backgroundColor: alpha(bgColor === 'primary' ? theme.palette.primary.main : bgColor,
            theme.palette.action.selectedOpacity
        ),
        color: bgColor === 'primary' ? theme.palette.primary.main : bgColor,
    },
})));

const StyledMenu = muStyled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "center",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "center",
            horizontal: "center"
        }}
        {...props}
    />))(({ theme }) => ({
        "& .MuiPaper-root": {
            borderRadius: 8,
            marginTop: theme.spacing(1),
            minWidth: 135,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
            boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            "& .MuiMenu-list": {
                width: 135,
            },
            "& .MuiMenuItem-root": {
                paddingTop: 5,
                paddingBottom: 5,
                minHeight: 10,
                "& .MuiSvgIcon-root": {
                    fontSize: 23,  // font size radio button icon
                    padding: 0,
                },
                "&:active": {
                    backgroundColor: alpha(
                        theme.palette.background.default,
                        theme.palette.action.selectedOpacity
                    )
                }
            }
        }
    }));

interface RadioSelectorProps {
    options: any[],
    selectedOption: string,
    bgColor?: string,
    defaultWidth?: number,
    onChange: (status: string) => void;
}

const RadioSelectorPanelComponent: FC<RadioSelectorProps> = ({ options, selectedOption, bgColor = 'primary', defaultWidth, onChange }): ReactElement => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [radioGroupValue, setRadioGroupValue] = useState<string>();
    const [buttonColor, setButtonColor] = useState(bgColor);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const value = ev.target.value;
        setRadioGroupValue(value);
        setButtonColor(options.find(o => o.value === value)?.color || bgColor);
        onChange(value);
    };

    useEffect(() => {
        setButtonColor(options.find(o => o.value === radioGroupValue)?.color || bgColor);
    }, [radioGroupValue]);

    useEffect(() => {
        setRadioGroupValue(selectedOption.toLocaleLowerCase());
    }, [selectedOption])

    return (
        <>
            <StyledButtonMultiColor
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                bgColor={buttonColor}
                defaultWidth={defaultWidth}
                endIcon={<KeyboardArrowDown />}>
                {radioGroupValue}
            </StyledButtonMultiColor>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button"
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <RadioGroup
                    name="use-radio-group"
                    value={radioGroupValue}
                    onChange={handleChange}>
                    {
                        options.map((option, index) => {
                            return (
                                <MenuItem key={index} onClick={handleClose} disableRipple>
                                    <MyFormControlLabel
                                        value={option.value}
                                        label={option.label}
                                        bgColor={option.color || bgColor}
                                        control={<Radio />}
                                    />
                                </MenuItem>
                            )
                        })
                    }
                </RadioGroup>
            </StyledMenu>
        </>
    )
}

export const RadioSelectorPanel = withDarkTheme(RadioSelectorPanelComponent);
