import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { AlertColor, Box } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  AlertState,
  setCustomAlertDetails,
  setPageTitle,
  setShowCustomAlert,
} from "../store/slices/main";
import { fetchHallPasses } from "../Services/HallPassService";
import {
  AddFloatButton,
  CustomAlert,
  HallPassList,
  HallPassModal,
} from "../Components";
import { LayoutContainer } from "../Layout/layout.container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HallPassLocation } from "../Interfaces/SchoolInterface";
import { fetchManagersBySchoolId } from "../Services/UsersService";
import { User } from "../Interfaces/UserInterface";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../store/slices/hallPass.slice";

const styles = {
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    minHeight: "79vh",
    maxHeight: "79vh",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "auto",
  } as CSSProperties,
  listGridContainer: {
    display: "flex",
    marginRight: "4px",
  } as CSSProperties,
  listGridText: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    marginLeft: "3px",
    paddingBottom: "6px",
  } as CSSProperties,
  divider: { borderColor: "#3C404E", background: "#3C404E" },
  newButton: {
    marginRight: 10,
    textTransform: "none",
    color: "white",
    height: 35,
    width: 150,
  } as CSSProperties,
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
  secondaryTextList: {
    variant: "caption",
    color: "rgba(122,193,65,255)",
    fontSize: "0.9rem",
    fontWeight: "500",
  } as CSSProperties,
  primaryTextList: {
    variant: "subtitle2",
    fontWeight: "500",
    fontSize: "1.0rem",
    marginBottom: "0",
    color: "textPrimary",
  } as CSSProperties,
};

const HallPass = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const [title, setTitle] = useState<string>("Update Location");
  const [selectedHallPass, setSelectedHallPass] = useState<HallPassLocation>();
  const [openModal, setOpenModal] = useState(false);
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { managers } = useSelector((state: RootState) => state.user);
  const { hallPassLocations, loadingIndicator, errorMessage, successMessage } =
    useSelector((state: RootState) => state.hallPass);

  const defaultPassLocation: HallPassLocation = {
    id: undefined,
    name: "",
    school_id: 0,
    max_students_allowed: undefined,
    scan_at_arrival: false,
    managers: [] as User[],
  };

  useEffect(() => {
    dispatch(setPageTitle("Pass Management"));
  });

  useEffect(() => {
    if (currentSchool.id) {
      dispatch(fetchHallPasses(currentSchool.id));
      dispatch(fetchManagersBySchoolId(currentSchool.id));
    }
  }, [currentSchool]);

  useEffect(() => {
    if (successMessage) {
      showAlert(successMessage, "success");
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      showAlert(errorMessage, "error");
    }
  }, [errorMessage]);

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleOpenModal(value: boolean, origin: string) {
    if (origin === "create") {
      setSelectedHallPass(defaultPassLocation);
      setTitle("Create New Location");
      setOpenModal(value);
    } else {
      setTitle("Update Location");
      setOpenModal(value);
    }
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  return (
    <>
      <LayoutContainer isRaised={true}>
        <Box>
          {isMobile && (
            <AddFloatButton
              handleOpenModal={() => handleOpenModal(true, "create")}
            />
          )}
          <HallPassList
            defaultPassLocation={defaultPassLocation}
            selectedHallPass={selectedHallPass}
            setSelectedHallPass={setSelectedHallPass}
            styles={styles}
            loadingClasses={loadingIndicator}
            hallPassLocations={hallPassLocations}
            isMobile={isMobile}
            handleOpenModal={handleOpenModal}
            school_id={parseInt(`${currentSchool.id}`)}
          />
        </Box>
        <HallPassModal
          title={title}
          school_id={parseInt(`${currentSchool.id}`)}
          handleOpenModal={handleOpenModal}
          openModal={openModal}
          managers={managers}
          styles={styles}
          selectedLocation={selectedHallPass}
        />
      </LayoutContainer>
      <CustomAlert handleClose={handleCloseAlert} />
    </>
  );
};

export default HallPass;
