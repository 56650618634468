import { CSSProperties, FC, ReactElement } from "react";
import { Box, Modal, useMediaQuery } from "@mui/material";
import { withLightTheme } from "../../Hocs/withLightTheme";
import { useTheme } from "@mui/material/styles";

interface LightModalComponentProps {
  width?: string,
  isOpenModal: boolean;
  handleOpenModal: (state: any, origin: string) => void;
  children: ReactElement | ReactElement[];
}


const LightModalComponentDefaultProps = {
  width: "500px"
};

const LightModalComponent: FC<LightModalComponentProps> = ({
  isOpenModal,
  handleOpenModal,
  children,
  width
}): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const styles = {
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "5px",
      minWidth: !isMobile ? width : "90vw",
      maxWidth: !isMobile ? width : "90vw",
      overflowY: "auto",
    } as CSSProperties,
  };
  return (
    <Modal
      open={isOpenModal}
      onClose={(ev, reason) =>
        reason !== "backdropClick" && handleOpenModal(false, "")
      }
    >
      <Box style={styles.modal}>{children}</Box>
    </Modal>
  );
};

LightModalComponent.defaultProps = LightModalComponentDefaultProps;

export const LightModal = withLightTheme(LightModalComponent);
