import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, CssBaseline, List, Tab } from "@mui/material";
import { CSSProperties, FC, useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutContainer } from "../Layout/layout.container";
import { RootState } from "../store";
import { setPageTitle, setRaiseHeader } from "../store/slices/main";
import useIsMobile from "../Hooks/useIsMobile";
import { fetchStudentsWithActivePassBySchoolId } from "../Services/HallPassService";
import HallPassListItem from "../Components/HallPass/ActiveHallPassListItem";
import TabPanelTitle from "../Components/HallPass/TabPanelTitle";
import { useWebSocket } from "../Hooks/useWebSocket";
import { ChevronRightOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ActiveHallPassesPage: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [selectedTab, setSelectedTab] = useState<string>("1");
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { studentsWithActivePass } = useSelector(
    (state: RootState) => state.hallPass
  );
  const navigate = useNavigate();

  useWebSocket([
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        dispatch(
          fetchStudentsWithActivePassBySchoolId(Number(currentSchool.id))
        );
      },
    },
  ]);

  const heightValue = isMobile ? "70vh" : "80vh";

  useEffect(() => {
    dispatch(setPageTitle("Active Hall Passes"));
    dispatch(setRaiseHeader(true));

    return () => {
      dispatch(setRaiseHeader(false));
    };
  }, []);

  const handleOnClickFullReport = (event: React.SyntheticEvent) => {
    event.preventDefault();
    navigate("/HallPassReportPage");
  };

  const tabContainerStyle = useMemo(() => {
    return isMobile
      ? ({
          background: "rgb(70, 75, 91)",
          height: 155,
          marginTop: -110,
          zIndex: -999,
          display: "flex",
          alignItems: "flex-end",
          width: "100%",
        } as CSSProperties)
      : ({} as CSSProperties);
  }, [isMobile]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <CssBaseline />
      <LayoutContainer isRaised={true} sx={{ padding: 0 }}>
        <TabContext value={selectedTab}>
          <Box
            style={tabContainerStyle}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "#3c404e",
              width: "100%",
            }}
          >
            <TabList onChange={handleChange}>
              <Tab
                label={
                  <TabPanelTitle
                    title="Hall pass"
                    quantity={studentsWithActivePass.length}
                    badgeCssBackground="#007aff"
                    isActive={selectedTab === "1"}
                  />
                }
                value="1"
              />
            </TabList>
          </Box>
          <TabPanel
            value="1"
            sx={{
              p: 0,
              minHeight: heightValue,
              maxHeight: heightValue,
              marginBottom: "10px",
              overflow: "auto",
            }}
          >
            <List sx={{ pt: "2px" }}>
              {studentsWithActivePass.map((studentOnLocation) => (
                <HallPassListItem
                  key={studentOnLocation.id}
                  studentOnLocation={studentOnLocation}
                />
              ))}
            </List>


            <Box
                  sx={{ cursor: "pointer", color: "#7AC142" }}
                  display="flow"
                  alignItems="center"
                  onClick={handleOnClickFullReport}
                >
                  View Full Report
                  <ChevronRightOutlined sx={{ position: 'relative', top: '7px', fill: '#7AC142' }} />
            </Box>
            
            </TabPanel>
          <TabPanel value="2"></TabPanel>
        </TabContext>
      </LayoutContainer>
    </>
  );
};

export default ActiveHallPassesPage;
