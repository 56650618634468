import { CssBaseline, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PreviousClassesList,
  TodayList,
  ToggleHeaderTeacher,
} from "../Components";
import { ToggleTeacherOptionEnum } from "../Interfaces/TeacherInterface";
import { LayoutContainer } from "../Layout/layout.container";
import {
  setPageTitle,
} from "../store/slices/main";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles";
import { fetchClasses } from '../Services/ClassService';
import { RootState } from "../store";

const styles = {
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "80px",
  } as CSSProperties,
  divider: {
    borderColor: "#3C404E",
    background: "#3C404E",
    borderBottomWidth: 2,
  },
  /*listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    minHeight: "79vh",
    maxHeight: "79vh",
    zIndex: 3,
  } as CSSProperties,*/
  listGridContainer: {
    display: "flex",
    marginRight: "4px",

  } as CSSProperties,
  listGridText: {
    fontSize: "0.9rem",
    fontWeight: "inherit",
    marginLeft: "3px",
    paddingBottom: "1px",
    color: "textSecondary",
  } as CSSProperties,
  newButton: {
    marginRight: 10,
    textTransform: "none",
    color: "white",
    height: 35,
    width: 150,
  } as CSSProperties,
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
  primaryTextList: {
    variant: "caption",
    fontSize: "0.7rem",
  } as CSSProperties,
  classNameText: {
    variant: "subtitle2",
    fontWeight: "500",
    fontSize: "1rem",
  } as CSSProperties,
  secondaryTextList: {
    variant: "subtitle2",
    fontWeight: "500",
    fontSize: "0.8rem",
    color: "rgba(122,193,65,255)",
  } as CSSProperties,
};
const Attendance = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const [selectedToggleOption, setSelectedToggleOption] = useState(
    ToggleTeacherOptionEnum.Previous
  );
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { classes, loadingIndicator, errorMessage, successMessage } =
    useSelector((state: RootState) => state.class_);
  useEffect(() => {
    if (currentSchool.id) {
      dispatch(fetchClasses(currentSchool.id));
    }


  }, [currentSchool,]);

  useEffect(() => {
    dispatch(setPageTitle("Classes list"));
  }, []);

  return (
    <>
      <CssBaseline />

      <LayoutContainer isRaised={true} sx={{ marginBottom: 8 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <ToggleHeaderTeacher
            selectedTabOption={selectedToggleOption}
            selectedToggleOption={setSelectedToggleOption}
          />
        </Grid>
      </LayoutContainer>

      <LayoutContainer isRaised={true}>
        {selectedToggleOption === ToggleTeacherOptionEnum.Previous ? (
          <>
            <PreviousClassesList
              isMobile={isMobile}
            />
          </>
        ) : (
          <>
            <TodayList
              loadingClasses={loadingIndicator}
              styles={styles}
              isMobile={isMobile}
              classes={classes}
            />
          </>
        )}
      </LayoutContainer>
    </>
  );
};

export default Attendance;
