import { useEffect, useState } from "react";
import { RootState } from "../../store";
import {
  Menu,
  MenuItem,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsByUserManager } from "../../Services/HallPassService";
import { setCurrentLocation } from "../../store/slices/main";
import { CSSProperties } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export interface Location {
  id: number;
  name: string;
  max_students_allowed: number;
  scan_at_arrival: boolean;
}

const styles = {
  formButton: {
    textTransform: "unset",
    color: "#FFFFFF",
    fontSize: 18,
    paddingBottom: "unset",
  } as CSSProperties,
  radioLabel: {
    color: "#7AC142",
  } as CSSProperties,
};

export const StaffLocationsDropdown = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.main);
  const [locations, setLocations] = useState([]);
  const [value, setValue] = useState<string>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  function loadLocationsByStaffUser() {
    if (currentUser.user_name) {
      const fetchData = async () => {
        return await getLocationsByUserManager(currentUser.user_name);
      };
      fetchData()
        .then((result: any) => {
          setLocations(result);
          if (result !== null && result.length) {
            setValue(result ? result[0].location.name : null);
          } else {
            setValue("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    //console.log("Current  user ", currentUser.user_name);
    loadLocationsByStaffUser();
  }, [currentUser.user_name]);

  useEffect(() => {
    if (value) {
      const selected: any = locations.filter(
        (l: any) => l.location.name === value
      );
      if (selected && selected.length > 0) {
        dispatch(setCurrentLocation(selected[0].location));
      }
    }
  }, [value]);

  return (
    <>
      {locations && (
        <>
          {value && (
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              color="inherit"
              style={styles.formButton}
              endIcon={<KeyboardArrowDownIcon fontSize="large" />}
            >
              {value}
            </Button>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>
              <RadioGroup
                name="use-radio-group"
                defaultValue="first"
                value={value}
                onChange={handleChange}
              >
                {locations.map((loc: any) => (
                  <FormControlLabel
                    key={loc.location.id}
                    value={loc.location.name}
                    label={loc.location.name}
                    control={<Radio />}
                    style={styles.radioLabel}
                  />
                ))}
              </RadioGroup>
            </MenuItem>
          </Menu>
          <Box style={{ color: "rgba(255, 255, 255, 0.75)", fontSize: 13 }}>
            • {moment().format("ddd MMM Do")} •
          </Box>
        </>
      )}
    </>
  );
};
