import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  AlertColor,
  Box,
  Button,
  LinearProgress,
  Typography,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  AlertState,
  setCustomAlertDetails,
  setPageSubtitle,
  setPageTitle,
  setShowCustomAlert,
} from "../store/slices/main";
import {
  setErrorMessage,
  setCurrentClass,
  setSuccessMessage,
} from "../store/slices/class.slice";
import { fetchAllBySchoolIdAndTeacherUsername } from "../Services/ClassService";
import { fetchDayTypesBySchoolId } from "../Services/ScheduleService";
import { fetchRoomsBySchoolId } from "../Services/RoomService";
import { Class } from "../Interfaces/ClassInterface";
import {
  AddFloatButton,
  ClassList,
  Group,
  TeacherModal,
  CustomAlert,
  GroupSvgMobile,
  ConfirmationDialog,
} from "../Components";
import { LayoutContainer } from "../Layout/layout.container";
import {
  fetchActiveSemestersBySchoolId,
  fetchAllSemestersBySchoolId,
} from "../Services/SemestersService";
import { setSelectedSemester } from "../store/slices/semester.slice";
import SchedulePageHeader from "../Components/Teacher/SchedulePageHeader";
import { CssBaseline } from "@mui/material";

const styles = {
  listContainer: {
    backgroundColor: "#3C404E",
    borderRadius: "5px",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    margin: "1rem 0",
    textAlign: "center",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    backgroundColor: "#464B5B",
    borderRadius: "5px",
    marginBottom: ".6rem",
    height: "auto",
  } as CSSProperties,
  listGridContainer: {
    display: "flex",
    marginRight: "4px",
  } as CSSProperties,
  listGridText: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    marginLeft: "3px",
    paddingBottom: "6px",
  } as CSSProperties,
  divider: { borderColor: "#3C404E", backgroundColor: "#3C404E" },
  newButton: {
    textTransform: "none",
    color: "white",
    height: 35,
    width: 150,
  } as CSSProperties,
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
  primaryTextList: {
    variant: "caption",
    color: "textPrimary",
    fontSize: "0.9rem",
  } as CSSProperties,
  secondaryTextList: {
    variant: "subtitle2",
    fontWeight: "500",
    fontSize: "1.1rem",
    marginBottom: "0",
    color: "textPrimary",
  } as CSSProperties,
};

const Classes = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const [title, setTitle] = useState<string>("Update Class");
  const [openModal, setOpenModal] = useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const { currentClass } = useSelector((state: RootState) => state.class_);
  const { currentSchool, currentUser } = useSelector(
    (state: RootState) => state.main
  );
  const {
    classes,
    filteredClasses,
    loadingIndicator: classLoadingIndicator,
    errorMessage,
    successMessage,
  } = useSelector((state: RootState) => state.class_);
  const roomState = useSelector((state: RootState) => state.room);
  const dayTypeState = useSelector((state: RootState) => state.schedule);
  const { selectedSemester, loadingIndicator: semesterLoadingIndicator } =
    useSelector((state: RootState) => state.semester);
  const getEmptyClass = (): Class => {
    return {
      id: undefined,
      name: "",
      description: "",
      day_type_id: undefined,
      period_id: undefined,
      room_id: undefined,
    };
  };

  useEffect(() => {
    dispatch(setPageTitle(" "));
    dispatch(setPageSubtitle(" "));
    return () => {
      dispatch(setSelectedSemester(undefined));
    };
  }, []);

  useEffect(() => {
    if (currentSchool.id) {
      dispatch(
        fetchAllBySchoolIdAndTeacherUsername({
          school_id: currentSchool.id,
          user_name: currentUser.user_name,
        } as any)
      );
      dispatch(fetchActiveSemestersBySchoolId(currentSchool.id));
      dispatch(fetchAllSemestersBySchoolId(currentSchool.id));
      dispatch(fetchDayTypesBySchoolId(currentSchool.id));
      dispatch(fetchRoomsBySchoolId(currentSchool.id));
    }
  }, [currentSchool]);

  useEffect(() => {
    if (successMessage) {
      showAlert(successMessage, "success");
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      showAlert(errorMessage, "error");
    }
  }, [errorMessage]);

  function isLoading() {
    return classLoadingIndicator || semesterLoadingIndicator;
  }

  function isClassesEmpty() {
    return !classes.length || !filteredClasses.length;
  }

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleOpenModal(value: boolean, origin: string) {
    if (origin === "create") {
      dispatch(setCurrentClass(getEmptyClass()));
      setTitle("Create New Class");
    } else {
      setTitle("Update Class");
    }

    setOpenModal(value);
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  function handleAddNewClicked() {
    if (!selectedSemester?.id) {
      setWarningDialogOpen(true);
      return;
    }
    handleOpenModal(true, "create");
  }

  return (
    <>
      {!isMobile ? <div style={{ marginTop: "-80px" }}></div> : <div></div>}
      <LayoutContainer isRaised={true}>
        <SchedulePageHeader
          areClassesLoaded={!isLoading() && !isClassesEmpty()}
          isMobile={isMobile}
          styles={styles}
          onAddNewClicked={handleAddNewClicked}
        />
        {isLoading() || isClassesEmpty() ? (
          <Box style={styles.listContainer} flexDirection="column">
            {(classLoadingIndicator || semesterLoadingIndicator) && (
              <Box style={styles.loading}>
                <LinearProgress />
              </Box>
            )}
            <Box
              flexDirection="column"
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "5%",
                overflow: "hidden",
              }}
            >
              {isMobile && <GroupSvgMobile style={{ marginTop: "10vh" }} />}
              {!isMobile && <Group />}
              <Typography
                variant="subtitle1"
                fontWeight="500"
                fontSize="1.5rem"
                marginTop="1.5rem"
                marginBottom="0.1rem"
                color="textPrimary"
              >
                Add a Class to Your
                <br /> Schedule
              </Typography>
              <div className="ellipsis1"></div>
            </Box>
            {!isMobile && (
              <Button
                sx={{ margin: "20px 0 50px" }}
                variant="contained"
                color="primary"
                style={styles.newButton}
                onClick={handleAddNewClicked}
                disabled={isLoading()}
              >
                + Add new
              </Button>
            )}
            {isMobile && (
              <AddFloatButton
                handleOpenModal={handleAddNewClicked}
                position="center"
                disableFloating
                disabled={isLoading()}
              />
            )}
          </Box>
        ) : (
          <Box>
            {isMobile && (
              <AddFloatButton handleOpenModal={handleAddNewClicked} />
            )}
            <ClassList
              defaultClass={getEmptyClass()}
              selectedClass={currentClass}
              setSelectedClass={setCurrentClass}
              styles={styles}
              loadingClasses={classLoadingIndicator || semesterLoadingIndicator}
              classes={filteredClasses}
              isMobile={isMobile}
              handleOpenModal={handleOpenModal}
            />
          </Box>
        )}
        <TeacherModal
          title={title}
          styles={styles}
          school_id={parseInt(`${currentSchool.id}`)}
          handleOpenModal={handleOpenModal}
          openModal={openModal}
          classes={classes}
          dayTypes={dayTypeState.dayTypes}
          periods={dayTypeState.periods}
          rooms={roomState.rooms}
          selectedClass={currentClass}
        />
        <ConfirmationDialog
          open={warningDialogOpen}
          title="Warning"
          message="You have to select a semester to add a class."
          onConfirm={() => setWarningDialogOpen(false)}
          loading={false}
        />
      </LayoutContainer>
      <CustomAlert handleClose={handleCloseAlert} />
    </>
  );
};

export default Classes;
