import { Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  List,
  TextField,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Attendance } from "../../Interfaces/AttendanceInterface";
import { filterAttendanceUsers } from "../../store/slices/attendance.slice";
import AttendanceListItem from "./AttendanceListItem";

const styles = {
  loading: { width: "100%" } as CSSProperties,
  divider: {
    borderColor: "#3C404E",
    background: "#3C404E",
    borderBottomWidth: 2,
  },
  searchContainer: {
    background: "#3C404E",
    padding: "10px",
    height: 50,
    width: "100%",
    borderRadius: "8px 8px 0px 0px ",
  } as CSSProperties,
};

interface AttendanceListProps {
  attendanceArray: Attendance[];
  loadingIndicator: boolean;
  selectedAttendanceDate: Date;
}

const AttendanceList: FC<AttendanceListProps> = ({
  attendanceArray,
  loadingIndicator,
  selectedAttendanceDate,
}) => {
  const dispatch = useDispatch();

  function filter(e: React.ChangeEvent<HTMLInputElement>) {
    const keyword = e.target.value.trim();
    dispatch(filterAttendanceUsers(keyword));
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box style={styles.searchContainer}>
            <TextField
              placeholder="Search by name"
              variant="standard"
              fullWidth
              onChange={filter}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {loadingIndicator ? (
        <Box style={styles.loading}>
          <LinearProgress />
        </Box>
      ) : (
        <List sx={{ padding: 0 }}>
          {attendanceArray?.map((item, index) => (
            <div key={index.toString()}>
              <AttendanceListItem
                attendance={item}
                selectedAttendanceDate={selectedAttendanceDate}
              />
              <Divider style={styles.divider} />
            </div>
          ))}
        </List>
      )}
    </>
  );
};

export default AttendanceList;
