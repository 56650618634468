import React, { FC } from "react";
import {
  CssBaseline,
  ToggleButton,
  ToggleButtonGroup,
  Box,
} from "@mui/material";
import { withLightTheme } from "../../Hocs/withLightTheme";
import { styled as muStyled } from "@mui/material";
import { ToggleSchoolOptionEnum } from "../../Interfaces/SchoolInterface";

const styles = {
  boxContainer: {
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    borderRadius: "5px",
    padding: 1,
    background: "rgb(70, 75, 91)",
  },
};

const StyledToggleButton = muStyled(ToggleButton)(
  ({ theme }) => `
  background-color: rgb(70, 75, 91);
  color: rgba(255, 255, 255, 0.7);
  border-radius: 6px !important;
  border: 0px;
  width: 100%;
  text-transform: none;
  font-weight: 500;
  &:hover {
    background-color: #fff;
    color: #FF4E32;
  }
  &:active {
    color: #FF4E32;
    background-color: #fff;
  },
  &.Mui-selected:hover: {
    color: ${theme.palette.primary.main};
    background-color: #fff !important;  
  },
  &.Mui-selected {
    color: #FF4E32;
    background-color: #fff !important;  
}`
);

interface ToggleHeaderProps {
  selectedTabOption: ToggleSchoolOptionEnum;
  selectedToggleOption: (value: ToggleSchoolOptionEnum) => void;
}

const ToggleHeaderComponent: FC<ToggleHeaderProps> = ({
  selectedTabOption,
  selectedToggleOption,
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedOption: string
  ) => {
    event.preventDefault();
    selectedToggleOption(newSelectedOption as ToggleSchoolOptionEnum);
  };

  return (
    <>
      <CssBaseline />
      <Box sx={styles.boxContainer}>
        <ToggleButtonGroup
          color="secondary"
          value={selectedTabOption}
          onChange={handleChange}
          exclusive
          fullWidth
        >
          <StyledToggleButton
            sx={{ marginRight: "2px" }}
            value={ToggleSchoolOptionEnum.Schools}
          >
            Schools
          </StyledToggleButton>
          <StyledToggleButton
            sx={{ marginLeft: "2px" }}
            value={ToggleSchoolOptionEnum.Districts}
          >
            Districts
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
};

export const ToggleHeader = withLightTheme(ToggleHeaderComponent);
