import { createSlice } from "@reduxjs/toolkit";
import {
  HallPassLocation,
  StatusStudentOnLocationEnum,
  StudentOnLocation,
} from "../../Interfaces/SchoolInterface";
import {
  cancelHallPassLocationRequest,
  confirmHallPassLocationRequest,
  createHallPassLocation,
  createHallPassLocationRequest,
  deleteHallPassLocation,
  fetchHallPasses,
  fetchStudentsWithActivePassBySchoolId,
  fetchStudentsWithCompletePassBySchoolId,
  updateHallPassLocation,
  getRemainingTimeHallPassLocationRequest,
} from "../../Services/HallPassService";

export const hallPassSlice = createSlice({
  name: "room",
  initialState: {
    studentsWithActivePass: [] as StudentOnLocation[],
    hallPassLocations: [] as HallPassLocation[],
    loadingIndicator: false,
    savingIndicator: false,
    errorMessage: "",
    successMessage: "",
    remainingTime: null,
  },
  reducers: {
    setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
  },
  extraReducers: (builder) => {
    // fetch all
    builder.addCase(fetchHallPasses.fulfilled, (state, action) => {
      state.hallPassLocations = action.payload as any;
      state.loadingIndicator = false;
    }),
      builder.addCase(fetchHallPasses.pending, (state) => {
        state.loadingIndicator = true;
      });

    // create hall pass location
    builder.addCase(createHallPassLocation.fulfilled, (state) => {
      state.successMessage = "Hall Pass created successfully";
      state.savingIndicator = false;
    }),
      builder.addCase(createHallPassLocation.pending, (state) => {
        state.savingIndicator = true;
      }),
      builder.addCase(createHallPassLocation.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // update hall pass location
    builder.addCase(updateHallPassLocation.fulfilled, (state) => {
      state.successMessage = "Hall Pass updated successfully";
      state.savingIndicator = false;
    }),
      builder.addCase(updateHallPassLocation.pending, (state) => {
        state.savingIndicator = true;
      }),
      builder.addCase(updateHallPassLocation.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // delete hall pass location
    builder.addCase(deleteHallPassLocation.fulfilled, (state) => {
      state.successMessage = "Hall Pass deleted successfully";
      state.savingIndicator = false;
    }),
      builder.addCase(deleteHallPassLocation.pending, (state) => {
        state.savingIndicator = true;
      }),
      builder.addCase(deleteHallPassLocation.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // create hall pass location request [Student]
    builder.addCase(createHallPassLocationRequest.pending, (state, action) => {
      state.savingIndicator = true;
    }),
      builder.addCase(
        createHallPassLocationRequest.fulfilled,
        (state, action) => {
          const hallPassLocations = updateStudentOnLocation(
            state.hallPassLocations,
            action.payload
          ) as HallPassLocation[];
          state.hallPassLocations = hallPassLocations;
          state.savingIndicator = false;
        }
      ),
      builder.addCase(createHallPassLocationRequest.rejected, (state) => {
        state.savingIndicator = false;
      });

    // confirm hall pass location request [Student]
    builder.addCase(confirmHallPassLocationRequest.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(
        confirmHallPassLocationRequest.fulfilled,
        (state, action) => {
          const hallPassLocations = updateStudentOnLocation(
            state.hallPassLocations,
            action.payload
          ) as HallPassLocation[];
          state.hallPassLocations = hallPassLocations;
          state.savingIndicator = false;
        }
      );

    // cancel hall pass location request [Student]
    builder.addCase(cancelHallPassLocationRequest.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(
        cancelHallPassLocationRequest.fulfilled,
        (state, action) => {
          const hallPassLocations = updateStudentOnLocation(
            state.hallPassLocations,
            action.payload
          ) as HallPassLocation[];
          state.hallPassLocations = hallPassLocations;
          state.savingIndicator = false;
        }
      );

    // find active hall passes [Student]
    builder.addCase(fetchStudentsWithActivePassBySchoolId.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchStudentsWithActivePassBySchoolId.fulfilled,
        (state, { payload }) => {
          state.studentsWithActivePass = payload as StudentOnLocation[];
          state.loadingIndicator = false;
        }
      );
    
    //find complete hall passes [Student]
    builder.addCase(fetchStudentsWithCompletePassBySchoolId.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchStudentsWithCompletePassBySchoolId.fulfilled,
        (state, { payload }) => {
          state.studentsWithActivePass = payload as StudentOnLocation[];
          state.loadingIndicator = false;
        }
      );

    // Handling getRemainingTimeHallPassLocationRequest
  builder.addCase(getRemainingTimeHallPassLocationRequest.pending, (state) => {
    state.loadingIndicator = true;
  });
  builder.addCase(getRemainingTimeHallPassLocationRequest.fulfilled, (state, action) => {
    state.remainingTime = action.payload; // assuming payload contains the remaining time
    state.loadingIndicator = false;
  });
  builder.addCase(getRemainingTimeHallPassLocationRequest.rejected, (state, { error }) => {
    state.errorMessage = error.message || "Failed to get remaining time";
    state.loadingIndicator = false;
  });
  },
});

export const getStudentsOnLocation = function (
  studentsOnLocations: StudentOnLocation[]
): StudentOnLocation[] {
  const result = studentsOnLocations.filter(
    (s) =>
      s.status === StatusStudentOnLocationEnum.Pending
  );
  return result;
};

export const getPositionInLine = function (
  studentsOnLocations: StudentOnLocation[],
  username: string
): number {
  const students = getStudentsOnLocation(studentsOnLocations);
  const position = students.findIndex((s) => s.student?.user_name === username);
  return position + 1;
};

function updateStudentOnLocation(
  hallPassLocations: HallPassLocation[],
  payload: StudentOnLocation
) {
  const index = hallPassLocations.findIndex(
    (s) => s.id === payload.location_id
  );

  if (index > -1) {
    const studentsOnLocations = hallPassLocations[index]
      .StudentsOnLocations as StudentOnLocation[];
    const requestIndex = studentsOnLocations.findIndex(
      (s) => s.id === payload.id
    ) as number;

    if (requestIndex > -1) {
      studentsOnLocations[requestIndex] = payload;
    } else {
      studentsOnLocations.push(payload);
    }
    hallPassLocations[index].StudentsOnLocations = studentsOnLocations;
    return hallPassLocations;
  }
}

export default hallPassSlice.reducer;
export const { setSuccessMessage, setErrorMessage } = hallPassSlice.actions;
