import { NativeSelect, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { setSelectedSemester } from "../../store/slices/semester.slice";
import { setFilteredClasses } from "../../store/slices/class.slice";
import { Semester } from "../../Interfaces/SemesterInterface";

const CustomSelect = styled(NativeSelect)`
  border: none;
  padding: 2px 7px;
  font-weight: 500;
  color: black;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 2px;

  svg {
    color: #7ac142;
  }

  ::before,
  ::after {
    display: none;
  }

  & option {
    background-color: #fff !important;
  }
`;

const SelectOptions = ({ semesters }: { semesters: Semester[] }) => (
  <>
    {semesters.map((semester) => (
      <option key={semester.id} value={semester.id}>
        {semester.name}
      </option>
    ))}
  </>
);

const SemesterSelect = () => {
  const dispatch = useDispatch();
  const { classes } = useSelector((state: RootState) => state.class_);
  const { selectedSemester, allSemesters, semesters, loadingIndicator } = useSelector(
    (state: RootState) => state.semester
  );

  useEffect(() => {
    if (!selectedSemester) {
      dispatch(setFilteredClasses([]));
      return;
    }

    filterClasses();
  }, [selectedSemester]);

  useEffect(() => {
    if (!allSemesters.length) return;

    dispatch(setSelectedSemester(semesters[0]));
  }, [allSemesters]);

  useEffect(() => {
    filterClasses();
  }, [classes]);

  const filterClasses = () => {
    if (!selectedSemester) {
      return;
    }
    const classesWithSemester = classes.filter(
      (classObj) => classObj.semester_id === selectedSemester.id
    );
    dispatch(setFilteredClasses(classesWithSemester));
  };

  const handleSemesterChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const semesterId = parseInt(event.target.value);
    const semester = allSemesters.find((semester) => semester.id === semesterId);
    dispatch(setSelectedSemester(semester));
  };

  return (
    <CustomSelect
      value={selectedSemester?.id}
      IconComponent={ExpandMoreIcon}
      onChange={handleSemesterChange}
    >
      {loadingIndicator || !allSemesters?.length ? (
        <option value="">Loading...</option>
      ) : (
        <SelectOptions semesters={allSemesters} />
      )}
    </CustomSelect>
  );
};

export default SemesterSelect;
