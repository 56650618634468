import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { LightModal } from "../LightModal/LightModal";
import {
  cancelHallPassLocationRequest,
  confirmHallPassLocationRequest,
  fetchHallPasses,
  scheduleHallPassCancellation,
  getRemainingTimeHallPassLocationRequest,
} from "../../Services/HallPassService";
import { CSSProperties } from "@mui/styles";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { RootState } from "../../store";
import { StudentOnLocation } from "../../Interfaces/SchoolInterface";
import { useNavigate } from "react-router-dom";

interface Props {
  openModal: boolean;
  studentOnLocation: StudentOnLocation;
  handleCloseDialog: () => void;
  currentSchool_id: number;
  passConfirmationTimeout: number; 
}

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

const ConfirmHallPassRequestModal: FC<Props> = ({
  openModal,
  studentOnLocation,
  handleCloseDialog,
  currentSchool_id,
  passConfirmationTimeout,
}): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { savingIndicator } = useSelector((state: RootState) => state.hallPass);
  const [countdown, setCountdown] = useState<number | null>(null);
  const remainingTime = useSelector((state: RootState) => state.hallPass.remainingTime);


  async function confirmHallPassRequest() {
    await dispatch(
      confirmHallPassLocationRequest(Number(studentOnLocation.id))
    );
    dispatch(fetchHallPasses(Number(currentSchool_id)));
    handleCloseDialog();
  }

  async function cancelHallPassRequest() {
    setOpenConfirmationModal(true);
  }

  async function onConfirmConfirmationModal() {
    console.log("modal id", studentOnLocation.id)
    await dispatch(
      cancelHallPassLocationRequest(Number(Number(studentOnLocation.id)))
    );
    handleCloseDialog();
    navigate("/StudentHallPassLocations");
  }

  // function formatTime(seconds: number) {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainderSeconds = seconds % 60;
  //   return `${minutes}:${remainderSeconds < 10 ? '0' : ''}${remainderSeconds}`;
  // }

  

  // useEffect(() => {
  //   if (studentOnLocation?.id && openModal) {
  //     dispatch(getRemainingTimeHallPassLocationRequest(studentOnLocation.id));
  //   }
  // }, [studentOnLocation, openModal, dispatch]);
  
  // useEffect(() => {
  //   console.log("remaining time", remainingTime);
  // }, [remainingTime]);



  
  // useEffect(() => {
  //   if (remainingTime != null) {
  //     setCountdown(remainingTime);
  //   }else {
  //     return;
  //   }
  // }, [remainingTime]);

  // useEffect(() => {
  //   if (remainingTime == null) {
  //     return;
  //   }
  //   let timerId: NodeJS.Timeout | null = null;
    
  //   if (countdown != null && countdown > 0) {
  //     timerId = setInterval(() => {
  //       setCountdown(prevCountdown => (prevCountdown ? prevCountdown - 1 : 0));
  //     }, 1000);
  //   }

  //   if (countdown === 0 || remainingTime === 0 || remainingTime < 0) {
  //     onConfirmConfirmationModal();
  //     clearInterval(timerId as NodeJS.Timeout);
  //   }

  //   return () => {
  //     if (timerId) clearInterval(timerId);
  //   };
  // }, [countdown, onConfirmConfirmationModal]);



  return (
    <>
      <CssBaseline />
      <LightModal
        width="330px"
        isOpenModal={openModal}
        handleOpenModal={() => {}}
      >
        <Card style={{}}>
          {/* Header */}
          <CardHeader title={`It's Your Turn!`}></CardHeader>
          {/* body */}
          <CardContent>
            <Typography>
              Confirm the pass to go to your destination 
            </Typography>
             {/* Countdown Timer */}
             {/* <Typography 
              align="center" 
              color="error" 
              gutterBottom 
              // variant="h4"
              style={{ fontSize: '4rem', fontWeight: 'bold' }}
              >
              {formatTime(countdown ?? 0)}
            </Typography> */}
          </CardContent>
          {/* footer */}
          <CardActions style={{ padding: 0, margin: 0 }}>
            <Grid
              container
              spacing="0"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid item xs={6}>
                <Button
                    disabled={savingIndicator}
                  style={{
                    ...styles.formButton,
                    background: "rgba(255, 78, 50, 1)",
                    color: "white",
                  }}
                  size="large"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={() => cancelHallPassRequest()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={savingIndicator}
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={styles.formButton}
                  fullWidth
                  size="large"
                  onClick={() => confirmHallPassRequest()}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </CardActions>
          {savingIndicator && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Card>
      </LightModal>
      <ConfirmationDialog
        open={openConfirmationModal}
        onCancel={() => setOpenConfirmationModal(false)}
        onConfirm={onConfirmConfirmationModal}
        title="Are you sure you want to cancel it?"
        message="You will have to make a new request and go back to the end of the line"
        loading={savingIndicator}
        okText="Yes"
        cancelText="No"
      />
    </>
  );
};

export default ConfirmHallPassRequestModal;
