import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import PageLoading from "./Pages/PageLoading";
import { Amplify, Auth } from "aws-amplify";

import appConfig from "./Config/appConfig";

const rememberMe = window.localStorage.getItem("SeedClassroomRememberMe");

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: appConfig.cognito.REGION,
    userPoolId: appConfig.cognito.USER_POOL_ID,
    userPoolWebClientId: appConfig.cognito.APP_CLIENT_ID,
  },
});

if(rememberMe){
  Auth.configure({
    storage:
    rememberMe === "true" ? window.localStorage : window.sessionStorage,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<PageLoading />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
