import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { FC, ReactElement, useEffect, useState, CSSProperties } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../Assets/logo.svg";
import { LoadingIndicator } from "../../../Components/LoadingIndicator/LoadingIndicator";
import { getGroupName } from "../../../Helpers/Utils";
import MenuItemInterface from "../../../Interfaces/MenuItem";
import { School } from "../../../Interfaces/SchoolInterface";
import { GroupEnum } from "../../../Interfaces/UserInterface";
import { getUserByUsername } from "../../../Services/UsersService";
import { RootState } from "../../../store";
import { setCurrentSchool } from "../../../store/slices/main";
import { SideMenuOptions } from "../side.menu.options";
import "./menu.drawer.css";
import { EditOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getUserProfileRoute } from "../../../Helpers/Navigation";
import { useCognitoAwsV2 } from "../../../Hooks/useCognitoAwsv2";
import { setEfficiencyDataSemesterAllSemester, setEfficiencyDataSemester, setSelectedSemester, setAllSemester } from "../../../store/slices/semester.slice";
import { margin } from "@mui/system";
import { setStudents } from "../../../store/slices/user.slice";

interface MenuDrawerProps {
  menuList?: MenuItemInterface[];
  onMenuItemSelected: () => void;
}

const styles = {
  formButton: {
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
    lineHeight: 1,
    backgroundColor: "white",
    color: "rgba(255, 78, 50, 1)",
  } as CSSProperties,
};

export const MenuDrawer: FC<MenuDrawerProps> = ({
  menuList,
  onMenuItemSelected,
}): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedSchool, setSelectedSchool] = useState<string>("");
  const [userSchools, setUserSchools] = useState<School[]>([]);
  const { currentUser } = useSelector((state: RootState) => state.main);
  const { user } = useSelector((state: RootState) => state.user);
  const { signOut, isAuthenticated } = useCognitoAwsV2();

  useEffect(() => {
    if (
      currentUser.group !== GroupEnum.SysAdmin &&
      !user?.profile_picture_encoded &&
      (currentUser.user_name !== undefined || currentUser?.user_name !== "")
    ) {
      getUserInfo();
    }
  }, [currentUser]);

  async function getUserInfo() {
    const { payload }: any = await dispatch(
      getUserByUsername(currentUser.user_name)
    );

    payload?.schools && setUserSchools(payload.schools);
    payload && changeCurrentSchool(payload.schools[0]);
  }

  function changeCurrentSchool(school: School) {
    dispatch(setCurrentSchool(school));
    setSelectedSchool(`${school?.id}`);
  }

  function handleEditProfileClick(e: React.MouseEvent<HTMLDivElement>) {
    const route = getUserProfileRoute(currentUser);

    route && navigate(route);
    onMenuItemSelected();
  }

  function handleSchoolChange({ target }: SelectChangeEvent<string>) {
    dispatch(setEfficiencyDataSemester({})) 
    dispatch(setEfficiencyDataSemesterAllSemester({}))
    dispatch(setSelectedSemester({}));
    dispatch(setAllSemester([]));
    dispatch(setStudents([]));
    const school = userSchools.find((s) => s.id == parseInt(target.value));
    school && changeCurrentSchool(school);

  }

  if (userSchools.length === 0 && currentUser.group === GroupEnum.SchoolAdmin) {
    return <LoadingIndicator />;
  }

  function handleLogOut() {
    signOut();
    if (isAuthenticated === false) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      navigate("/");
    }
  }

  return (
    <div>
      <div className="menu-drawer-container" onClick={handleEditProfileClick}>
        <div className="menu-drawer-container-logo">
          <div className="menu-user-card">
            <div className="menu-user-photo-profile">
              <Avatar
                className="photo-profile"
                src={user.profile_picture_encoded}
                alt={currentUser.first_name}
              ></Avatar>
            </div>
            <div className="menu-user-info-profile" style={{ display: "flex" }}>
              <p className="user-name-profile">
                <span>
                  {currentUser.first_name} {currentUser.last_name}
                </span>
                <br></br>
                {currentUser.group && getGroupName(currentUser.group)}
              </p>
              <EditOutlined sx={{ width: ".7em", margin: "10px 0 0 5px" }} />
            </div>
          </div>
          <img src={logo} className="logo" alt="logo" />
        </div>
        <span className="curve"></span>
        <div className="menu-drawer-ellipse1"></div>
        <div className="menu-drawer-ellipse2"></div>
      </div>
      <div className="menu-drawer-content">
        {currentUser.group === GroupEnum.SchoolAdmin && (
          <div className="button-school-container">
            <div className="button-box">
              <Select
                value={selectedSchool}
                variant="standard"
                onChange={handleSchoolChange}
                displayEmpty
                disableUnderline
                style={{ padding: "8px 15px", width: 255, maxWidth: 255 }}
              >
                {userSchools.length === 0 ? (
                  <MenuItem value={""}>Loading schools...</MenuItem>
                ) : (
                  userSchools.map((item, index) => (
                    <MenuItem key={index.toString()} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </div>
          </div>
        )}
        <SideMenuOptions
          itemList={menuList}
          onOptionSelected={onMenuItemSelected}
        />
      </div>
      <Box style={{ margin: 10 }}>
        <Button
          onClick={handleLogOut}
          style={styles.formButton}
          size="large"
          variant="contained"
          fullWidth
        >
          Logout
        </Button>
      </Box>
    </div>
  );
};
