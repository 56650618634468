import { createSlice } from "@reduxjs/toolkit";
import { Notification } from "../../Interfaces/UserInterface";
import {
  fetchAllNotificationsByUserName,
  updateReadNotificationById,
} from "../../Services/NotificationService";

export const notificationSlice = createSlice({
  name: "schedule",
  initialState: {
    notifications: [] as Notification[],
    loadingIndicator: false,
    savingIndicator: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllNotificationsByUserName.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchAllNotificationsByUserName.fulfilled,
        (state, { payload }) => {
          state.notifications = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(
        fetchAllNotificationsByUserName.rejected,
        (state) => {
          state.loadingIndicator = false;
        }
      );

    builder.addCase(updateReadNotificationById.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(
        updateReadNotificationById.fulfilled,
        (state, { payload }) => {
          const index = state.notifications.findIndex(
            (s) => s.id === payload.id
          );
          state.notifications[index] = payload;
          state.savingIndicator = false;
        }
      );
  },
});

export default notificationSlice.reducer;
