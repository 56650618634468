import React, { useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { FC, ReactElement } from "react";
import { Semester } from "../../Interfaces/SemesterInterface";
import { CSSProperties } from "@mui/styles";
import { PopButtonMenu } from "../PopButtonMenu/PopButtonMenu";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { deleteSemester } from "../../Services/SemestersService";
import moment from "moment";
import { useDispatch } from "react-redux";

interface ListProps {
  loadingSemesters: boolean;
  semesters: Semester[];
  selectedSemester?: Semester;
  isMobile: boolean;
  setSelectedSemester: (semester: Semester) => void;
  handleOpenModal: (state: boolean) => void;
}

const styles = {
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    minHeight: "79vh",
    maxHeight: "79vh",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "auto",
  } as CSSProperties,
  divider: { borderColor: "#3C404E", background: "#3C404E" },
  newButton: {
    marginRight: 10,
    textTransform: "none",
    color: "white",
    height: 35,
    width: 120,
  } as CSSProperties,
};

const buttonMenuOptions: string[] = ["Edit", "Delete", "Cancel"];

const confirmationDialogData: any = [
  {
    title: "Edit Semester",
    message: "Are you sure you want to edit this semester?",
  },
  {
    title: "Delete Semester",
    message: "Are you sure you want to delete this semester?",
  },
];

const defaultSemester: Semester = {
  name: "",
  start_date: new Date(),
  end_date: new Date(),
};

const SemesterList: FC<ListProps> = ({
  loadingSemesters,
  semesters,
  selectedSemester,
  isMobile,
  setSelectedSemester,
  handleOpenModal,
}): ReactElement => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState<boolean>(false);
  const [confirmationDialog, setConfirmationDialog] = React.useState<any>(
    confirmationDialogData[0]
  );

  const popUpHandlerClick = (
    event: React.MouseEvent<HTMLElement>,
    selectedOption: string | null,
    selectedSemester?: Semester
  ) => {
    event.preventDefault();

    if (!selectedOption) return;

    if (selectedSemester) {
      setSelectedSemester(selectedSemester);
    }

    if (selectedOption === "Cancel") {
      setConfirmationDialogOpen(false);
      return;
    } else if (selectedOption === "Edit") {
      handleOpenModal(true);
      return;
    }

    setConfirmationDialog(
      confirmationDialogData[buttonMenuOptions.indexOf(selectedOption)]
    );
    setConfirmationDialogOpen(true);
  };

  const optionListSubItem = (text: string, semesterDate: Date): JSX.Element => (
    <>
      <Typography
        sx={{ display: "inline", marginBottom: "0" }}
        component="span"
        variant="caption"
        color="text.primary"
      >
        {text}
      </Typography>
      <br />
      <Typography component="span" variant="body2" color="primary">
        {moment(semesterDate).format("MMM DD YYYY")}
      </Typography>
    </>
  );

  const confirmationDialogHandler = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    try {
      event.preventDefault();

      if (selectedSemester) {
        setDeleting(true);
        await dispatch(deleteSemester(parseInt(`${selectedSemester.id}`)));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSelectedSemester(defaultSemester);
      setDeleting(false);
      setConfirmationDialogOpen(false);
    }
  };

  const SemesterListItems = useMemo(
    () =>
      semesters?.map((item: Semester, index) => (
        <React.Fragment key={item.id}>
          <ListItem style={styles.listItem}>
            <ListItemText
              primaryTypographyProps={{
                variant: "subtitle2",
                fontWeight: "500",
                fontSize: "1rem",
                marginBottom: "0",
                color: "textPrimary",
              }}
              primary={item.name}
              secondary={
                <React.Fragment>
                  <Grid container spacing={1} mt={0}>
                    <Grid item xs={6} md={2} pt={0}>
                      {optionListSubItem("Start Date", item.start_date)}
                    </Grid>
                    <Grid item xs={6} md={2} pt={0}>
                      {optionListSubItem("End Date", item.end_date)}
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              secondaryTypographyProps={{
                variant: "caption",
                color: "textPrimary",
              }}
            />
            <PopButtonMenu
              menuOptions={buttonMenuOptions}
              onClick={popUpHandlerClick}
              data={item}
            />
            <ConfirmationDialog
              open={confirmationDialogOpen}
              title={confirmationDialog.title}
              message={confirmationDialog.message}
              onConfirm={confirmationDialogHandler}
              onCancel={() => setConfirmationDialogOpen(false)}
              loading={deleting}
            />
          </ListItem>
          <Divider key={`${item.id}${index}`} style={styles.divider} />
        </React.Fragment>
      )),
    [semesters, popUpHandlerClick, confirmationDialogHandler]
  );

  return (
    <>
      <Box style={styles.listContainer}>
        {loadingSemesters ? (
          <Box style={styles.loading}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <Box pl={2}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sm={9}>
                  <Typography
                    component="div"
                    align="left"
                    variant="subtitle2"
                    style={{ fontSize: "14px", lineHeight: "48px" }}
                  >
                    List of the semesters
                  </Typography>
                </Grid>
                <Grid item>
                  {!isMobile && (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      style={styles.newButton}
                      onClick={() => handleOpenModal(true)}
                    >
                      + Add new
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
            <List style={styles.list}>{SemesterListItems}</List>
          </>
        )}
      </Box>
    </>
  );
};

export default SemesterList;
