import { Route, Routes } from "react-router-dom";
import { LayoutAdaptive } from "../Layout/layout-adaptive/layout.adaptive";
import CalendarPage from "../Pages/CalendarPage";
import ChangePassword from "../Pages/ChangePassword";
import Dashboard from "../Pages/Dashboard";
import ForgotPassword from "../Pages/ForgotPassword";
import SignIn from "../Pages/Login";
import PageNotFound from "../Pages/PageNotFound";
import Schools from "../Pages/Schools";
import SignUp from "../Pages/Signup";
import UserProfile from "../Pages/UserProfile";
import ProtectedRoute from "./ProtectedRoute";
import SchedulePage from "../Pages/SchedulePage";
import SchoolAdmins from "../Pages/SchoolAdmins";
import AdminSettingsPage from "../Pages/AdminSettingsPage";
import CompleteRegistration from "../Pages/CompleteRegistration";
import OnEnterRoute from "./OnEnterRoute";
import UserAndRoles from "../Pages/UserAndRoles";
import RoomManagementPage from "../Pages/RoomManagementPage";
import Semesters from "../Pages/Semesters";
import TeachersRegistration from "../Pages/TeachersRegistrationPage";
import StudentRegistration from "../Pages/StudentRegistrationPage";
import Classes from "../Pages/ScheduleTeacherPage";
import Attendance from "../Pages/AttendanceTeacherPage";
import AttendanceList from "../Pages/AttendanceListPage";
import PrintQRCodes from "../Pages/PrintQRCodes";
import DashboardStudent from "../Pages/DashboardStudent";
import HallPass from "../Pages/HallPass";
import ReadQRForRegisterAttendance from "../Pages/ReadQRForRegisterAttendance";
import OnEnterRouteQR from "./OnEnterRouteQR";
import AttendanceRegisterPage from "../Pages/AttendanceRegisterPage";
import ReadQRPage from "../Pages/ReadQRPage";
import StudentHallPassLocations from "../Pages/StudentHallPassLocations";
import StudentHallPassLocationRequest from "../Pages/StudentHallPassLocationRequest";
import TeacherClassRosterPage from "../Pages/TeacherClassRosterPage";
import AdminSchoolSettingsPage from "../Pages/AdminSchoolSettingsPage";
import ActiveHallPassesPage from "../Pages/ActiveHallPassesPage";
import AbsentStudentsPage from "../Pages/AbsentStudentsPage";
import HallPassReportPage from "../Pages/HallPassReportPage";
import KioskTeacherPage from "../Pages/KioskTeacherPage";
import TeacherHallPassLocations from "../Pages/TeacherHallPassLocations";
import KioskStaffPage from "../Pages/KioskStaffPage";
import ScheduleClassesPage from "../Pages/ScheduleClasses";
import TeacherClassScheduleRosterPage from "../Pages/TeacherClassScheduleRosterPage";
import KioskStudentHallPassLocations from "../Pages/KioskStudentHallPassLocations";
import ReadQRForRegisterHallPass from "../Pages/ReadQRForRegisterHallPass";
import AdminStudentAttendance from "../Pages/AdminStudentAttendance";
import ClassTimeUtilization from "../Pages/ClassTimeUtilization";
import ProcessClassLink from "../Pages/ProcessClassLink";

const RouterApp: React.FC<any> = () => {
  return (
    <Routes>
      <Route element={<OnEnterRoute />}>
        <Route path="/" element={<SignIn />} />
        <Route path="ProcessClassLink" element={<ProcessClassLink />} />
        <Route path="SignUp" element={<SignUp />} />
        <Route path="ForgotPassword/:URLparams" element={<ForgotPassword />} />
        <Route
          path="SignUpTeachers/:cipher_params"
          element={<TeachersRegistration />}
        />
        <Route
          path="CompleteRegistration/:email"
          element={<CompleteRegistration />}
        >
          <Route
            path=":firstName/:lastName"
            element={<CompleteRegistration />}
          />
        </Route>
        <Route path="SignUpStudents" element={<StudentRegistration />} />
      </Route>

      <Route element={<OnEnterRouteQR />}>
        <Route
          path="school/:idSchool/room/:idRoom"
          element={<ReadQRForRegisterAttendance />}
        ></Route>
          <Route
          path="school/:idSchool/location/:idLocation"
          element={<ReadQRForRegisterHallPass />}
        ></Route>
      </Route>

      <Route element={<LayoutAdaptive />}>
        <Route
          element={
            <ProtectedRoute
              authGroups={[
                "SysAdmin",
                "SchoolAdmin",
                "Teacher",
                "Student",
                "Staff",
              ]}
            />
          }
        >
          <Route path="Dashboard" element={<Dashboard />} />
          <Route path="UserProfile" element={<UserProfile />} />
          <Route path="AdminSettings" element={<AdminSettingsPage />} />
          <Route path="ChangePassword" element={<ChangePassword />} />
          <Route path="Attendance" element={<Attendance />} />
          <Route path="AttendanceList" element={<AttendanceList />} />
          <Route path="HallPass" element={<HallPass />} />
        </Route>

        <Route element={<ProtectedRoute authGroups={["SysAdmin"]} />}>
          <Route path="Schools" element={<Schools />} />
          <Route path="SchoolAdmins" element={<SchoolAdmins />} />
        </Route>

        <Route element={<ProtectedRoute authGroups={["SchoolAdmin"]} />}>
          <Route path="Semesters" element={<Semesters />} />
          <Route path="Calendar" element={<CalendarPage />} />
          <Route path="Schedule" element={<SchedulePage />} />
          <Route path="SchoolSettings" element={<AdminSchoolSettingsPage />} />
          <Route path="UserAndRoles" element={<UserAndRoles />} />
          <Route path="RoomManagement" element={<RoomManagementPage />} />
          <Route path="ActiveHallPasses" element={<ActiveHallPassesPage />} />
          <Route path="Absents" element={<AbsentStudentsPage />} />
          <Route path="HallPassReportPage" element={<HallPassReportPage />} />
          <Route path="AdminStudentProfile" element={<AdminStudentAttendance />} />
          <Route path="ClassTimeUtilization" element={<ClassTimeUtilization />} />
        </Route>

        <Route element={<ProtectedRoute authGroups={["Teacher"]} />}>
          <Route path="Semesters" element={<Semesters />} />
          <Route path="PrintQRCodes" element={<PrintQRCodes />} />
          <Route path="ClassRoster" element={<TeacherClassRosterPage />} />
          <Route path="ClassScheduleRoster" element={<TeacherClassScheduleRosterPage />} />
          <Route path="Kiosk" element={<KioskTeacherPage />} />
          <Route path="ScheduleClasses" element={<ScheduleClassesPage />} />
          <Route
            path="TeacherHallPassLocations"
            element={<TeacherHallPassLocations />}
          />
          <Route path="KioskStudentHallPassLocations" element={<KioskStudentHallPassLocations />} />
        </Route>

        <Route
          element={<ProtectedRoute authGroups={["SchoolAdmin", "Teacher"]} />}
        >
          <Route path="TeacherSchedule" element={<Classes />} />
        </Route>

        <Route element={<ProtectedRoute authGroups={["Student"]} />}>
          <Route path="DashboardStudent" element={<DashboardStudent />} />
          <Route
            path="AttendanceRegister"
            element={<AttendanceRegisterPage />}
          />
          <Route path="ScanQRCode" element={<ReadQRPage />} />
          <Route
            path="StudentHallPassLocations"
            element={<StudentHallPassLocations />}
          />
          <Route
            path="StudentHallPassLocationRequest/location/:locationId/student/:userName/class/:classId/turnQueue/:turnQueue"
            element={<StudentHallPassLocationRequest />}
          />
        </Route>

        <Route element={<ProtectedRoute authGroups={["Staff"]} />}>
          <Route path="KioskStaff" element={<KioskStaffPage />} />
          <Route path="StudentProfile" element={<DashboardStudent />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default RouterApp;