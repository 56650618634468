
const PageNotFound = () => {
 
  return (
    <div>
      <p>Page not found</p>
    </div>
  );
};

export default PageNotFound;
