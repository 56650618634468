import { configureStore } from "@reduxjs/toolkit";
import mainReducer from "./slices/main";
import userReducer from "./slices/user.slice";
import schoolReducer from "./slices/school.slice";
import scheduleReducer from "./slices/schedule.slice";
import semesterReducer from "./slices/semester.slice";
import roomReducer from "./slices/room.slice";
import classReducer from "./slices/class.slice";
import calendarReducer from "./slices/calendar.slice";
import attendanceReducer from "./slices/attendance.slice";
import hallPassReducer from "./slices/hallPass.slice";
import notificationReducer from "./slices/notification.slice";

export const store = configureStore({
  reducer: {
    main: mainReducer,
    user: userReducer,
    school: schoolReducer,
    schedule: scheduleReducer,
    semester: semesterReducer,
    room: roomReducer,
    calendar: calendarReducer,
    attendance: attendanceReducer,
    class_: classReducer,
    hallPass: hallPassReducer,
    notification: notificationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
