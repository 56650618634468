import { FC, ReactElement } from "react";
import logo from "../../Assets/logo.svg";
import "./style.css";

export const Logo: FC = (): ReactElement => {
  return (
    <div className="logo-container">
      <div className="container-bg">
        <div className="ellipse1"></div>
        <div className="ellipse2"></div>
        <div className="container-content">
          <img src={logo} alt="logo" />
        </div>
        <span className="curve"></span>
      </div>
    </div>
  );
};
