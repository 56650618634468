import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoRefreshToken,
  CognitoIdToken,
  ISignUpResult,
} from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import UserPool from "./UserPool";

export class CognitoAuthService {

  private static _instance: CognitoAuthService;

  static getInstance() {
    if (!CognitoAuthService._instance) {
      CognitoAuthService._instance = new CognitoAuthService();
    }

    return CognitoAuthService._instance;
  }

  /**
   * signIn
   *
   * @param {string} email
   * @param {string} password
   * @return {*}  {Promise<any>}
   * @memberof CognitoAuthService
   */
  async signIn(email: string, password: string): Promise<any> {
    const signInResult = await Auth.signIn(email, password); ///HERE

    if (signInResult.challengeName === "NEW_PASSWORD_REQUIRED") {
      return {
        newPasswordRequired: true,
        cognitoUser: signInResult,
      };
    }
    return {
      newPasswordRequired: false,
      cognitoUser: signInResult,
    };
  }

  /**
   * completeNewPasswordChallenge
   *
   * @param {string} email
   * @param {*} oldPassword
   * @param {*} newPassword
   * @return {*} 
   * @memberof CognitoAuthService
   */
  async completeNewPasswordChallenge(
    email: string,
    oldPassword: any,
    newPassword: any,
    formFirstName?: string,
    formLastName?: string
  ): Promise<any> {
    const signInResult = await this.signIn(email, oldPassword);

    if (signInResult.newPasswordRequired) {
      const completeNewPasswordResult = await Auth.completeNewPassword(
        signInResult.cognitoUser,
        newPassword,
        { given_name: formFirstName, family_name: formLastName}
      );
      return completeNewPasswordResult;
    }
  }

  /**
   * signUp
   *
   * @param {string} email
   * @param {string} password
   * @param {string} firstName
   * @param {string} lastName
   * @return {*}  {(Promise<ISignUpResult | undefined>)}
   * @memberof CognitoAuthService
   */
  async signUp(
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ): Promise<ISignUpResult | undefined> {
    return new Promise((resolve, reject) => {
      const attributeList = [
        new CognitoUserAttribute({
          Name: "given_name",
          Value: firstName,
        }),
        new CognitoUserAttribute({
          Name: "family_name",
          Value: lastName,
        }),
      ];

      UserPool.signUp(
        email,
        password,
        attributeList,
        null as any,
        (err, data: ISignUpResult | undefined) => {
          if (err) {
            console.error(err);
            reject(err);
          }

          resolve(data);
        }
      );
    });
  }

  /**
   * authenticates the user
   *
   * @param {string} Username
   * @param {string} Password
   * @memberof CognitoAuthService
   */
  async authenticate(Username: string, Password: string) {
    await new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: Username,
        Pool: UserPool,
      });
      const authDetails = new AuthenticationDetails({ Username, Password });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("onSuccess:", data);
          resolve(data);
        },

        onFailure: (err) => {
          console.error("onFailure:", err);
          reject(err);
        },

        newPasswordRequired: (data) => {
          console.log("newPasswordRequired:", data);
          resolve(data);
        },
      });
    });
  }

  /**
   * changePasswordUser
   *
   * @param {string} email
   * @param {string} currentPassword
   * @param {string} newPassword
   * @param {*} cognitoUser
   * @return {*}  {Promise<any>}
   * @memberof CognitoAuthService
   */
  async changePasswordUser(
    currentPassword: string,
    newPassword: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getSession().then((result: any) => {
        const { user, email } = result;

        this.authenticate(email, currentPassword).then(() => {
          user.changePassword(
            currentPassword,
            newPassword,
            (err: any, result: any) => {
              if (err) {
                console.error(err);
                reject(err);
              }

              console.log(result);
              resolve(result);
            }
          );
        });
      });
    });
  }

  /**
   * forgotPassword
   *
   * @param {string} email
   * @return {*}  {Promise<any>}
   * @memberof CognitoAuthService
   */
  async forgotPassword(email: string): Promise<any> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    console.log(email);
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  async confirmForgotPassword(
    email: string,
    verificationCode: string,
    newPassword: string
  ): Promise<any> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  async confirmSignUp(email: string, registrationCode: string) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(
        registrationCode,
        false,
        (err, result) => {
          if (err) {
            reject(err);
          }
          resolve(result);
        }
      );
    });
  }

  signOut(): boolean {
    let result: boolean = false;

    try {
      const cognitoUser = UserPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.signOut();
        result = true;
      }
    } catch (error) {
      console.log("logOut error", error);
    }

    return result;
  }

  async refreshCognitoToken(token: string) {
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: token,
    });
    const cognitoUser = new CognitoUser({
      Username: refreshToken.getToken(),
      Pool: UserPool,
    });
    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, async (err, result) => {
        if (err) {
          reject(err);
        } else {
          const response = result;
          resolve(response);
        }
      });
    });
  }

  isTokenExpired(token: string): boolean {
    if (!token) {
      return true;
    }
    const idToken = new CognitoIdToken({
      IdToken: token,
    });
    const payload = idToken.decodePayload();
    const currentTime = new Date().getTime() / 1000;
    if (payload.exp < currentTime) {
      return true;
    }
    return false;
  }

  /**
   * getCurrentSession
   *
   * @return {*}  {Promise<any>}
   * @memberof CognitoAuthService
   */
  getCurrentSession(): Promise<any> {
    return new Promise((success, reject) => {
      const cognitoUser = UserPool.getCurrentUser();

      if (!cognitoUser) {
        reject("Could not retrieve current user");
        return;
      }

      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          reject("Error retrieving user session: ");
          return;
        }

        if (session.isValid()) {
          success(session);
        } else {
          reject("Session is not valid");
        }
      });
    });
  }

  /**
   * getSession
   *
   * @return {*}  {Promise<any>}
   * @memberof CognitoAuthService
   */
  async getSession(): Promise<any> {
    await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession(async (err: any, session: any) => {
          if (err) {
            reject();
          } else {
            const attributes: any = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results: any = {};

                  if (!attributes) {
                    return;
                  }

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                }
              });
            });

            resolve({
              user,
              ...session,
              ...attributes,
            });
          }
        });
      } else {
        reject();
      }
    });
  }

  /**
   * resendSignUp
   *
   * @param {string} email
   * @return {*}
   * @memberof CognitoAuthService
   */
  async resendSignUp(email: string) {
    // TODO: this is pending to implement
    console.log(email);
    return Promise.resolve();
  }
}
