import { CSSProperties, FC, ReactElement } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Grid,
  Button,
  CardActions,
  Alert,
} from "@mui/material";
import { LightModal } from "../LightModal/LightModal";
import { ReportGmailerrorredOutlined } from '@mui/icons-material';

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
    lineHeight: 1,
  } as CSSProperties,
};

interface ListProps {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
}

export const AccountLockedModal: FC<ListProps> = ({
  openModal,
  handleOpenModal,
}): ReactElement => {

  return (
    <>
      <LightModal isOpenModal={openModal} handleOpenModal={handleOpenModal}>
        <Card>
          <CardHeader title="Blocked account" sx={{ mx: 1, textAlign: 'center' }} />
          <CardContent sx={{ mx: 1 }}>
            <div
              style={{
                display: "flex",
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <span style={{ marginLeft: 20, marginRight: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <ReportGmailerrorredOutlined color="error" />
                  <div style={{ marginTop: 10 }}>For security reasons we have blocked your account, we have sent an email with the recovery instructions.</div>
                </div>
              </span>
            </div>
          </CardContent>
          <CardActions style={{ padding: 0, margin: 0, marginTop: 10 }}>
            <Grid
              container
              spacing="0"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    handleOpenModal(false);
                  }}
                  style={styles.formButton}
                  size="large"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Ok
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </LightModal>
    </>
  );
};
