import { ChevronRightOutlined } from "@mui/icons-material";
import { Card, CardContent, Box, Typography, useTheme } from "@mui/material";
import { CSSProperties, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useWebSocket } from "../../Hooks/useWebSocket";
import { SocketContext } from "../../Providers/SocketProvider";
import { fetchStudentsWithActivePassBySchoolId } from "../../Services/HallPassService";
import { RootState } from "../../store";

const styles = {
  dimmedFont: {
    color: "rgba(255,255,255,0.7)",
    fill: "rgba(255,255,255,0.7)",
  } as CSSProperties,
};

const ActiveHallPassesCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { studentsWithActivePass } = useSelector(
    (state: RootState) => state.hallPass
  );

  useWebSocket([
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        currentSchool.id &&
          dispatch(fetchStudentsWithActivePassBySchoolId(currentSchool.id));
      },
    },
  ]);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.primary.main,
        boxShadow: 8,
        cursor: "pointer",
      }}
      onClick={() => navigate("/ActiveHallPasses")}
    >
      <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={500}>Active Hall Passes</Typography>
          <Box display="flex" alignItems="center">
            <Typography
              fontWeight={500}
              paddingRight={1}
              sx={styles.dimmedFont}
            >
              Total:
            </Typography>
            <Typography fontWeight={500} fontSize="1.75em" paddingRight={2}>
              {studentsWithActivePass.length}
            </Typography>
            <ChevronRightOutlined sx={styles.dimmedFont} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ActiveHallPassesCard;
