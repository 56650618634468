import React, { FC, ReactElement, useState } from 'react'
import { Button, alpha, styled as muStyled, Box, useMediaQuery, useTheme } from "@mui/material";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import { withDarkTheme } from '../../Hocs/withDarkTheme';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { KeyboardArrowDown } from "@mui/icons-material";


const StyledButtonMultiColor = muStyled(Button, { shouldForwardProp: (prop: any) => prop !== 'bgColor' && prop !== 'defaultWidth' }
)<{ bgColor?: string; defaultWidth?: number }>(({ bgColor = 'primary', defaultWidth = 0 }) => (({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    width: defaultWidth && defaultWidth !== 0 ? defaultWidth : 'auto',
    color: bgColor === 'primary' ? theme.palette.primary.main : bgColor,
    textTransform: 'capitalize',
    '&:hover, &:focus': {
        backgroundColor: alpha(bgColor === 'primary' ? theme.palette.primary.main : bgColor,
            theme.palette.action.selectedOpacity
        ),
    },
    '&.Mui-selected': {
        backgroundColor: alpha(bgColor === 'primary' ? theme.palette.primary.main : bgColor,
            theme.palette.action.selectedOpacity
        ),
        color: bgColor === 'primary' ? theme.palette.primary.main : bgColor,
    },
    '&.Mui-selected:hover, &.Mui-selected:focus': {
        backgroundColor: alpha(bgColor === 'primary' ? theme.palette.primary.main : bgColor,
            theme.palette.action.selectedOpacity
        ),
        color: bgColor === 'primary' ? theme.palette.primary.main : bgColor,
    },
    '&.MuiPickersDay-root:not(.Mui-selected)': {
        backgroundColor: alpha(bgColor === 'primary' ? theme.palette.primary.main : bgColor,
            theme.palette.action.selectedOpacity
        ),
        color: bgColor === 'primary' ? theme.palette.primary.main : bgColor,
    },
})));

const StyledInput = muStyled('input')(({ theme }) => ({
    color: theme.palette.primary.main,
    boxShadow: 'none',
    fontSize: '14px',
    backgroundColor: theme.palette.background.default,
    textTransform: 'capitalize',
    border: 0,
    boxSizing: 'content-box',
    margin: 0,
    display: 'block',
    animationName: 'mui-auto-fill-cancel',
    animationDuration: '10ms',
    width: '105px',
    minWidth: '64px',
    padding: '9px 15px',
    borderRadius: '4px',
    outline: 'none',
    fontFamily: '-apple-system,Gotham,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 500,
}));

interface CalendarSelectorProps {
    bgColor?: string,
    defaultWidth?: number,
    style?: any,
    onChange: (date: Date) => void,
    selectedDate: Date,
}

const CalendarSelectorComponent: FC<CalendarSelectorProps> = ({ bgColor = 'primary', defaultWidth, style, onChange, selectedDate }): ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [buttonColor, setButtonColor] = useState(bgColor);
    const [value, setValue] = React.useState<Date | null>(new Date(selectedDate) || new Date());

    const open = Boolean(anchorEl);

    return (
        <div style={style}>
            <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker
                    label="Select Date"
                    value={value}
                    onChange={(newValue: any) => {
                        setValue(newValue);
                        !isMobile && onChange(newValue as Date);
                    }}
                    onClose={() => {
                        isMobile && onChange(value as Date)
                    }}
                    inputFormat="E MMM dd'th'"
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {!isMobile &&
                                <StyledButtonMultiColor
                                    id="demo-customized-button"
                                    ref={inputRef}
                                    aria-controls={open ? "demo-customized-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    variant="contained"
                                    disableElevation
                                    bgColor={buttonColor}
                                    defaultWidth={defaultWidth}
                                    endIcon={InputProps?.endAdornment}>
                                    {inputProps?.value}
                                </StyledButtonMultiColor>
                            }
                            {isMobile &&
                                <>
                                    <StyledInput ref={inputRef} {...inputProps} />
                                    <KeyboardArrowDown fontSize="small" color="primary" sx={{ ml: -3 }} />
                                </>
                            }
                        </Box>
                    )}
                />
            </LocalizationProvider>
        </div>
    )
}

export const CalendarSelector = withDarkTheme(CalendarSelectorComponent);
