import {
  Box,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { FC, ReactElement } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { District } from "../../Interfaces/SchoolInterface";
import { CSSProperties } from "@mui/styles";

interface ListProps {
  loadingIndicator: boolean;
  districts: District[];
  isMobile: boolean;
}

const styles = {
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "56px",
  } as CSSProperties,
  divider: {
    borderColor: "#3C404E",
    background: "#3C404E",
    borderBottomWidth: 2,
  },
};

const DistrictList: FC<ListProps> = ({
  loadingIndicator,
  districts,
  isMobile,
}): ReactElement => {
  styles.listContainer = {
    ...styles.listContainer,
    maxHeight: isMobile ? "60vh" : "50vh",
    minHeight: isMobile ? "60vh" : "50vh",
  };
  return (
    <>
      <Box style={styles.listContainer}>
        {loadingIndicator ? (
          <Box style={styles.loading}>
            <LinearProgress />
          </Box>
        ) : (
          <List style={styles.list}>
            {districts?.map((item, index) => (
              <div key={index.toString()}>
                <ListItem style={styles.listItem}>
                  <ListItemText primary={item.name} />
                  <IconButton edge="end" aria-label="menu">
                    <MoreVertIcon />
                  </IconButton>
                </ListItem>
                <Divider style={styles.divider} />
              </div>
            ))}
          </List>
        )}
      </Box>
    </>
  );
};

export default DistrictList;
