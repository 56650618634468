import { DeleteOutline } from "@mui/icons-material";
import { Badge, Divider, Grid, IconButton, List } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { useState } from "react";
import { Class } from "../../Interfaces/ClassInterface";
import { User } from "../../Interfaces/UserInterface";
import { RemoveStudentDialog } from "./RemoveStudentDialog";

const styles = {
  divider: {
    borderColor: "#3C404E",
    background: "#3C404E",
    borderBottomWidth: 2,
  } as CSSProperties,
  badge: {
    fontWeight: "lighter",
    span: {
      fontSize: "1em",
      color: "white",
    } as CSSProperties,
    "span.percent": {
      fontSize: ".7em",
    } as CSSProperties,
  },
  classListItem: {
    backgroundColor: "#464b5b",
    marginBottom: "8px",
    paddingTop: 0,
  } as CSSProperties,
};

interface Props {
  students: User[];
  selectedClass: Class;
  isSaving: boolean;
}

const ClassRosterList = ({ students, selectedClass, isSaving }: Props) => {
  const [removeStudentModalOpen, setRemoveStudentModalOpen] =
    useState<boolean>(false);
  const [selectedStudentId, setSelectedStudentId] = useState<
    number | undefined
  >();

  const handleDeleteClicked = (studentId: number | undefined) => {
    if (!studentId) return;

    setSelectedStudentId(studentId);
    setRemoveStudentModalOpen(true);
  };

  return (
    <>
      <List sx={{ p: 0 }}>
        {students.map((item: User, index: number) => (
          <div key={index.toString()} style={styles.classListItem}>
            <Grid
              container
              rowSpacing={1}
              sx={{
                ".MuiGrid-item": {
                  paddingTop: 0,
                },
              }}
            >
              {/* NOTE: Feature will be used in a future US
                <Grid
                  item
                  xs={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                    <Badge
                      badgeContent={
                        <span>
                          95<span className="percent">%</span>
                        </span>
                      }
                      color="warning"
                      sx={{ ...styles.badge }}
                    />
                </Grid>
              */}
              <Grid
                item
                xs={10}
                md={11}
                display="flex"
                justifyContent="start"
                alignItems="center"
                paddingLeft={2}
              >
                <p>{`${item.first_name} ${item.last_name}`}</p>
              </Grid>
              <Grid
                item
                xs={2}
                md={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton onClick={() => handleDeleteClicked(item.id)}>
                  <DeleteOutline sx={{ fill: "#ff4e32" }} />
                </IconButton>
              </Grid>
            </Grid>
            {index !== students.length - 1 && (
              <Divider style={styles.divider} />
            )}
          </div>
        ))}
      </List>
      <RemoveStudentDialog
        openModal={removeStudentModalOpen}
        handleOpenModal={setRemoveStudentModalOpen}
        studentId={selectedStudentId}
        selectedClass={selectedClass}
        isSaving={isSaving}
      />
    </>
  );
};

export default ClassRosterList;
