import { createSlice } from "@reduxjs/toolkit";
import { Absent, Attendance } from "../../Interfaces/AttendanceInterface";
import {
  createAttendance,
  fetchAttendanceByClassID,
  findAttendanceByDateAndPeriod,
  fetchAbsentsBySchoolID,
  updateAttendanceStatus,
} from "../../Services/AttendanceService";

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState: {
    attendances: [] as Attendance[],
    absents: [] as Absent[],
    initialAttendances: [] as Attendance[],
    loadingIndicator: false,
    savingIndicator: false,
    errorMessage: "",
    successMessage: "",
  },
  reducers: {
    setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    filterAttendanceUsers: (state, { payload }) => {
      if (payload !== "") {
        const results = state.initialAttendances.filter((attendance) => {
          const fullName = `${attendance?.user?.first_name} ${attendance?.user?.last_name}`;
          return fullName.toLowerCase().startsWith(payload.toLowerCase());
        });
        state.attendances = results;
      } else {
        state.attendances = state.initialAttendances;
      }
    },
    clearAttendance: (state) => {
      state.attendances = [];
    },
  },
  extraReducers: (builder) => {
    /* #region  Create Attendance */
    builder.addCase(createAttendance.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(createAttendance.fulfilled, (state, { payload }) => {
        state.attendances.push(payload);
        state.successMessage = "Attendance created successfully";
        state.savingIndicator = false;
        state.errorMessage = "";
      }),
      builder.addCase(createAttendance.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.successMessage = "";
        state.savingIndicator = false;
      });
    /* #endregion */
    /* #region  Fetch Attendance by ClassID */
    builder.addCase(fetchAttendanceByClassID.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchAttendanceByClassID.fulfilled,
        (state, { payload }) => {
          state.attendances = payload;
          state.initialAttendances = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(fetchAttendanceByClassID.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.loadingIndicator = false;
      });
    /* #endregion */
    /* #region  Fetch Attendance by Attendance_date AND Period_id */
    builder.addCase(findAttendanceByDateAndPeriod.pending, (state) => {
      state.attendances = [];
      state.initialAttendances = [];
      state.loadingIndicator = true;
    }),
      builder.addCase(
        findAttendanceByDateAndPeriod.fulfilled,
        (state, { payload }) => {
          state.attendances = payload;
          state.initialAttendances = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(
        findAttendanceByDateAndPeriod.rejected,
        (state, { error }) => {
          state.attendances = [];
          state.errorMessage = `${error.message}`;
          state.loadingIndicator = false;
        }
      );
    /* #endregion */

    /* #region  Absents by SchoolID */
    builder.addCase(fetchAbsentsBySchoolID.pending, (state) => {
      state.loadingIndicator = true;
      state.absents = [];
    }),
      builder.addCase(
        fetchAbsentsBySchoolID.fulfilled,
        (state, { payload }) => {
          state.absents = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(fetchAbsentsBySchoolID.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.loadingIndicator = false;
      });
    /* #endregion */

    /* #region  Update Attendace status */
    builder.addCase(updateAttendanceStatus.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(
        updateAttendanceStatus.fulfilled,
        (state, { payload }) => {
          const index = state.attendances.findIndex(
            (s) => s.user_id === payload.user_id
          );
          if (index > -1) {
            state.attendances[index] = payload;
          }
          state.successMessage = "Attendance updated successfully";
          state.savingIndicator = false;
        }
      ),
      builder.addCase(updateAttendanceStatus.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });
    /* #endregion */
  },
});

export default attendanceSlice.reducer;
export const {
  setSuccessMessage,
  setErrorMessage,
  filterAttendanceUsers,
  clearAttendance,
} = attendanceSlice.actions;
