import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay';
import { FC } from 'react';
import { Calendar, DateTypeEnum } from '../../Interfaces/CalendarInterface';
import { withDarkTheme } from '../../Hocs/withDarkTheme';
import moment from 'moment';

type CustomPickerDayProps = PickersDayProps<Date> & {
  isHoliday: boolean;
  isSchoolDay: boolean;
};

type SelectedPickersDayProps = PickersDayProps<Date> & {
  backgroundColor?: string;
};

const SelectedPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<SelectedPickersDayProps>(({ theme, backgroundColor }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  '&:hover, &:focus': {
    backgroundColor: backgroundColor === 'primary' ? theme.palette.primary.main : theme.palette.background.paper,
  },
  '&.Mui-selected': {
    backgroundColor: backgroundColor === 'primary' ? theme.palette.primary.main : theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  '&.Mui-selected:hover, &.Mui-selected:focus': {
    backgroundColor: backgroundColor === 'primary' ? theme.palette.primary.main : theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  '&.MuiPickersDay-root:not(.Mui-selected)' : {
    backgroundColor: backgroundColor === 'primary' ? theme.palette.primary.main : theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: backgroundColor === 'primary' ?? 'none',
  },
})) as React.ComponentType<SelectedPickersDayProps>;

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isHoliday' && prop !== 'isSchoolDay',
})<CustomPickerDayProps>(({ theme, isHoliday, isSchoolDay }) => ({
  ...(isHoliday && {
    backgroundColor: 'rgba(255, 78, 50, 0.2)',
    color: theme.palette.secondary.main,
    '&:hover, &:focus': {
      backgroundColor: 'rgba(255, 78, 50, 0.2)',
      color: theme.palette.secondary.main,
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(255, 78, 50, 0.2)',
      color: theme.palette.secondary.main,
    },
    '&.Mui-selected:hover, &.Mui-selected:focus': {
      backgroundColor: 'rgba(255, 78, 50, 0.2)',
      color: theme.palette.secondary.main,
    },
  }),
  ...(isSchoolDay && {
    backgroundColor: 'rgb(255 173 0 / 20%)',
    color: '#fdbb11',
    '&:hover, &:focus': {
      backgroundColor: 'rgb(255 173 0 / 20%)',
      color: '#fdbb11',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgb(255 173 0 / 20%)',
      color: '#fdbb11',
    },
    '&.Mui-selected:hover, &.Mui-selected:focus': {
      backgroundColor: 'rgb(255 173 0 / 20%)',
      color: '#fdbb11',
    },
  }),
})) as React.ComponentType<CustomPickerDayProps>;

interface CustomCalendarProps {
  selectedDate: Date | null;
  calendarDays: Calendar[];
  onChange: (date: Date | null) => void;
}

export const CustomCalendarComponent: FC<CustomCalendarProps> = (props) => {

  const { selectedDate, calendarDays, onChange }: CustomCalendarProps = props;

  /* This function is calling for each day of the year */
  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>,
  ) => {

    // Verifies if any of the saved calendar days is the current day to render the day in RED Color
    const calendarDayExists = calendarDays?.find((calendarDay: any) => moment(calendarDay.selected_date).isSame(date, 'day'));

    if (calendarDayExists) {

      const isHoliday = calendarDayExists?.day_type_enum === DateTypeEnum.Holiday;
      const isSchoolDay = calendarDayExists?.day_type_enum === DateTypeEnum.Schoolday;

      return <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        isHoliday={isHoliday}
        isSchoolDay={isSchoolDay}
      />;
    }

    // if it's today and it's not a saved calendar day set GREEN color
    if (moment(date).isSame(moment(), 'day')) {
      return <SelectedPickersDay backgroundColor="primary" {...pickersDayProps} />;
    }

    // if the user selects a day in the calendar avoid the green color
    if (moment(selectedDates[0]).isSame(date, 'day')) {
      return <SelectedPickersDay {...pickersDayProps} />;
    }

    return <PickersDay {...pickersDayProps} />;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        label="Week picker"
        value={selectedDate}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        renderDay={renderWeekPickerDay}
        renderInput={(params) => <TextField {...params} />}
        inputFormat="'Week of' MMM d"
        allowSameDateSelection={true}
      />
    </LocalizationProvider>
  );
}

CustomCalendarComponent.defaultProps = {
  selectedDate: new Date(),
  calendarDays: []
}

export const CustomCalendar = withDarkTheme(CustomCalendarComponent);