import { useEffect, useState, CSSProperties } from "react";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { StudentHallPassLocationsList } from "../Components";
import { LayoutContainer } from "../Layout/layout.container";
import { getQueueManagerByUserName } from "../Services/UsersService";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, LinearProgress } from "@mui/material";
import {
  setCurrentClass,
  setPageSubtitle,
  setPageTitle,
} from "../store/slices/main";
import {
  fetchHallPasses,
  getCurrentHallPassRequestByStudentUserName,
} from "../Services/HallPassService";
import { useWebSocket } from "../Hooks/useWebSocket";
import { useNavigate } from "react-router-dom";
import { Class } from "../Interfaces/ClassInterface";
import { getCurrentClassByStudent } from "../Services/ClassService";
import { getAttendanceByStudentAndClass } from "../Services/AttendanceService";

const styles = {
  loading: { width: "100%" } as CSSProperties,
};

const StudentHallPassLocations = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentClassLoading, setCurrentClassLoading] =
    useState<boolean>(false);

  const { currentSchool, currentUser, currentClass } = useSelector(
    (state: RootState) => state.main
  ) || {};
  const { hallPassLocations, loadingIndicator } = useSelector(
    (state: RootState) => state.hallPass
  ) || {};
    const [localStorageCurrentClass, setLocalStorageCurrentClass] =
    useState<Class>();
  
  async function getClassByStudent() {
    try {
      const currentClass = await getCurrentClassByStudent(
        currentUser?.user_name
      );
      
      console.log("currentClass", currentClass)
      if (!currentClass) throw new Error();
      const attendance = await getAttendanceByStudentAndClass(currentUser?.user_name,currentClass.id);
      if(attendance.length == 0) throw new Error();
      setLocalStorageCurrentClass(currentClass);
      dispatch(setCurrentClass(currentClass));
      dispatch(setPageTitle(currentClass?.name || ""));
      dispatch(setPageSubtitle(`Room ${currentClass?.room?.name || "unknown"}`));
    } catch (error) {
      navigate("/ScanQRCode");
    }
  }

  async function getData() {
    try {
      const result = await getCurrentHallPassRequestByStudentUserName(currentUser?.user_name);
      const queueManager = await getQueueManagerByUserName(currentUser?.user_name)
      console.log("current user", currentUser)
      if (result) {
        dispatch(setCurrentClass(result?.current_class));
        navigate(
          `/StudentHallPassLocationRequest/location/${result?.location_id}/student/${currentUser?.user_name}/class/${result?.student.current_class_id}/turnQueue/${queueManager.turn ?? 0}`
        );
      } else {
        await getClassByStudent();
        await dispatch(fetchHallPasses(Number(currentSchool?.id)));
      }
      if(queueManager && queueManager.turn >= 0) {
        navigate(
          `/StudentHallPassLocationRequest/location/${queueManager?.location_id}/student/${currentUser?.user_name}/class/${result?.student.current_class_id}/turnQueue/${queueManager.turn}`
        );
      } 
    } catch (error) {
      console.error(error);
    } finally {
      setCurrentClassLoading(false);
    }
  }
  
  useEffect(() => {
    if (currentSchool?.id) {
      setCurrentClassLoading(true);
      //get current request
      getData();
      const interval = setInterval(() => getData(), 60000);
      return () => clearInterval(interval);
    }
  }, [currentSchool]);  

  WebSocket;
  useWebSocket([
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        currentSchool?.id && dispatch(fetchHallPasses(Number(currentSchool?.id)));
      },
    },
  ]);


  return (
    <>
      <LayoutContainer isRaised={true}>
        {currentClassLoading ? (
          <Box style={styles.loading}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            {localStorageCurrentClass?.id && (
              <StudentHallPassLocationsList
                loadingIndicator={loadingIndicator}
                hallPassLocations={hallPassLocations}
                isMobile={isMobile}
              />
            )}
          </>
        )}
            
      </LayoutContainer>
    </>
  );
};

export default StudentHallPassLocations;
