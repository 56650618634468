import { createSlice } from "@reduxjs/toolkit";
import {
  District,
  School,
  HallPassLocation,
  SchoolSettings,
} from "../../Interfaces/SchoolInterface";
import {
  fetchSchools,
  createSchool,
  GetDistricts,
  PostDistricts,
  fetchSchoolSettingsBySchoolId,
  updateSchoolSettingsBySchoolId,
} from "../../Services/SchoolsService";

export const schoolSlice = createSlice({
  name: "school",
  initialState: {
    schools: [] as School[],
    districts: [] as District[],
    hallPassLocations: [] as HallPassLocation[],
    currentSchoolSettings: {} as SchoolSettings,
    loadingIndicator: false,
    savingIndicator: false,
    errorMessage: "",
    successMessage: "",
  },
  reducers: {
    setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSchools.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(fetchSchools.fulfilled, (state, { payload }) => {
        state.schools = payload;
        state.loadingIndicator = false;
      }),
      builder.addCase(fetchSchools.rejected, (state) => {
        state.loadingIndicator = false;
      });

    builder.addCase(createSchool.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(createSchool.fulfilled, (state, { payload }) => {
        state.schools.push(payload);
        state.successMessage = "School created successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(createSchool.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // Districts
    builder.addCase(GetDistricts.fulfilled, (state, action) => {
      state.districts = action.payload as any;
      state.loadingIndicator = false;
    }),
      builder.addCase(GetDistricts.pending, (state) => {
        state.loadingIndicator = true;
      });
    builder.addCase(PostDistricts.fulfilled, (state, action) => {
      state.districts.push(action.payload);
      state.successMessage = "District created successfully";
      state.loadingIndicator = false;
    }),
      builder.addCase(PostDistricts.pending, (state) => {
        state.loadingIndicator = true;
      });

    // Settings
    builder.addCase(
      fetchSchoolSettingsBySchoolId.fulfilled,
      (state, { payload }) => {
        state.currentSchoolSettings = payload;
        state.loadingIndicator = false;
      }
    ),
      builder.addCase(fetchSchoolSettingsBySchoolId.pending, (state) => {
        state.loadingIndicator = true;
      }),
      builder.addCase(
        fetchSchoolSettingsBySchoolId.rejected,
        (state, { error }) => {
          state.errorMessage = `${error.message}`;
          state.savingIndicator = false;
        }
      );

    builder.addCase(
      updateSchoolSettingsBySchoolId.fulfilled,
      (state, { payload }) => {
        state.successMessage = "Settings updated successfully";
        state.currentSchoolSettings = payload;
        state.savingIndicator = false;
      }
    ),
      builder.addCase(updateSchoolSettingsBySchoolId.pending, (state) => {
        state.savingIndicator = true;
      }),
      builder.addCase(
        updateSchoolSettingsBySchoolId.rejected,
        (state, { error }) => {
          state.errorMessage = `${error.message}`;
          state.savingIndicator = false;
        }
      );
  },
});

export default schoolSlice.reducer;
export const { setSuccessMessage, setErrorMessage } = schoolSlice.actions;
