import { FC, ReactElement, CSSProperties, useEffect, useState } from "react";
import SchedulePageHeader from "../Components/Teacher/SchedulePageHeader";
import { LayoutContainer } from "../Layout/layout.container";
import { RootState } from "../store";
import { useSelector, useDispatch } from "react-redux";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  fetchAllBySchoolIdAndTeacherUsername,
  getClassesByTeacherAndDate,
} from "../Services/ClassService";
import { fetchRoomsBySchoolId } from "../Services/RoomService";
import { fetchDayTypesBySchoolId } from "../Services/ScheduleService";
import {
  fetchActiveSemestersBySchoolId,
  fetchAllSemestersBySchoolId,
  findByDate,
} from "../Services/SemestersService";
import {
  setPageTitle,
  setPageSubtitle,
  AlertState,
  setCustomAlertDetails,
  setShowCustomAlert,
  setCurrentClass,
} from "../store/slices/main";
import { setSelectedSemester } from "../store/slices/semester.slice";
import {
  AlertColor,
  Box,
  LinearProgress,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { Class, ScheduleClasses } from "../Interfaces/ClassInterface";

import {
  AddFloatButton,
  ClassList,
  Group,
  TeacherModal,
  CustomAlert,
  GroupSvgMobile,
  ConfirmationDialog,
} from "../Components";
import ScheduleClassesModal from "../Components/Teacher/TeracherAddClassModal";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../store/slices/class.slice";
import ClassListSchedule from "../Components/Teacher/ClassListSchedule";

import "./ScheduleClasses.css";
import "../Assets/calendar.svg";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import { Semester } from "../Interfaces/SemesterInterface";
import { findBySchoolAndDate } from "../Services/CalendarService";
import { Calendar } from "../Interfaces/CalendarInterface";

const styles = {
  listContainer: {
    backgroundColor: "#3C404E",
    borderRadius: "5px",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    margin: "1rem 0",
    textAlign: "center",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    backgroundColor: "#464B5B",
    borderRadius: "5px",
    marginBottom: ".6rem",
    height: "auto",
  } as CSSProperties,
  listGridContainer: {
    display: "flex",
    marginRight: "4px",
  } as CSSProperties,
  listGridText: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    marginLeft: "3px",
    paddingBottom: "6px",
  } as CSSProperties,
  divider: { borderColor: "#3C404E", backgroundColor: "#3C404E" },
  newButton: {
    textTransform: "none",
    color: "white",
    height: 35,
    width: 150,
  } as CSSProperties,
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
  primaryTextList: {
    variant: "caption",
    color: "textPrimary",
    fontSize: "0.9rem",
  } as CSSProperties,
  secondaryTextList: {
    variant: "subtitle2",
    fontWeight: "500",
    fontSize: "1.1rem",
    marginBottom: "0",
    color: "textPrimary",
  } as CSSProperties,
};

const ScheduleClassesPage: FC<any> = (): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const dayTypeState = useSelector((state: RootState) => state.schedule);
  const roomState = useSelector((state: RootState) => state.room);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [title, setTitle] = useState<string>("Update Class");

  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { user } = useSelector((state: RootState) => state.user);

  // const [selectedSemester, setSelectedSemester] = useState<Semester>();
  // const [semesterLoadingIndicator, setSemesterLoadingIndicator] =
  //   useState<boolean>(false);

  const { selectedSemester, loadingIndicator: semesterLoadingIndicator } =
    useSelector((state: RootState) => state.semester);

  const [multipleClasses, setMultipleClasses] = useState<ScheduleClasses[]>([]);
  // const [filteredClasses, setFilteredClasses] = useState<Class[]>([]);
  // const [filteredClassesIndicator, setFilteredClassesIndicator] =
  //   useState<boolean>(false);

  // //DatePicker
  // const [openStartDate, setOpenStartDate] = useState<boolean>(false);
  // const [date, setDate] = useState<Date | null>(new Date());

  const {
    classes,
    currentClass,
    currentScheduleClass,
    filteredClasses,
    loadingIndicator: classLoadingIndicator,
    errorMessage,
    successMessage,
  } = useSelector((state: RootState) => state.class_);

  useEffect(() => {
    dispatch(setPageTitle(" "));
    dispatch(setPageSubtitle(" "));
    return () => {
      dispatch(setSelectedSemester(undefined));
    };
  }, []);

  //
  useEffect(() => {
    if (currentSchool.id) {
      dispatch(
        fetchAllBySchoolIdAndTeacherUsername({
          school_id: currentSchool.id,
          user_name: user.user_name,
        } as any)
      );
      dispatch(fetchActiveSemestersBySchoolId(currentSchool.id));
      dispatch(fetchAllSemestersBySchoolId(currentSchool.id));
      dispatch(fetchDayTypesBySchoolId(currentSchool.id));
      dispatch(fetchRoomsBySchoolId(currentSchool.id));
    }
  }, [currentSchool]);

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  // useEffect(() => {
  //   setSemesterCalendarIndicator(true);
  //   const fetchData = async () => {
  //     if (user.id && currentSchool.id && date)
  //       return await findBySchoolAndDate(currentSchool.id, date);
  //   };
  //   try {
  //     fetchData()
  //       .then((result: Calendar) => {
  //         if (result.id) {
  //           setCalendar(result);
  //         } else {
  //           setCalendar(undefined);
  //         }
  //         setSemesterCalendarIndicator(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setCalendar(undefined);
  //         setSemesterCalendarIndicator(false);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     console.log(
  //       "No existe un calendar day o un semestre para la fecha seleccionada"
  //     );
  //     setSelectedSemester(undefined);
  //     setSemesterLoadingIndicator(false);
  //   }
  // }, [date]);

  // useEffect(() => {
  //   setSemesterLoadingIndicator(true);
  //   const fetchData = async () => {
  //     if (user.id && currentSchool.id && date)
  //       return await findByDate(currentSchool.id, date);
  //   };
  //   try {
  //     fetchData()
  //       .then((result: Semester) => {
  //         if (result.id) {
  //           setSelectedSemester(result);
  //         } else {
  //           setSelectedSemester(undefined);
  //         }
  //         setSemesterLoadingIndicator(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setSelectedSemester(undefined);
  //         setSemesterLoadingIndicator(false);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     console.log(
  //       "No existe un calendar day o un semestre para la fecha seleccionada"
  //     );
  //     setSelectedSemester(undefined);
  //     setSemesterLoadingIndicator(false);
  //   }
  // }, [date]);

  // useEffect(() => {
  //   if (user && currentSchool && selectedSemester) {
  //     setFilteredClassesIndicator(true);
  //     const fetchData = async () => {
  //       if (user.id && currentSchool.id)
  //         return await getClassesByTeacherAndDate(
  //           user.id,
  //           currentSchool.id,
  //           date
  //         );
  //     };
  //     fetchData()
  //       .then((result: Class[]) => {
  //         //console.log(result);
  //         setFilteredClasses(result);
  //         setFilteredClassesIndicator(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setFilteredClasses([]);
  //         setFilteredClassesIndicator(false);
  //       });
  //   }
  // }, [user, currentSchool, selectedSemester]);

  useEffect(() => {
    if (filteredClasses && filteredClasses.length > 0) {
      const groupClasses: ScheduleClasses[] = [];
      filteredClasses.forEach((c) => {
        if (c.schedule_id) {
          //
          const gcFound = groupClasses.find(
            (x) => x.schedule_id === c.schedule_id
          );
          if (gcFound) {
            //
            groupClasses[
              groupClasses.indexOf(gcFound)
            ].classesByScheduleId.push(c);
          } else {
            //
            groupClasses.push({
              classesByScheduleId: [c],
              schedule_id: c.schedule_id,
            });
          }
        } else {
          //
          groupClasses.push({
            classesByScheduleId: [c],
          });
        }
      });
      // console.log(groupClasses);
      setMultipleClasses(groupClasses);
    } else {
      setMultipleClasses([]);
    }
  }, [filteredClasses]);

  useEffect(() => {
    if (successMessage) {
      showAlert(successMessage, "success");
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      showAlert(errorMessage, "error");
    }
  }, [errorMessage]);

  function isLoading() {
    return classLoadingIndicator || semesterLoadingIndicator;
  }

  function isClassesEmpty() {
    return !classes.length || !filteredClasses.length;
  }

  function handleAddNewClicked() {
    if (!selectedSemester?.id) {
      setWarningDialogOpen(true);
      return;
    }
    handleOpenModal(true, "create");
  }

  function handleOpenModal(value: boolean, origin: string) {
    if (origin === "create") {
      setTitle("Create New Class");
    } else {
      setTitle("Update Class");
    }

    setOpenModal(value);
  }

  function reload() {
    dispatch(
      fetchAllBySchoolIdAndTeacherUsername({
        school_id: currentSchool.id,
        user_name: user.user_name,
      } as any)
    );
  }

  // const DatePickerItem = (): ReactElement => (
  //   <MobileDatePicker
  //     open={openStartDate}
  //     onOpen={() => setOpenStartDate(true)}
  //     onClose={() => setOpenStartDate(false)}
  //     inputFormat="MMM dd, yyyy"
  //     onAccept={(date: any) => handleAcceptDate(date)}
  //     onChange={(date: any) => handleChangeDate(date)}
  //     value={date}
  //     renderInput={({ inputRef, inputProps, InputProps }) => (
  //       <div className="schedule-list-selector-date">
  //         <table>
  //           <tbody>
  //             <tr>
  //               <th className="curr-date">
  //                 <p> Your schedule for </p>
  //                 <p>{moment(date).format("MMM DD , ddd")}</p>
  //                 <input
  //                   style={{ display: "none" }}
  //                   ref={inputRef}
  //                   {...inputProps}
  //                 />
  //               </th>
  //               <th className="cal">
  //                 <div
  //                   className="cal-button"
  //                   onClick={() =>
  //                     setOpenStartDate((openStartDate) => !openStartDate)
  //                   }
  //                 ></div>
  //               </th>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>
  //     )}
  //   ></MobileDatePicker>
  // );

  function handleChangeDate(date: any) {
    // setDate(date);
  }

  function handleAcceptDate(date: any) {
    const newDate = moment(date)
      .set("hour", moment(new Date()).get("hour"))
      .set("minute", moment(new Date()).get("minute"))
      .set("second", moment(new Date()).get("second"))
      .set("millisecond", moment(new Date()).get("millisecond"));
    // setDate(newDate.toDate());
  }

  const getEmptyClass = (): Class => {
    return {
      id: undefined,
      name: "",
      description: "",
      day_type_id: undefined,
      period_id: undefined,
      room_id: undefined,
    };
  };

  return (
    <>
      {!isMobile ? <div style={{ marginTop: "-80px" }}></div> : <div></div>}
      <LayoutContainer isRaised={true}>
        <SchedulePageHeader
          areClassesLoaded={!isLoading() && !isClassesEmpty()}
          isMobile={isMobile}
          styles={styles}
          onAddNewClicked={handleAddNewClicked}
        />

        {isLoading() || isClassesEmpty() ? (
          <Box style={styles.listContainer} flexDirection="column">
            {semesterLoadingIndicator && (
              <Box style={styles.loading}>
                <LinearProgress />
              </Box>
            )}
            <Box
              flexDirection="column"
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "5%",
                overflow: "hidden",
              }}
            >
              {isMobile && <GroupSvgMobile style={{ marginTop: "10vh" }} />}
              {!isMobile && <Group />}
              <Typography
                variant="subtitle1"
                fontWeight="500"
                fontSize="1.5rem"
                marginTop="1.5rem"
                marginBottom="0.1rem"
                color="textPrimary"
              >
                Add a Class to Your
                <br /> Schedule
              </Typography>
              <div className="ellipsis1"></div>
            </Box>
            {!isMobile && (
              <Button
                sx={{ margin: "20px 0 50px" }}
                variant="contained"
                color="primary"
                style={styles.newButton}
                onClick={handleAddNewClicked}
                disabled={isLoading()}
              >
                + Add new
              </Button>
            )}
            {isMobile && (
              <AddFloatButton
                handleOpenModal={handleAddNewClicked}
                position="center"
                disableFloating
                disabled={isLoading()}
              />
            )}
          </Box>
        ) : (
          <Box>
            {isMobile && (
              <AddFloatButton handleOpenModal={handleAddNewClicked} />
            )}
            <ClassListSchedule
              defaultClass={getEmptyClass()}
              selectedClass={currentClass}
              setSelectedClass={setCurrentClass}
              styles={styles}
              loadingClasses={classLoadingIndicator || semesterLoadingIndicator}
              classes={filteredClasses}
              multipleClasses={multipleClasses}
              isMobile={isMobile}
              handleOpenModal={handleOpenModal}
              reload={reload}
            />
          </Box>
        )}

        <ScheduleClassesModal
          title={title}
          styles={styles}
          school_id={parseInt(`${currentSchool.id}`)}
          handleOpenModal={handleOpenModal}
          reload={reload}
          openModal={openModal}
          classes={classes}
          dayTypes={dayTypeState.dayTypes}
          periods={dayTypeState.periods}
          rooms={roomState.rooms}
          selectedScheduleClass={currentScheduleClass}
          selectedClass={currentClass}
          selectedSemester={selectedSemester}
        />
        <ConfirmationDialog
          open={warningDialogOpen}
          title="Warning"
          message="The current date doesn't have a related semester or calendar record"
          onConfirm={() => setWarningDialogOpen(false)}
          loading={false}
        />
      </LayoutContainer>
      <CustomAlert handleClose={handleCloseAlert} />
    </>
  );
};

export default ScheduleClassesPage;
