import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
  Stack,
  InputAdornment,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { FC, ReactElement, useEffect, useState } from "react";
import {
  IsBeforeDateField,
  IsSameDateField,
  MaxCharCountField,
  RequiredStringField,
} from "../../Helpers/FormValidations";
import useForm, { FormField } from "../../Hooks/useForm";
import { LightModal } from "../LightModal/LightModal";
import { Period, PeriodFormError } from "../../Interfaces/ScheduleInterface";
import { createPeriod } from "../../Services/ScheduleService";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileTimePicker from "@mui/lab/MobileTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ScheduleIcon from "@mui/icons-material/Schedule";
import moment from "moment";
import { getTimeDateformat } from "../../Helpers/Utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

interface ListProps {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
  day_type_id: number;
}

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

const defaultDateTime: string = "07:30";

const newPeriodForm: Period = {
  name: "",
  day_type_id: 0,
  start_time: defaultDateTime,
  end_time: defaultDateTime,
};

const NewPeriodModal: FC<ListProps> = ({
  openModal,
  handleOpenModal,
  day_type_id,
}): ReactElement => {
  const {
    form,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleCompleted,
  } = useForm<Period>(newPeriodForm, validateformField, submitForm);
  const dispatch = useDispatch();
  const { periods, successMessage, periodsSavingIndicator } = useSelector(
    (state: RootState) => state.schedule
  );

  useEffect(() => {
    successMessage !== "" && handleCloseModal();
  }, [successMessage]);

  function handleCloseModal() {
    handleCompleted(true);
    handleOpenModal(false);
  }

  async function submitForm(form: Period) {
    if (!form.name || !!errors.name || !!errors.end_time) return;
    form.day_type_id = day_type_id;
    form.start_time = getFormattedDateTime(form.start_time.toString());
    form.end_time = getFormattedDateTime(form.end_time.toString());
    dispatch(createPeriod(form));
  }

  function getFormattedDateTime(time: string): string {
      const timeArray = time.split(":");
      const formattedDate = moment().set({
        hour: Number(timeArray[0]),
        minute: Number(timeArray[1]),
        second: 0,
      });
      return moment.utc(formattedDate).format();
  }

  function checkIfAlreadyExist(value: string): string {
    if (periods) {
      const result = periods?.find((s) => s.name === value);
      return result === undefined
        ? ""
        : "A period with the same name already exists";
    }
    return "";
  }

  function validateformField(
    fieldName: string,
    fieldValue: string | Date
  ): FormField {
    let value: string = "";

    switch (fieldName) {
      case "name": {
        value =
          RequiredStringField(fieldName, fieldValue as string) ||
          MaxCharCountField(fieldName, fieldValue as string, 40) ||
          checkIfAlreadyExist(fieldValue as string);
        break;
      }
      case "start_time": {
        const startTime = getFormattedDateTime(fieldValue.toString());
        value = isBetweenPeriodDates(new Date(startTime));
        break;
      }
      case "end_time": {

        const startTime = getFormattedDateTime(form.start_time.toString());
        const endTime = getFormattedDateTime(fieldValue.toString());
        value =
          IsBeforeDateField(startTime, endTime) ||
          IsSameDateField(startTime, endTime) ||
          isBetweenPeriodDates(new Date(endTime)) ||
          isPeriodBetweenTimeRange(new Date(startTime), new Date(endTime));
        break;
      }
    }
    return {
      name: fieldName,
      value: value,
    } as FormField;
  }

  function isBetweenPeriodDates(time: Date) {
    /***Required to be disabled */
    return "";
    let isBetween: boolean = false;
    let errorMessage: string = "";

    for (let i = 0; i < periods.length; i++) {
      isBetween = moment(time).isBetween(
        `${time.toISOString().slice(0, 10)}T${
          (periods[i].start_time as string).split("T")[1]
        }`,
        `${time.toISOString().slice(0, 10)}T${
          (periods[i].start_time as string).split("T")[1]
        }`
      );

      if (isBetween) {
        errorMessage = "Another period already has this time span";
        break;
      }
    }

    return errorMessage;
  }

  function isPeriodBetweenTimeRange(startTime: Date, endTime: Date) {
    /***Required to be disabled */
    return "";
    let isStartTimeBetween: boolean = false;
    let isEndTimeBetween: boolean = false;
    let errorMessage: string = "";

    for (let i = 0; i < periods.length; i++) {
      isStartTimeBetween = moment(periods[i].start_time).isBetween(
        startTime,
        endTime
      );
      isEndTimeBetween = moment(periods[i].end_time).isBetween(
        startTime,
        endTime
      );
      if (isStartTimeBetween || isEndTimeBetween) {
        errorMessage = "Another period is within hours range";
        break;
      }
    }
    return errorMessage;
  }

  function readyToSubmit(): boolean {
    return !(
      !form.name ||
      periodsSavingIndicator ||
      !!errors.name ||
      !!errors.end_time
    );
  }

  return (
    <>
      <LightModal isOpenModal={openModal} handleOpenModal={handleOpenModal}>
        <Card>
          <CardHeader title="Add new period" sx={{ mx: 1 }} />
          <form onSubmit={handleSubmit} autoComplete="off">
            <CardContent sx={{ mx: 1 }}>
              <Stack spacing={2}>
                <TextField
                  style={{ marginBottom: "5px" }}
                  type="text"
                  name="name"
                  fullWidth
                  label="Period name"
                  variant="standard"
                  value={form.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.name}
                  helperText={errors.name && errors.name}
                />
                <TextField
                  style={{ marginBottom: "5px", width: "100%" }}
                  label="Time start"
                  type="time"
                  name="start_time"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.start_time}
                  helperText={errors.start_time && errors.start_time}
                  defaultValue={form.start_time}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 60, // 1 min
                  }}
                  sx={{ width: 150 }}
                />
                <TextField
                  style={{ marginBottom: "5px", width: "100%" }}
                  label="Time end"
                  type="time"
                  name="end_time"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.end_time}
                  helperText={errors.end_time && errors.end_time}
                  defaultValue={form.end_time}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 60, // 1 min
                  }}
                  sx={{ width: 150 }}
                />
              </Stack>
            </CardContent>
            <CardActions style={{ padding: 0, margin: 0 }}>
              <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleCompleted(true);
                      handleOpenModal(false);
                    }}
                    disabled={periodsSavingIndicator}
                    style={styles.formButton}
                    size="large"
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={styles.formButton}
                    fullWidth
                    size="large"
                    disabled={!readyToSubmit()}
                  >
                    Add period
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            {periodsSavingIndicator && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </form>
        </Card>
      </LightModal>
    </>
  );
};

export default NewPeriodModal;
