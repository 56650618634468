import { GroupEnum, User } from "../Interfaces/UserInterface";

export const getUserProfileRoute = (user: User) => {
  // Navigate to different views depending on user group
  let route = "";
  switch (user.group) {
    case GroupEnum.SchoolAdmin:
      route = "/AdminSettings";
      break;
    default:
      break;
  }

  return route;
};
