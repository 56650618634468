import { Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  List,
  TextField,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSchoolAdminsBySchoolId } from "../../Services/UsersService";
import { RootState } from "../../store";
import { filterSchoolAdmins } from "../../store/slices/user.slice";
import UserListItem from "./UserListItem";

const styles = {
  loading: { width: "100%" } as CSSProperties,
  divider: {
    borderColor: "#3C404E",
    background: "#3C404E",
    borderBottomWidth: 2,
  },
  searchContainer: {
    background: "#3C404E",
    padding: "10px",
    height: 50,
    width: "100%",
    borderRadius: "8px 8px 0px 0px ",
  } as CSSProperties,
};

interface Props {
  isMobile: boolean;
  handleOpenModal: (status: boolean) => void;
}

const SchoolAdminsList: FC<Props> = ({ isMobile, handleOpenModal }) => {
  const dispatch = useDispatch();
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { schoolAdmins, loadingIndicator } = useSelector(
    (state: RootState) => state.user
  );
  useEffect(() => {}, []);

  useEffect(() => {
    if (currentSchool.id) {
      dispatch(fetchAllSchoolAdminsBySchoolId(currentSchool.id));
    }
  }, [currentSchool]);

  function filter(e: React.ChangeEvent<HTMLInputElement>) {
    const keyword = e.target.value.trim();
    dispatch(filterSchoolAdmins(keyword));
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box style={styles.searchContainer}>
            <TextField
              placeholder="Search by name"
              variant="standard"
              fullWidth
              onChange={filter}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {loadingIndicator ? (
        <Box style={styles.loading}>
          <LinearProgress />
        </Box>
      ) : (
        <List sx={{ padding: 0 }}>
          {schoolAdmins?.map((item, index) => (
            <div key={index.toString()}>
              <UserListItem
                user={item}
                isMobile={isMobile}
                group="Admin"
                handleOpenModal={handleOpenModal}
              />
              <Divider style={styles.divider} />
            </div>
          ))}
        </List>
      )}
    </>
  );
};

export default SchoolAdminsList;
