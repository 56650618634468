import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  TextField,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  SelectChangeEvent,
  FormHelperText,
  Avatar,
  Typography,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { FC, ReactElement, useEffect, useState } from "react";
import { LightModal } from "../LightModal/LightModal";
import { GroupEnum, User, UserFormError } from "../../Interfaces/UserInterface";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBackIos } from "@mui/icons-material";
import { RootState } from "../../store";
import {
  IsValidEmail,
  MaxCharCountField,
  RequiredStringField,
} from "../../Helpers/FormValidations";
import { createUser, updateUser, getUsersByPreventMeeting, updatePreventMeetingByStudent } from "../../Services/UsersService";
import { fetchSchools } from "../../Services/SchoolsService";
import { UserRolesTabOptions } from "../../Config/usersRolesTabList";
import useForm, { FormField } from "../../Hooks/useForm";
import profilePictureIcon from "../../Assets/profile_picture_icon.png";
import { setSelectedUserStore, setErrorMessage } from "../../store/slices/user.slice";

  
interface ListProps {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
  isRolSelectionDisabled?: boolean;
}

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

let newUserForm: User = {
  first_name: "",
  last_name: "",
  email: "",
  user_name: "",
  group: GroupEnum.SchoolAdmin,
  schools: [],
  profile_picture: "",
};

const modalTitles = { insert: "Create a New User", edit: "Edit User" };

export const NewUserModal: FC<ListProps> = ({
  openModal,
  handleOpenModal,
  isRolSelectionDisabled = false,
}): ReactElement => {
  const dispatch = useDispatch();

  const [roleSelected, setRoleSelected] = useState<GroupEnum>(
    GroupEnum.SchoolAdmin
  );
  const [profilePicture, setProfilePicture] = useState<string>();
  const [modalTitle, setModalTitle] = useState<string>(modalTitles.insert);
  const [isNewUser, setIsNewUser] = useState<boolean>(true);
  const [studentsSelected, setStudentsSelected] = useState<number[]>([]);
  const [openPreventMeetupModal, setOpenPreventMeetupModal] = useState<boolean>(false);
  const [studentsFieldError, setStudentsFieldError] = useState<string>("");
  const [searchValue, setSearchValue] = useState("");
  /* #region  Store */
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { usersByPreventMeeting, students, schoolAdmins, loadingIndicator, successMessage, selectedUser } =
    useSelector((state: RootState) => state.user);
  /* #endregion */

  const {
    form,
    setForm,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleCompleted,
  } = useForm<User>(newUserForm, validateformField, submitForm);

  useEffect(() => {
    dispatch(fetchSchools());
  }, []);

  useEffect(() => {
    setForm({ ...selectedUser, profile_picture: "" } ?? newUserForm);
    setRoleSelected(selectedUser?.group ?? GroupEnum.None);
    setModalTitle(selectedUser.id ? modalTitles.edit : modalTitles.insert);
    setIsNewUser(selectedUser.id ? false : true);
    if(selectedUser?.id)
      dispatch(getUsersByPreventMeeting({ studentId: selectedUser?.id ?? 1 }));
  }, [openModal, selectedUser]);

  useEffect(() => {
    setStudentsSelected(usersByPreventMeeting);
  }, [usersByPreventMeeting]);

  useEffect(() => {
    successMessage !== "" && handleCloseModal();
  }, [successMessage]);

  
  function handleSelectManagers() {
    setOpenPreventMeetupModal(true);
  }

  function handleCloseManagersModal() {
    console.log("handleCloseStudentsModal");
    setOpenPreventMeetupModal(false);
  }

  function handleSaveManagersModal() {
    setOpenPreventMeetupModal(false);
    setStudentsFieldError("");
  }

  function validateformField(fieldName: string, fieldValue: string): FormField {
    let value: string = "";

    switch (fieldName) {
      case "first_name": {
        const labelValue = "First Name";
        value = RequiredStringField(labelValue, fieldValue);
        break;
      }
      case "last_name": {
        const labelValue = "Last Name";
        value = RequiredStringField(labelValue, fieldValue);
        break;
      }
      case "floorName": {
        const labelValue = "Floor";
        value =
          RequiredStringField(labelValue, fieldValue) ||
          MaxCharCountField(labelValue, fieldValue, 25);
        break;
      }
      case "roomName": {
        const labelValue = "Room";
        value =
          RequiredStringField(labelValue, fieldValue) ||
          MaxCharCountField(labelValue, fieldValue, 25);
        break;
      }
      case "manager_id": {
        value = fieldValue ? "" : "No manager selected";
        break;
      }
    }

    return {
      name: fieldName,
      value: value,
    } as FormField;
  }

  function checkErrors() {
    return (
      !!errors.first_name ||
      !!errors.last_name ||
      !!errors.email ||
      !!errors.profile_picture ||
      !!errors.school_ids||
      !!errors.group
    );
  }

  function submitForm(form: User) {
    setStudentsFieldError('')
    if (checkErrors()) return;

    form.group = roleSelected;
    form.schools = [currentSchool];

    if (isNewUser) {
      if(form.group)
      dispatch(createUser(form));
      else
      dispatch(setErrorMessage("Role should not be empty"));
    } else {
      //dispatch(updateUser(form));
      if (form.id) {
        const arr = [...studentsSelected];
        console.log(arr.length)
        if(arr.length > 1) arr.push(form.id)
        if(arr.length == 1) { 
          (arr[0] == form.id) ? arr.length = 0 : arr.push(form.id)
        }
        dispatch(updatePreventMeetingByStudent({ studentId: form.id ?? 1, students : arr }));
      }
    }
  }

  function handleCloseModal() {
    handleCompleted(true);
    handleOpenModal(false);
    setStudentsSelected([]);
    setProfilePicture("");
    setRoleSelected(GroupEnum.None);
    dispatch(setSelectedUserStore({}));
  }

  function handleChangeSchool({
    target,
  }: SelectChangeEvent<typeof roleSelected>) {
    setRoleSelected(target.value as GroupEnum);
  }

  function renderStudents(selected: number[]) {
    return students
      .filter((s) => selected.includes(s.id || 0))
      .map((m) => `${m.last_name} ${m.first_name}`)
      .join(", ");
  }

  function checkIfAlreadyExist(value: string): string {
    let message = "";

    if (schoolAdmins) {
      const result = schoolAdmins?.find((s) => s.email === value);
      if (result !== undefined)
        message = "Another user with the same email already exists";
    }
    return message;
  }

  function validateProfilePicture() {
    let message = "";
    if (!profilePicture) {
      message = "Profile picture not found";
    }
    return message;
  }

  async function handlePreviewProfilePicture(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    if (e.target.files?.length) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target?.files[0]);
      const url = URL.createObjectURL(e.target?.files[0]);
      setProfilePicture(url);
      reader.addEventListener(
        "load",
        () => {
          setForm({
            ...form,
            profile_picture: reader.result,
          });
        },
        false
      );
    }
  }

  function validateForm() {
    let errors = {} as UserFormError;

    !errors.first_name &&
      (errors.first_name = RequiredStringField("name", form.first_name));
    !errors.last_name &&
      (errors.last_name = RequiredStringField("name", form.last_name));
    !errors.email && (errors.email = RequiredStringField("name", form.email));
    !errors.email && (errors.email = checkIfAlreadyExist(form.email));

    !errors.school_ids &&
      (errors.school_ids = roleSelected === "" ? "No schools selected" : "");

    !errors.profile_picture &&
      (errors.profile_picture = validateProfilePicture());

    !errors.email && (errors.email = IsValidEmail(form.email));

    return errors;
  }

  return (
    <>
      <LightModal
        width="650px"
        isOpenModal={openModal}
        handleOpenModal={handleOpenModal}
      >
        <Card>
          <CardHeader title={modalTitle} sx={{ mx: 1 }} />
          <form onSubmit={handleSubmit} autoComplete="off">
            <CardContent sx={{ mx: 1 }}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 8,
                    }}
                  >
                    <Avatar
                      alt="Profile picture"
                      src={profilePicture || profilePictureIcon}
                      style={{ width: 56, height: 56 }}
                    />
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "unset",
                          height: 32,
                          marginLeft: 10,
                        }}
                        onClick={() =>
                          document.getElementById("profile_picture")?.click()
                        }
                      >
                        Upload photo
                      </Button>
                      {!!errors.profile_picture && (
                        <Typography
                          style={{ marginLeft: 10, fontSize: "0.75rem" }}
                          color="error"
                        >
                          {errors.profile_picture && errors.profile_picture}
                        </Typography>
                      )}
                    </div>
                    <input
                      id="profile_picture"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ display: "none" }}
                      onChange={handlePreviewProfilePicture}
                    />
                  </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    type="text"
                    name="first_name"
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    value={form.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.first_name}
                    helperText={errors.first_name && errors.first_name}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    type="text"
                    name="last_name"
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    value={form.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.last_name}
                    helperText={errors.last_name && errors.last_name}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={!!errors.school_ids}
                    disabled={isRolSelectionDisabled}
                  >
                    <InputLabel id="new-admin-modal-school-select">
                      Assign a Role
                    </InputLabel>
                    <Select
                      label="Assign a Role"
                      labelId="new-admin-modal-school-select"
                      value={roleSelected}
                      onChange={handleChangeSchool}
                    >
                      <MenuItem value={GroupEnum.None} disabled>
                        Assign a Role
                      </MenuItem>
                      { isRolSelectionDisabled ? (
                          UserRolesTabOptions?.map((userRole) => (
                            <MenuItem
                              key={userRole.id}
                              value={userRole.name.replace(" ", "")}
                            >
                              <ListItemText primary={userRole.name} />
                            </MenuItem>
                          ))
                        ) : (
                          UserRolesTabOptions.filter(x => !x.isRolSelectionDisabled)?.map((userRole) => (
                            <MenuItem
                              key={userRole.id}
                              value={userRole.name.replace(" ", "")}
                            >
                              <ListItemText primary={userRole.name} />
                            </MenuItem>
                          ))
                        )}
                    </Select>
                    <FormHelperText>
                      {errors.group && errors.group}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    style={{ marginBottom: "5px" }}
                    type="text"
                    name="email"
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={form.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!isNewUser}
                    error={!!errors.email}
                    helperText={errors.email && errors.email}
                  />
                </Grid>
                {roleSelected === GroupEnum.Student &&(
                <Grid item sm={12} xs={12}>
                  <FormControl
                      variant="standard"
                      fullWidth
                      error={!!studentsFieldError}
                    >
                      <InputLabel id="new-admin-modal-school-select">
                       Meet-up prevention
                      </InputLabel>
                      <Select
                        onClick={handleSelectManagers}
                        disabled
                        labelId="new-admin-modal-school-select"
                        multiple
                        value={studentsSelected}
                        renderValue={renderStudents}
                        name="managers_ids"
                      ></Select>
                      <FormHelperText>
                      {studentsFieldError && studentsFieldError}
                    </FormHelperText>
                    </FormControl>
                </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions style={{ padding: 0, margin: 0 }}>
              <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => handleCloseModal()}
                    disabled={loadingIndicator}
                    style={styles.formButton}
                    size="large"
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={styles.formButton}
                    fullWidth
                    size="large"
                    disabled={loadingIndicator || checkErrors()}
                  >
                    {isNewUser ? "Create User" : "Update User"}
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            {loadingIndicator && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </form>
        </Card>
      </LightModal>

      {/* Managers modal */}
      <LightModal
  width="650px"
  isOpenModal={openPreventMeetupModal}
  handleOpenModal={() => {}}
>
  <Card>
    <CardHeader
      title={
        <>
          <IconButton
            style={{ marginTop: -3 }}
            onClick={() => {
              handleCloseManagersModal();
            }}
          >
            <ArrowBackIos fontSize="small" />
          </IconButton>
          <span>Student Selection</span>
        </>
      }
    />
    <CardContent sx={{ mx: 2, overflowY: "scroll", maxHeight: 300 }}>
      <TextField
        sx={{ marginBottom : '1rem'}}
        label="Search Students"
        variant="outlined"
        fullWidth
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      {students?.map((student, index) => {
        const isDisabled = selectedUser.id === student.id;
        const shouldDisplay = !searchValue || `${student.last_name} ${student.first_name}`.toLowerCase().includes(searchValue.toLowerCase());

        if (!shouldDisplay) {
          return null;
        }

        const handleChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          if (isDisabled) {
            return;
          }
          const arr = [...studentsSelected];
          if (event.target.checked) {
            arr.push(student.id as number);
          } else {
            arr.splice(arr.indexOf(student.id as number), 1);
          }
          setStudentsSelected(arr);
        };
        return (
          <div key={`checkbox-${index}`} style={{display : (isDisabled) ? 'none' : 'block'}}>
            <FormControlLabel
              className="checkbox-group-checkbox-label"
              control={
                <Checkbox
                  name={`${student.last_name}-${student.first_name}`}
                  value={student.id as number}
                  checked={
                    studentsSelected.indexOf(parseInt(`${student.id}`)) >
                    -1
                  }
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              }
              label={`${student.last_name} ${student.first_name}`}
            />
          </div>
        );
      })}
    </CardContent>
    <CardActions style={{ padding: 0, margin: 0 }}>
      <Grid
        container
        spacing="0"
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: "1rem" }}
      >
        <Grid item xs={6}>
          <Button
            onClick={() => {
              handleCloseManagersModal();
            }}
            disabled={loadingIndicator}
            style={styles.formButton}
            size="large"
            color="secondary"
            variant="contained"
            fullWidth
          >
            Go Back
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => handleSaveManagersModal()}
            color="primary"
            variant="contained"
            style={styles.formButton}
            fullWidth
            size="large"
            disabled={loadingIndicator && !studentsSelected.length}
          >
            Select student
          </Button>
        </Grid>
      </Grid>
    </CardActions>
  </Card>
</LightModal>
    </>
  );
};
