import { NotificationsOutlined } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { FC, ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getGroupName } from "../../../Helpers/Utils";
import { GroupEnum } from "../../../Interfaces/UserInterface";
import { getUserByUsername } from "../../../Services/UsersService";
import { RootState } from "../../../store";
import { setLogOut } from "../../../store/slices/user.slice";
import "../components/user.toolbar.desktop.css";
import ReactHtmlParser from "react-html-parser";
import { SocketContext } from "../../../Providers/SocketProvider";
import { getUserProfileRoute } from "../../../Helpers/Navigation";
import { StaffLocationsDropdown } from "../../../Components";

export const UserToolbarDesktop: FC = (): ReactElement => {
  const navigate = useNavigate();

  //store
  const dispatch = useDispatch();
  const { pageTitle, pageSubtitle, currentUser, isStaffLocationPage } =
    useSelector((state: RootState) => state.main);
  const { user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (
      currentUser.group !== GroupEnum.SysAdmin &&
      !user?.profile_picture_encoded &&
      (currentUser.user_name !== undefined || currentUser.user_name !== "")
    ) {
      dispatch(getUserByUsername(currentUser.user_name));
    }
  }, [currentUser]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClicked = () => {
    const route = getUserProfileRoute(currentUser);

    route && navigate(route);
    handleClose();
  };

  const handleLogOut = () => {
    dispatch(setLogOut);

    //clean session localStorage session storage and redirect to login page
    localStorage.clear();
    sessionStorage.clear();

    setAnchorEl(null);

    navigate("/");
  };

  return (
    <>
      <Toolbar>
        <table className="table-toolbar-user">
          <tbody>
            <tr>
              <td className="tool-bar-title">
                <p className="title">{pageTitle}</p>
                <p className="subtitle">{ReactHtmlParser(pageSubtitle)}</p>
                {isStaffLocationPage && <StaffLocationsDropdown />}
              </td>
              <td></td>
              <td className="tool-bar-notifications">
                <IconButton className="notification-icon-desktop">
                  <NotificationsOutlined />
                </IconButton>
              </td>
              <td className="tool-bar-photo">
                <IconButton
                  sx={{ p: 0 }}
                  onClick={handleClick}
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    alt={currentUser.first_name}
                    src={user.profile_picture_encoded}
                  />
                </IconButton>
              </td>
              <td className="tool-bar-info">
                <div>
                  {currentUser.first_name} {currentUser.last_name}
                </div>
                <div style={{ lineHeight: 1.2, fontSize: 12 }}>
                  {currentUser.group && getGroupName(currentUser.group)}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleProfileClicked}>Profile</MenuItem>
          <MenuItem onClick={handleLogOut}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </>
  );
};
