import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import { LayoutContainer } from "../Layout/layout.container";
import { Box, CssBaseline, LinearProgress, Alert, AlertColor } from "@mui/material";
import { createHallPassKioskLocation, completeHallPassLocationRequest, getCurrentHallPassRequestByStudentUserName, logDeviceInformation } from "../Services/HallPassService";
import { fetchCurrentClassByRoomId } from "../Services/ClassService";
import useLocalStorage from "../Hooks/useLocalStorage";
import { CURRENT_SCHOOL } from "../Helpers/Constants";
import moment from "moment";
import { CreateStudentOnLocationDto } from "../Interfaces/SchoolInterface";
import { AlertState, setCustomAlertDetails, setShowCustomAlert } from "../store/slices/main";

export const ReadQRForRegisterHallPass: FC<any> = (): ReactElement => {
  let { idSchool, idRoom, idLocation } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isSessionValid, currentAuthenticatedUser } = useCognitoAwsV2();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [returnToClass, setReturnToClass] = useLocalStorage(
    "SeedClassroomReturnToClass",
    ""
  );

  const [savingAttendance, setSavingAttendance] = useState<boolean>(false);

  const [lastReloadAux, setLastReloadAux] = useState<Date>(new Date());

  useEffect(() => {
    if (isLoading == true) {
      validateUserSession();
    }
  }, []);

  async function logUserDeviceAndClassroomInfo(user: any,  returnToClass: any) {
    try {
      const logData = {
        scan_info: 'Student user has scanned a Location QR code',
        user_info: user.attributes,
        stored_User_Info: user.storage.current_class,
        school_id: idSchool,
        id_Of_Room: idRoom,
        id_Of_location: idLocation,
        User_is_returning_from_pass: returnToClass, 
      };
  
      
      await logDeviceInformation(logData);
    } catch (error) {
      console.error('Error logging user and device info:', error);
    }
  }

  async function validateUserSession(): Promise<void> {
    try {
      setIsLoading(true);
      let idSchoolNum = Number(idSchool);
      let idRoomNum = Number(idRoom);
      let idLocationNum = Number(idLocation)

      setSavingAttendance(false);


      if (isNaN(idSchoolNum) || isNaN(idLocationNum)) {
        throw new Error("Invalid QR Code");
      }

      // if session is not valid then require login first
      window.localStorage.setItem(CURRENT_SCHOOL, idSchoolNum.toString());

     

      // Check if session is valid
      const isValid = await isSessionValid();
      if (!isValid) {
        navigate("/");
        return;
      }

      const user = await currentAuthenticatedUser();
      console.log("scan qr for hallpass", user)
      const activePass = await getCurrentHallPassRequestByStudentUserName(user.username);
      console.log("active pass staff", activePass)

      // Complete hall pass location request
      // if (activePass && (activePass.status === "InProgress" || activePass.status === "AtLocation")) {
        // const user = await currentAuthenticatedUser();
        // setSavingAttendance(true);
        // console.log('*** user ***', user)
        const request: CreateStudentOnLocationDto = {
          location_id: Number(idLocationNum),
          user_name: user?.username,
        };
        console.log("scan location request", request);
        createLocationKioskHallPass(request);
        // const result: any = await dispatch(completeHallPassLocationRequest(user?.username));

        // const result: any = await dispatch(createHallPassKioskLocation(request));

        // if (result?.payload) {
        //   setSuccessMessage("Hall Pass Request completed successfully");

        //   console.log("result", result?.payload);
        //   setTimeout(() => {
        //     setReturnToClass(false);
        //     navigate("/StudentHallPassLocations");
        //   }, 2000);
        // }
      //   return;

      await logUserDeviceAndClassroomInfo(user, activePass);
      // }



    } catch (error) {
      const message = error instanceof Error ? error.message : "Invalid QR Code";
      setErrorMessage(message);
      setTimeout(() => { navigate("/ScanQRCode") }, 2000);
    } finally {
      setIsLoading(false);
    }

  }

  function createLocationKioskHallPass(request: CreateStudentOnLocationDto) {
    const registerLocationKiosk = async () => {
      return await createHallPassKioskLocation(request);
    };

    registerLocationKiosk()
      .then((result: any) => {
        // showAlert("Location's attendance successfully saved", "success");
        // setTimeout(() => {
        //   handleCloseAlert();
        // }, 2000);
        setSuccessMessage("Location's attendance successfully saved");
        console.log('register location kiosk result', result);
        setTimeout(() => {

          navigate("/");
        }, 2000);

        forceLastReloadAttendanceList();
        setSavingAttendance(false);
        // setReturnToClass(false);
      })
      .catch((err) => {
        showAlert(err.message ? err.message : 'Error processing info', "error");
        setTimeout(() => {
          handleCloseAlert();
        }, 2000);
        console.log(err);
      });
  }


  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  function forceLastReloadAttendanceList() {
    setLastReloadAux(new Date());
  }

  
  return (
    <>
      <CssBaseline />
      <LayoutContainer isRaised={true}>
        {isLoading && (
          <Box sx={{ width: "100%", top: 0, position: "fixed", left: 0 }}>
            <LinearProgress sx={{ marginBottom: 40 }} />
            Processing QR Code... 
          </Box>
        )}
        {errorMessage && (
          <Alert severity="warning" variant="outlined" color="error">
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" variant="outlined" color="success">
            {successMessage}
          </Alert>
        )}
      </LayoutContainer>
    </>
  );
};

export default ReadQRForRegisterHallPass;
