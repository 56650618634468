import React, { useEffect } from 'react';
import { Html5Qrcode } from 'html5-qrcode';

const qrcodeRegionId = "html5qr-code-full-region";

const Html5QrcodePlugin = (props: any) => {
    useEffect(() => {
        const constraints: MediaTrackConstraints = {
            facingMode: 'environment'
        };

        const html5QrCode = new Html5Qrcode(qrcodeRegionId);

        const cameraScanConfig = {
            fps: 10, // Adjust the frame rate as needed
            videoConstraints: constraints, // Pass the constraints here

        };

        html5QrCode.start("camera", cameraScanConfig,
            (decodedText) => {
                // Callback para manejar los datos del código leído
                props.qrCodeSuccessCallback(decodedText);
            },
            // Error callback, even if it doesn't handle errors.
            (error) => {
                props.qrCodeErrorCallback
            }
        );

        return () => {
            html5QrCode.stop().catch(error => {
                console.error("Error al detener Html5Qrcode: ", error);
            });
        };
    }, []);

    return <div id={qrcodeRegionId} />;
};

export default Html5QrcodePlugin;
