import { Box, Button, CssBaseline, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { CSSProperties } from "@mui/styles";
import { LayoutContainer } from "../Layout/layout.container";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import {
  setPageTitle,
  setRaiseHeader,
  setShowCustomAlert,
} from "../store/slices/main";
import {
  AddFloatButton,
  CustomAlert,
  NewUserModal,
  SchoolAdminsList,
  TeachersList,
  StudentsList,
  StaffList,
} from "../Components";
import { setErrorMessage, setSuccessMessage } from "../store/slices/user.slice";
import useMediaQuery from "@mui/material/useMediaQuery";

const buildDesktopStyles = () => {
  return {
    containerBox: {
      borderRadius: "5px 5px 0 0",
    } as CSSProperties,
    header: {
      height: "auto",
      marginBottom: 12,
      zIndex: 3,
    } as CSSProperties,
    listContainer: {
      overflow: "auto",
      minHeight: "60vh",
      maxHeight: "60vh",
      textAlign: "center",
      background: "#3C404E",
      zIndex: 3,
      borderRadius: "8px 8px 0px 0px ",
    } as CSSProperties,
    tabsContainer: {
      background: "rgb(70, 75, 91)",
      padding: "5px 10px",
      maxWidth: "89vw",
      height: 55,
      width: "100%",
      borderRadius: 5,
    } as CSSProperties,
    tab: {
      textTransform: "none",
    } as CSSProperties,
    tabPanel: {
      padding: 0,
    } as CSSProperties,
  };
};

function UserAndRoles() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState("1");
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const downMd = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(setPageTitle("Users and roles"));
    dispatch(setRaiseHeader(true));

    return () => {
      dispatch(setRaiseHeader(false));
    };
  }, []);

  const styles = useMemo(() => {
    let style = buildDesktopStyles();
    if (isMobile) {
      style.containerBox = {
        width: "100%",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
      };
      style.header.width = "100%";
      style.listContainer.width = "95%";
      style.tabsContainer = {
        background: "rgb(70, 75, 91)",
        height: 155,
        marginTop: -120,
        zIndex: -999,
        display: "flex",
        alignItems: "flex-end",
        width: "100%",
      };
    }
    return style;
  }, [isMobile]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setValue(newValue);
  };

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  function handleOpenModal(value: boolean) {
    setOpenModal(value);
  }

  // Disable creation of new users for the following options ["Students", ""]
  // const isCreationDisabled = useMemo(() => value === "2" ? true : false, [value]);
  const isCreationDisabled = false;

  return (
    <>
      {!downMd ? <div style={{ marginTop: "-80px" }}></div> : <div></div>}

      <div></div>
      <CssBaseline />
      <LayoutContainer isRaised={true} sx={{ padding: 0 }}>
        <Box style={styles.containerBox}>
          <TabContext value={value}>
            <Box style={styles.header}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sm={9}>
                  <Box style={styles.tabsContainer}>
                    <TabList
                      onChange={handleChange}
                      variant="fullWidth"
                      sx={{ flex: 1 }}
                    >
                      <Tab label="Teachers" value="1" style={styles.tab} />
                      <Tab label="Students" value="2" style={styles.tab} />
                      <Tab label="Staff" value="3" style={styles.tab} />
                      <Tab label="Admins" value="4" style={styles.tab} />
                    </TabList>
                  </Box>
                </Grid>
                <Grid item>
                  {!isMobile && (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        textTransform: "none",
                        color: isCreationDisabled ? "gray" : "white",
                        height: 48,
                        width: 150,
                      }}
                      disabled={isCreationDisabled}
                      onClick={() => handleOpenModal(true)}
                    >
                      + Add new User
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box style={styles.listContainer}>
              <TabPanel value="1" style={styles.tabPanel}>
                <TeachersList
                  isMobile={isMobile}
                  handleOpenModal={handleOpenModal}
                />
              </TabPanel>
              <TabPanel value="2" style={styles.tabPanel}>
                <StudentsList
                  isMobile={isMobile}
                  handleOpenModal={handleOpenModal}
                />
              </TabPanel>
              <TabPanel value="3" style={styles.tabPanel}>
                <StaffList
                  isMobile={isMobile}
                  handleOpenModal={handleOpenModal}
                />
              </TabPanel>
              <TabPanel value="4" style={styles.tabPanel}>
                <SchoolAdminsList
                  isMobile={isMobile}
                  handleOpenModal={handleOpenModal}
                />
              </TabPanel>
              {isMobile && (
                <AddFloatButton
                  handleOpenModal={handleOpenModal}
                  disabled={isCreationDisabled}
                />
              )}
            </Box>
          </TabContext>
        </Box>
      </LayoutContainer>
      <NewUserModal
        handleOpenModal={handleOpenModal}
        openModal={openModal}
        isRolSelectionDisabled={isCreationDisabled}
      />
      <CustomAlert handleClose={handleCloseAlert} />
    </>
  );
}

export default UserAndRoles;
