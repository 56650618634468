import { createAsyncThunk } from "@reduxjs/toolkit";
import { DayType, Period } from "../Interfaces/ScheduleInterface";
import ApiService from "./apiService";

// const baseUrl =
//   process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local"
//     ? "/schedule"
//     : "";

const baseUrl = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE === "local"
    ? "/schedule"
    : ""
  : "/schedule";

export const dayTypesURL = `${baseUrl}/schedule/daytypes/`;
export const periodsURL = `${baseUrl}/schedule/periods/`;

export const fetchDayTypesBySchoolId = createAsyncThunk(
  "schedule/fetchDayTypesBySchoolId",
  async (school_id: number) => {
    const result: any = await ApiService.get<DayType[]>(
      `${dayTypesURL}school/${school_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const fetchPeriodsBySelectedDateChar = createAsyncThunk(
  "schedule/fetchPeriodsBySelectedDateChar",
  async ({ attendance_date, school_id }: any) => {
    const result: any = await ApiService.get<DayType[]>(
      `${periodsURL}attendance_date/${attendance_date}/${school_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export const fetchPeriodsByDayTypeId = createAsyncThunk(
  "schedule/fetchPeriodsByDayTypeId",
  async (day_type_id: number) => {
    const result: any = await ApiService.get<Period[]>(
      `${periodsURL}daytype/${day_type_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export async function fetchPeriodsByDayTypeIdRequest(
  day_type_id: number
): Promise<Period[]> {
  const result: any = await ApiService.get<Period[]>(
    `${periodsURL}daytype/${day_type_id}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export const createDayType = createAsyncThunk(
  "schedule/createDayType",
  async (dayType: DayType) => {
    const result: any = await ApiService.post<DayType>(dayTypesURL, dayType);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const editDayType = createAsyncThunk(
  "schedule/editDayType",
  async (dayType: DayType) => {
    const result: any = await ApiService.put<DayType>(dayTypesURL, dayType);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const createPeriod = createAsyncThunk(
  "schedule/createPeriod",
  async (period: Period) => {
    const result: any = await ApiService.post<Period>(periodsURL, period);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const fetchPeriodsBySchoolID = createAsyncThunk(
  "attendance/fetchPeriodsBySchoolID",
  async (schoolID: number) => {
    const result: any = await ApiService.get<Period[]>(
      periodsURL + "periods/" + schoolID
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);
