import {
  LockOutlined,
  PersonOutline,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { FC, ReactElement, useState, useEffect } from "react";
import {
  CustomButton,
  AttemptExceedModal,
  AccountLockedModal,
} from "../Components";
import { IsValidEmail, RequiredStringField } from "../Helpers/FormValidations";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import useForm, { FormField } from "../Hooks/useForm";
import useDidMountEffect from "../Hooks/useDidMountEffect";
import useLocalStorage from "../Hooks/useLocalStorage";
import { LayoutSign } from "../Layout/layout-sign/layout-sign";
import { LayoutContainer } from "../Layout/layout.container";
import { REGISTER_STUDENT } from "../Helpers/Constants";
import appConfig from "../Config/appConfig";

const loginForm = {
  email: "",
  password: "",
};

const MAX_ATTEMPTS_ALLOWED = 3;
const ATTEMPTS_BEFORE_WARN = 2;

export const SignIn: FC<any> = (): ReactElement => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  // TODO: pass to DB to be attempts by user, and no attempts by device
  const [AttemptsCount, setAttemptsCount] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openBlockedAccountModal, setOpenBlockedAccountModal] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useLocalStorage(
    "SeedClassroomRememberMe",
    true
  );
  const { error, signIn, forgotPassword } = useCognitoAwsV2();

  const { form, errors, handleChange, handleBlur, handleSubmit } = useForm(
    loginForm,
    validateformField,
    submitForm
  );

  const [registerStudent, setRegisterStudent] = useState<string | null>("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const responseType = urlParams.get("response_type");

    if (code && responseType) {
      window.location.href = `ProcessClassLink?code=${code}&response_type=${responseType}`;
    }
  }, []);
  
  useEffect(() => {
    setRegisterStudent(window.localStorage.getItem(REGISTER_STUDENT));
  }, []);

  useDidMountEffect(() => {
    const handleAttemptsCountEvents = async () => {
      if (AttemptsCount >= MAX_ATTEMPTS_ALLOWED) {
        await forgotPassword(
          email,
          {
            accountStatusEvent: "ACCOUNT_BLOCKED",
          },
          false
        );
        setOpenBlockedAccountModal(true);
      }

      if (AttemptsCount === ATTEMPTS_BEFORE_WARN) {
        await forgotPassword(
          email,
          {
            accountStatusEvent: "WARN_ACCOUNT_OWNER",
          },
          false
        );
      }
    };

    handleAttemptsCountEvents();
  }, [AttemptsCount]);

  async function submitForm() {
    if (!readyToSubmit()) return;

    if (!rememberMe) {
      setRememberMe(false);
    }

    Auth.configure({
      storage: rememberMe ? window.localStorage : window.sessionStorage,
    });

    setIsLoading(true);

    await signIn(email, password);

    setAttemptsCount(AttemptsCount + 1);

    setIsLoading(false);
  }

  function validateformField(fieldName: string, fieldValue: string): FormField {
    let value: string = "";

    switch (fieldName) {
      case "email": {
        value =
          RequiredStringField(fieldName, fieldValue) ||
          IsValidEmail(fieldValue);
        break;
      }
      case "password": {
        value = RequiredStringField(fieldName, fieldValue);
        break;
      }
    }
    return {
      name: fieldName,
      value: value,
    } as FormField;
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function readyToSubmit(): boolean {
    return !(
      !form.email ||
      !form.password ||
      !!errors.email ||
      !!errors.password ||
      isLoading
    );
  }

  const submitClassLink = () => {
    const classLinkAuthUrl = `${appConfig.classlink.URL}&redirect_uri=${appConfig.classlink.REDIRECT_URI}&client_id=${appConfig.classlink.CLIEND_ID}&response_type=code`;
    window.location.href = classLinkAuthUrl;
  } 
  console.log(registerStudent);

  return (
    <>
      <LayoutSign
        children={
          <LayoutContainer>
            <Typography component="h2" variant="h5">
              Sign In
            </Typography>
            <Typography style={{ color: "#8A92A6", marginTop: 5 }}>
              Sign in to stay connected.
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3, mb: 0 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email"
                name="email"
                autoComplete="on"
                onChange={(e) => {
                  setEmail(e.target.value);
                  handleChange(e, false);
                }}
                onBlur={handleBlur}
                value={form.email}
                error={!!errors.email}
                helperText={errors.email && errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutline />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                autoComplete="on"
                value={form.password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  handleChange(e, false);
                }}
                onBlur={handleBlur}
                error={!!errors.password}
                helperText={errors.password && errors.password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={handleClickShowPassword}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined color="disabled" />
                      ) : (
                        <VisibilityOutlined color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container justifyContent="space-between">
                <Grid item>
                  <FormControlLabel
                    style={{
                      marginLeft: 0,
                      marginTop: 2,
                      padding: 0,
                      height: 8,
                    }}
                    label="Remember me?"
                    control={
                      <Checkbox
                        checked={rememberMe}
                        sx={{
                          "&, & + .MuiFormControlLabel-label": {
                            fontSize: "0.85rem",
                          },
                        }}
                        onChange={() => setRememberMe(!rememberMe)}
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setOpenModal(true)}
                    variant="text"
                    style={{
                      textTransform: "none",
                      textDecoration: "underline",
                      height: 8,
                    }}
                  >
                    Forgot password?
                  </Button>
                </Grid>
              </Grid>

              {error && (
                <Stack sx={{ width: "100%", mt: 2, mb: 0 }} spacing={2}>
                  <Alert severity="warning" variant="outlined" color="error">
                    {AttemptsCount >= 1 &&
                    AttemptsCount < MAX_ATTEMPTS_ALLOWED ? (
                      <>
                        Wrong password you have{" "}
                        {MAX_ATTEMPTS_ALLOWED - AttemptsCount} attempts left
                        before we lock your account
                      </>
                    ) : AttemptsCount > MAX_ATTEMPTS_ALLOWED - 1 ? (
                      <>
                        For security reasons we have blocked your account, we
                        have sent an email with the recovery instructions.
                      </>
                    ) : (
                      error?.message
                    )}
                  </Alert>
                </Stack>
              )}

              <CustomButton
                text="Sign in"
                disabled={!readyToSubmit()}
                isLoading={isLoading}
                type="submit"
                sx={{ mt: 5, mb: 1 }}
              />

              {<CustomButton
                text="Sign in with classlink"
                isLoading={isLoading}
                type="button"
                onClick={submitClassLink}
                sx={{ mt: 5, mb: 1, background: '#00c9f5', padding: '0px', '&:hover': {
                  background: '#00c9f5', 
                }, }}
              />}

              {registerStudent === "TRUE" ? (
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography>Don&apos;t have an account?</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      href="/SignUpStudents"
                      variant="text"
                      style={{
                        textTransform: "none",
                        textDecoration: "underline",
                        height: 8,
                      }}
                    >
                      Sign up here
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Box>
            <AttemptExceedModal
              openModal={openModal}
              handleOpenModal={setOpenModal}
            />
            <AccountLockedModal
              openModal={openBlockedAccountModal}
              handleOpenModal={setOpenBlockedAccountModal}
            />
          </LayoutContainer>
        }
      ></LayoutSign>
    </>
  );
};

export default SignIn;