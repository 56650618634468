import { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { LayoutContainer } from "../Layout/layout.container";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { setCurrentClass, setPageSubtitle, setPageTitle } from "../store/slices/main";
import { useNavigate, useParams } from "react-router-dom";
import {
  cancelHallPassLocationRequest,
  createHallPassLocationRequest,
  fetchHallPasses,
  scheduleHallPassCancellation,
} from "../Services/HallPassService";
import {
  CreateStudentOnLocationDto,
  StatusStudentOnLocationEnum,
  StudentOnLocation,
} from "../Interfaces/SchoolInterface";
import { CSSProperties } from "@mui/styles";
import {
  getPositionInLine,
  getStudentsOnLocation,
} from "../store/slices/hallPass.slice";
import usingHallPassImage from "../Assets/using_hall_pass.png";
import "./StudentHallPassLocationRequest.css";
import { ConfirmHallPassRequestModal } from "../Components";
import useLocalStorage from "../Hooks/useLocalStorage";
import { useWebSocket } from "../Hooks/useWebSocket";
import moment from "moment";
import { createQueueManagerByUserName, removeTurnFromQueue } from "../Services/UsersService";
import { setTurnQueue } from "../store/slices/user.slice";
import { fetchSchoolSettingsBySchoolId } from "../Services/SchoolsService";
import { getClassId } from "../Services/ClassService";
import { CURRENT_CLASS } from "../Helpers/Constants";

const styles = {
  container: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    textAlign: "left",
    minHeight: "50vh",
    maxHeight: "70vh",
    zIndex: 3,
  } as CSSProperties,
  titleContainer: {
    height: "70px",
    background: "rgba(255, 255, 255, 1)",
  } as CSSProperties,
  backIcon: {
    position: "fixed",
    marginTop: 15,
    marginLeft: 5,
  } as CSSProperties,
  title: {
    color: "rgba(34, 43, 69, 1)",
    fontWeight: 500,
    fontSize: 16,
    margin: 0,
  } as CSSProperties,
  subTitleContainer: {
    margin: 0,
  } as CSSProperties,
  noStudentsInlineSubtitle: {
    color: "rgba(34, 43, 69, 0.7)",
    fontWeight: 500,
    fontSize: 14,
  } as CSSProperties,
  studentsInlineSubtitle: {
    color: "rgba(34, 43, 69, 0.7)",
    fontWeight: 500,
    fontSize: 14,
  } as CSSProperties,
  contentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "59vh",
  } as CSSProperties,
  mobileActionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "10vh",
  } as CSSProperties,
  cancelButton: { background: "#fff", textTransform: "none" } as CSSProperties,
  completeHallPassButton: {
    textTransform: "none",
    color: "#fff",
    height: 46,
    marginTop: 40,
  } as CSSProperties,
};

const StudentHallPassLocationRequest = () => {
  
  let { locationId, userName, classId, turnQueue } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentStudentsInLine, setCurrentStudentsInLine] = useState<number>(0);
  const [turnUpdateWebSockey, setTurnUpdateWebSockey] = useState<number>(-1);
  const [studentOnLocation, setStudentOnLocation] =
    useState<StudentOnLocation>();
  const [studentPosition, setStudentPosition] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [returnToClass, setReturnToClass] = useLocalStorage(
    "SeedClassroomReturnToClass",
    false
  );
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  styles.container = {
    ...styles.container,
    marginTop: isMobile ? "-50px" : "15%",
  };
  const { currentClass, currentSchool } = useSelector(
    (state: RootState) => state.main
  );
  const { hallPassLocations, savingIndicator } = useSelector(
    (state: RootState) => state.hallPass
  );

  const {  currentUser } = useSelector(
    (state: RootState) => state.main
  );

  const passConfirmationTimeout = useSelector((state: RootState) => state.school.currentSchoolSettings.pass_timeout || 120);

 

  useEffect(() => {
    if (currentClass?.id) {
      dispatch(setPageTitle(currentClass?.name));
      dispatch(setPageSubtitle(`Room ${currentClass?.room?.name}`));
    }
  }, [currentClass]);

  useEffect(() => {
    if (currentSchool?.id) {
      dispatch(fetchHallPasses(Number(currentSchool?.id)));
    }
  }, [currentSchool]);

  useEffect(() => {
    if (currentClass.school_id) {
      dispatch(fetchSchoolSettingsBySchoolId(currentClass.school_id))
      
    }
  }, [dispatch, currentClass.school_id]);

  useEffect(() => {
    if (hallPassLocations && hallPassLocations.length > 0) {
      const location = hallPassLocations.find(
        (s) => s.id === Number(locationId)
      );
      if (location) {
        const studentOnLocation = location?.StudentsOnLocations?.find(
          (s) =>
            s.student?.user_name === userName &&
            s.status !== StatusStudentOnLocationEnum.Completed &&
            s.status !== StatusStudentOnLocationEnum.Cancelled
        ) as StudentOnLocation;
        if (studentOnLocation) {
          setStudentOnLocation(studentOnLocation);
          setStudentPosition(
            getPositionInLine(
              location?.StudentsOnLocations as StudentOnLocation[],
              userName as string
            )
          );
          setCurrentStudentsInLine(
            getStudentsOnLocation(
              location?.StudentsOnLocations as StudentOnLocation[]
            ).length
          );
        }
      }
    }
  }, [hallPassLocations]);

  useEffect(() => {
    switch (studentOnLocation?.status) {
      case StatusStudentOnLocationEnum.Authorized:
        setOpenModal(true);
        break;
      case StatusStudentOnLocationEnum.InProgress:
        dispatch(setPageTitle(studentOnLocation?.location?.name));
        dispatch(setPageSubtitle(""));
        break;
      case StatusStudentOnLocationEnum.AtLocation:
        dispatch(setPageTitle(studentOnLocation?.location?.name));
        dispatch(setPageSubtitle(""));
        break;
      default:
        dispatch(setPageTitle(currentClass?.name));
        dispatch(setPageSubtitle(`Room ${currentClass?.room?.name}`));
        break;
    }
  }, [studentOnLocation?.status]);

  

  
  

  useEffect(() => {
    if (parseInt(turnQueue?.toString() ?? "-1") == 0) {
      const request: CreateStudentOnLocationDto = {
        location_id: Number(locationId),
        class_id: Number(classId),
        user_name: `${userName}`,
      };
      dispatch(createHallPassLocationRequest(request));
      
      
    } else {
      createQueueManagerByUserName({studentId : currentUser?.user_name?.toString() || "0", locationId : locationId?.toString() || "0"}).then((result: number) => {
        dispatch(setTurnQueue(result))
        setTurnUpdateWebSockey(result);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, []);

  
  useEffect(() => {
    if (currentClass?.school_id) {
      const interval = setInterval(
        () => dispatch(fetchHallPasses(Number(currentClass?.school_id))),
        10000
      );
      return () => clearInterval(interval);
    }
  }, [currentClass]);


  useEffect(() => {
    if (turnUpdateWebSockey == 0) {
      const request: CreateStudentOnLocationDto = {
        location_id: Number(locationId),
        class_id: Number(classId),
        user_name: `${userName}`,
      };
      dispatch(createHallPassLocationRequest(request));
     
    }
  }, [turnUpdateWebSockey]);

  async function cancelRequest() {
    if (studentOnLocation?.id) {

      await dispatch(cancelHallPassLocationRequest(studentOnLocation?.id as number));

      if(currentUser?.meetup_prevention)await removeTurnFromQueue(currentUser?.user_name?.toString() || "0")
      navigate(-1);
    }
  }

  
  async function cancelRequestOnly() {
    if (studentOnLocation?.id) {
      await dispatch(cancelHallPassLocationRequest(studentOnLocation?.id as number));

      navigate(-1);
    }
  }

  function handleCloseDialog() {
    setOpenModal(false);
  }

  const studentsInLineStyle = {
    background:
      currentStudentsInLine <= 1
        ? "rgba(122, 193, 66, 1)"
        : currentStudentsInLine <= 4
        ? "rgba(248, 147, 28, 1)"
        : "rgba(255, 78, 50, 1)",
    marginLeft: 5,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 5,
    paddingBottom: 1.5,
    fontWeight: 500,
    fontSize: 14,
    color: "rgba(255, 255, 255, 1)",
  };

  useWebSocket([
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        dispatch(fetchHallPasses(Number(currentClass?.school_id)));
        createQueueManagerByUserName({studentId : currentUser?.user_name?.toString() || "0", locationId : locationId?.toString() || "0"})
        .then((result: number) => {
          dispatch(setTurnQueue(result))
          setTurnUpdateWebSockey(result);
        })
        .catch((err) => {
          console.log(err);
        });
      },
    },
  ]);

  async function completeRequest() {
    let period : any; 
    if (!currentClass?.period) {
      const storedCurrentClass = (window.localStorage.getItem(CURRENT_CLASS)) ? JSON.parse(window.localStorage.getItem(CURRENT_CLASS) || "{}") : undefined;
        if(!storedCurrentClass && classId){
          const classByService = await getClassId(parseInt(classId))
          period = classByService?.period;
        } else {
          period = storedCurrentClass?.period;
        }
      if (storedCurrentClass) {
        dispatch(setCurrentClass(storedCurrentClass));
      }
    } else {
      period = currentClass?.period;
    }
    const hourFormat = "HH:mm";
    const currentTime = moment(new Date(), hourFormat);
    const startTime = moment(period?.start_time).format(hourFormat);
    const endTime = moment(period?.end_time).format(hourFormat);
    const checkHourStart = moment(startTime, hourFormat);
    const checkHourEnd = moment(endTime, hourFormat);

    const currentPeriodFound =
      checkHourStart.isBefore(currentTime) && checkHourEnd.isAfter(currentTime);

    if (currentPeriodFound) {
      setReturnToClass(true);
    }
    navigate("/ScanQRCode");
  }

  return (
    <>
      {!isMobile ? <div style={{ marginTop: "-80px" }}></div> : <div></div>}
      <LayoutContainer isRaised={true}>
      {studentOnLocation !== undefined && 
        (studentOnLocation?.status === StatusStudentOnLocationEnum.InProgress || studentOnLocation?.status === StatusStudentOnLocationEnum.AtLocation) ? (
          <Box style={{ paddingTop: 40 }}>
            <img
              src={usingHallPassImage}
              alt="usingHallPassImage"
              width="100"
            />
            <Typography variant="h4">You are now using a hall pass</Typography>
            <Button
              variant="contained"
              color="primary"
              style={styles.completeHallPassButton}
              onClick={() => completeRequest()}
            >
              Scan to End Pass
            </Button>
          </Box>
        ) : (
          <Box>
            <Box style={styles.container}>
              <Box style={styles.titleContainer}>
                <div style={{ padding: "10px 10px 10px 40px" }}>
                  <p style={styles.title}>
                    {studentOnLocation &&
                      studentOnLocation.location &&
                      studentOnLocation.location.name}
                  </p>
                  <p style={styles.subTitleContainer}>
                    {currentStudentsInLine === 0 ? (
                      <span style={styles.noStudentsInlineSubtitle}>
                        No Students in Line
                      </span>
                    ) : (
                      <span>
                        <span style={styles.studentsInlineSubtitle}>
                          Students in Line:
                        </span>
                        <span style={studentsInLineStyle}>
                          {currentStudentsInLine}
                        </span>
                      </span>
                    )}
                  </p>
                </div>
              </Box>
              <Box style={styles.contentContainer}>
                {savingIndicator ? (
                  <Stack spacing={4}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress size={60} />
                    </Box>
                    <p>Submitting your request</p>
                  </Stack>
                ) : (turnUpdateWebSockey > 0 || parseInt(turnQueue?.toString() ?? "-1") > 0)  ? (
                      <div className="ticket-container">
                        <div className="upper-inf">
                          <div className="info">
                            <div className="info-container">
                              <p>You are now in line</p>
                              <p className="sub-text">
                                You can leave the room when it&apos;s your turn
                              </p>
                            </div>
                          </div>
                          <div className="rigth-detail"></div>
                          <div className="left-detail"></div>
                        </div>
                        <div className="lower-inf">
                          <div className="info">
                            <div className="info-container">
                              <p className="info-number">{turnUpdateWebSockey === -1 || parseInt(turnQueue?.toString() ?? "-1") === -1
                                                          ? Math.max(turnUpdateWebSockey, parseInt(turnQueue?.toString() ?? "-1"))
                                                          : Math.min(turnUpdateWebSockey, parseInt(turnQueue?.toString() ?? "-1"))}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                ) : (
                  <div className="ticket-container">
                        <div className="upper-inf">
                          <div className="info">
                            <div className="info-container">
                              <p>You are now in line</p>
                              <p className="sub-text">
                                You can leave the room when it&apos;s your turn
                              </p>
                            </div>
                          </div>
                          <div className="rigth-detail"></div>
                          <div className="left-detail"></div>
                        </div>
                        <div className="lower-inf">
                          <div className="info">
                            <div className="info-container">
                              <p className="info-number">{studentPosition}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                )}
              </Box>
            </Box>
            <Box style={styles.mobileActionsContainer}>
              <Button
                disabled={savingIndicator}
                variant="contained"
                style={styles.cancelButton}
                onClick={() => cancelRequest()}
              >
                Cancel request
              </Button>
            </Box>
          </Box>
        )}
      </LayoutContainer>
      <ConfirmHallPassRequestModal
        openModal={openModal}
        studentOnLocation={studentOnLocation as StudentOnLocation}
        handleCloseDialog={handleCloseDialog}
        currentSchool_id={Number(currentClass?.school_id)}
        passConfirmationTimeout={passConfirmationTimeout}
      />
    </>
  );
};

export default StudentHallPassLocationRequest;
