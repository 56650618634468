import {
  createClassWithUser,
  fetchAllBySchoolIdAndTeacherUsername,
  fetchAttendanceClassByPeriodAndDayType,
} from "./../../Services/ClassService";
import { createSlice } from "@reduxjs/toolkit";
import { Class, ScheduleClasses } from "../../Interfaces/ClassInterface";
import {
  createClass,
  deleteClass,
  fetchClasses,
  updateClass,
} from "../../Services/ClassService";

export const classSlice = createSlice({
  name: "class",
  initialState: {
    classes: [] as Class[],
    filteredClasses: [] as Class[],
    currentClass: {} as Class,
    currentScheduleClass: {} as ScheduleClasses,
    loadingIndicator: false,
    savingIndicator: false,
    attendanceCurrentClass: {} as Class,
    errorMessage: "",
    successMessage: "",
  },
  reducers: {
    setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    setFilteredClasses: (state, { payload }) => {
      state.filteredClasses = payload;
    },
    setCurrentClass: (state, { payload }) => {
      state.currentClass = payload;
    },
    setCurrentScheduleClass: (state, { payload }) => {
      state.currentScheduleClass = payload;
    },
  },
  extraReducers: (builder) => {
    //fetch classes
    builder.addCase(fetchClasses.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(fetchClasses.fulfilled, (state, { payload }) => {
        state.classes = payload;
        state.loadingIndicator = false;
      }),
      builder.addCase(fetchClasses.rejected, (state) => {
        state.loadingIndicator = false;
      });

    //fetch classes by school and teacher user name
    builder.addCase(fetchAllBySchoolIdAndTeacherUsername.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchAllBySchoolIdAndTeacherUsername.fulfilled,
        (state, { payload }) => {
          state.classes = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(
        fetchAllBySchoolIdAndTeacherUsername.rejected,
        (state) => {
          state.loadingIndicator = false;
        }
      );

    // create classes
    builder.addCase(createClass.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(createClass.fulfilled, (state, { payload }) => {
        state.classes.push(payload);
        state.successMessage = "Class created successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(createClass.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // create classes with user
    builder.addCase(createClassWithUser.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(createClassWithUser.fulfilled, (state, { payload }) => {
        state.classes.push(payload);
        state.successMessage = "Class created successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(createClassWithUser.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // update classes

    builder.addCase(updateClass.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(updateClass.fulfilled, (state, { payload }) => {
        const updateByPayloadId = (c: Class) =>
          c.id === payload.id ? (payload as Class) : c;
        state.classes = state.classes.map(updateByPayloadId);
        state.filteredClasses = state.filteredClasses.map(updateByPayloadId);

        state.successMessage = "Class updated successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(updateClass.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // delete classes

    builder.addCase(deleteClass.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(deleteClass.fulfilled, (state, { payload }) => {
        const filterByPayloadId = (c: Class) => c.id !== payload.id;
        state.classes = state.classes.filter(filterByPayloadId);
        state.filteredClasses = state.filteredClasses.filter(filterByPayloadId);

        state.successMessage = "Class removed successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(deleteClass.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // get attendance current class

    builder.addCase(fetchAttendanceClassByPeriodAndDayType.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchAttendanceClassByPeriodAndDayType.fulfilled,
        (state, { payload }) => {
          state.attendanceCurrentClass =
            payload && payload.length > 0 ? payload[0] : payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(
        fetchAttendanceClassByPeriodAndDayType.rejected,
        (state) => {
          state.loadingIndicator = false;
        }
      );
  },
});

export default classSlice.reducer;
export const {
  setSuccessMessage,
  setErrorMessage,
  setFilteredClasses,
  setCurrentClass,
  setCurrentScheduleClass,
} = classSlice.actions;
