import React, { FC } from "react";
import {
  CssBaseline,
  ToggleButton,
  ToggleButtonGroup,
  Box,
} from "@mui/material";
import { withLightTheme } from "../../Hocs/withLightTheme";
import { styled as muStyled } from "@mui/material";
import { ToggleTeacherOptionEnum } from '../../Interfaces/TeacherInterface';
const styles = {
  boxContainer: {
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    borderRadius: "5px",
    padding: 1,
    background: "rgb(70, 75, 91)",
  },
};

const StyledToggleButton = muStyled(ToggleButton)(
  ({ theme }) => `
  background-color: rgb(70, 75, 91);
  color: rgba(196,198,202,255);
  border-radius: 6px !important;
  border: 0px;
  width: 100%;
  text-transform: none;
  font-weight: bold;
  &:hover {
    background-color: #fff;
    color: rgba(122,193,65,255);
  }
  &:active {
    color: rgba(122,193,65,255);
    background-color: #fff;
  },
  &.Mui-selected:hover: {
    color: rgba(122,193,65,255);
    background-color: #fff !important;  
  },
  &.Mui-selected {
    color: rgba(122,193,65,255);
    background-color: #fff !important;  
}`
);

interface ToggleHeaderProps {
  selectedTabOption: ToggleTeacherOptionEnum;
  selectedToggleOption: (value: ToggleTeacherOptionEnum) => void;
}

const ToggleHeaderComponent: FC<ToggleHeaderProps> = ({
  selectedTabOption,
  selectedToggleOption,
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedOption: string
  ) => {
    event.preventDefault();
    selectedToggleOption(newSelectedOption as ToggleTeacherOptionEnum);
  };


  return (
    <>
      <CssBaseline />
      <Box sx={styles.boxContainer} >
        <ToggleButtonGroup
          color="secondary"
          value={selectedTabOption}
          onChange={handleChange}
          exclusive
          fullWidth
        >
          <StyledToggleButton
            sx={{ marginRight: "2px" }}
            value={ToggleTeacherOptionEnum.Previous}
          >
            Previous Classes
          </StyledToggleButton>
          <StyledToggleButton
            sx={{ marginLeft: "2px" }}
            value={ToggleTeacherOptionEnum.Today}
          >
            Today
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
};

export const ToggleHeaderTeacher = withLightTheme(ToggleHeaderComponent);
