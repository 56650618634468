import React, { useCallback, useEffect, useState } from "react";
import appConfig from "../Config/appConfig";
import { Auth } from "aws-amplify";

import { useDispatch } from "react-redux";

interface ISocketContext {
  socket: WebSocket | null;
  payload: string;
}

const baseURL = appConfig.api.WEBSOCKET;
//const webSocket = new WebSocket(`${baseURL}?param=1`, ["Bearer"]);

export const SocketContext = React.createContext<Partial<ISocketContext>>({});

export const SocketProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  //For websocket
  // const webSocket = new WebSocket(`${baseURL}?param=1`, ["Bearer"]);
  const [webSocket, setWebSocket] = React.useState<WebSocket | null>(null);
  const [payload, setPayLoad] = useState<string>("");

  useEffect(() => {
    connect().then(() => {
      console.log("connected");
    });
  }, []);

  async function connect() {
    async function getJwtToken(): Promise<string> {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const jwtToken = currentUser?.signInUserSession?.accessToken?.jwtToken;
        return jwtToken;
      } catch (err) {
        console.log(err);
        return "";
      }
    }
    const token = getJwtToken();
    token
      .then((tokenstring) => {
        setWebSocket(
          new WebSocket(`${baseURL}?param=1`, ["secure-protocol", tokenstring])
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onMessage = useCallback((message) => {
    const data = JSON.parse(message?.data);
    // console.log(data);
    setPayLoad(data);
  }, []);

  const onError = useCallback((error) => {
    console.log(error);
  }, []);

  const onClose = useCallback(() => {
    setTimeout(() => {
      connect().then(() => {
        console.log("connected");
      });
    }, 10000);
  }, []);

  useEffect(() => {
    if (webSocket) {
      webSocket?.addEventListener("message", onMessage);
      webSocket?.addEventListener("error", onError);
      webSocket?.addEventListener("close", onClose);
    }
    return () => {
      webSocket?.removeEventListener("message", onMessage);
    };
  }, [webSocket]);

  return (
    <SocketContext.Provider value={{ socket: webSocket, payload: payload }}>
      {children}
    </SocketContext.Provider>
  );
};
