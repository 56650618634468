import { FC, ReactElement, useEffect, useState } from "react";
import {
  Box,
  LinearProgress,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import "./AttendanceListLocationComponent.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { getStudentsByLocation } from "../../Services/HallPassService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedUser } from "../../store/slices/main";
import { User } from "../../Interfaces/UserInterface";

interface IAttendanceListClass {
  lastReload?: Date | null;
  locationId: number | null | undefined;
}

const styles = {
  searchContainer: {
    background: "#3C404E",
    padding: "10px",
    height: 50,
    width: "100%",
    borderRadius: "8px 8px 0px 0px ",
  },
};

/**
 *This component is used to display the list
 * of students which that are registered in a certain location (Kiosk Mode)
 * @param param0
 * @returns
 */
export const AttendanceListLocationComponent: FC<IAttendanceListClass> = ({
  locationId,
  lastReload,
}): ReactElement => {
  const [data, setData] = useState<ReportAttendanceByCurrentClass[]>([]);
  const [dataFiltered, setDataFiltered] = useState<
    ReportAttendanceByCurrentClass[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [studentFilter, setStudentFilter] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /***
   * Reload students list every x milisecons
   */
  useEffect(() => {
    if (locationId) {
      const interval = setInterval(() => {
        loadData(locationId);
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [locationId]);

  /***
   * Load students list for the fist time
   */
  useEffect(() => {
    if (locationId) loadData(locationId);
  }, [locationId, lastReload]);

  function filter(e: React.ChangeEvent<HTMLInputElement>) {
    const keyword = e.target.value.trim().toLowerCase();
    setStudentFilter(keyword);
    filterStudents(keyword);
  }

  function filterStudents(filterTerm: string) {
    const filtered = data.filter(({ first_name, last_name }) =>
      `${first_name} ${last_name}`.toLowerCase().includes(filterTerm)
    );
    setDataFiltered(filtered);
  }

  function loadData(locationId: number | null) {
    setLoading(true);

    const fetchData = async () => {
      return await getStudentsByLocation(locationId);
    };

    fetchData()
      .then((result: any[]) => {
        console.log("getStudentsByLocation response ", result);
        setData(result);
        setDataFiltered(result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
        setDataFiltered([]);
        setLoading(false);
      });
  }

  function selectStudent(user: any) {
    dispatch(setSelectedUser(user));
    navigate("/StudentProfile");
  }
  
  return (
    <>
      {/* search by name */}
      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={12}>
          <Box style={styles.searchContainer}>
            <TextField
              placeholder="Search by name"
              variant="standard"
              fullWidth
              onChange={filter}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {loading ? (
            <LinearProgress />
          ) : (
            <table className="attendance-list-studentclass2">
              <tbody>
                {dataFiltered
                  .filter((x) => {
                    return x != null;
                  })
                  .map((x) => (
                    <tr key={x.id}>
                      <td className="studentNameAtLocation" onClick={() => selectStudent(x.user)}>
                        {x.first_name} {x.last_name}
                      </td>
                      <td className="class-status">
                        <AccessTimeIcon
                          fontSize="small"
                          sx={{ color: "#7AC142" }}
                        />{" "}
                        {x.timeToNow}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export interface ReportAttendanceByCurrentClass {
  id: number;
  started_at: string;
  timeToNow: string;
  user: User;
  first_name: string;
  last_name: string;
}
