import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { RadioSelectorPanel } from "../RadioButtons/RadioSelectorPanel";
import {} from "../../Interfaces/AttendanceInterface";
import copyIcon from "../../Assets/copyicon.svg";
import inline from "../../Assets/inline.svg";
import locationIcon from "../../Assets/locationIcon.svg";
import { useWebSocket } from "../../Hooks/useWebSocket";
import { checkStudentHallPassLocation } from "../../Services/HallPassService";
import { setSelectedUser } from "../../store/slices/main";
import { useNavigate } from "react-router-dom";
import { updateAttendanceStatus } from "../../Services/AttendanceService";
import { LightModal } from "../LightModal/LightModal";
import { CSSProperties } from "@mui/styles";
import {
  Attendance,
  AttendanceStatus,
} from "../../Interfaces/AttendanceInterface";
import { StatusStudentOnLocationEnum, StudentOnLocation } from "../../Interfaces/SchoolInterface";

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

interface ListProps {
  attendance: Attendance;
  selectedAttendanceDate: Date;
}

const AttendanceListItem: FC<ListProps> = ({
  attendance,
  selectedAttendanceDate,
}: ListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = attendance;
  const [hallPassRequestStatus, setHallPassRequestStatus] =
    useState<StatusStudentOnLocationEnum | null>(null);

  const [StudentOnLocation, setStudentOnLocation] = useState<
    StudentOnLocation | null
  >(null);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [statusAttendance, setStatusAttendance] = useState<string>(
    `${attendance.statusAttendance}`
  );
  const [userIdToUpdate, setUserIdToUpdate] = useState<number>(0);
  const [delayedMinutes, setDelayedMinutes] = useState<string>("");
  const [formDelayedMinutesError, setFormDelayedMinutesError] =
    useState<string>("");

  useEffect(() => {
    getHallPassStatus();
  }, []);

  function getHallPassStatus() {
    // console.log("** student hallpass location **", checkStudentHallPassLocation(Number(user?.id)));
    checkStudentHallPassLocation(Number(user?.id)).then((s: StatusStudentOnLocationEnum | null) => {
      // console.log("** student hallpass location **", s);
      setHallPassRequestStatus(s);
      
    });
  }


  useWebSocket([
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        getHallPassStatus();
      },
    },
  ]);

  function selectStudent(userId: any) {
    dispatch(setSelectedUser(user));
    navigate("/Dashboard");
  }

  async function onStatusChange(new_status: string) {
    setStatusAttendance(new_status);
    switch (new_status) {
      case "absent":
      case "present":
        await updateAttendance(new_status);
        break;
      case "tardy":
        setOpenModal(true);
        break;
    }
  }

  function handleTardyModal(val: any) {
    console.log(val);
  }

  async function updateAttendance(
    new_status: string,
    minutes_tardy: number = 0
  ) {
    setUserIdToUpdate(Number(user?.id));
    const new_attendance: any = {
      id: attendance.id,
      user_id: user?.id,
      class_id: attendance.class_id,
      new_status: new_status,
      selected_date: selectedAttendanceDate,
      minutes_tardy: minutes_tardy,
    };
    await dispatch(updateAttendanceStatus(new_attendance));
    setUserIdToUpdate(0);
  }

  async function validateForm(value: number) {
    if (value <= 0) {
      setFormDelayedMinutesError("Value must greatter than 0");
    } else {
      setFormDelayedMinutesError("");
      setOpenModal(false);
      await updateAttendance(statusAttendance, value);
    }
  }

  function getRequestStatusIcon(status: string) {
    console.log("***status***", status)
    // console.log("***student on location enum***", StatusStudentOnLocationEnum)
    switch (status) {
      case StatusStudentOnLocationEnum.InProgress:
        return (
          <img
            src={copyIcon}
            alt="hall pass request In progress"
            style={{ marginRight: 4 }}
          />
        );

      case StatusStudentOnLocationEnum.Pending:
        return (
          <img
            src={inline}
            alt="hall pass request In pending"
            style={{ marginRight: 4 }}
          />
        );

      case StatusStudentOnLocationEnum.AtLocation:
        return (
          <img
            src={locationIcon}
            alt="student at location"
            style={{ marginRight: 4 }}
          />
        );

      default:
        return <></>;
    }
  }

  return (
    <>
      <ListItem style={{ background: "#464B5B", height: "56px" }}>
        <ListItemText
          onClick={() => selectStudent(user?.id)}
          primary={`${user?.first_name} ${user?.last_name}`}
        />
        {/* {hallPassRequestStatus && getRequestStatusIcon(hallPassRequestStatus)} */}
        {hallPassRequestStatus && getRequestStatusIcon(hallPassRequestStatus)}
        {userIdToUpdate > 0 && <CircularProgress sx={{ mx: 1 }} size={20} />}
        <RadioSelectorPanel
          options={AttendanceStatus}
          selectedOption={statusAttendance}
          defaultWidth={90}
          onChange={onStatusChange}
        />
      </ListItem>
      <LightModal
        isOpenModal={openModal}
        handleOpenModal={handleTardyModal}
        width="400px"
      >
        <Card>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "space-between",
              marginLeft: "10px",
              marginTop: "15px",
              width: "100%",
            }}
          >
            <CardHeader
              title="Set delayed minutes:"
              titleTypographyProps={{ variant: "h6" }}
            />
          </Box>
          <CardContent sx={{ width: "100%" }}>
            <TextField
              error={formDelayedMinutesError !== ""}
              required
              fullWidth
              label="# minutes"
              type="number"
              value={delayedMinutes}
              onBlur={(ev) => validateForm(Number(ev.target.value))}
              onChange={(ev) => setDelayedMinutes(ev.target.value)}
              helperText={formDelayedMinutesError}
            />
          </CardContent>
          <CardActions style={{ padding: 0, margin: 0 }}>
            <Grid
              container
              spacing="0"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    setOpenModal(false);
                    setStatusAttendance(`${attendance.statusAttendance}`);
                  }}
                  style={styles.formButton}
                  size="large"
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={styles.formButton}
                  disabled={formDelayedMinutesError !== ""}
                  onClick={async () => {
                    await validateForm(Number(delayedMinutes));
                  }}
                  fullWidth
                  size="large"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </LightModal>
    </>
  );
};

AttendanceListItem.propTypes = {};

export default AttendanceListItem;
