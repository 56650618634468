import React, { FC, ReactElement, useState } from 'react';
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { withLightTheme } from '../../Hocs/withLightTheme';
import { Semester } from '../../Interfaces/SemesterInterface';

interface PopMenuProps<T> {
  anchorEl?: any;
  icon?: JSX.Element;
  menuOptions?: string[];
  onClick?: (event: React.MouseEvent<HTMLElement>, selectedOption: string | null, selectedData?: T) => void;
  data?: T;
  children?: ReactElement | ReactElement[];
}

const defaultPopMenuProps = {
  anchorEl: null,
  icon: <MoreVert />,
  menuOptions: ['Edit', 'Delete', 'Cancel'],
};

const PopButtonMenuComponent: FC<PopMenuProps<any>> = (props: PopMenuProps<any>): ReactElement => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(props.anchorEl);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOption = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);

    if (props.onClick)
      props.onClick(event, event.currentTarget.textContent, props.data);
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        color="secondary"
        onClick={handleClick}>
        {props.icon}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.32))",
            borderRadius: '3px',
            width: "17ch",
            mt: 1,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              borderRadius: '3px',
              top: 0,
              right: 13,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}>
        {props.menuOptions?.map((option, key) => (
          <MenuItem
            key={`${option}-${key}`}
            style={{
              height: 'unset',
              fontWeight: "500",
            }}
            onClick={handleOption}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      {props.children}
    </>
  );
}

PopButtonMenuComponent.defaultProps = defaultPopMenuProps;

export const PopButtonMenu = withLightTheme(PopButtonMenuComponent);

