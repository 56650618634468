import moment from "moment";
import { emailRegex } from "./Constants";

export function RequiredStringField(fieldName: string, value: string): string {
  if (typeof value !== "string") return "";

  return !value.trim() ? `The field ${fieldName} is required` : "";
}

export function RequiredNumberField(fieldName: string, value: number): string {
  return !value ? `The field ${fieldName} must be greater than zero` : "";
}

export function IsLessThanNumberField(
  firstFieldName: string,
  firstValue: number,
  lastFieldName: string,
  lastValue: number
): string {
  return lastValue <= firstValue
    ? `The field ${lastFieldName} must be greater than ${firstFieldName}`
    : "";
}

export function MaxCharCountField(
  fieldName: string,
  value: string,
  maxValue: number
): string {
  return !RegExp(`^.{0,${maxValue}}$`).test(value)
    ? `The ${fieldName} field must not exceed ${maxValue} characters`
    : "";
}
export function MinCharCountField(
  fieldName: string,
  value: string,
  minValue: number
): string {
  return value.length < minValue
    ? `The ${fieldName} field must not be less than ${minValue} characters`
    : "";
}

export function IsBeforeDateField(
  startDateString: string,
  endDateString: string
): string {
  return moment(endDateString).isBefore(moment(startDateString), "minutes")
    ? "Time end cannot be the less than time start"
    : "";
}

export function IsSameDateField(
  startDateString: string,
  endDateString: string
): string {
  return moment(endDateString).isSame(moment(startDateString), "minutes")
    ? "Time end cannot be the same as time start"
    : "";
}

export function IsValidEmail(email: string): string {
  return emailRegex.test(email) ? "" : "Invalid email format";
}
