import {
    Box,
    Button,
    Grid,
    LinearProgress,
    Popover,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
  import { CSSProperties, useEffect, useState } from "react";
  import { WatchLaterOutlined, InfoOutlined } from "@mui/icons-material";
  import { useDispatch, useSelector } from "react-redux";
  import { RootState } from "../../store";
  import { SchoolSettings } from "../../Interfaces/SchoolInterface";
  import { updateSchoolSettingsBySchoolId } from "../../Services/SchoolsService";
  
  const styles = {
    listContainer: {
      backgroundColor: "#3C404E",
      borderRadius: "5px",
      boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
      overflow: "auto",
      margin: "1rem 0",
      textAlign: "start",
      zIndex: 3,
    } as CSSProperties,
  };
  
  interface Props {
    schoolId?: number;
    schoolSettings: SchoolSettings;
    isSaving: boolean;
    isLoading: boolean;
  }
  
  const PassConfirmationTimeout = ({
    schoolId,
    schoolSettings,
    isSaving,
    isLoading,
  }: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
    const dispatch = useDispatch();
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>();
    // const [lateBufferValue, setLateBufferValue] = useState<number>(0);
    // const [timeoutValue, setTimeoutValue] = useState<number>(120);

    const [timeoutValue, setTimeoutValue] = useState<number | "">("");
  
   

    useEffect(() => {
        setTimeoutValue(schoolSettings.pass_timeout ?? 120);
      }, [schoolSettings]);
  
    const handleHover = (e: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(e.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorElement(null);
    };
  
    

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = parseInt(e.target.value);
    //     if (value < 60) return;
    
    //     setTimeoutValue(value);
    // };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '' || /^\d+$/.test(value)) { // Check if it's a number or an empty string
          setTimeoutValue(value === '' ? '' : parseInt(value));
        }
    };

    // New handleBlur function to enforce the minimum value when input loses focus
  const handleBlur = () => {
    if (timeoutValue === '' || timeoutValue < 60) {
      setTimeoutValue(60);
    }
  };
  
    const handleSubmit = () => {
      if (!schoolId) return;
  
    const finalTimeoutValue = typeof timeoutValue === 'number' && timeoutValue >= 60 ? timeoutValue : 120;

    const newSettings = {
        ...schoolSettings,
        pass_timeout: finalTimeoutValue,
      };
  
      console.log("newsettings", newSettings)
      dispatch(
        updateSchoolSettingsBySchoolId({
          schoolId,
          settings: newSettings,
        })
      );
    };
  
    return (
      <Box style={styles.listContainer} flexDirection="column">
        <Grid container p={2}>
          <Grid item xs={1}>
            <WatchLaterOutlined sx={{ fill: "#7AC142" }} />
          </Grid>
          <Grid item xs={10} paddingLeft={1}>
            <Typography fontWeight={500}>Pass Confirmation Timeout</Typography>
          </Grid>
          <Grid
            item
            xs={1}
            textAlign="end"
            aria-owns={anchorElement ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handleHover}
            onMouseLeave={handleClose}
          >
            <InfoOutlined />
          </Grid>
  
          <Grid item xs={12} mt={1}>
            <Typography fontSize=".8em">Seconds</Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <TextField
              type="number"
              variant="standard"
              fullWidth
              placeholder="120"
            //   value={timeoutValue}
            onBlur={handleBlur} // Add the onBlur event handler
            value={timeoutValue === '' ? '' : timeoutValue.toString()} // Ensure the value is a string for the input

              onChange={handleChange}
              disabled={isSaving || isLoading}
            />
          </Grid>
  
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              sx={{
                textTransform: "capitalize",
                color: "white",
                fontWeight: 400,
              }}
              disabled={isSaving || isLoading}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={Boolean(anchorElement)}
          anchorEl={anchorElement}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleClose}
          disableRestoreFocus
        >
          <Typography
            fontSize=".8em"
            p={2}
            sx={{
              maxWidth: isMobile ? "60vw" : "30vw",
              backgroundColor: "white",
              color: "black",
            }}
          >
            Please enter the amount of time in seconds that you would like to give
            your students to accept a hall pass before it is automatically declined.
          </Typography>
        </Popover>
        {isSaving && <LinearProgress />}
      </Box>
    );
  };
  
  export default PassConfirmationTimeout;

// import {
//     Box,
//     Button,
//     Grid,
//     LinearProgress,
//     Popover,
//     TextField,
//     Typography,
//     useMediaQuery,
//     useTheme,
//   } from "@mui/material";
//   import { CSSProperties, useEffect, useState } from "react";
//   import { WatchLaterOutlined, InfoOutlined } from "@mui/icons-material";
//   import { useDispatch, useSelector } from "react-redux";
//   import { RootState } from "../../store";
//   import { SchoolSettings } from "../../Interfaces/SchoolInterface";
//   import { updateSchoolSettingsBySchoolId } from "../../Services/SchoolsService";
  
//   const styles = {
//     listContainer: {
//       backgroundColor: "#3C404E",
//       borderRadius: "5px",
//       boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
//       overflow: "auto",
//       margin: "1rem 0",
//       textAlign: "start",
//       zIndex: 3,
//     } as CSSProperties,
//   };
  
//   interface Props {
//     schoolId?: number;
//     schoolSettings: SchoolSettings;
//     isSaving: boolean;
//     isLoading: boolean;
//   }
  
//   const PassConfirmationTimeout = ({
//     schoolId,
//     schoolSettings,
//     isSaving,
//     isLoading,
//   }: Props) => {
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
//     const dispatch = useDispatch();
//     const [anchorElement, setAnchorElement] = useState<HTMLElement | null>();
//     // const [lateBufferValue, setLateBufferValue] = useState<number>(0);
//     const [timeoutValue, setTimeoutValue] = useState<number>(120);
  
   

//     useEffect(() => {
//         setTimeoutValue(schoolSettings.pass_timeout ?? 120);
//       }, [schoolSettings]);
  
//     const handleHover = (e: React.MouseEvent<HTMLElement>) => {
//       setAnchorElement(e.currentTarget);
//     };
  
//     const handleClose = () => {
//       setAnchorElement(null);
//     };
  
    

//     const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//         const value = parseInt(e.target.value);
//         if (value < 10) return;
    
//         setTimeoutValue(value);
//       };
  
//     const handleSubmit = () => {
//       if (!schoolId) return;
  
    

//     const newSettings = {
//         ...schoolSettings,
//         pass_timeout: timeoutValue,
//       };
  
  
//       dispatch(
//         updateSchoolSettingsBySchoolId({
//           schoolId,
//           settings: newSettings,
//         })
//       );
//     };
  
//     return (
//       <Box style={styles.listContainer} flexDirection="column">
//         <Grid container p={2}>
//           <Grid item xs={1}>
//             <WatchLaterOutlined sx={{ fill: "#7AC142" }} />
//           </Grid>
//           <Grid item xs={10} paddingLeft={1}>
//             <Typography fontWeight={500}>Pass Confirmation Timeout</Typography>
//           </Grid>
//           <Grid
//             item
//             xs={1}
//             textAlign="end"
//             aria-owns={anchorElement ? "mouse-over-popover" : undefined}
//             aria-haspopup="true"
//             onMouseEnter={handleHover}
//             onMouseLeave={handleClose}
//           >
//             <InfoOutlined />
//           </Grid>
  
//           <Grid item xs={12} mt={1}>
//             <Typography fontSize=".8em">Seconds</Typography>
//           </Grid>
//           <Grid item xs={12} mb={3}>
//             <TextField
//               type="number"
//               variant="standard"
//               fullWidth
//               placeholder="E.g.: 40"
//               value={timeoutValue}
//               onChange={handleChange}
//               disabled={isSaving || isLoading}
//             />
//           </Grid>
  
//           <Grid item xs={12}>
//             <Button
//               variant="contained"
//               fullWidth
//               onClick={handleSubmit}
//               sx={{
//                 textTransform: "capitalize",
//                 color: "white",
//                 fontWeight: 400,
//               }}
//               disabled={isSaving || isLoading}
//             >
//               Save
//             </Button>
//           </Grid>
//         </Grid>
//         <Popover
//           id="mouse-over-popover"
//           sx={{
//             pointerEvents: "none",
//           }}
//           open={Boolean(anchorElement)}
//           anchorEl={anchorElement}
//           anchorOrigin={{
//             vertical: "bottom",
//             horizontal: "right",
//           }}
//           transformOrigin={{
//             vertical: "top",
//             horizontal: "right",
//           }}
//           onClose={handleClose}
//           disableRestoreFocus
//         >
//           <Typography
//             fontSize=".8em"
//             p={2}
//             sx={{
//               maxWidth: isMobile ? "60vw" : "30vw",
//               backgroundColor: "white",
//               color: "black",
//             }}
//           >
//             Please enter the amount of time in seconds that you would like to give
//             your students to accept a hall pass before it is automatically declined.
//           </Typography>
//         </Popover>
//         {isSaving && <LinearProgress />}
//       </Box>
//     );
//   };
  
//   export default PassConfirmationTimeout;