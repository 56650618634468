import {
  Box,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { CSSProperties } from "@mui/styles";
import {
  HallPassLocation,
  StudentOnLocation,
} from "../../Interfaces/SchoolInterface";
import { createQueueManagerByUserName } from "../../Services/UsersService";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getStudentsOnLocation } from "../../store/slices/hallPass.slice";
import { setTurnQueue } from "../../store/slices/user.slice";

interface ListProps {
  loadingIndicator: boolean;
  hallPassLocations: HallPassLocation[];
  isMobile: boolean;
}

const styles = {
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    minHeight: "79vh",
    maxHeight: "79vh",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "auto",
    margin: 0,
  } as CSSProperties,
  divider: { borderColor: "#3C404E", background: "#3C404E" },
};

const StudentHallPassLocationsList: FC<ListProps> = ({
  loadingIndicator,
  hallPassLocations,
}): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {  currentUser, currentClass } = useSelector(
    (state: RootState) => state.main
  );

  function StudentsInLine(studentsOnLocations: StudentOnLocation[]) {
    const total = getStudentsOnLocation(studentsOnLocations).length;
    const color =
      total <= 1
        ? "rgba(122, 193, 66, 1)"
        : total <= 4
        ? "rgba(248, 147, 28, 1)"
        : "rgba(255, 78, 50, 1)";
    return (
      <>
        {total > 0 ? (
          <>
            <span>Students in Line:</span>
            <span
              style={{
                color: color,
                marginLeft: 5,
              }}
            >
              {total}
            </span>
          </>
        ) : (
          <span>No Students in Line</span>
        )}
      </>
    );
  }

  
  


  function hallPassRequest(hallPassLocation: HallPassLocation) {
    createQueueManagerByUserName({studentId : currentUser?.user_name?.toString() || "0", locationId : hallPassLocation?.id?.toString() || "0"}).then(( res : number) => {
      dispatch(setTurnQueue(res))
      navigate(`/StudentHallPassLocationRequest/location/${hallPassLocation.id}/student/${currentUser.user_name}/class/${currentClass.id}/turnQueue/${res}`);
    }).catch((error) => {
      console.error("Error:", error);
    });
  }

  return (
    <>
      <Box style={styles.listContainer}>
        {loadingIndicator ? (
          <Box style={styles.loading}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <Box pl={2}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sm={9}>
                  <Typography
                    component="div"
                    align="left"
                    variant="subtitle2"
                    style={{ fontSize: "14px", lineHeight: "48px" }}
                  >
                    Request a Hall Pass
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <List style={styles.list}>
              {hallPassLocations?.map((item, index) => (
                <div key={index.toString()}>
                  <ListItem
                    style={styles.listItem}
                    onClick={() => hallPassRequest(item)}
                  >
                    <ListItemButton sx={{ padding: 0, margin: 0 }}>
                      <ListItemText
                        primary={item.name}
                        secondary={StudentsInLine(
                          item.StudentsOnLocations as StudentOnLocation[]
                        )}
                      />
                      <IconButton edge="end" aria-label="menu">
                        <ArrowForwardIos fontSize="small" />
                      </IconButton>
                    </ListItemButton>
                  </ListItem>
                  <Divider style={styles.divider} />
                </div>
              ))}
            </List>
          </>
        )}
      </Box>
    </>
  );
};

export default StudentHallPassLocationsList;
