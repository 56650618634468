import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListItemText,
  Checkbox,
  SelectChangeEvent,
  FormHelperText,
  Avatar,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { FC, ReactElement, useEffect, useState } from "react";
import useForm, { FormField } from "../../Hooks/useForm";
import { LightModal } from "../LightModal/LightModal";
import { GroupEnum, User } from "../../Interfaces/UserInterface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  IsValidEmail,
  RequiredStringField,
} from "../../Helpers/FormValidations";
import { School } from "../../Interfaces/SchoolInterface";
import profilePictureIcon from "../../Assets/profile_picture_icon.png";
import { createSchoolAdminUser } from "../../Services/UsersService";
import { fetchSchools } from "../../Services/SchoolsService";

interface ListProps {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
}

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

const newSchoolAdminForm: User = {
  first_name: "",
  last_name: "",
  email: "",
  user_name: "",
  group: GroupEnum.SchoolAdmin,
  schools: [],
  profile_picture: "",
};

const SchoolAdminModal: FC<ListProps> = ({
  openModal,
  handleOpenModal,
}): ReactElement => {
  const [schoolsSelected, setSchoolsSelected] = useState<number[]>([]);
  const [profilePicture, setProfilePicture] = useState<string>();
  //store
  const { schools } = useSelector((state: RootState) => state.school);
  const { schoolAdmins, savingIndicator, successMessage } = useSelector(
    (state: RootState) => state.user
  );

  const dispach = useDispatch();

  useEffect(() => {
    dispach(fetchSchools());
  }, []);

  useEffect(() => {
    successMessage !== "" && handleCloseModal();
  }, [successMessage]);

  const {
    form,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleCompleted,
  } = useForm<User>(newSchoolAdminForm, validateformField, submitForm);

  async function submitForm(form: User) {
    if (!readyToSubmit()) return;
    
    form.schools = schoolsSelected.map((s) => {
      const school: School = {
        id: s,
        district_id: 0,
        name: "",
      };
      return school;
    });
    await dispach(createSchoolAdminUser(form));
  }

  function handleCloseModal() {
    handleCompleted(true);
    handleOpenModal(false);
    setProfilePicture("");
    setSchoolsSelected([]);
  }

  function handleChangeSchool({
    target,
  }: SelectChangeEvent<typeof schoolsSelected>) {
    setSchoolsSelected(target.value as number[]);
  }

  function checkIfAlreadyExist(value: string): string {
    let message = "";

    if (schoolAdmins) {
      const result = schoolAdmins?.find((s) => s.email === value);
      if (result !== undefined)
        message = "Another user with the same email already exists";
    }
    return message;
  }

  function validateformField(fieldName: string, fieldValue: string): FormField {
    let value: string = "";
    
    switch (fieldName) {
      case "first_name": {
        value = RequiredStringField("first name", fieldValue);
        break;
      }
      case "last_name": {
        value = RequiredStringField("last name", fieldValue);
        break;
      }
      case "email": {
        const labelName = "email";
        value =
          RequiredStringField(labelName, fieldValue) ||
          checkIfAlreadyExist(labelName) ||
          IsValidEmail(fieldValue);
        break;
      }
      case "school_ids": {
        value = fieldValue.length === 0 ? "No schools selected" : "";
        break;
      }
    }
    return {
      name: fieldName,
      value: value,
    } as FormField;
  }
 
  function renderSchools(selected: number[]) {
    return schools
      .filter((s) => selected.includes(s.id || 0))
      .map((m) => m.name)
      .join(", ");
  }

  async function handlePreviewProfilePicture(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    if (e.target.files?.length) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target?.files[0]);
      const url = URL.createObjectURL(e.target?.files[0]);
      setProfilePicture(url);
      reader.addEventListener(
        "load",
        () => {
          form.profile_picture = reader.result;
        },
        false
      );
    }
  }

  function readyToSubmit(): boolean {
    return !(
      !form.first_name ||
      !form.last_name ||
      !form.email ||
      !schoolsSelected.length ||
      !!errors.first_name ||
      !!errors.first_name ||
      !!errors.email ||
      savingIndicator
    );
  }

  return (
    <>
      <LightModal
        width="650px"
        isOpenModal={openModal}
        handleOpenModal={handleOpenModal}
      >
        <Card>
          <CardHeader title="Create a New Admin" sx={{ mx: 1 }} />
          <form onSubmit={handleSubmit} autoComplete="off">
            <CardContent sx={{ mx: 1 }}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 8,
                    }}
                  >
                    <Avatar
                      alt="Profile picture"
                      src={profilePicture || profilePictureIcon}
                      style={{ width: 56, height: 56 }}
                    />
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "unset",
                          height: 32,
                          marginLeft: 10,
                        }}
                        onClick={() =>
                          document.getElementById("profile_picture")?.click()
                        }
                      >
                        Upload photo
                      </Button>
                    </div>
                    <input
                      id="profile_picture"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ display: "none" }}
                      onChange={handlePreviewProfilePicture}
                    />
                  </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    required
                    type="text"
                    name="first_name"
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    value={form.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.first_name}
                    helperText={errors.first_name && errors.first_name}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    required
                    type="text"
                    name="last_name"
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    value={form.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.last_name}
                    helperText={errors.last_name && errors.last_name}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    required
                    error={!!errors.school_ids}
                  >
                    <InputLabel id="new-admin-modal-school-select">
                      School
                    </InputLabel>
                    <Select
                      label="School"
                      labelId="new-admin-modal-school-select"
                      multiple
                      value={schoolsSelected}
                      renderValue={renderSchools}
                      name="school_ids"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChangeSchool(e);
                        handleChange(e);
                      }}
                    >
                      <MenuItem value={0} disabled>
                        Select s School
                      </MenuItem>
                      {schools?.map((school) => (
                        <MenuItem key={school.id} value={school.id}>
                          <Checkbox
                            checked={
                              schoolsSelected.indexOf(
                                parseInt(`${school.id}`)
                              ) > -1
                            }
                          />
                          <ListItemText primary={school.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.school_ids && errors.school_ids}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    required
                    style={{ marginBottom: "5px" }}
                    type="text"
                    name="email"
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={form.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.email}
                    helperText={errors.email && errors.email}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions style={{ padding: 0, margin: 0 }}>
              <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => handleCloseModal()}
                    disabled={savingIndicator}
                    style={styles.formButton}
                    size="large"
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={styles.formButton}
                    fullWidth
                    size="large"
                    disabled={!readyToSubmit()}
                  >
                    Create User
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            {savingIndicator && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </form>
        </Card>
      </LightModal>
    </>
  );
};

export default SchoolAdminModal;
