import { FC, ReactElement, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { HallPassLocation, Room } from "../../Interfaces/SchoolInterface";
import { LightModal } from "../LightModal/LightModal";
import { GroupEnum, User } from "../../Interfaces/UserInterface";
import {
  createHallPassLocation,
  fetchHallPasses,
  updateHallPassLocation,
} from "../../Services/HallPassService";
import { ArrowBackIos } from "@mui/icons-material";
interface ListProps {
  title: string;
  school_id: number;
  openModal: boolean;
  handleOpenModal: (state: boolean, origin: string) => void;
  managers: User[];
  selectedLocation?: HallPassLocation;
  styles: any;
}
interface IForm {
  name: string;
  max_students_allowed: number;
  scan_at_arrival: boolean;
}

const HallPassModal: FC<ListProps> = ({
  title,
  school_id,
  openModal,
  handleOpenModal,
  managers,
  selectedLocation,
  styles,
}): ReactElement => {
  const dispatch = useDispatch();
  const { successMessage, savingIndicator } = useSelector(
    (state: RootState) => state.school
  );

  const [managersSelected, setManagersSelected] = useState<number[]>([]);
  const [openManagersModal, setOpenManagersModal] = useState<boolean>(false);
  const [scanAtArrival, setScanAtArrival] = useState<boolean>(true);
  const [managersFieldError, setManagersFieldError] = useState<string>("");
  const schema = yup.object().shape({
    name: yup.string().required("Name field is required"),
    max_students_allowed: yup
      .string()
      .required("Max students allowed field is required"),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      max_students_allowed: "",
    },
  });
  const formSubmitHandler: SubmitHandler<any> = async (data: IForm) => {
    setManagersFieldError("");

    if (scanAtArrival && !managersSelected.length) {
      setManagersFieldError("Managers not selected");
      return;
    }

    if (selectedLocation?.id) {
      const newForm: HallPassLocation = {
        id: selectedLocation.id,
        name: data.name,
        max_students_allowed: Number(data.max_students_allowed),
        school_id: school_id,
        scan_at_arrival: scanAtArrival,
        managers: managersSelected.map((s) => {
          const user: User = {
            id: s,
            first_name: "",
            last_name: "",
            email: "",
            user_name: "",
            group: GroupEnum.SchoolAdmin,
            schools: [],
          };
          return user;
        }),
      };
      await dispatch(updateHallPassLocation(newForm));
    } else {
      const newForm: HallPassLocation = {
        name: data.name,
        max_students_allowed: Number(data.max_students_allowed),
        school_id: school_id,
        scan_at_arrival: scanAtArrival,
        managers: managersSelected.map((s) => {
          const user: User = {
            id: s,
            first_name: "",
            last_name: "",
            email: "",
            user_name: "",
            group: GroupEnum.SchoolAdmin,
            schools: [],
          };
          return user;
        }),
      };
      await dispatch(createHallPassLocation(newForm));
      handleCloseModal();
    }

    await dispatch(fetchHallPasses(school_id));
  };

  function handleCloseModal() {
    handleOpenModal(false, "");
    setManagersSelected([]);
    setScanAtArrival(true);
    setValue("name", "");
    setValue("max_students_allowed", null);
  }

  useEffect(() => {
    successMessage !== "" && handleCloseModal();
    reset();
  }, [successMessage]);

  useEffect(() => {
    if (selectedLocation?.id) {
      title = "Update Location";
      setValue("name", selectedLocation.name);
      setValue("max_students_allowed", selectedLocation.max_students_allowed);
      setManagersSelected(
        selectedLocation.managers?.map((m) => m.id) as number[]
      );
      setScanAtArrival(selectedLocation.scan_at_arrival);
    }
  }, [selectedLocation]);

  function renderManagers(selected: number[]) {
    return managers
      .filter((s) => selected.includes(s.id || 0))
      .map((m) => `${m.last_name} ${m.first_name}`)
      .join(", ");
  }

  function handleSelectManagers() {
    setOpenManagersModal(true);
  }

  function handleCloseManagersModal() {
    console.log("handleCloseManagersModal");
    setManagersSelected(
      selectedLocation?.managers?.map((m) => m.id) as number[]
    );
    setOpenManagersModal(false);
  }

  function handleSaveManagersModal() {
    setOpenManagersModal(false);
    setManagersFieldError("");
  }

  return (
    <>
      <LightModal
        width="650px"
        isOpenModal={openModal}
        handleOpenModal={handleOpenModal}
      >
        <Card>
          <CardHeader title={title} sx={{ mx: 1 }} />
          <form autoComplete="off" onSubmit={handleSubmit(formSubmitHandler)}>
            <CardContent sx={{ mx: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        id="name"
                        label="Location name"
                        variant="standard"
                        error={!!errors.name}
                        helperText={errors.name ? errors.name?.message : ""}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="max_students_allowed"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        id="max_students_allowed"
                        label="Max # of students allowed at once"
                        variant="standard"
                        error={!!errors.max_students_allowed}
                        helperText={
                          errors.max_students_allowed
                            ? errors.max_students_allowed?.message
                            : ""
                        }
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={!!managersFieldError}
                  >
                    <InputLabel id="new-admin-modal-school-select">
                      Manager
                    </InputLabel>
                    <Select
                      onClick={handleSelectManagers}
                      disabled
                      labelId="new-admin-modal-school-select"
                      multiple
                      value={managersSelected}
                      renderValue={renderManagers}
                      name="managers_ids"
                    ></Select>
                    <FormHelperText>
                      {managersFieldError && managersFieldError}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={scanAtArrival}
                        onChange={() => setScanAtArrival(!scanAtArrival)}
                      />
                    }
                    label="Scan At Arrival"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions style={{ padding: 0, margin: 0 }}>
              <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleCloseModal();
                      reset();
                    }}
                    disabled={savingIndicator}
                    style={styles.formButton}
                    size="large"
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={styles.formButton}
                    fullWidth
                    size="large"
                  >
                    {selectedLocation?.id ? (
                      <div>Update Location</div>
                    ) : (
                      <div>Add Location</div>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            {savingIndicator && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </form>
        </Card>
      </LightModal>
      {/* Managers modal */}
      <LightModal
        width="650px"
        isOpenModal={openManagersModal}
        handleOpenModal={() => {}}
      >
        <Card>
          <CardHeader
            title={
              <>
                <IconButton
                  style={{ marginTop: -3 }}
                  onClick={() => {
                    handleCloseManagersModal();
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                </IconButton>
                <span>Manager Selection</span>
              </>
            }
          />
          <CardContent sx={{ mx: 2, overflowY: "scroll", maxHeight: 300 }}>
            {managers?.map((manager, index) => {
              const handleChange = (
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                const arr = [...managersSelected];
                if (event.target.checked) {
                  arr.push(manager.id as number);
                } else {
                  arr.splice(arr.indexOf(manager.id as number), 1);
                }
                setManagersSelected(arr);
              };
              return (
                <div key={`checkbox-${index}`}>
                  <FormControlLabel
                    className="checkbox-group-checkbox-label"
                    control={
                      <Checkbox
                        name={`${manager.last_name}-${manager.first_name}`}
                        value={manager.id as number}
                        checked={
                          managersSelected.indexOf(parseInt(`${manager.id}`)) >
                          -1
                        }
                        onChange={handleChange}
                      />
                    }
                    label={`${manager.last_name} ${manager.first_name}`}
                  />
                </div>
              );
            })}
          </CardContent>
          <CardActions style={{ padding: 0, margin: 0 }}>
            <Grid
              container
              spacing="0"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    handleCloseManagersModal();
                  }}
                  disabled={savingIndicator}
                  style={styles.formButton}
                  size="large"
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Go Back
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => handleSaveManagersModal()}
                  color="primary"
                  variant="contained"
                  style={styles.formButton}
                  fullWidth
                  size="large"
                  disabled={scanAtArrival && !managersSelected.length}
                >
                  Select manager
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </LightModal>
    </>
  );
};

export default HallPassModal;
