export function formatDate(value: any): string {
  try {
    let date = new Date(value);
    const day = date.toLocaleString("default", { day: "2-digit" });
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.toLocaleString("default", { year: "numeric" });
    return `${day} ${month} ${year}`;
  } catch (error) {
    return "";
  }
}

export function formatDateLong(value: any): string {
  try {
    let date = new Date(value);
    const day = date.toLocaleString("default", { day: "2-digit" });
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.toLocaleString("default", { year: "numeric" });
    const dayOfWeek = date.toLocaleString("default", { weekday: "long" });
    return `${day} ${month}, ${year} (${dayOfWeek})`;
  } catch (error) {
    return "";
  }
}

export function toLocalDateWithoutTime(dateString: string): Date{
  return new Date(new Date(dateString).toLocaleDateString());
}

