import { AlertColor, Box, CssBaseline } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlert } from "../Components";
import LateBufferForm from "../Components/SchoolSettings/LateBufferForm";
import TimeZoneOffsetForm from "../Components/SchoolSettings/TImeZoneOffset";
import PassConfirmationTimeout from "../Components/SchoolSettings/PassConfirmationTimeout";
import { LayoutContainer } from "../Layout/layout.container";
import { fetchSchoolSettingsBySchoolId } from "../Services/SchoolsService";
import { RootState } from "../store";
import {
  AlertState,
  setCustomAlertDetails,
  setPageTitle,
  setShowCustomAlert,
} from "../store/slices/main";
import {
  setSuccessMessage,
  setErrorMessage,
} from "../store/slices/school.slice";

const AdminSchoolSettingsPage = () => {
  const dispatch = useDispatch();
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const {
    currentSchoolSettings,
    successMessage,
    errorMessage,
    savingIndicator,
    loadingIndicator,
  } = useSelector((state: RootState) => state.school);

  useEffect(() => {
    if (!currentSchool?.id) return;

    dispatch(setPageTitle("School Settings"));
    dispatch(fetchSchoolSettingsBySchoolId(currentSchool.id));
  }, [currentSchool]);

  useEffect(() => {
    if (successMessage) {
      showAlert(successMessage, "success");
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      showAlert(errorMessage, "error");
    }
  }, [errorMessage]);

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  return (
    <>
      <CssBaseline />
      <LayoutContainer isRaised={true}>
        <Box style={{ marginTop: "-50px" }}>
          <LateBufferForm
            schoolSettings={currentSchoolSettings}
            schoolId={currentSchool?.id}
            isSaving={savingIndicator}
            isLoading={loadingIndicator}
          />
          <TimeZoneOffsetForm
            schoolSettings={currentSchoolSettings}
            schoolId={currentSchool?.id}
            isSaving={savingIndicator}
            isLoading={loadingIndicator}
          />
          <PassConfirmationTimeout
            schoolSettings={currentSchoolSettings}
            schoolId={currentSchool?.id}
            isSaving={savingIndicator}
            isLoading={loadingIndicator}
          />
        </Box>
        <CustomAlert handleClose={handleCloseAlert} />
      </LayoutContainer>
    </>
  );
};

export default AdminSchoolSettingsPage;
