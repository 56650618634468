import { createSlice } from "@reduxjs/toolkit";
import { Calendar } from "../../Interfaces/CalendarInterface";
import {
  createCalendarDay,
  deleteCalendarDay,
  fetchCalendarDaysBySchoolId,
  updateCalendarDay,
} from "../../Services/CalendarService";

export const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    calendarDays: [] as Calendar[],
    loadingIndicator: false,
    savingIndicator: false,
    errorMessage: "",
    successMessage: "",
  },
  reducers: {
    setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
  },
  extraReducers: (builder) => {
    /* #region  fetch calendar days */
    builder.addCase(fetchCalendarDaysBySchoolId.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchCalendarDaysBySchoolId.fulfilled,
        (state, { payload }) => {
          state.calendarDays = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(fetchCalendarDaysBySchoolId.rejected, (state) => {
        state.loadingIndicator = false;
      });
    /* #endregion */

    /* #region  create calendar day */
    builder.addCase(createCalendarDay.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(createCalendarDay.fulfilled, (state, { payload }) => {
        // validate if payload is an array
        if (Array.isArray(payload)) {
          state.calendarDays = [...state.calendarDays, ...payload];
        } else {
          state.calendarDays.push(payload);
        }
        state.successMessage = "Calendar day created successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(createCalendarDay.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });
    /* #endregion */

    /* #region  update calendar day */
    builder.addCase(updateCalendarDay.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(updateCalendarDay.fulfilled, (state, { payload }) => {
        const index = state.calendarDays.findIndex((s) => s.id === payload.id);
        if (index > -1) {
          state.calendarDays[index] = payload;
        }
        state.successMessage = "Calendar day updated successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(updateCalendarDay.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });
    /* #endregion */

    /* #region  delete calendar day */
    builder.addCase(deleteCalendarDay.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(deleteCalendarDay.fulfilled, (state, { payload }) => {
        const index = state.calendarDays.findIndex((s) => s.id === payload.id);
        if (index > -1) {
          state.calendarDays.splice(index, 1);
        }
        state.successMessage = "Calendar day removed successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(deleteCalendarDay.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });
    /* #endregion */
  },
});

export default calendarSlice.reducer;
export const { setSuccessMessage, setErrorMessage } = calendarSlice.actions;
