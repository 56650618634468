import { FC, ReactElement } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import React from "react";

interface ConfirmationDialogProps {
  open: boolean;
  onCancel?: () => void;
  onConfirm: (event: React.MouseEvent<HTMLElement>) => void;
  title: string;
  message: string;
  loading: boolean;
  okText?: string;
  cancelText?: string;
}

const ConfirmationDialogDefaultProps = {
  open: false,
  onCancel: undefined,
  onConfirm: () => {},
  title: "",
  message: "",
  loading: false,
  okText: "Accept",
  cancelText: "Cancel",
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = (
  props
): ReactElement => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.onCancel && (
          <Button onClick={props.onCancel}>{props.cancelText}</Button>
        )}
        <Button onClick={props.onConfirm} autoFocus>
          {props.okText}
        </Button>
      </DialogActions>
      {props.loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = ConfirmationDialogDefaultProps;
