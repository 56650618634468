import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import { LayoutContainer } from "../Layout/layout.container";
import { Box, CssBaseline, LinearProgress, Alert } from "@mui/material";
import { completeHallPassLocationRequest, logDeviceInformation, getCurrentHallPassRequestByStudentUserName } from "../Services/HallPassService";
import { fetchCurrentClassByRoomId } from "../Services/ClassService";
import useLocalStorage from "../Hooks/useLocalStorage";
import { CURRENT_SCHOOL } from "../Helpers/Constants";
import moment from "moment";
import { deleteQueueManagerByStudent } from "../Services/UsersService";

export const ReadQRForRegisterAttendance: FC<any> = (): ReactElement => {
  let { idSchool, idRoom } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isSessionValid, currentAuthenticatedUser } = useCognitoAwsV2();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [returnToClass, setReturnToClass] = useLocalStorage(
    "SeedClassroomReturnToClass",
    ""
  );

  useEffect(() => {
    if (isLoading == true) {
      validateUserSession();
    }
  }, []);

  function detectDevice() {
    const userAgent = navigator.userAgent;
  
    // Device type detection
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    const isTablet = /iPad|Android/i.test(userAgent) && !/(Mobile)/i.test(userAgent);
    const isDesktop = !isMobile && !isTablet;
  
    // OS detection
    const isWindows = /Win(dows)?/i.test(userAgent);
    const isMac = /Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent);
    const isLinux = /Linux/i.test(userAgent);
    const isUbuntu = /Ubuntu/i.test(userAgent); 
    const isFreeBSD = /FreeBSD/i.test(userAgent);
    const isAndroid = /Android/i.test(userAgent);
    const isiPhone = /iPhone/i.test(userAgent);
  
    // OS version detection (basic)
    const windowsVersionMatch = userAgent.match(/Windows NT (\d+\.\d+)/);
    const macVersionMatch = userAgent.match(/Mac OS X (\d+[._]\d+)/);
    const androidVersionMatch = userAgent.match(/Android (\d+\.\d+)/);
    const iPhoneVersionMatch = userAgent.match(/iPhone OS (\d+_\d+)/);
  
    const windowsVersion = windowsVersionMatch ? `Windows ${windowsVersionMatch[1]}` : 'Unknown Windows version';
    const macVersion = macVersionMatch ? `macOS ${macVersionMatch[1].replace('_', '.')}` : 'Unknown macOS version';
    const androidVersion = androidVersionMatch ? `Android ${androidVersionMatch[1]}` : 'Unknown Android version';
    const iPhoneVersion = iPhoneVersionMatch ? `iOS ${iPhoneVersionMatch[1].replace('_', '.')}` : 'Unknown iOS version';
  
    return {
      // Device types
      isMobile,
      isTablet,
      isDesktop,
  
      // Operating Systems
      isWindows,
      isMac,
      isLinux,
      isUbuntu,
      isFreeBSD,
      isAndroid, 
      isiPhone,  
  
      // Versions
      windowsVersion: isWindows ? windowsVersion : null,
      macVersion: isMac ? macVersion : null,
      androidVersion: isAndroid ? androidVersion : null, 
      iPhoneVersion: isiPhone ? iPhoneVersion : null 
    };
  }
  

  async function logUserDeviceAndClassroomInfo(user: any, deviceInfo: any, classDetails: any, returnToClass: any) {
    try {
      const deviceInfo = detectDevice(); 
      const logData = {
        scan_info: 'Student user has scanned a Room QR code',
        user_info: user.attributes,
        stored_User_Info: user.storage.current_class,
        school_id: idSchool,
        id_Of_Room_Scanned: idRoom,
        User_is_pass_info: returnToClass, 
        current_class: classDetails, 
        device_info: deviceInfo,
      };
  
      
      await logDeviceInformation(logData);
    } catch (error) {
      console.error('Error logging user and device info:', error);
    }
  }

  async function validateUserSession(): Promise<void> {
    try {
      setIsLoading(true);
      let idSchoolNum = Number(idSchool);
      let idRoomNum = Number(idRoom);

      if (isNaN(idSchoolNum) || isNaN(idRoomNum)) {
        throw new Error("Invalid QR Code");
      }

      // if session is not valid then require login first
      window.localStorage.setItem(CURRENT_SCHOOL, idSchoolNum.toString());

      const { payload } = (await dispatch(fetchCurrentClassByRoomId(idRoomNum))) as any;
      const classDetails = payload

      // Check if session is valid
      const isValid = await isSessionValid();
      if (!isValid) {
        navigate("/");
        return;
      }
      const user = await currentAuthenticatedUser();
      console.log("scan qr for attendacne", user)
      const activePass = await getCurrentHallPassRequestByStudentUserName(user.username);
      console.log("active pass", activePass)
      const deviceInfo = detectDevice();
      // const returningFromPass = returnToClass === '' ? false : returnToClass;

      
      await logUserDeviceAndClassroomInfo(user, deviceInfo, classDetails, activePass);

      // Complete hall pass location request
      if (activePass && (activePass.status === "InProgress" || activePass.status === "AtLocation")) {
        const user = await currentAuthenticatedUser();
        console.log(user)
        const result: any = await dispatch(completeHallPassLocationRequest(user?.username));

        if (result?.payload) {
          dispatch(deleteQueueManagerByStudent(user?.username))
          setSuccessMessage("Hall Pass Request completed successfully");
         
          setTimeout(() => {
            setReturnToClass(false);
            navigate("/");
          }, 2000);
        }
        return;
      }

      // Navigate to attendance register
      if (!payload) {
        throw new Error(
          "A class associated with the QR code and the current time was not found"
        );
      }
      navigate("/AttendanceRegister");

    } catch (error) {
      const message = error instanceof Error ? error.message : "Invalid QR Code";
      setErrorMessage(message);
      setTimeout(() => { navigate("/ScanQRCode") }, 2000);
    } finally {
      setIsLoading(false);
    }
  }

  

  return (
    <>
      <CssBaseline />
      <LayoutContainer isRaised={true}>
        {isLoading && (
          <Box sx={{ width: "100%", top: 0, position: "fixed", left: 0 }}>
            <LinearProgress sx={{ marginBottom: 40 }} />
            Processing QR Code...
          </Box>
        )}
        {errorMessage && (
          <Alert severity="warning" variant="outlined" color="error">
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" variant="outlined" color="success">
            {successMessage}
          </Alert>
        )}
      </LayoutContainer>
    </>
  );
};

export default ReadQRForRegisterAttendance;
