import { Search } from "@mui/icons-material";
import {
  AlertColor,
  Box,
  CssBaseline,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AddFloatButton, CustomAlert } from "../Components";
import { CSSProperties } from "@mui/styles";
import { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import { LayoutContainer } from "../Layout/layout.container";
import { RootState, AppDispatch } from "../store";
import {
  AlertState,
  setBackwardNavigationRoute,
  setCustomAlertDetails,
  setPageSubtitle,
  setPageTitle,
  setShowCustomAlert,
} from "../store/slices/main";
import ClassRosterList from "../Components/Class/ClassRosterList";
import {
  fetchStudentsByClassId,
  fetchStudentsBySchoolId,
} from "../Services/UsersService";
import { EnrollStudentModal } from "../Components/Class/EnrollStudentModal";
import { Class } from "../Interfaces/ClassInterface";
import {
  setSuccessMessage,
  setErrorMessage,
  resetStudentsInClass,
} from "../store/slices/user.slice";
import { User } from "../Interfaces/UserInterface";

const styles = {
  loading: {
    width: "100%",
    background: "#3C404E",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    borderRadius: "0 0 5px 5px",
    overflow: "auto",
    textAlign: "center",
    minHeight: "12px",
    maxHeight: "12px",
    zIndex: 3,
  } as CSSProperties,
  searchContainer: {
    background: "#3C404E",
    padding: "10px",
    height: 50,
    width: "100%",
    borderRadius: "8px 8px 0px 0px ",
  } as CSSProperties,
  containerBox: {
    borderRadius: "5px 5px 0 0",
  } as CSSProperties,
};

const ClassSubtitleHtml = ({ selectedClass }: { selectedClass: Class }) => (
  <span style={{ fontSize: ".95em" }}>
    Room <b>{selectedClass.room?.name}</b> &bull; Day Type{" "}
    <b>{selectedClass.day_type?.name}</b> &bull; Period{" "}
    <b>{selectedClass.period?.name}</b>
  </span>
);

const TeacherClassRosterPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch<AppDispatch>();
  const [addStudentModalOpen, setAddStudentModalOpen] = useState(false);
  const [filteredStudents, setFilteredStudents] = useState<User[]>([]);
  const [studentFilter, setStudentFilter] = useState<string>("");

  const {
    currentClass: selectedClass,
    loadingIndicator: classLoadingIndicator,
  } = useSelector((state: RootState) => state.class_);

  const {
    studentsInClass,
    students,
    successMessage,
    errorMessage,
    loadingIndicator: studentsLoadingIndicator,
    savingIndicator: userSavingIndicator,
  } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const subtitleHtml = <ClassSubtitleHtml selectedClass={selectedClass} />;
    fetchDependencies();
    dispatch(setPageTitle(selectedClass.name));
    dispatch(setPageSubtitle(renderToString(subtitleHtml)));

    return () => {
      dispatch(setPageSubtitle(""));
      dispatch(resetStudentsInClass());
      dispatch(setBackwardNavigationRoute(""));
    };
  }, [selectedClass]);

  useEffect(() => {
    filterStudents(studentFilter);
  }, [studentsInClass]);

  useEffect(() => {
    if (successMessage) {
      showAlert(successMessage, "success");
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      showAlert(errorMessage, "error");
    }
  }, [errorMessage]);

  const fetchDependencies = () => {
    if (!selectedClass?.id) return;

    dispatch(fetchStudentsByClassId(selectedClass.id));

    if (selectedClass?.school_id) {
      dispatch(fetchStudentsBySchoolId(selectedClass.school_id));
    }
  };

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  const buildConditionalStyles = () => {
    return {
      timeSpent: {
        padding: isMobile ? "2px" : "5px",
        fontSize: isMobile ? ".7em" : "1em",
        lineHeight: "1em",
      } as CSSProperties,
    };
  };

  function filterStudents(filterTerm: string) {
    const filtered = studentsInClass.filter(({ first_name, last_name }) =>
      `${first_name} ${last_name}`.toLowerCase().includes(filterTerm)
    );

    setFilteredStudents(filtered);
  }

  function filter(e: React.ChangeEvent<HTMLInputElement>) {
    const keyword = e.target.value.trim().toLowerCase();
    setStudentFilter(keyword);

    filterStudents(keyword);
  }

  const handleOpenAddStudentModal = () => {
    setAddStudentModalOpen(!addStudentModalOpen);
  };

  return (
    <>
      <CssBaseline />
      <LayoutContainer isRaised={true}>
        <Box style={styles.containerBox}>
          <Grid container>
            {/* NOTE: Feature will be used in a future US
              <Grid
                item
                xs={2}
                sm={2}
                justifyContent="center"
                alignItems="center"
                sx={buildConditionalStyles().timeSpent}
              >
                  Time spent in class
              </Grid>
            */}
            <Grid item xs={12} sm={12}>
              <Box style={styles.searchContainer}>
                <TextField
                  placeholder="Search by name"
                  variant="standard"
                  fullWidth
                  onChange={filter}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {classLoadingIndicator ||
            (studentsLoadingIndicator && (
              <Box style={styles.loading}>
                <LinearProgress />
              </Box>
            ))}
          <ClassRosterList
            students={filteredStudents}
            selectedClass={selectedClass}
            isSaving={userSavingIndicator}
          />
        </Box>
        <AddFloatButton handleOpenModal={handleOpenAddStudentModal} />
        <EnrollStudentModal
          openModal={addStudentModalOpen}
          handleOpenModal={handleOpenAddStudentModal}
          selectedClass={selectedClass}
          students={students}
          studentsInClass={studentsInClass}
          isSaving={userSavingIndicator}
        />
        <CustomAlert handleClose={handleCloseAlert} />
      </LayoutContainer>
    </>
  );
};

export default TeacherClassRosterPage;
