import { CSSProperties, FC } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
  CardActions,
  Typography,
  LinearProgress,
  Box,
} from "@mui/material";
import { LightModal } from "../LightModal/LightModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { Class } from "../../Interfaces/ClassInterface";
import { removeStudentsFromClass } from "../../Services/UsersService";
import {
  setCurrentClass,
} from "../../store/slices/main";

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
    lineHeight: 1,
  } as CSSProperties,
};

interface Props {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
  studentId: number | undefined;
  selectedClass: Class;
  isSaving: boolean;
}


export const RemoveStudentDialog: FC<Props> = ({
  openModal,
  handleOpenModal,
  studentId,
  // selectedClass,
  isSaving,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const selectedClass = useSelector((state: RootState) => state.main.currentClass);

  

  const handleSubmit = () => {
    console.log('test')
    deleteStudent();
  };

  const deleteStudent = () => {
    if (!selectedClass?.id || !studentId) return;
    dispatch(
      removeStudentsFromClass({ studentId, class_id: selectedClass.id })
    ).then(() => handleOpenModal(false));
  };

  return (
    <>
      <LightModal isOpenModal={openModal} handleOpenModal={handleOpenModal}>
        <Card>
          <CardHeader
            title="Remove student from the roster"
            sx={{ mx: 1, fontWeight: 500, textAlign: "center" }}
          />
          <CardContent sx={{ mx: 1 }}>
            <Typography textAlign="center">
              This user will be archived and you can include it in the roster in
              the future whenever you want
            </Typography>
          </CardContent>
          <CardActions style={{ padding: 0, margin: 0, marginTop: 10 }}>
            <Grid
              container
              spacing="0"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "1rem" }}
            >
              {isSaving && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress color="error" />
                </Box>
              )}
              <Grid item xs={6}>
                <Button
                  onClick={() => handleOpenModal(false)}
                  style={styles.formButton}
                  size="large"
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  style={{ ...styles.formButton, backgroundColor: "#ff4e32" }}
                  size="large"
                  variant="contained"
                  type="submit"
                  fullWidth
                  onClick={handleSubmit}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </LightModal>
    </>
  );
};
