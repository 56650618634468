import {
  Box,
  CssBaseline,
  Grid,
  LinearProgress,
  AlertColor,
} from "@mui/material";
import { LayoutContainer } from "../Layout/layout.container";
import { CSSProperties } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../store";
import {
  AlertState,
  setCustomAlertDetails,
  setShowCustomAlert,
  setIsStaffLocationPage,
  setPageTitle,
} from "../store/slices/main";
import { getUserByStudentIdNumber } from "../Services/UsersService";
import { Class } from "../Interfaces/ClassInterface";
import { User } from "../Interfaces/UserInterface";
import { setErrorMessage, setSuccessMessage } from "../store/slices/user.slice";
import { CustomAlert } from "../Components";
import Html5QrcodePlugin from "../Services/Html5QrcodePlugin"
import {
  createHallPassKioskLocation,
  getLocationsByUserManager,
} from "../Services/HallPassService";
import { setCurrentLocation } from "../store/slices/main";
import { CreateStudentOnLocationDto } from "../Interfaces/SchoolInterface";
import { AttendanceListLocationComponent } from "../Components/AttendanceList/AttendanceListLocationComponent";
import { createQueueManagerByUserName, validateQueueManager } from "../Services/UsersService";

const styles = {
  searchContainer: {
    background: "#3C404E",
    padding: "10px",
    height: 50,
    width: "100%",
    borderRadius: "8px 8px 0px 0px ",
  },
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    textAlign: "center",
    minHeight: "50vh",
    maxHeight: "50vh",
    zIndex: 3,
  } as CSSProperties,
};

const KioskStaffPage = () => {
  const dispatch = useDispatch();
  const { currentLocation } = useSelector((state: RootState) => state.main);
  const [singleStudentId, setSingleStudentId] = useState("");
  const [lastReloadAux, setLastReloadAux] = useState<Date>(new Date());
  const [loadingLocations, setLoadingLocations] = useState<boolean>(false);
  const [studentUser, setStudentUser] = useState<User | null>(null);
  const [savingAttendance, setSavingAttendance] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
  useEffect(() => {
    dispatch(setIsStaffLocationPage(true));
    dispatch(setPageTitle(""));
    forceLastReloadAttendanceList();
    console.log("🍉 currentLocation ", currentLocation);
    return () => {
      dispatch(setIsStaffLocationPage(false));
      dispatch(setCurrentLocation({}));
    };
  }, []);

  useEffect(() => {
    if (singleStudentId !== "") {
      setSavingAttendance(true);
      getUserByStudentId(singleStudentId);
    }
  }, [singleStudentId]);

  function getUserByStudentId(singleStudentId: string) {
    const studentUser = async () => {
      return await getUserByStudentIdNumber(singleStudentId);
    };
    studentUser()
      .then((result: User) => {
        setSavingAttendance(false);
        if (result) setStudentUser(result)
        else{
          showAlert('Student ID not found', "error");
          setTimeout(() => {
            handleCloseAlert()
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("getUserByStudentId error ", err);
        setStudentUser(null);
        showAlert(err.message, "error");
        setTimeout(() => {
          handleCloseAlert();
        }, 2000);
      });
  }

  useEffect(() => {
    if (studentUser) {
      console.log('*** studentUser ***', studentUser)
      setSavingAttendance(true);
      console.log('*** current location ***', currentLocation)
      const request: CreateStudentOnLocationDto = {
        location_id: Number(currentLocation.id),
        user_name: studentUser.user_name,
      };
      createLocationKioskHallPass(request);
    }
  }, [studentUser]);

  async function createLocationKioskHallPass(request: CreateStudentOnLocationDto) {
      const studentQueue = await validateQueueManager(studentUser?.user_name.toString() || "0")
      if(!studentQueue) {
      const registerLocationKiosk = async () => {
        return await createHallPassKioskLocation(request);
      };
      await createQueueManagerByUserName({studentId : studentUser?.user_name.toString() || "0", locationId : currentLocation?.id.toString() || "0"})
      registerLocationKiosk()
        .then((result: any) => {
          showAlert("Location's attendance successfully saved", "success");
          setTimeout(() => {
            handleCloseAlert();
          }, 2000);
          console.log('register location kiosk result', result);
          setSingleStudentId("");
          forceLastReloadAttendanceList();
          setSavingAttendance(false);
        })
        .catch((err) => {
          showAlert(err.message ? err.message: 'Error processing info' , "error");
          setTimeout(() => {
            handleCloseAlert();
          }, 2000);
          console.log(err);
          setSingleStudentId("");
          forceLastReloadAttendanceList();
          setSavingAttendance(false);
        });
    } else {
          showAlert('The pass is not currently available, please check back again soon' , "error");
          setTimeout(() => {
            handleCloseAlert();
          }, 2000);
          console.log();
          setSingleStudentId("");
          forceLastReloadAttendanceList();
          setSavingAttendance(false);
    } 
  }

  /*useEffect(() => {
    console.log("Logged user ", user.user_name);
    loadLocationsByStaffUser();
  }, [user.user_name]);*/

  /*function loadLocationsByStaffUser() {
    setLoadingLocations(true);
    if (user.user_name) {
      const fetchData = async () => {
        return await getLocationsByUserManager(user.user_name);
      };
      fetchData()
        .then((result: any) => {
          //console.log("🍉 getLocationsByUserManager ", result);
          if (Object.keys(result).length === 0) {
            setCurrenClass(null);
          } else {
            setCurrenClass(result);
          }
          setLoadingLocations(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingLocations(false);
          setCurrenClass(null);
        });
    }
  }*/

  function forceLastReloadAttendanceList() {
    setLastReloadAux(new Date());
  }

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  const onNewScanResult = (decodedText : any, decodedResult : any) => {
    setSingleStudentId(decodedText);
  };

  return (
    <>
      <CssBaseline />
      {loadingLocations ? (
        <LinearProgress />
      ) : (
        <LayoutContainer isRaised={true}>
          {currentLocation == null ||
          Object.keys(currentLocation).length === 0 ? (
            <h2>You do not have locations assigned</h2>
          ) : (
            <>
              <Box style={styles.listContainer} flexDirection="column">
                <Grid
                  sx={{ height: "50vh" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="camera-container-ui">
                    <div className="camera-container">
                      {savingAttendance ? (
                        <LinearProgress />
                      ) : (
                        <Html5QrcodePlugin
                            fps={10}
                            qrbox={250}
                            disableFlip={false}
                            qrCodeSuccessCallback={onNewScanResult}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
              </Box>

              <CustomAlert handleClose={handleCloseAlert} />

              <AttendanceListLocationComponent
                locationId={currentLocation?.id}
                lastReload={lastReloadAux}
              ></AttendanceListLocationComponent>
            </>
          )}
        </LayoutContainer>
      )}
    </>
  );
};

export default KioskStaffPage;
