import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CssBaseline, LinearProgress } from "@mui/material";
import { LayoutContainer } from "../Layout/layout.container";
import { setPageTitle } from "../store/slices/main";

import { AttendanceListComponent } from "../Components/AttendanceList/AttendanceListComponent";
import { PercentageComponent } from "../Components/percentage/Percentage";
import { ClassEfficiencyList } from "../Components";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { RootState } from "../store";
import { getUserByUsernameDashboard } from "../Services/UsersService";
import CreatePassComponent from "../Components/CreatePass/CreatePass";

const DashboardStudent: FC<any> = (): ReactElement => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("sm"));

  const { currentUser, selectedUser, currentSchool } = useSelector(
    (state: RootState) => state.main
  );

  const [loadingComponents, setLoadingComponets] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");

  const [userId, setUserId] = useState<number | null>(null);
  const [userSchool, setStudent] = useState({});

  useEffect(() => {
    let username;
    if (selectedUser.user_name) {
      username = selectedUser.user_name;
    } else if (currentUser.user_name) {
      username = currentUser.user_name;
    }

    getUserByUsernameDashboard(username)
      .then((user) => {
        if (user.id) setUserId(user.id); setStudent(user);
        setTitle(user.first_name + " " + user.last_name);
        setLoadingComponets(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!downMd) {
      dispatch(setPageTitle(title));
    } else {
      dispatch(setPageTitle("Dashboard"));
    }
  }, [title]);

  return (
    <>
      <CssBaseline />
      {/* <Header title="List of schools" /> */}
      <LayoutContainer
        isRaised={true}
        sx={{ paddingLeft: "5px", paddingRight: "5px" }}
      >
        {loadingComponents ? (
          <>
            {!downMd && (
              <>
                <PercentageComponent studentId={userId} userSchool={userSchool} />

                {selectedUser.id && <CreatePassComponent />}
              </>
            )}
            <ClassEfficiencyList studentId={userId} />
            <AttendanceListComponent studentId={userId} />
          </>
        ) : (
          <>
            <LinearProgress />
          </>
        )}
      </LayoutContainer>
    </>
  );
};

export default DashboardStudent;
