import { FC, ReactElement, useEffect, useState } from "react";
import { Box, LinearProgress, Alert, Grid, AlertColor } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import MobileDatePicker from "@mui/lab/MobileDatePicker";

import "./AttendanceListComponent.css";
import "./calendar.svg";
import { getAttendanceClassByUserId } from "../../Services/AttendanceService";

import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../store";

import copyIcon from "../../Assets/copyicon.svg";
import inline from "../../Assets/inline.svg";
import locationIcon from "../../Assets/locationIcon.svg";

import moment from "moment";
import { Class } from "../../Interfaces/ClassInterface";
import { DayType, Period } from "../../Interfaces/ScheduleInterface";
import { Attendance } from "../../Interfaces/AttendanceInterface";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Semester } from "../../Interfaces/SemesterInterface";

import { checkStudentHallPassLocation } from "../../Services/HallPassService";
import { StatusStudentOnLocationEnum } from "../../Interfaces/SchoolInterface";

import {
  AlertState,
  setCustomAlertDetails,
  setShowCustomAlert,
} from "../../store/slices/main";

import {
  fetchSemestersBySchoolId,
  getActiveSemestersBySchoolId,
} from "../../Services/SemestersService";

import { useWebSocket } from "../../Hooks/useWebSocket";

interface IAttendanceList {
  studentId: number | null;
}

export const AttendanceListComponent: FC<IAttendanceList> = ({
  studentId,
}): ReactElement => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const [openStartDate, setOpenStartDate] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(new Date());
  const [data, setData] = useState<ReportAttendanceByDate[]>([]);

  const [currentSemester, setCurrentSemester] = useState<
    Semester | undefined
  >();

  // const { user } = useSelector((state: RootState) => state.user);
  const [semesters, setSemesters] = useState<Semester[]>([]);
  const { currentSchool } = useSelector((state: RootState) => state.main);

  const [loading, setLoading] = useState<boolean>(true);

  const [hallPassRequestStatus, setHallPassRequestStatus] =
    useState<StatusStudentOnLocationEnum | null>(null);

  useWebSocket([
    {
      entity: "attendance",
      method: "UPDATE",
      onMessageReceived: () => {
        loadData(studentId, date);
      },
    },
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        loadData(studentId, date);
      },
    },
  ]);

  useEffect(() => {
    getHallPassStatus();
  }, []);

  function getHallPassStatus() {
    checkStudentHallPassLocation(Number(studentId)).then((s: StatusStudentOnLocationEnum | null) => {
      setHallPassRequestStatus(s);
      
    });
  }

  useEffect(() => {
    loadData(studentId, date);
    console.log("attendance page", data);
  }, [date, studentId, currentSemester]);

  function loadData(studentId: number | null, date: Date | null) {
    const selectedDateFormat = moment(date).format("YYYY-MM-DD");

    if (
      studentId &&
      moment(selectedDateFormat).isBetween(
        moment(currentSemester?.start_date).format("YYYY-MM-DD"),
        moment(moment(currentSemester?.end_date).format("YYYY-MM-DD")).add(
          1,
          "day"
        ),
        undefined,
        "[]"
      )
    ) {
      setLoading(true);
      const fetchData = async () => {
        return await getAttendanceClassByUserId(selectedDateFormat, studentId);
      };
      fetchData()
        .then((result: ReportAttendanceByDate[]) => {
          console.log("attendance page",result);
          setData(result);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setData([]);
      setLoading(false);
      // showAlert("out of semester dates", "error");
    }
  }

  useEffect(() => {
    if (semesters && semesters.length > 0) {
      setCurrentSemester(semesters[0]);
    } else {
      console.log("there is not a current semester selected");
      return;
    }
  }, [semesters]);

  function getSemestersrBySchoolId(schoolId: number) {
    const getSemesters = async () => {
      return await getActiveSemestersBySchoolId(schoolId);
    };
    getSemesters()
      .then((result: Semester[]) => {
        setSemesters(result);
      })
      .catch((err) => {
        console.log(err);
        // showAlert(err.message, "error");
        // setTimeout(() => {
        //   handleCloseAlert();
        // }, 2000);
      });
  }

  useEffect(() => {
    if (currentSchool.id) {
      getSemestersrBySchoolId(currentSchool.id);
    } else {
      console.log("there is not a school selected");
    }
  }, [currentSchool]);

  const DatePickerItem = (): ReactElement => (
    <MobileDatePicker
      open={openStartDate}
      onOpen={() => setOpenStartDate(true)}
      onClose={() => setOpenStartDate(false)}
      inputFormat="MMM dd, yyyy"
      onAccept={(date: any) => handleAcceptDate(date)}
      onChange={(date: any) => handleChangeDate(date)}
      value={date}
      renderInput={({ inputRef, inputProps, InputProps }) => (
        <div className="attendance-list-selector-date">
          <table>
            <tbody>
              <tr>
                <th className="curr-date">
                  <p>{moment(date).format("DD MMM, ddd")}</p>
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    {...inputProps}
                  />
                </th>
                <th className="cal">
                  <div
                    className="cal-button"
                    onClick={() =>
                      setOpenStartDate((openStartDate) => !openStartDate)
                    }
                  ></div>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    ></MobileDatePicker>
  );

  function handleChangeDate(date: any) {
    // setDate(date);
  }

  function handleAcceptDate(date: any) {
    setDate(date);
  }

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }



  return (
    <>
      <Box
        className={
          downMd
            ? "attendance-list-container"
            : "attendance-list-container-desk"
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <div className="attendance-list-title">Attendance</div>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {DatePickerItem()}
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <LinearProgress />
            ) : (
              <table className="attendance-list-studentclass">
                <tbody>
                  {data
                    .filter((x) => {
                      return x != null;
                    })
                    .map((x) => (
                      <tr
                        className={
                          !x.isAlreadyStarted
                            ? ""
                            : "attendance-list-studentclass-gray"
                        }
                        key={x.clase.id}
                      >
                        <td className="class-info">
                          {x.currentClass ? (
                            <div className="class-curr-button">
                              Current Class
                            </div>
                          ) : (
                            <></>
                          )}
                          <h4>{x.clase.name}</h4>
                          <h5>{x.clase.period.name}</h5>
                        </td>

                        {/* {x.currentPass && hallPassRequestStatus === "AtLocation" ? (
                          <td className="class-curr">
                            <div className="icon"></div>
                          </td>
                        ) : (
                          <td></td>
                        )} */}
                        {x.currentPass && hallPassRequestStatus === "InProgress" ? (
                          <td className="class-curr">
                            <div className="icon"></div>
                          </td>
                        ) : x.currentPass && hallPassRequestStatus === "AtLocation" ? (
                          <td className="class-curr">
                            <img
                          src={locationIcon}
                          alt="student at location"
                          style={{ marginRight: 6 }}
                        />
                          </td>

                        ) : (
                          <td></td>
                        )}
                        <td className="class-status">
                          {x.attendanceStatus == "Present" &&
                            !x.isAlreadyStarted ? (
                            <div className="button-present">Present</div>
                          ) : (
                            <></>
                          )}
                          {x.attendanceStatus == "Absent" &&
                            !x.isAlreadyStarted ? (
                            <div className="button-absent">Absent</div>
                          ) : (
                            <></>
                          )}
                          {x.attendanceStatus == "Tardy" &&
                          !x.isAlreadyStarted ? (
                            <div className="button-tardy">Tardy</div>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            {!loading &&
            data.filter((x) => {
              return x != null;
            }).length == 0 ? (
              <>No data for this date</>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export interface ReportAttendanceByDate {
  clase: Class & { day_type: DayType; period: Period };
  attendance: Attendance | null;
  attendanceStatus: string;
  currentClass: boolean;
  currentPass: boolean;
  isAlreadyStarted: boolean;
}
