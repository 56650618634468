import { useState } from "react";

export interface FormField {
  name: string;
  value: Date | string | null;
}

function useForm<Type>(
  initialForm: Type,
  validateformField: (fieldName: string, fieldValue: any) => FormField,
  submitForm: any,
): any {
  const [form, setForm] = useState<Type>(initialForm);
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState(null);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>, validate: boolean = true) {
    const { name, value } = e.target;
    if(validate){
      const formField = validateformField(name, value);
      setErrors({
        ...errors,
        [formField.name]: formField.value,
      });
    }
    setForm({
      ...form,
      [name]: value,
    });
  }

  function handleChangeByAttrName(
    fieldName: string,
    newValue: Date | string | null
  ) {
    const formField = validateformField(fieldName, newValue);
    setErrors({
      ...errors,
      [formField.name]: formField.value,
    });
    setForm({
      ...form,
      [fieldName]: newValue,
    });
  }

  function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    const formField = validateformField(name, value);
    setErrors({
      ...errors,
      [formField.name]: formField.value,
    });
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setResponse(submitForm(form));
  }

  function handleCompleted(completed: boolean) {
    completed && setForm(initialForm);
  }

  return {
    form,
    setForm,
    errors,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
    handleCompleted,
    handleChangeByAttrName,
  };
}

export default useForm;
