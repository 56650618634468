import { createAsyncThunk } from "@reduxjs/toolkit";
import { ClassEfficiency } from "../Interfaces/ClassInterface";
import { Semester, SemesterStatistics } from "../Interfaces/SemesterInterface";
import ApiService from "./apiService";
import moment from "moment";

// const baseURL =
//   process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local";

const baseURL = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE === "local"
    ? true
    : false
  : true;

const semesterURL = `${baseURL ? "semesters" : ""}/semesters/`;

export const fetchSemestersBySchoolId = createAsyncThunk(
  "semester/fetchSemesters",
  async (school_id: number) => {
    const result: any = await ApiService.get<Semester[]>(
      `${semesterURL}school/${school_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const fetchActiveSemestersBySchoolId = createAsyncThunk(
  "semester/fetchActiveSemesters",
  async (school_id: number) => {
    const result: any = await ApiService.get<Semester[]>(
      `${semesterURL}school/${school_id}/active`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const fetchAllSemestersBySchoolId = createAsyncThunk(
  "semester/fetchAllSemesters",
  async (school_id: number) => {
    const result: any = await ApiService.get<Semester[]>(
      `${semesterURL}school/${school_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export async function getActiveSemestersBySchoolId(school_id: number) {
  const result: any = await ApiService.get<Semester[]>(
    `${semesterURL}school/${school_id}/active`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function findByDate(school_id: number, date: Date) {
  const selectedDate = moment(date).format("YYYY-MM-DD");
  const result: any = await ApiService.get<Semester>(
    `${semesterURL}school/${school_id}/${selectedDate}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

//@Get('school/:school_id/:select_date')
export const createSemester = createAsyncThunk(
  "semester/createSemester",
  async (semester: Semester) => {
    const result: any = await ApiService.post<Semester>(semesterURL, semester);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const updateSemester = createAsyncThunk(
  "semester/updateSemester",
  async (semester: Semester) => {
    const result: any = await ApiService.put<Semester>(
      `${semesterURL}${semester.id}`,
      semester
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const deleteSemester = createAsyncThunk(
  "semester/deleteSemester",
  async (semester_id: number) => {
    const result: any = await ApiService.delete<Semester>(
      `${semesterURL}${semester_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export async function getAverageNumberOfPasses(
  schoolId: number
): Promise<number> {
  const result: any = await ApiService.get<number>(
    `${semesterURL}statistics/averageNumberOfPasses/school/${schoolId}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getWaitDailyAverage(
  schoolId: number
): Promise<any | null> {
  //statistics/waitdailyaverage/:schoolId
  const result = await ApiService.get<number>(
    `${semesterURL}waitdailyaverage/${schoolId}`
  );
  return result.data;
}

// export async function getWaitAverage(schoolId: number): Promise<any | null> {
//   const result = await ApiService.get<number>(
//     `${semesterURL}waitaverage/${schoolId}`
//   );
//   return;
// }

export async function getWaitAverage(schoolId: number): Promise<any> {
  const result: any = await ApiService.get<any>(
    `${semesterURL}waitaverage/${schoolId}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getClassRoomEfficiencyAverage(
  schoolId: number
): Promise<number> {
  const result: any = await ApiService.get<number>(
    `${semesterURL}statistics/classroom_efficiency_averageV2/school/${schoolId}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getClassRoomEfficiencyAverageByStudent(
  schoolId: number,
  studentId: number
): Promise<ClassEfficiency[]> {
  const result: any = await ApiService.get<number>(
    `${semesterURL}statistics/classroom_efficiency_average/school/${schoolId}/student/${studentId}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getClassRoomEfficiencyAverageByStudentBySemester(
  schoolId: number,
  studentId: number,
  semesterId: number
): Promise<ClassEfficiency[]> {
  const result: any = await ApiService.get<number>(
    `${semesterURL}statistics/classroom_efficiency_average/school/${schoolId}/student/${studentId}/semester/${semesterId}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getAbsentStudentsStatisticBySchool(
  schoolId: number
): Promise<number> {
  const result: any = await ApiService.get<number>(
    `${semesterURL}statistics/absent_statistic_school/school/${schoolId}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getTopPassLocationsStatistic(
  schoolId: number
): Promise<any> {
  const result: any = await ApiService.get<any>(
    `${semesterURL}statistics/topPassLocations/school/${schoolId}`
  );
  if (result?.success) {
    return result.data ? result.data : [];
  }
  throw new Error(result?.message);
}

export async function getAveragePassDurationInMinutes(
  schoolId: number
): Promise<number> {
  const result = await ApiService.get<number>(
    `${semesterURL}statistics/averagePassDuration/${schoolId}`
  );
  return result.data;
}
