import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material';

const darkTheme = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'Gotham',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#7AC142'
        },
        background: {
            default: 'rgba(42, 45, 56, 1)',
            paper: 'rgb(61 64 78)'
        },
        secondary: {
            main: '#FF4E32'
        },
        text: {
            primary: '#FFFFFF'
        }
    },
    components: {
        MuiPaper: {
            styleOverrides:
            {
                root: {
                    backgroundImage: 'none',
                }
            }
        },
    }
});


export function withDarkTheme<T>(WrappedComponent: React.ComponentType<T>) {

    const hocComponent = (hocProps: T) =>
    (
        <ThemeProvider theme={darkTheme}>
            <WrappedComponent {...hocProps as T} />
        </ThemeProvider>
    )

    return hocComponent;
}