import { createSlice } from "@reduxjs/toolkit";
import { Room } from "../../Interfaces/SchoolInterface";
import {
  createRoom,
  deleteRoom,
  fetchRoomsBySchoolId,
  massCreateRoom,
  updateRoom,
} from "../../Services/RoomService";

export const roomSlice = createSlice({
  name: "room",
  initialState: {
    rooms: [] as Room[],
    loadingIndicator: false,
    savingIndicator: false,
    errorMessage: "",
    successMessage: "",
  },
  reducers: {
    setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
  },
  extraReducers: (builder) => {
    //fetch
    builder.addCase(fetchRoomsBySchoolId.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(fetchRoomsBySchoolId.fulfilled, (state, { payload }) => {
        state.rooms = payload;
        state.loadingIndicator = false;
      }),
      builder.addCase(fetchRoomsBySchoolId.rejected, (state) => {
        state.loadingIndicator = false;
      });

    // create
    builder.addCase(createRoom.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(createRoom.fulfilled, (state, { payload }) => {
        state.rooms.push(payload);
        state.successMessage = "Room created successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(createRoom.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // mass create
    builder.addCase(massCreateRoom.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(massCreateRoom.fulfilled, (state, { payload }) => {
        let counter = 0;
        Array.from<Room>(payload).forEach((room) => {
          const roomIndex = state.rooms.findIndex((s) => s.id === room.id);
          if (roomIndex === -1) {
            state.rooms.push(room);
            counter++;
          }
        });
        state.successMessage = `${counter} of ${
          Array.from(payload).length
        } Rooms created massively`;
        state.savingIndicator = false;
      }),
      builder.addCase(massCreateRoom.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // update
    builder.addCase(updateRoom.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(updateRoom.fulfilled, (state, { payload }) => {
        const index = state.rooms.findIndex((s) => s.id === payload.id);
        if (index > -1) {
          state.rooms[index] = payload;
        }
        state.successMessage = "Room updated successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(updateRoom.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // delete
    builder.addCase(deleteRoom.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(deleteRoom.fulfilled, (state, { payload }) => {
        const index = state.rooms.findIndex((s) => s.id === payload.id);
        if (index > -1) {
          state.rooms.splice(index, 1);
        }
        state.successMessage = "Room removed successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(deleteRoom.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });
  },
});

export default roomSlice.reducer;
export const { setSuccessMessage, setErrorMessage } = roomSlice.actions;
