import React, { FC, ReactElement, useState } from "react";
import { Box, Button, Menu, MenuItem, Stack } from "@mui/material";
import { withLightTheme } from "../../Hocs/withLightTheme";
import { CSSProperties } from "@mui/styles";

export interface PopupMenuOption {
  value: string;
  text: string;
  icon?: JSX.Element;
}

const styles = {
  newButton: {
    textTransform: "none",
    color: "white",
    height: 48,
    width: 120,
    marginRight: 15,
  } as CSSProperties,
};

interface PopMenuProps<T> {
  menuOptions: PopupMenuOption[];
  onClick: (
    event: React.MouseEvent<HTMLElement>,
    selectedOption: string | null
  ) => void;
}

const NewRoomPopupButtonMenuComponent: FC<PopMenuProps<any>> = (
  props: PopMenuProps<any>
): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOption = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);

    if (props.onClick) props.onClick(event, event.currentTarget.id);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        style={styles.newButton}
        onClick={handleClick}
      >
        + Add new
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.32))",
            borderRadius: "3px",
            mt: 1,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              borderRadius: "3px",
              top: 0,
              right: 13,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {props.menuOptions?.map((option, key) => (
          <MenuItem
            key={`${option.value}-${key}`}
            style={{
              height: "unset",
              fontWeight: "500",
            }}
            id={option.value}
            onClick={handleOption}
          >
            <Stack direction="row" spacing={1}>
              <Box>{option.icon}</Box>
              <Box>{option.text}</Box>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const NewRoomPopupButtonMenu = withLightTheme(
  NewRoomPopupButtonMenuComponent
);
