import { FC, ReactElement } from "react";
import { CssBaseline } from "@mui/material";
import { LayoutContainer } from "../Layout/layout.container";
import AdminSettingsComponent from "../Components/AdminSettings/AdminSettingsComponent";

const AdminSettingsPage: FC<any> = (): ReactElement => {
  return (
    <>
      <CssBaseline />
      {/* <Header title="List of schools" /> */}
      <LayoutContainer isRaised={true}>
        <AdminSettingsComponent></AdminSettingsComponent>
      </LayoutContainer>
    </>
  );
};

export default AdminSettingsPage;
