import { CSSProperties, FC, ReactElement } from "react";
import { ChangePasswordForm } from "../../Interfaces/UserInterface";
import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  TextField,
  Grid,
  Button,
  AlertColor,
  CardActions,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { LightModal } from "../LightModal/LightModal";
import { useCognitoAwsV2 } from "../../Hooks/useCognitoAwsv2";

import { useDispatch } from "react-redux";

import {
  AlertState,
  setCustomAlertDetails,
  setShowCustomAlert,
} from "../../store/slices/main";

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
    lineHeight: 1,
  } as CSSProperties,
};

interface ListProps {
  openModal: boolean;
  handleOpenModal: () => void;
}

const changePasswordForm: ChangePasswordForm = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePasswordModal: FC<ListProps> = ({
  openModal,
  handleOpenModal,
}): ReactElement => {
  const dispatch = useDispatch();
  const { currentAuthenticatedUser, changePassword } = useCognitoAwsV2();

  const formik = useFormik({
    initialValues: changePasswordForm,
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .required("The field current password is required")
        .max(30, "The current password field must not exceed 30 characters"),
      newPassword: Yup.string()
        .required("The field new password is required")
        .min(8, "The new password field must not be less than 8 characters")
        .max(30, "The new password field must not exceed 30 characters"),
      confirmPassword: Yup.string()
        .required("The field confirm password is required")
        .min(8, "The confirm password field must not be less than 8 characters")
        .max(30, "The confirm password field must not exceed 30 characters")
        .oneOf([Yup.ref("newPassword"), null], "Passwords don't match"),
    }),
    onSubmit: submitForm,
  });

  async function submitForm(form: ChangePasswordForm) {
    try {
      if (await currentAuthenticatedUser()) {
        await changePassword(form.currentPassword, form.newPassword);
        showAlert("Password was changed", "success");
      } else {
        console.log("User not authenticated");
      }
    } catch (e) {
      console.error("pw_Error:", e);
      showAlert("Current password was incorrect. Please try again.", "error");
    }
    handleOpenModal();
    formik.resetForm();
  }

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  return (
    <>
      <LightModal isOpenModal={openModal} handleOpenModal={handleOpenModal}>
        <Card>
          <CardHeader title="Create new password" sx={{ mx: 1 }} />
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <CardContent sx={{ mx: 1 }}>
              <Stack spacing={2}>
                <TextField
                  style={{ marginBottom: "5px" }}
                  type="password"
                  name="currentPassword"
                  fullWidth
                  label="Current password"
                  variant="standard"
                  value={formik.values.currentPassword}
                  onChange={formik.handleChange}
                  autoComplete="false"
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched.currentPassword &&
                      formik.errors.currentPassword
                  )}
                  helperText={
                    formik.touched.currentPassword &&
                    formik.errors.currentPassword
                      ? formik.errors.currentPassword
                      : null
                  }
                />
              </Stack>
              <Stack spacing={2}>
                <TextField
                  style={{ marginBottom: "5px" }}
                  type="password"
                  name="newPassword"
                  fullWidth
                  label="New password"
                  variant="standard"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  autoComplete="false"
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched.newPassword && formik.errors.newPassword
                  )}
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                      ? formik.errors.newPassword
                      : null
                  }
                />
              </Stack>
              <Stack spacing={2}>
                <TextField
                  style={{ marginBottom: "5px" }}
                  type="password"
                  name="confirmPassword"
                  fullWidth
                  label="Confirm password"
                  variant="standard"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  autoComplete="false"
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                  )}
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? formik.errors.confirmPassword
                      : null
                  }
                />
              </Stack>
            </CardContent>
            <CardActions style={{ padding: 0, margin: 0 }}>
              <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      handleOpenModal();
                    }}
                    style={styles.formButton}
                    size="large"
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={styles.formButton}
                    fullWidth
                    size="large"
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    Create new password
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </form>
        </Card>
      </LightModal>
    </>
  );
};

export default ChangePasswordModal;
