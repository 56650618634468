import { NotificationsOutlined } from "@mui/icons-material";
import { Grid, IconButton, LinearProgress } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { FC, ReactElement, useEffect, useState } from "react";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../../Assets/logo.svg";
import MenuCustomIcon from "../../../Icons/MenuCustomIcon";
import { setBackwardNavigationRoute } from "../../../store/slices/main";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ReactHtmlParser from "react-html-parser";
import "./user.toolbar.css";
import { PercentageComponent } from "../../../Components/percentage/Percentage";
import { useLocation } from "react-router-dom";
import { getUserByUsernameDashboard } from "../../../Services/UsersService";
import { StaffLocationsDropdown } from "../../../Components";

interface UserToolbarProps {
  handleDrawerToggle: () => void;
}

export const UserToolbar: FC<UserToolbarProps> = ({
  handleDrawerToggle,
}): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //store
  const {
    pageTitle,
    pageSubtitle,
    pageHeaderChildren,
    backwardNavigationRoute,
    raiseHeader,
    isStaffLocationPage,
  } = useSelector((state: RootState) => state.main);

  // const [curLocation, setCurLocation] = useState("");
  const location = useLocation();

  const [isDashboardProfile, setIsDashboardProfile] = useState<boolean>(false);

  /** */

  const [loadingComponents, setLoadingComponets] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | null>(null);

  const { currentUser, selectedUser, currentSchool } = useSelector(
    (state: RootState) => state.main
  );

  useEffect(() => {
    if (location.pathname.endsWith("Dashboard")) {
      setIsDashboardProfile(true);
      let username;
      if (selectedUser.user_name) {
        username = selectedUser.user_name;
      } else if (currentUser.user_name) {
        username = currentUser.user_name;
      }

      getUserByUsernameDashboard(username)
        .then((user) => {
          if (user.id && user.group === "Student") {
            setUserId(user.id);
            setLoadingComponets(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingComponets(false);
        });
    } else {
      setIsDashboardProfile(false);
      setLoadingComponets(false);
    }
  }, [selectedUser, currentUser, location]);

  /** */

  // useEffect(() => {}, []);

  let headerToolbarHeight = 85;
  headerToolbarHeight += pageSubtitle ? 20 : 0;
  headerToolbarHeight += pageTitle ? 30 : 0;
  headerToolbarHeight += isDashboardProfile ? 30 : 0;

  const styles = {
    headerToolbar: {
      height: `${headerToolbarHeight}px`,
      marginBottom: pageHeaderChildren ? "60px" : "0px",
      zIndex: raiseHeader ? 999 : 0,
      position: "relative",
    } as CSSProperties,
    curve: {
       bottom: pageTitle ? "-140px" : "-100px",
       marginTop: pageHeaderChildren ? "-138px" : "-150px",
      pointerEvents: "none",
    } as CSSProperties,
    titleContainer: {
      textAlign: backwardNavigationRoute ? "start" : "center",
      height: "100%",
      marginBottom: "1rem",
    } as CSSProperties,
  };

  return (
    <>
      <div className="header-user-toolbar" style={styles.headerToolbar}>
        <div className="ellipse1"></div>
        <div className="ellipse2"></div>
        <div className="header-user-content">
          <table className="header-table">
            <tbody>
              <tr>
                <td className="header-icon-menu-td">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ display: { sm: "none" } }}
                  >
                    <MenuCustomIcon />
                  </IconButton>
                </td>
                <td className="header-icon-td">
                  <img className="logo" src={logo} alt="logo" />
                </td>
                <td className="header-icon-notification-td">
                  <IconButton size="small" className="notification-icon">
                    <NotificationsOutlined />
                  </IconButton>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            className="header-title"
            style={{ marginTop: pageHeaderChildren ? "-25px" : "-20px" }}
          >
            {pageHeaderChildren ? (
              <div onClick={() => navigate("/TeacherSchedule")}>
                {ReactHtmlParser(pageHeaderChildren.toString())}
              </div>
            ) : (
              <Grid container sx={styles.titleContainer}>
                {backwardNavigationRoute && (
                  <Grid item xs={1} display="flex" alignItems="center">
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        const navigateRoute = backwardNavigationRoute;
                        dispatch(setBackwardNavigationRoute(""));
                        navigate(navigateRoute);
                      }}
                      sx={{
                        width: "2.25rem",
                        height: "2.25rem",
                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                        marginRight: "1rem",
                        marginTop: "1rem",
                        marginLeft: "1rem",
                      }}
                    >
                      <ArrowBackIosNewIcon sx={{ width: "1rem" }} />
                    </IconButton>
                  </Grid>
                )}
                <Grid item xs={backwardNavigationRoute ? 10 : 12}>
                  {pageTitle && <p className="title">{pageTitle}</p>}
                  {pageSubtitle && (
                    <p
                      className="subtitle"
                      style={{
                        marginLeft: backwardNavigationRoute ? "20px" : 0,
                      }}
                    >
                      {ReactHtmlParser(pageSubtitle)}
                    </p>
                  )}
                  {isStaffLocationPage && <StaffLocationsDropdown />}
                </Grid>
              </Grid>
            )}
          </div>
        </div>
        {loadingComponents ? (
          isDashboardProfile ? (
            <PercentageComponent studentId={userId}></PercentageComponent>
          ) : (
            <LinearProgress />
          )
        ) : (
          <></>
        )}

        <span className="curve" style={styles.curve}></span>
      </div>
    </>
  );
};
