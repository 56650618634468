import { useContext, useEffect } from "react";
import { SocketContext } from "../Providers/SocketProvider";

interface Listener {
  entity: string;
  method: string;
  onMessageReceived: () => void;
}

export const useWebSocket = (listeners: Listener[]) => {
  const { payload, socket } = useContext(SocketContext);

  useEffect(() => {
    if (!payload || payload === "") return;
    const data = JSON.parse(payload);

    const validListeners = listeners.filter(
      (l) => l.entity === data.entity && l.method === data.method
    );

    validListeners.forEach((l) => l.onMessageReceived());
  }, [payload]);

  return { payload, socket };
};
