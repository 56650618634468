import { Class } from "./ClassInterface";
import { User } from "./UserInterface";

export interface SchoolFormError {
  name?: string;
  district?: string;
}

export interface School {
  id?: number;
  name: string;
  district_id: number;
  school_id?: number;
}

export interface District {
  id?: number;
  name: string;
}

export enum ToggleSchoolOptionEnum {
  Schools = "schools",
  Districts = "districts",
}

export interface Room {
  id?: number;
  name: string;
  school_id: number;
}

export interface MassCreateRoom {
  firstRoomName: number;
  lastRoomName: number;
  school_id: number;
}

export interface HallPassLocation {
  id?: number;
  name: string;
  school_id: number;
  max_students_allowed?: number;
  stay_max_time?: number;
  scan_at_arrival: boolean;
  managers?: User[];
  StudentsOnLocations?: StudentOnLocation[];
}

export interface CreateStudentOnLocationDto {
  location_id: number;
  class_id?: number;
  user_name: string;
}

export interface StudentOnLocation {
  id?: number;
  student?: User;
  student_id?: number;
  location?: HallPassLocation;
  location_id?: number;
  status?: StatusStudentOnLocationEnum;
  requested_at?: Date;
  current_class_id?: number;
  current_class?: Class;
  started_at?: Date;
  ended_at?: Date;
}

export enum StatusStudentOnLocationEnum {
  Pending = "Pending",
  Authorized = "Authorized",
  InProgress = "InProgress",
  Completed = "Completed",
  Cancelled = "Cancelled",
  AtLocation = "AtLocation",
}

export interface SchoolSettings {
  late_buffer?: number;
  time_zone?: number;
  pass_timeout?: number;
}
