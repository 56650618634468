import { FC, ReactElement } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
} from "@mui/material";
import React from "react";
import { LightModal } from "../LightModal/LightModal";
import { CSSProperties } from "@mui/styles";

interface ConfirmationDialogProps {
  open: boolean;
  onCancel?: () => void;
  onConfirm: (event: React.MouseEvent<HTMLElement>) => void;
  title: string;
  message: string;
  savingIndicator: boolean;
  okText?: string;
  cancelText?: string;
}

const ConfirmationDialogDefaultProps = {
  open: false,
  onCancel: undefined,
  onConfirm: () => {},
  title: "",
  message: "",
  savingIndicator: false,
  okText: "Accept",
  cancelText: "Cancel",
};

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

export const LightConfirmationDialog: FC<ConfirmationDialogProps> = (
  props
): ReactElement => {
  return (
    <LightModal
      width="350px"
      isOpenModal={props.open}
      handleOpenModal={() => {}}
    >
      <Card>
        <CardHeader title={props.title} sx={{ mx: 1 }} />
        <CardContent sx={{ mx: 1 }}>{props.message}</CardContent>
        <CardActions style={{ padding: 0, margin: 0 }}>
          <Grid
            container
            spacing="0"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "1rem" }}
          >
            <Grid item xs={6}>
              <Button
                onClick={props.onCancel}
                disabled={props.savingIndicator}
                style={styles.formButton}
                size="large"
                color="secondary"
                variant="contained"
                fullWidth
              >
               {props.cancelText}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={styles.formButton}
                fullWidth
                size="large"
                disabled={props.savingIndicator}
                onClick={props.onConfirm}
              >
                {props.okText}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
        {props.savingIndicator && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Card>
    </LightModal>
  );
};

LightConfirmationDialog.defaultProps = ConfirmationDialogDefaultProps;
