import { FC, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDistricts } from "../Services/SchoolsService";
import { CssBaseline } from "@mui/material";
import { LayoutContainer } from "../Layout/layout.container";
import { setPageTitle } from "../store/slices/main";
import { RootState } from "../store";
import { GroupEnum } from "../Interfaces/UserInterface";
import DashboardSchoolAdmin from "./DashboardSchoolAdmin";
import DashboardStudent from "./DashboardStudent";
import AttendanceListPage from "./AttendanceListPage";
import DashboardStaff from "./DashboardStaff";

const Dashboard: FC<any> = (): ReactElement => {
  const dispatch = useDispatch();
  const { currentUser, selectedUser, currentSchool } = useSelector(
    (state: RootState) => state.main
  );

  useEffect(() => {
    let pageTitle = currentUser.group != GroupEnum.Teacher && "Dashboard";
    switch (currentUser.group) {
      case GroupEnum.SchoolAdmin:
        pageTitle = currentSchool.name;
        break;
      case GroupEnum.Staff:
        pageTitle = currentSchool.name;
        break;
    }

    dispatch(setPageTitle(pageTitle));
  }, [currentSchool]);

  const checkGroupAndRender = () => {

    switch (currentUser.group) {
      case GroupEnum.SchoolAdmin:
        return <DashboardSchoolAdmin />;
      case GroupEnum.Student:
        return <DashboardStudent />;
      case GroupEnum.Teacher:
        return <DashboardStudent />;
      case GroupEnum.Staff:
        return <DashboardStaff />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <CssBaseline />
      {/* <Header title="List of schools" /> */}
      <LayoutContainer isRaised={true}>{checkGroupAndRender()}</LayoutContainer>
    </>
  );
};

export default Dashboard;
