import React, { useMemo, useState } from "react";
import { FC, ReactElement } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { removeClassSchedule } from "../../Services/ClassService";
import { Class, ScheduleClasses } from "../../Interfaces/ClassInterface";
import { PopButtonMenu, ConfirmationDialog } from "../../Components";
import { setCurrentScheduleClass } from "../../store/slices/class.slice";
import { setBackwardNavigationRoute } from "../../store/slices/main";
import { useNavigate } from "react-router-dom";

interface ListProps {
  defaultClass: Class;
  styles: any;
  loadingClasses: boolean;
  classes: Class[];
  multipleClasses: ScheduleClasses[];
  selectedClass?: Class;
  isMobile: boolean;
  setSelectedClass: (class_: Class) => void;
  handleOpenModal: (state: boolean, origin: string) => void;
  reload: () => void;
}

const EDIT_CLASS_OPT = "Edit Class";
const VIEW_ATTENDANCE_OPT = "View Attendance";
const EDIT_ROSTER_OPT = "Edit Roster";
const DELETE_OPT = "Delete";

const buttonMenuOptions: string[] = [
  EDIT_CLASS_OPT,
  DELETE_OPT,
  // VIEW_ATTENDANCE_OPT,
  EDIT_ROSTER_OPT,
];

const confirmationDialogData: any = [
  {
    title: "Edit Class",
    message: "Are you sure you want to edit this class?",
  },
  {
    title: "Delete Class",
    message: "Are you sure you want to delete this class?",
  },
];

const BulletSeparator = ({
  isMobile,
  style,
}: {
  isMobile: boolean;
  style: any;
}) => (
  <Grid sx={style}>
    <span style={{ display: isMobile ? "inline" : "none" }}>&bull;</span>
  </Grid>
);

const ClassListSchedule: FC<ListProps> = ({
  defaultClass,
  styles,
  loadingClasses,
  classes,
  multipleClasses,
  selectedClass,
  isMobile,
  handleOpenModal,
  reload
}): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [confirmationDialog, setConfirmationDialog] = useState<any>(
    confirmationDialogData[0]
  );

  const [classToRemove, setClassToRemove] = useState<number>(0);
  
  const buildContainerStyle = () => {
    return loadingClasses
      ? styles.listContainer
      : {
          ...styles.listContainer,
          backgroundColor: "transparent",
          boxShadow: "none",
        };
  };

  function showUpdateClasses(selectedScheduleClass: ScheduleClasses): void {
    if (selectedScheduleClass?.classesByScheduleId.length > 0) {
      dispatch(setCurrentScheduleClass(selectedScheduleClass));
      handleOpenModal(true, "update");
    }
  }

  const popUpHandlerClick = (
    event: React.MouseEvent<HTMLElement>,
    selectedOption: string | null,
    selectedClass?: ScheduleClasses
  ) => {
    event.preventDefault();

    if (!selectedOption) return;

    setConfirmationDialogOpen(false);
    switch (selectedOption) {
      case EDIT_CLASS_OPT:
        if (selectedClass) {
          showUpdateClasses(selectedClass);
          if (selectedClass.schedule_id) {
            dispatch(setCurrentScheduleClass(selectedClass));
          }
        }
        handleOpenModal(true, "update");
        break;
      case VIEW_ATTENDANCE_OPT:
        navigate("/AttendanceList", {
          state: { navigationClass: selectedClass },
        });
        break;
      case EDIT_ROSTER_OPT:
        if (selectedClass) {
          if (selectedClass.schedule_id) {
            dispatch(setCurrentScheduleClass(selectedClass));
            dispatch(setBackwardNavigationRoute("/TeacherSchedule"));
            navigate("/ClassScheduleRoster");
          }
        }

        return;
      case DELETE_OPT:
        setClassToRemove(Number(selectedClass?.classesByScheduleId[0]?.id));
        setConfirmationDialog(confirmationDialogData[1]);
        setConfirmationDialogOpen(true);
        break;
    }
  };

  const confirmationDialogHandler = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    try {
      event.preventDefault();
      if (classToRemove) {
        setDeleting(true);
        await removeClassSchedule(classToRemove);
        reload();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
      setConfirmationDialogOpen(false);
    }
  };

  const ClassListItems = useMemo(
    () =>
      multipleClasses.map((item: ScheduleClasses, index) => (
        <React.Fragment key={index}>
          <ListItem
            style={styles.listItem}
            secondaryAction={
              <PopButtonMenu
                menuOptions={buttonMenuOptions}
                onClick={popUpHandlerClick}
                data={item}
              />
              // <Button
              //   type="submit"
              //   color="primary"
              //   variant="contained"
              //   onClick={() => {
              //     showUpdateClasses(item);
              //   }}
              //   style={
              //     (styles.formButton,
              //     {
              //       borderRadius: "5px",
              //       textTransform: "unset",
              //       fontWeight: 500,
              //       color: "white",
              //     })
              //   }
              //   fullWidth
              //   size="small"
              // >
              //   View Schedule
              // </Button>
            }
          >
            <ListItemText>{item.classesByScheduleId[0].name}</ListItemText>
            <ConfirmationDialog
              open={confirmationDialogOpen}
              title={confirmationDialog.title}
              message={confirmationDialog.message}
              onConfirm={confirmationDialogHandler}
              onCancel={() => {
                setConfirmationDialogOpen(false);
              }}
              loading={deleting}
            />
          </ListItem>
        </React.Fragment>
      )),

    [classes, selectedClass, popUpHandlerClick, confirmationDialogHandler]
  );

  return (
    <>
      <Box style={buildContainerStyle()}>
        {loadingClasses ? (
          <Box style={styles.loading}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <List style={styles.list}>{ClassListItems}</List>
            <div style={{ height: "80px" }}></div>
          </>
        )}
      </Box>
    </>
  );
};

export default ClassListSchedule;
