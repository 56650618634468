import { Class } from "./ClassInterface";
import { User } from "./UserInterface";

export interface AttendanceFormError {
  name?: string;
}
export enum StatusAttendanceEnum {
  Late,
  OnTime,
}

export type Attendance = {
  id?: number;
  user_id?: number;
  class_id: number;
  attendance_date?: Date;
  timezoneOffset?: number;
  day_type_id?: number;
  statusAttendance?: StatusAttendanceEnum;
  user?: User;
  class?: Class;
};

export type Absent = {
  student_id_number: string;
  user_name(user_name: any): void;
  user_id?: number;
  first_name: string;
  last_name: string;
  grade?: string;
  absents_count: number;
};

export interface AttendanceMenuOption {
  label?: string;
  value?: string;
  color?: string;
}

export const AttendanceStatus: AttendanceMenuOption[] = [
  {
    label: "Present",
    value: "present",
    color: "primary",
  },
  {
    label: "Absent",
    value: "absent",
    color: "#DB3522",
  },
  {
    label: "Tardy",
    value: "tardy",
    color: "#FFFB00",
  },
];
