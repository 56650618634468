import { useEffect, useState } from "react";
import { AddFloatButton, SemesterList, SemesterModal } from "../Components";
import { Semester } from "../Interfaces/SemesterInterface";
import { LayoutContainer } from "../Layout/layout.container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { fetchSemestersBySchoolId } from "../Services/SemestersService";
import CustomAlert from "../Components/CustomAlert/CustomAlert";
import {
  AlertState,
  setCustomAlertDetails,
  setPageSubtitle,
  setPageTitle,
  setShowCustomAlert,
} from "../store/slices/main";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../store/slices/semester.slice";
import { AlertColor } from "@mui/material";

const defaultSemester: Semester = {
  name: "",
  start_date: new Date(),
  end_date: new Date(),
};

const Semesters = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const [selectedSemester, setSelectedSemester] = useState<Semester>();
  const [openModal, setOpenModal] = useState(false);
  const { semesters, loadingIndicator, errorMessage, successMessage } =
    useSelector((state: RootState) => state.semester);

  useEffect(() => {
    dispatch(setPageTitle("School settings"));
  }, []);

  useEffect(() => {
    if (currentSchool.id) {
      dispatch(fetchSemestersBySchoolId(currentSchool.id));
    }
  }, [currentSchool]);

  useEffect(() => {
    if (errorMessage) {
      showAlert(errorMessage, "error");
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      showAlert(successMessage, "success");
    }
  }, [successMessage]);

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleOpenModal(value: boolean) {
    setOpenModal(value);
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
    setSelectedSemester(defaultSemester);
  }

  return (
    <>
      <LayoutContainer isRaised={true}>
        <SemesterList
          loadingSemesters={loadingIndicator}
          semesters={semesters}
          isMobile={isMobile}
          selectedSemester={selectedSemester}
          setSelectedSemester={setSelectedSemester}
          handleOpenModal={handleOpenModal}
        />
        {isMobile && <AddFloatButton handleOpenModal={handleOpenModal} />}
        <SemesterModal
          school_id={parseInt(`${currentSchool.id}`)}
          handleOpenModal={handleOpenModal}
          openModal={openModal}
          semesters={semesters}
          setSelectedSemester={setSelectedSemester}
          selectedSemester={selectedSemester}
        />
      </LayoutContainer>
      <CustomAlert handleClose={handleCloseAlert} />
    </>
  );
};

export default Semesters;
