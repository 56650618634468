import { School } from "./SchoolInterface";

export interface User {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  user_name: string;
  group: GroupEnum;
  profile_picture?: string;
  profile_picture_encoded?: string;
  nickname?: string;
  student_id_number?: string;
  grade?: string;
  schools: School[];
  created_at?: Date;
  created_by?: number;
  last_updated_at?: Date;
  last_updated_by?: number;
  current_class_id?: number;
  // For user student creation
  password?: string;
  sentToNextClass?: boolean;
  sentToHallPass?: boolean;
  meetup_prevention?: string;
}

export interface UserFormError {
  first_name?: string;
  last_name?: string;
  email?: string;
  school_ids?: string;
  profile_picture?: string;
}

export enum GroupEnum {
  None = "",
  SysAdmin = "SysAdmin",
  SchoolAdmin = "SchoolAdmin",
  Teacher = "Teacher",
  Student = "Student",
  Staff="Staff",
}

export interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface ChangePasswordFormError {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface Notification {
  id?: number;
  user: User;
  user_id: number;
  title: string;
  message: string;
  read: boolean;
  metadata: string;
  created_at?: Date;
  readed_at?: Date;
}

export interface CreateTurnForm {
  studentId: string;
  locationId: string;
}


export interface QueueManagement {
  id: number
  user: User;
  user_id: number
  location_id: number
  meetup_prevention: string | null
  turn: number
}
