import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material';

const lightTheme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "Gotham",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    mode: "light",
    primary: {
      main: "#7AC142",
      contrastText: "#fff",
    },
    background: {
      default: "rgba(42, 45, 56, 1)",
    },
    secondary: {
      main: "#FFF",
    },
  },
});


export function withLightTheme<T>(WrappedComponent: React.ComponentType<T>) {

  const hocComponent = (hocProps: T) =>
  (
    <ThemeProvider theme={lightTheme}>
      <WrappedComponent {...hocProps as T} />
    </ThemeProvider>
  )

  return hocComponent;
}