import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  District,
  School,
  SchoolSettings,
} from "../Interfaces/SchoolInterface";
import ApiService from "./apiService";

const basicUrl = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE === "local"
    ? "/schools"
    : ""
  : "/schools";

export const schoolsURL = `${basicUrl}/schools/schools`;
export const districtsURL = `${basicUrl}/schools/districts`;

export const fetchSchools = createAsyncThunk(
  "school/fetchSchools",
  async () => {
    const result: any = await ApiService.get<School[]>(schoolsURL);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const createSchool = createAsyncThunk(
  "school/createSchool",
  async (school: School) => {
    const result: any = await ApiService.post<School>(schoolsURL, school);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const GetDistricts = createAsyncThunk(
  "schools/GetDistricts",
  async () => {
    const result = await ApiService.get(`${districtsURL}`);
    return result?.data;
  }
);

export const PostDistricts = createAsyncThunk(
  "schools/PostDistricts",
  async (district: District) => {
    const result = await ApiService.post(`${districtsURL}`, district);
    return result?.data;
  }
);

export const fetchSchoolSettingsBySchoolId = createAsyncThunk(
  "settings/fetchSchoolSettingsBySchoolId",
  async (schoolId: number) => {
    const result: any = await ApiService.get<SchoolSettings>(
      `${schoolsURL}/${schoolId}/settings`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const updateSchoolSettingsBySchoolId = createAsyncThunk(
  "settings/updateSchoolSettingsBySchoolId",
  async ({
    schoolId,
    settings,
  }: {
    schoolId: number;
    settings: SchoolSettings;
  }) => {
    const result: any = await ApiService.post<SchoolSettings>(
      `${schoolsURL}/${schoolId}/settings`,
      settings
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);
