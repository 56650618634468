import React, {
  CSSProperties,
  FC,
  ReactElement,
  useEffect,
  useState,
} from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  TextField,
  Grid,
  Button,
  CardActions,
  Box,
  LinearProgress,
  Typography,
} from "@mui/material";
import { LightModal } from "../LightModal/LightModal";

import {
  IsLessThanNumberField,
  RequiredNumberField,
} from "../../Helpers/FormValidations";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchAllSchoolAdminsBySchoolId } from "../../Services/UsersService";
import { MassCreateRoom } from "../../Interfaces/SchoolInterface";
import { massCreateRoom } from "../../Services/RoomService";

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
    lineHeight: 1,
  } as CSSProperties,
};

const _FIRST_ROOM_INPUT_LABEL = "FirstRoom";
const _LAST_ROOM_INPUT_LABEL = "LastRoom";
interface Props {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
}

const CreateMultipleRoomsModal: FC<Props> = ({
  openModal,
  handleOpenModal,
}): ReactElement => {
  const dispatch = useDispatch();
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { successMessage, savingIndicator } = useSelector(
    (state: RootState) => state.room
  );

  const [step, setStep] = useState<number>(1);
  const [firstRoom, setFirstRoom] = useState<number>(1);
  const [lastRoom, setLastRoom] = useState<number>(1);
  const [firstRoomError, setFirstRoomError] = useState<string>("");
  const [lastRoomError, setLastRoomError] = useState<string>("");

  useEffect(() => {
    if (currentSchool.id) {
      dispatch(fetchAllSchoolAdminsBySchoolId(currentSchool.id));
    }
  }, [currentSchool]);

  useEffect(() => {
    successMessage !== "" && closeModal();
  }, [successMessage]);

  async function validateformField(fieldName: string) {
    return new Promise<string>((resolve) => {
      switch (fieldName) {
        case _FIRST_ROOM_INPUT_LABEL: {
          const result = RequiredNumberField("First room", +firstRoom);
          setFirstRoomError(result);
          return resolve(result);
        }
        case _LAST_ROOM_INPUT_LABEL: {
          const result =
            RequiredNumberField("First room", lastRoom) ||
            IsLessThanNumberField(
              "First room",
              +firstRoom,
              "Last room",
              +lastRoom
            );
          setLastRoomError(result);
          return resolve(result);
        }
      }
    });
  }

  function handleInputChange(e: React.ChangeEvent<any>) {
    const { name, value } = e.target;

    switch (name) {
      case _FIRST_ROOM_INPUT_LABEL: {
        setFirstRoom(value);
        break;
      }
      case _LAST_ROOM_INPUT_LABEL:
        setLastRoom(value);
        break;
    }
  }

  function closeModal() {
    handleOpenModal(false);
    setFirstRoom(1);
    setLastRoom(1);
    setStep(1);
  }

  function handleLeftButtonClick() {
    switch (step) {
      case 1:
        closeModal();
        break;
      case 2:
        setStep(1);
        break;
    }
  }

  async function handleRightButtonClick() {
    switch (step) {
      case 1: {
        const isReady = await readyToSubmit();
        if (!isReady) return;
        setStep(2);
        break;
      }
      case 2:
        await submitForm();
        break;
    }
  }

  async function readyToSubmit(): Promise<boolean> {
    switch (step) {
      case 1: {
        const inputValidation =
          (await validateformField(_FIRST_ROOM_INPUT_LABEL)) ||
          (await validateformField(_LAST_ROOM_INPUT_LABEL));
        return !inputValidation;
      }
      default:
        return false;
    }
  }

  async function submitForm() {
    const massCreate: MassCreateRoom = {
      firstRoomName: firstRoom,
      lastRoomName: lastRoom,
      school_id: parseInt(`${currentSchool.id}`),
    };
    await dispatch(massCreateRoom(massCreate));
  }

  return (
    <>
      <LightModal isOpenModal={openModal} handleOpenModal={handleOpenModal}>
        <Card>
          <CardHeader title="Create Multiple Rooms" sx={{ mx: 1 }} />
          <CardContent sx={{ mx: 1 }}>
            {step === 1 ? (
              <Stack spacing={3}>
                <Box>
                  <Typography variant="body2">
                    Enter the number of the first classroom to create
                  </Typography>
                  <TextField
                    required
                    sx={{ mt: 1 }}
                    type="number"
                    name={_FIRST_ROOM_INPUT_LABEL}
                    fullWidth
                    variant="standard"
                    value={firstRoom}
                    onChange={handleInputChange}
                    onBlur={readyToSubmit}
                    autoComplete="false"
                    error={!!firstRoomError}
                    helperText={firstRoomError && firstRoomError}
                  />
                </Box>
                <Box>
                  <Typography variant="body2">
                    Enter the number of the last classroom to create
                  </Typography>
                  <TextField
                    required
                    sx={{ mt: 1 }}
                    type="number"
                    name={_LAST_ROOM_INPUT_LABEL}
                    fullWidth
                    variant="standard"
                    value={lastRoom}
                    onChange={handleInputChange}
                    onBlur={readyToSubmit}
                    autoComplete="false"
                    error={!!lastRoomError}
                    helperText={lastRoomError && lastRoomError}
                  />
                </Box>
              </Stack>
            ) : (
              <>
                <Stack spacing={1}>
                  <Box>
                    <Typography variant="body2">
                      <strong>The total number</strong> of classrooms you are
                      going to create is:
                    </Typography>
                    <Typography
                      sx={{ fontWeight: "bold", mt: 1 }}
                      color="primary"
                    >
                      {firstRoom && lastRoom && Math.abs(firstRoom - +lastRoom) + 1}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      <strong>Starting with</strong> classroom number:
                    </Typography>
                    <Typography
                      sx={{ fontWeight: "bold", mt: 1 }}
                      color="primary"
                    >
                      {`${firstRoom} up to ${lastRoom}`}
                    </Typography>
                  </Box>
                </Stack>
              </>
            )}
          </CardContent>
          <CardActions style={{ padding: 0, margin: 0 }}>
            <Grid
              container
              spacing="0"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => handleLeftButtonClick()}
                  style={styles.formButton}
                  size="large"
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  {step === 1 ? "Cancel" : "Back"}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => handleRightButtonClick()}
                  color="primary"
                  variant="contained"
                  style={styles.formButton}
                  fullWidth
                  size="large"
                >
                  {step === 1 ? "Continue" : "Create"}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
          {savingIndicator && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Card>
      </LightModal>
    </>
  );
};

export default CreateMultipleRoomsModal;
