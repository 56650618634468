import { CSSProperties, FC, ReactElement, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  TextField,
  Grid,
  Button,
  CardActions,
  Box,
  LinearProgress,
} from "@mui/material";
import useForm, { FormField } from "../../Hooks/useForm";
import { LightModal } from "../LightModal/LightModal";

import { RequiredNumberField } from "../../Helpers/FormValidations";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchAllSchoolAdminsBySchoolId } from "../../Services/UsersService";
import { Room } from "../../Interfaces/SchoolInterface";
import { createRoom, updateRoom } from "../../Services/RoomService";

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
    lineHeight: 1,
  } as CSSProperties,
};

interface Props {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
  selectedRoom?: Room;
}

const roomForm: Room = {
  name: "1",
  school_id: 0,
};

const RoomManagementModal: FC<Props> = ({
  openModal,
  handleOpenModal,
  selectedRoom,
}): ReactElement => {
  const {
    form,
    setForm,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleCompleted,
  } = useForm<Room>(roomForm as Room, validateformField, submitForm);

  const dispatch = useDispatch();
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { rooms, successMessage, savingIndicator } = useSelector(
    (state: RootState) => state.room
  );

  useEffect(() => {
    setForm(selectedRoom ?? roomForm);
  }, [setForm, selectedRoom]);

  useEffect(() => {
    if (currentSchool.id) {
      dispatch(fetchAllSchoolAdminsBySchoolId(currentSchool.id));
    }
  }, [currentSchool]);

  useEffect(() => {
    successMessage !== "" && handleCloseModal();
  }, [successMessage]);

  function validateformField(
    fieldName: string,
    fieldValue: string | number
  ): FormField {
    let value: string = "";

    switch (fieldName) {
      case "name": {
        const labelValue = "Room";
        value =
          checkIfAlreadyExist(fieldValue as string) ||
          RequiredNumberField(labelValue, +fieldValue);
        break;
      }
    }

    return {
      name: fieldName,
      value: value,
    } as FormField;
  }

  async function submitForm() {
    if (!readyToSubmit()) return;

    const room: Room = {
      id: selectedRoom?.id,
      name: form.name,
      school_id: parseInt(`${currentSchool.id}`),
    };

    room.id
      ? await dispatch(updateRoom(room))
      : await dispatch(createRoom(room));
  }

  function handleCloseModal() {
    handleCompleted(true);
    handleOpenModal(false);
  }

  function readyToSubmit(): boolean {
    return !(!form.name || !!errors.name || savingIndicator);
  }

  function checkIfAlreadyExist(value: string): string {
    let message = "";
    if (rooms) {
      const result = rooms?.find((s) => s.name === value);
      if (result !== undefined)
        message = "Another room with the same name already exists";
    }
    return message;
  }

  return (
    <>
      <LightModal isOpenModal={openModal} handleOpenModal={handleOpenModal}>
        <Card>
          <CardHeader title="Create new room" sx={{ mx: 1 }} />
          <form onSubmit={handleSubmit} autoComplete="off">
            <CardContent sx={{ mx: 1 }}>
              <Stack spacing={2}>
                <TextField
                  style={{ marginBottom: "5px" }}
                  type="number"
                  name="name"
                  fullWidth
                  label="Room"
                  variant="standard"
                  value={form.name}
                  onChange={handleChange}
                  autoComplete="false"
                  onBlur={handleBlur}
                  error={!!errors.name}
                  helperText={errors.name && errors.name}
                />
              </Stack>
            </CardContent>
            <CardActions style={{ padding: 0, margin: 0 }}>
              <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => handleCloseModal()}
                    style={styles.formButton}
                    size="large"
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={styles.formButton}
                    fullWidth
                    size="large"
                    disabled={!readyToSubmit()}
                  >
                    {`${selectedRoom?.id ? "Update" : "Add"} classroom`}
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </form>
          {savingIndicator && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Card>
      </LightModal>
    </>
  );
};

export default RoomManagementModal;
