import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Link,
  ToggleButtonGroup,
  ToggleButton,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import moment from "moment";
import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, DateTypeEnum, Frequency } from "../../Interfaces/CalendarInterface";
import {
  createCalendarDay,
  updateCalendarDay,
} from "../../Services/CalendarService";
import { RootState } from "../../store";
import { LightModal } from "../LightModal/LightModal";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FrecuencyType } from "../../Interfaces/ScheduleInterface";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { ChevronLeft } from "@mui/icons-material";

interface ModalProps {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
  selectedDate: Date | null;
  selectedDateType: DateTypeEnum;
  setSelectedDateType: Function;
  currentCalendarDay: Calendar | null | undefined;
  school_id: number;
  onSave?: (eventTitle: string, selectedDateType: DateTypeEnum) => void;
}

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

enum NavigationTabEnum {
  TabDayType,
  TabFrequency,
}

const daysButtons = [
  { value: "Mo", text: "M" },
  { value: "Tu", text: "T" },
  { value: "We", text: "W" },
  { value: "Th", text: "T" },
  { value: "Fr", text: "F" },
];

const CalendarModal: FC<ModalProps> = (props): ReactElement => {

  const { dayTypes } = useSelector((state: RootState) => state.schedule);

  const {
    openModal,
    handleOpenModal,
    selectedDate,
    selectedDateType,
    setSelectedDateType,
    currentCalendarDay,
    school_id,
    onSave,
  } = props;

  const [selectedDayTypeID, setSelectedDayType] = useState<any>(null);
  const [navigationTab, setNavigationTab] = useState<NavigationTabEnum>(NavigationTabEnum.TabDayType);
  const [frecuency, setFrecuency] = useState(FrecuencyType.NonRecurring);
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>(["Mo"]);
  const [schoolDays, setSchoolDays] = useState<number>(0);
  const [openStartDate, setOpenStartDate] = useState<boolean>(false);
  const [openEndDate, setOpenEndDate] = useState<boolean>(false);
  const [endTimeEveryXDays, setEndTimeEveryXDays] = useState<any>(new Date());
  const [endTimeDaysOFWeek, setEndTimeDaysOFWeek] = useState<any>(new Date());

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedDateType === DateTypeEnum.Holiday)
      return;

    (dayTypes && dayTypes.length > 0) && dayTypes.find(type => type.id === currentCalendarDay?.day_type_id) ?
      setSelectedDayType(currentCalendarDay?.day_type_id) :
      setSelectedDayType(dayTypes[0]?.id);

  }, [currentCalendarDay, dayTypes]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const dateTypeSelected = (event.target as HTMLInputElement).value as DateTypeEnum;

    setSelectedDateType(dateTypeSelected);
    dateTypeSelected === DateTypeEnum.Holiday ?
      setSelectedDayType(null) :
      setSelectedDayType(dayTypes && dayTypes?.length > 0 ? dayTypes[0]?.id : 0);
  };

  const { savingIndicator, successMessage } = useSelector(
    (state: RootState) => state.calendar
  );

  const schema = yup.object().shape({
    day_type: yup.string().required("this field is required"),
  });

  const {
    formState: { errors },
    control,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      day_type: "",
    },
  });

  useEffect(() => {
    successMessage !== "" && handleOpenModal(false);
  }, [successMessage]);

  async function submitForm() {

    let details = '';
    let end_date_time = null;

    switch (frecuency) {
      case FrecuencyType.DaysOFWeek:
        details = JSON.stringify(daysOfWeek);
        end_date_time = endTimeDaysOFWeek;
        break;
      case FrecuencyType.EveryXDays:
        details = JSON.stringify(schoolDays);
        end_date_time = endTimeEveryXDays;
        break;
    }

    const frequencyObj: Frequency = {
      start_time: selectedDate as Date,
      end_time: end_date_time,
      frecuency_type: frecuency,
      details
    }

    const calendarDay: Calendar = {
      id: currentCalendarDay?.id,
      selected_date: selectedDate as Date,
      day_type_enum: selectedDateType,
      school_id: school_id,
      day_type_id: selectedDayTypeID > 0 ? selectedDayTypeID : null,
      frequency: frequencyObj
    };


    calendarDay.id
      ? await dispatch(updateCalendarDay(calendarDay))
      : await dispatch(createCalendarDay(calendarDay));

    const eventTitle = selectedDateType === DateTypeEnum.Holiday ? "Day Off" :
      dayTypes.find(x => x.id === selectedDayTypeID)?.name || "New Event";

    setNavigationTab(NavigationTabEnum.TabDayType);
    onSave && onSave(eventTitle, selectedDateType);
  }

  const handleSelectedTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDayType(parseInt(event.target.value));
  };

  const Tab1 = () => {
    return <Box>
      <Typography
        style={{
          color: "#222B45B2",
          marginBottom: 5,
          fontSize: "15px",
        }}
      >
        {moment(selectedDate).format("DD MMM, YYYY (dddd)")}
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        width: "100%"
      }}>
        <RadioGroup
          sx={{ width: "100%" }}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedDateType}
          onChange={handleChange}
        >
          <FormControlLabel
            value="Holiday"
            componentsProps={{ typography: { variant: "body2" } }}
            control={<Radio />}
            label="Off day"
          />
          <FormControlLabel
            value="Schoolday"
            componentsProps={{ typography: { variant: "body2" } }}
            control={<Radio />}
            label="Schoolday"
          />
        </RadioGroup>
        {selectedDateType === DateTypeEnum.Schoolday && (
          <>
            <Divider orientation="vertical" flexItem />
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              width: "100%"
            }}>
              <Controller
                name="day_type"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="day_type"
                    select
                    label="Day Type"
                    sx={{ width: '95%', marginLeft: '20px', paddingRight: '20px' }}
                    error={!!errors.day_type}
                    helperText={
                      errors.day_type ? errors.day_type.message : ""
                    }
                    value={selectedDayTypeID}
                    onChange={handleSelectedTypeChange}
                    variant="standard"
                    fullWidth
                  >
                    {dayTypes?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <Box mt={2} ml={2.5}>
                <Typography variant="subtitle2">
                  Frequency
                </Typography>

                <Typography variant="body1">
                  Non-Recurring
                </Typography>

                <Link sx={{ marginTop: 2, marginBottom: 1 }}
                  variant="button" component="button" underline="none"
                  onClick={() => setNavigationTab(NavigationTabEnum.TabFrequency)}>
                  Edit
                </Link>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>;
  }

  const handleDaysOfWeek = (
    e: React.MouseEvent<HTMLElement>,
    newDays: string[]
  ) => {
    setDaysOfWeek(newDays);
  };

  function handleSchoolDaysChange({
    target,
  }: React.ChangeEvent<HTMLInputElement>) {
    setSchoolDays(parseInt(target.value));
  }

  function handleFrecuencyChange({
    target,
  }: React.ChangeEvent<HTMLInputElement>) {
    const frec: FrecuencyType = FrecuencyType[target.value as FrecuencyType];
    setFrecuency(frec);
  }

  const FrecuencyLabel = (
    <Box>
      <label>Every </label>
      <TextField
        style={{ width: 40 }}
        inputProps={{ min: 0, style: { padding: 0, textAlign: "center" } }}
        type="number"
        name="details"
        variant="standard"
        onChange={handleSchoolDaysChange}
        value={schoolDays}
        error={
          frecuency === FrecuencyType.EveryXDays &&
          (!schoolDays || schoolDays === 0)
        }
        disabled={frecuency !== FrecuencyType.EveryXDays}
      />
      <label> School days</label>
    </Box>
  );

  const handleChangeByAttrName = (attributeName: any, date: any) => {
    if (frecuency === FrecuencyType.EveryXDays) {
      setEndTimeEveryXDays(date);
    }
    else if (frecuency === FrecuencyType.DaysOFWeek) {
      setEndTimeDaysOFWeek(date);
    }
  }

  const DatePickerItem = (
    labelName: string,
    attributeName: string,
    formDate: Date,
    errorsDate: any
  ): ReactElement => (
    <MobileDatePicker
      open={attributeName === "start_date" ? openStartDate : openEndDate}
      onOpen={() =>
        attributeName === "start_date"
          ? setOpenStartDate(true)
          : setOpenEndDate(true)
      }
      onClose={() =>
        attributeName === "start_date"
          ? setOpenStartDate(false)
          : setOpenEndDate(false)
      }
      label={labelName}
      inputFormat="MMM dd, yyyy"
      value={formDate}
      onChange={(date) => handleChangeByAttrName(attributeName, date)}
      renderInput={(params) => (
        <TextField
          onClick={() =>
            attributeName === "start_date"
              ? setOpenStartDate(true)
              : setOpenEndDate(true)
          }
          {...params}
          fullWidth
          id="modal-startDate"
          name={attributeName}
          variant="outlined"
          error={!!errorsDate}
          // onBlur={handleBlur}
          helperText={errorsDate && errorsDate}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <DateRangeIcon
                  style={{ cursor: "pointer" }}
                  color="primary"
                  fontSize="small"
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );

  return (
    <>
      <LightModal isOpenModal={openModal} handleOpenModal={handleOpenModal}>
        <Card>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'space-between',
            marginLeft: "10px",
            marginTop: "15px",
            width: "100%"
          }}>
            {navigationTab === NavigationTabEnum.TabFrequency &&
              <IconButton className="notification-icon-desktop"
                color="primary"
                onClick={() => setNavigationTab(NavigationTabEnum.TabDayType)}>
                <ChevronLeft />
              </IconButton>}
            <CardHeader
              title={navigationTab === NavigationTabEnum.TabDayType ? "Change date type" : "Edit Day Type Frequency"}
              titleTypographyProps={{ variant: "h6", fontWeight: "500" }}
              sx={{ mx: 1, p: navigationTab === NavigationTabEnum.TabFrequency ? 0 : 1, ml: 0, width: "100%" }}
            />
          </Box>
          <CardContent sx={{ mx: 1, pt: 0, pb: 0, width: "100%" }}>
            <FormControl sx={{ width: "100%" }}>
              {navigationTab === NavigationTabEnum.TabDayType &&
                <Tab1 />
              }
              {navigationTab === NavigationTabEnum.TabFrequency &&
                <>
                  <FormControl sx={{ my: 2 }}>
                    <Typography variant="body1" sx={{ mb: 1.5 }}>Frecuency</Typography>
                    <RadioGroup
                      aria-labelledby="radio-button-frecuency"
                      value={frecuency}
                      onChange={handleFrecuencyChange}
                    >
                      <FormControlLabel
                        value={FrecuencyType.EveryXDays}
                        sx={{ mb: 0.5 }}
                        control={<Radio />}
                        label={FrecuencyLabel}
                      />
                      {frecuency === FrecuencyType.EveryXDays &&
                        <>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>Ends On:</Typography>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid item xs={12} sm={6} mt={1} mb={1}>
                              {DatePickerItem(
                                "",
                                "EveryXDays_EndDate",
                                endTimeEveryXDays,
                                errors.start_date
                              )}
                            </Grid>
                          </LocalizationProvider>
                        </>
                      }

                      <FormControlLabel
                        value={FrecuencyType.DaysOFWeek}
                        control={<Radio />}
                        label="Choose day of week"
                      />

                      <Grid
                        container
                        spacing="0"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mt: '8px', mb: '10px' }}
                      >
                        <ToggleButtonGroup
                          color="primary"
                          value={daysOfWeek}
                          onChange={handleDaysOfWeek}
                          size="large"
                          style={{ textAlign: "center" }}
                          disabled={frecuency === FrecuencyType.EveryXDays}
                        >
                          {daysButtons.map((day, index) => (
                            <ToggleButton
                              key={index}
                              value={day.value}
                              style={{ width: 47 }}
                            >
                              {day.text}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                      </Grid>

                      {frecuency === FrecuencyType.DaysOFWeek &&
                        <>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>Ends On:</Typography>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid item xs={12} sm={6} mt={1} mb={1}>
                              {DatePickerItem(
                                "",
                                "DaysOFWeek_EndDate",
                                endTimeDaysOFWeek,
                                errors.start_date
                              )}
                            </Grid>
                          </LocalizationProvider>
                        </>
                      }

                      <FormControlLabel
                        value={FrecuencyType.NonRecurring}
                        control={<Radio />}
                        label="Non-Recurring"
                      />
                    </RadioGroup>
                  </FormControl>
                </>
              }
            </FormControl>
          </CardContent>
          <CardActions style={{ padding: 0, margin: 0 }}>
            <Grid
              container
              spacing="0"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    setSelectedDateType(DateTypeEnum.Holiday);
                    setNavigationTab(NavigationTabEnum.TabDayType);
                    setFrecuency(FrecuencyType.NonRecurring);
                    handleOpenModal(false);
                  }}
                  disabled={savingIndicator}
                  style={styles.formButton}
                  size="large"
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={styles.formButton}
                  onClick={submitForm}
                  fullWidth
                  size="large"
                  disabled={savingIndicator}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </CardActions>
          {savingIndicator && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Card>
      </LightModal>
    </>
  );
};

export default CalendarModal;
