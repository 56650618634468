import {
  AlertColor,
  Box,
  Button,
  CssBaseline,
  Divider,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { CSSProperties, Fragment, useEffect, useRef, useState } from "react";
import { LayoutContainer } from "../Layout/layout.container";
import { Add } from "@mui/icons-material";
import NewDayTypeModal from "../Components/Schedule/NewDayTypeModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NewPeriodModal from "../Components/Schedule/NewPeriodModal";
import AddIcon from "@mui/icons-material/Add";
import { getTimeStringFormat } from "../Helpers/Utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  fetchDayTypesBySchoolId,
  fetchPeriodsByDayTypeId,
} from "../Services/ScheduleService";
import CustomAlert from "../Components/CustomAlert/CustomAlert";
import {
  AlertState,
  setCustomAlertDetails,
  setPageTitle,
  setShowCustomAlert,
} from "../store/slices/main";
import { setErrorMessage, setSuccessMessage } from "../store/slices/user.slice";
import { cleanPeriods } from "../store/slices/schedule.slice";
import { Visibility } from '@mui/icons-material';

const styles = {
  containerBox: {
    minHeight: "79vh",
    maxHeight: "79vh",
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
  },
  header: {
    height: 52,
    marginTop: "-50px",
    zIndex: 3,
  },
  select: {
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.2)",
    borderWidth: "0 2px 0 0",
  },
  listContainer: {
    overflow: "auto",
    textAlign: "center",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "56px",
  } as CSSProperties,
  listItemFragment: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 13,
  },
  divider: { borderColor: "#3C404E", background: "#3C404E" },
  fab: {
    backgroundColor: "transparent",
    color: "transparent",
    boxShadow: "unset",
    border: "2px solid #7AC142",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow:
        "rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px",
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      border: "2px solid rgba(0, 0, 0, 0.26)",
    },
  },
  addIcon: {
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      backgroundColor: "transparent",
    },
  },
  addPeriod: {
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      backgroundColor: "transparent",
    },
  },
  selectItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  }
};

const SchedulePage = () => {
  //store
  const {
    dayTypes,
    periods,
    errorMessage,
    successMessage,
    periodsLoadingIndicator,
    dayTypesLoadingIndicator,
  } = useSelector((state: RootState) => state.schedule);
  const { currentSchool } = useSelector((state: RootState) => state.main);

  const dispatch = useDispatch();

  const [dayType, setDayType] = useState<string>("");
  const [openNewDayTypeModal, setOpenNewDayTypeModal] = useState(false);
  const [openNewPeriodModal, setOpenNewPeriodModal] = useState(false);
  const [selectedDayType, setSelectedDayType] = useState<any>(null);
  const callbackRef = useRef(null);

  useEffect(() => {
    dispatch(setPageTitle("Schedule"));
  }, []);

  useEffect(() => {
    if (dayTypes.length) {
      if (!dayType) {
        const firstDayTypeId: number = dayTypes[0].id as number;
        setDayType(`${firstDayTypeId}`);
      }
    }
  }, [dayTypes]);

  useEffect(() => {
    if (currentSchool.id) {
      setDayType("");
      dispatch(fetchDayTypesBySchoolId(currentSchool.id));
    }
  }, [currentSchool]);

  useEffect(() => {
    if (dayType) {
      dispatch(fetchPeriodsByDayTypeId(parseInt(dayType)));
    }
    else {
      dispatch(cleanPeriods());
    }
  }, [dayType]);

  useEffect(() => {
    if (errorMessage) {
      showAlert(errorMessage, "error");
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      showAlert(successMessage, "success");
      if (!dayType && dayTypes.length) {
        const firstDayTypeId: number = dayTypes[0].id as number;
        setDayType(`${firstDayTypeId}`);
      }
    }
  }, [successMessage]);

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  async function handleNewDayTypeModal(value: boolean) {
    setSelectedDayType(null);
    setOpenNewDayTypeModal(value);
  }

  async function handleEditDayTypeModal(selectedDayTypeValue: any) {
    if (!selectedDayTypeValue) { return; }

    await setSelectedDayType(selectedDayTypeValue);
    setOpenNewDayTypeModal(true);
  }

  async function handleNewPeriodModal(value: boolean) {
    setOpenNewPeriodModal(value);
  }

  async function handleDayTypeChange({ target }: SelectChangeEvent<string>) {
    if (!target?.value) { return; }

    setDayType(target.value);
    dispatch(fetchPeriodsByDayTypeId(parseInt(target.value)));
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  return (
    <>
      <CssBaseline />
      <LayoutContainer isRaised={true}>
        <Box style={styles.containerBox}>
          {/* start list header */}
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={styles.header}
          >
            <Grid item xs={3}>
              <Typography style={{ color: "rgba(255, 255, 255, 0.7)" }}>
                Day Type
              </Typography>
            </Grid>
            <Grid item xs={dayTypes ? 9 : 3} style={styles.select}>
              <Select
                value={dayType}
                variant="standard"
                onChange={handleDayTypeChange}
                displayEmpty
                disableUnderline
                style={{ maxWidth: "100%", minWidth: "100%" }}
              >
                {dayTypesLoadingIndicator ? (
                  <MenuItem value={""}>Loading day types</MenuItem>
                ) : (
                  dayTypes.map((item, index) => (
                    <MenuItem key={index.toString()} value={item.id} style={styles.selectItem}>
                      <div style={styles.selectItem}>
                        <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
                          {item.name}
                        </Typography>

                        <IconButton
                          component="span"
                          size="small"
                          sx={{
                            color: "white",
                            width: '15px', height: '15px',
                            display: "flex", justifyContent: "center", alignItems: "center",
                          }}
                          onClick={() => handleEditDayTypeModal(item)}
                          disableRipple={true}
                        >
                          <Visibility fontSize="small" />
                        </IconButton>
                      </div>
                    </MenuItem>
                  ))
                )}
                {!dayTypes.length && (
                  <MenuItem value={""}>No day types found</MenuItem>
                )}
                <MenuItem>
                  <Button
                    variant="text"
                    startIcon={<Add />}
                    style={{ textTransform: "unset" }}
                    disabled={!currentSchool?.id}
                    onClick={() => handleNewDayTypeModal(true)}
                  >
                    Add day type
                  </Button>
                </MenuItem>
              </Select>
            </Grid>

            {!dayTypes && (
              <Grid item xs={6}>
                <Button
                  variant="text"
                  startIcon={<Add />}
                  style={{ textTransform: "unset" }}
                  disabled={!currentSchool?.id}
                  onClick={() => handleNewDayTypeModal(true)}
                >
                  Add day type
                </Button>
              </Grid>
            )}
          </Grid>
          {/* end list header */}

          {/* start list body */}
          <Grid container spacing={0} style={styles.listContainer}>
            <Grid item xs={12}>
              <Box>
                {periodsLoadingIndicator || !currentSchool?.id ? (
                  <Box style={styles.loading}>
                    <LinearProgress />
                  </Box>
                ) : (
                  <List style={styles.list}>
                    {periods?.map((item, index) => (
                      <div key={index.toString()}>
                        <ListItem style={styles.listItem}>
                          <ListItemText
                            primary={item.name}
                            secondary={
                              <Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="primary"
                                  style={styles.listItemFragment}
                                >
                                  {`${getTimeStringFormat(
                                    item.start_time as Date
                                  )} to ${getTimeStringFormat(item.end_time as Date)}`}
                                </Typography>
                              </Fragment>
                            }
                          />
                          <IconButton edge="end" aria-label="menu">
                            <MoreVertIcon />
                          </IconButton>
                        </ListItem>
                        <Divider style={styles.divider} />
                      </div>
                    ))}
                  </List>
                )}
              </Box>
            </Grid>
          </Grid>
          {/* end list body */}

          {/* start list body */}
          <Grid container spacing={0} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
              <Fab
                onClick={() => handleNewPeriodModal(true)}
                disabled={dayType === ""}
                sx={styles.fab}
              >
                <AddIcon
                  sx={styles.addIcon}
                  className={dayType === "" ? "Mui-disabled" : ""}
                  color="primary"
                />
              </Fab>
              <Typography
                sx={styles.addPeriod}
                className={dayType === "" ? "Mui-disabled" : ""}
                color="primary"
                style={{ fontWeight: 500, marginTop: 8 }}
              >
                Add period
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </LayoutContainer >
      <NewDayTypeModal
        schoolId={parseInt(`${currentSchool.id}`)}
        handleOpenModal={handleNewDayTypeModal}
        openModal={openNewDayTypeModal}
        selectedDayType={selectedDayType}
      ></NewDayTypeModal>
      <NewPeriodModal
        handleOpenModal={handleNewPeriodModal}
        openModal={openNewPeriodModal}
        day_type_id={parseInt(dayType)}
      ></NewPeriodModal>
      <CustomAlert handleClose={handleCloseAlert} />
    </>
  );
};

export default SchedulePage;
