import React, { FC, ReactElement } from "react";
import { Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  List,
  TextField,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserListItem from "./UserListItem";
import { RootState } from "../../store";
import { setPageTitle } from "../../store/slices/main";
import { filterTeachers } from "../../store/slices/user.slice";
import { getUsersByGroupAndSchoolID } from "../../Services/UsersService";
import { GroupEnum, User } from "../../Interfaces/UserInterface";

const styles = {
  loading: { width: "100%" } as CSSProperties,
  divider: {
    borderColor: "#3C404E",
    background: "#3C404E",
    borderBottomWidth: 2,
  },
  searchContainer: {
    background: "#3C404E",
    padding: "10px",
    height: 50,
    width: "100%",
    borderRadius: "8px 8px 0px 0px ",
  } as CSSProperties,
};

interface TeachersListProps {
  isMobile: boolean;
  handleOpenModal: (state: boolean) => void;
}

export const TeachersList: FC<TeachersListProps> = ({
  isMobile,
  handleOpenModal,
}): ReactElement => {
  const dispatch = useDispatch();

  //store
  const { teachers, loadingIndicator } = useSelector(
    (state: RootState) => state.user
  );
  const { currentSchool } = useSelector((state: RootState) => state.main);

  useEffect(() => {
    dispatch(setPageTitle("Users and Roles"));
  });

  useEffect(() => {
    //if (!currentSchool || !currentSchool.id) return;
    dispatch(
      getUsersByGroupAndSchoolID({
        groupName: GroupEnum.Teacher,
        schoolID: currentSchool.id || 1,
      })
    );
  }, [currentSchool]);

  function filter(e: React.ChangeEvent<HTMLInputElement>) {
    const keyword = e.target.value.trim();
    dispatch(filterTeachers(keyword));
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box style={styles.searchContainer}>
            <TextField
              placeholder="Search by name"
              variant="standard"
              fullWidth
              onChange={filter}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {loadingIndicator ? (
        <Box style={styles.loading}>
          <LinearProgress />
        </Box>
      ) : (
        <List sx={{ padding: 0 }}>
          {teachers?.map((item: User, index: number) => (
            <div key={index.toString()}>
              <UserListItem
                user={item}
                group="Teacher"
                isMobile={isMobile}
                handleOpenModal={handleOpenModal}
              />
              <Divider style={styles.divider} />
            </div>
          ))}
        </List>
      )}
    </>
  );
};
