import {
  Dialog,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { FC, Fragment, ReactElement, useState } from "react";
import { withLightTheme } from "../../Hocs/withLightTheme";

interface BaseMenuDialogProps {
  open: boolean;
  onClose: (value: string) => void;
  items: string[];
}

const BaseMenuDialogComponent: FC<BaseMenuDialogProps> = (
  props: BaseMenuDialogProps
): ReactElement => {
  const { onClose, open, items } = props;

  function handleClose(value: string) {
    onClose(value);
  }

  function handleListItemClick(value: string) {
    onClose(value);
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          padding: 0,
          position: "fixed",
          bottom: 0,
          width: "80vw",
          backgroundColor: "transparent",
        },
      }}
    >
      <List sx={{ padding: 0, margin: 0 }}>
        {items.map((item, index) => (
          <Fragment key={index}>
            <ListItem
              style={{
                backgroundColor: "white",
                borderRadius:
                  items.length - 1 === index ? "0px 0px 5px 5px" : "none",
                marginBottom: "0.05rem",
              }}
              button
              onClick={() => handleListItemClick(item)}
            >
              <ListItemText
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 0,
                  margin: 0,
                }}
                primary={<Typography color="primary">{item}</Typography>}
              />
            </ListItem>
          </Fragment>
        ))}
        <ListItem
          style={{ backgroundColor: "white", marginTop: 10, borderRadius: 5 }}
          autoFocus
          button
          onClick={() => handleListItemClick("cancel")}
        >
          <ListItemText
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              margin: 0,
            }}
            primary={<Typography>Cancel</Typography>}
          />
        </ListItem>
      </List>
    </Dialog>
  );
};

export const BaseMenuDialog = withLightTheme(BaseMenuDialogComponent);
