import { Close } from "@mui/icons-material";
import { Alert, Box, Collapse, IconButton } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setCustomAlertDetails } from "../../store/slices/main";

interface AlertProps {
  handleClose: () => void;
}
const CustomAlert: FC<AlertProps> = ({ handleClose }): ReactElement => {
  const dispatch = useDispatch();
  const { customAlertDetails, showCustomAlert } = useSelector(
    (state: RootState) => state.main
  );

  useEffect(() => {
    if (customAlertDetails.message) {
      setTimeout(() => {
        handleClose();
        setTimeout(() => {
          dispatch(setCustomAlertDetails({ message: "",}))
        }, 2000);
      }, 3000);
    }
  }, [customAlertDetails.message]);

  return (
    <Box
      sx={{
        position: "fixed",
        maxWidth: "95%",
        bottom: 15,
        marginTop: 10,
        right: 10,
        zIndex: 2000,
        backgroundColor: "rgba(42, 45, 56, 1)",
      }}
    >
      <Collapse in={showCustomAlert}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          severity={customAlertDetails.severity}
          variant="outlined"
        >
          {customAlertDetails.message}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default CustomAlert;
