import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ClassEfficiency } from "../../Interfaces/ClassInterface";
import { getClassRoomEfficiencyAverageByStudent } from "../../Services/SemestersService";
import { RootState } from "../../store";
import { PercentDonut } from "./PercentDonut";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const styles = {
  listContainerMargin: {
    marginTop: "180px",
  } as CSSProperties,
  listContainerMarginDesk: {
    marginTop: "40px",
  } as CSSProperties,
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    minHeight: "79vh",
    maxHeight: "79vh",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    background: "#3C404E",
    height: "auto",
    alignItems: "center",
  } as CSSProperties,
  divider: { borderColor: "#3C404E", background: "#3C404E" },
  newButton: {
    marginRight: 10,
    textTransform: "none",
    color: "white",
    height: 35,
    width: 120,
  } as CSSProperties,
  titleItem: {
    fontSize: "1rem",
    lineHeight: "50px",
    color: "rgba(255, 255, 255, 0.7)",
  },
};

interface IClassEfficiencyList {
  studentId: number | null;
}

export const ClassEfficiencyList: FC<IClassEfficiencyList> = ({ studentId }) => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("sm"));

  // redux state
  const { currentSchool } = useSelector((state: RootState) => state.main);

  // local state
  const [classesEfficiencyList, setClassesEfficiencyList] = useState<ClassEfficiency[]>();
  const [classesEfficiencyListLoading, setClassesEfficiencyListLoading] = useState<boolean>(false);

  useEffect(() => {
    setClassesEfficiencyList([]);
    setClassesEfficiencyListLoading(true);

    if (currentSchool && currentSchool.id && studentId) {
      getClassRoomEfficiencyAverageByStudent(
        Number(currentSchool.id),
        studentId
      )
        .then((classesEfficiency: ClassEfficiency[]) =>
          setClassesEfficiencyList(classesEfficiency)
        )
        .finally(() => {
          setClassesEfficiencyListLoading(false);
        });
    }
  }, [currentSchool?.id, studentId]);

  const ClassEfficiencyListItems = useMemo(
    () =>
      classesEfficiencyList &&
      classesEfficiencyList.length > 0 &&
      classesEfficiencyList?.map((item: ClassEfficiency, index: number) => (
        <React.Fragment key={item.class_id}>
          <ListItem style={styles.listItem}>
            <ListItemText
              primaryTypographyProps={{
                variant: "subtitle2",
                fontWeight: "500",
                fontSize: "0.9rem",
                marginBottom: "0",
                color: "textPrimary",
              }}
              primary={
                <React.Fragment>
                  <Grid
                    container
                    spacing={1}
                    mt={0}
                    style={{ alignItems: "center" }}
                  >
                    <Grid
                      item
                      xs={6}
                      md={6}
                      pt={0}
                      mt={0}
                      style={{ paddingTop: 0 }}
                    >
                      <Typography variant="subtitle1" style={{ marginBottom: "2px", fontWeight: 600}}>
                        {item?.class_name}
                      </Typography>
                      
                      <Typography variant="subtitle2">
                        {item?.class_teacher_name}
                        {/* {console.log("***teacher name***", item)} */}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      pt={0}
                      mt={0}
                      style={{ paddingTop: 0 }}
                    >
                      <PercentDonut
                        chartDataPercentage={item?.class_efficiency_average}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              secondaryTypographyProps={{
                variant: "caption",
                color: "textPrimary",
              }}
            />
          </ListItem>
          <Divider key={`${item.class_id}${index}`} style={styles.divider} />
        </React.Fragment>
      )),
    [classesEfficiencyList]
  );

  return (
    <Box
      style={
        downMd ? styles.listContainerMargin : styles.listContainerMarginDesk
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <div className="attendance-list-title">Class Efficiency</div>
        </Grid>
        {classesEfficiencyListLoading ? (
          <Box style={styles.loading}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{
                paddingLeft: "10px",
                backgroundColor: "#3C404E",
                marginTop: "5px",
                marginLeft: "0px",
                marginRight: "0px",
              }}
            >
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                pt={0}
                mt={0}
                style={{ paddingTop: 0 }}
              >
                <Typography
                  component="div"
                  align="left"
                  variant="subtitle2"
                  style={styles.titleItem}
                >
                  Class
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                pt={0}
                mt={0}
                style={{ paddingTop: 0 }}
              >
                <Typography
                  component="div"
                  align="center"
                  variant="subtitle2"
                  style={styles.titleItem}
                >
                  Efficiency
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {ClassEfficiencyListItems}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
