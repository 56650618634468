import {
  School,
  Today,
  Schedule,
  SupervisorAccount,
  Dashboard,
  PersonOutline,
  Assignment,
  Room,
  DateRange,
  AlarmOn,
  MenuBook,
  PrintOutlined,
  Event,
  QrCodeScanner,
  SettingsOutlined,
  QrCode,
  CalendarToday,
} from "@mui/icons-material";

import MenuItem from "../Interfaces/MenuItem";

export const menuItemList: MenuItem[] = [
  {
    id: 1,
    name: "Dashboard",
    icon: <Dashboard />,
    route: "/Dashboard",
    authGroups: ["SchoolAdmin", "Student", "Staff"],
  },
  {
    id: 2,
    name: "User and Roles",
    icon: <PersonOutline />,
    route: "/UserAndRoles",
    authGroups: ["SchoolAdmin"],
  },
  {
    id: 4,
    name: "Pass Management",
    icon: <Assignment />,
    route: "/HallPass",
    authGroups: ["SchoolAdmin"],
  },
  {
    id: 5,
    name: "School Settings",
    icon: <SettingsOutlined />,
    route: "/SchoolSettings",
    authGroups: ["SchoolAdmin"],
  },
  {
    id: 6,
    name: "Add Semester",
    icon: <DateRange />,
    route: "/Semesters",
    authGroups: ["SchoolAdmin"],
  },
  {
    id: 7,
    name: "Room Management",
    icon: <Room />,
    route: "/RoomManagement",
    authGroups: ["SchoolAdmin"],
  },
  {
    id: 8,
    name: "Calendar",
    icon: <Today />,
    route: "/calendar",
    authGroups: ["SchoolAdmin"],
  },
  {
    id: 9,
    name: "Schools",
    icon: <School />,
    route: "/schools",
    authGroups: ["SysAdmin"],
  },
  {
    id: 10,
    name: "Schedule",
    icon: <Schedule />,
    route: "/schedule",
    authGroups: ["SchoolAdmin"],
  },
  {
    id: 11,
    name: "School Admins",
    icon: <SupervisorAccount />,
    route: "/schoolAdmins",
    authGroups: ["SysAdmin"],
  },
  // {
  //   id: 13,
  //   name: "Classes",
  //   icon: <MenuBook />,
  //   route: "/TeacherSchedule",
  //   authGroups: ["Teacher"],
  // },
  {
    id: 14,
    name: "Print QR Codes",
    icon: <PrintOutlined />,
    route: "/PrintQRCodes",
    authGroups: ["Teacher"],
  },
  {
    id: 15,
    name: "Attendance",
    icon: <AlarmOn />,
    route: "/AttendanceList",
    authGroups: ["Teacher"],
  },
  {
    id: 16,
    name: "Request Hall Pass",
    icon: <Event />,
    route: "/StudentHallPassLocations",
    authGroups: ["Student"],
  },
  {
    id: 17,
    name: "Scan QR Code",
    icon: <QrCodeScanner />,
    route: "/ScanQRCode",
    authGroups: ["Student"],
  },
  {
    id: 18,
    name: "Kiosk",
    icon: <QrCode />,
    route: "/Kiosk",
    authGroups: ["Teacher"],
  },
  {
    id: 19,
    name: "Kiosk",
    icon: <QrCode />,
    route: "/KioskStaff",
    authGroups: ["Staff"],
  },
  {
    id: 20,
    name: "Schedule",
    icon: <CalendarToday />,
    route: "/ScheduleClasses",
    authGroups: ["Teacher"],
  },
];
