import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import { LayoutContainer } from "../Layout/layout.container";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../store/slices/main";
import { GroupEnum, User } from "../Interfaces/UserInterface";

interface Props {
  authGroups: string[];
}

const ProtectedRoute = ({ authGroups }: Props) => {
  const dispatch = useDispatch();
  const { currentAuthenticatedUser, getSessionGroups, isSessionValid } =
    useCognitoAwsV2();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  React.useEffect(() => {
    if (isLoading == true) {
      checkAuthorizedUser();
    }
  }, []);

  async function checkAuthorizedUser(): Promise<void> {
    const isValid = await isSessionValid();
    
    setIsValid(isValid);
    if (isValid) {
      const groups = await getSessionGroups();
      if(groups && groups.length && authGroups.includes(groups[0])){
        setIsAuthorized(true);
        const user = await currentAuthenticatedUser();
        const sessionUser: User = {
          user_name: user.username,
          email: user.attributes.email,
          first_name: user.attributes.given_name,
          last_name: user.attributes.family_name,
          schools: [],
          group: groups[0] as GroupEnum,
        }
        await dispatch(setCurrentUser(sessionUser))        
      }
    } else {
      console.log("No tiene grupos asociados");
    }
    setIsLoading(false);
  }

  return (
    <>
      {isLoading ? (
        <LayoutContainer isRaised={true}>
          <Box sx={{ width: "100%" }}>{/* <LinearProgress /> */}</Box>
        </LayoutContainer>
      ) : isAuthorized ? (
        <Outlet />
      ) : isValid ? (
        <div>You dont have permission to view this resource. </div>
        ): (
          <Navigate to="/" />
      )}
    </>
  );
};

export default ProtectedRoute;
