// import { useState } from "react";
// import {
//   Avatar,
//   Button,
//   TextField,
//   Link,
//   Grid,
//   Box,
//   Typography,
//   Stack,
//   Alert,
// } from "@mui/material";
// import { LockOutlined } from "@mui/icons-material";
// import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
// import CssBaseline from "@mui/material/CssBaseline";
// import Container from "@mui/material/Container";
// import { LayoutSign } from "../Layout/layout-sign/layout-sign";

export default function SignUp() {
  // const { signUp, confirmSignUp, isAuthenticated, user, error } =
  // useCognitoAws();
  // const [verifyCode, setVerifyCode] = useState<boolean>(false);
  // const [succeedAlert, setSucceedAlert] = useState<boolean>(false);
  // const [errorAlertMsg, setErrorAlertMsg] = useState<string>("");
  // const [errorAlert, setErrorAlert] = useState<boolean>(false);

  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);

  //   const formData = {
  //     email: data.get("email")?.toString() || "",
  //     password: data.get("password")?.toString() || "",
  //     firstName: data.get("firstName")?.toString() || "",
  //     lastName: data.get("lastName")?.toString() || "",
  //     verifyCode: data.get("verifyCode")?.toString() || "",
  //   };

  //   try {
  //     if (verifyCode) {
  //       confirmSignUp(formData.email, formData.verifyCode);

  //       if (isAuthenticated) {
  //         setSucceedAlert(true);
  //       }
  //       return;
  //     }

  //     signUp(
  //       formData.email,
  //       formData.password,
  //       formData.firstName,
  //       formData.lastName
  //     );

  //     if (user && user.userConfirmed === false) {
  //       setVerifyCode(true);
  //     }

  //     setErrorAlert(false);
  //     console.log(user);
  //   } catch (e: any) {
  //     console.log(e);
  //     setErrorAlertMsg(e?.message);
  //     setErrorAlert(true);
  //   }
  // };

  return (
    <div>
      {/* <CssBaseline /> */}

      {/* <LayoutSign
        children={
          <Container>
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign up
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  {verifyCode && (
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="verifyCode"
                        label="Verification Code"
                        type="password"
                        id="verifyCode"
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ marginTop: 10 }}
                >
                  <Grid item>
                    <Link href="/" variant="body2">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{ width: 180, marginTop: 15 }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  {verifyCode ? "Verify Code" : "Sign Up"}
                </Button>

                <Stack sx={{ width: "100%" }} spacing={2}>
                  {errorAlert && (
                    <Alert severity="error">
                      {error + " " + errorAlertMsg}
                    </Alert>
                  )}
                  {succeedAlert && (
                    <Alert severity="success">
                      Account created successfully!
                    </Alert>
                  )}
                </Stack>
              </Box>
            </Box>
          </Container>
        }
      ></LayoutSign> */}
    </div>
  );
}
