import { FC, ReactElement } from "react";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface AddFloatButton {
  handleOpenModal: Function;
  position?: string;
  disableFloating?: boolean;
  disabled?: boolean;
}

const AddFloatButton: FC<AddFloatButton> = ({
  handleOpenModal,
  position,
  disableFloating = false,
  disabled = false,
}): ReactElement => {
  const floatingStyle: any = {};
  if (!disableFloating) floatingStyle.position = "fixed";

  return (
    <>
      {position == "left" ? (
        <div
          style={{
            ...floatingStyle,
            padding: "25px",
            bottom: "0",
            left: "0",
            zIndex: 3,
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => handleOpenModal(true)}
            disabled={disabled}
          >
            <AddIcon color="action" />
          </Fab>
        </div>
      ) : position == "center" ? (
        <div
          style={{
            ...floatingStyle,
            paddingBottom: "25px",
            width: "100%",
            marginTop: "10%",
            display: "flex",
            justifyContent: "center",
            zIndex: 3,
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            disabled={disabled}
            onClick={() => handleOpenModal(true)}
          >
            <AddIcon color="action" />
          </Fab>
        </div>
      ) : (
        <div
          style={{
            ...floatingStyle,
            padding: "25px",
            bottom: "0",
            right: "0",
            zIndex: 3,
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            disabled={disabled}
            onClick={() => handleOpenModal(true)}
          >
            <AddIcon color="action" />
          </Fab>
        </div>
      )}
    </>
  );
};

export default AddFloatButton;
