import { FC, ReactElement, useEffect, useState, useRef, useMemo } from "react";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    ChartData,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

import "../percentage/Percentage.css";

ChartJS.register(ArcElement, Tooltip, Legend);


interface IPercentDonutProps {
    chartDataPercentage?: number;
}

export const PercentDonut: FC<IPercentDonutProps> = ({ chartDataPercentage = 0 }): ReactElement => {
    const theme = useTheme();
    const downMd = useMediaQuery(theme.breakpoints.down("sm"));

    const chartRef = useRef<ChartJSOrUndefined<"doughnut">>(null);
    const [chartData, setChartData] = useState<ChartData<"doughnut">>({
        datasets: [],
    });

    const getChartColor = useMemo(() => {
        let arrayColors = { lower: "#E86C00", medium: "#FDCC4A", high: "#7AC142" };

        if (chartDataPercentage < 33) {
            return arrayColors.lower;
        }
        else if (chartDataPercentage >= 33 && chartDataPercentage < 66) {
            return arrayColors.medium;
        }
        else if (chartDataPercentage >= 66) {
            return arrayColors.high;
        }

    }, [chartDataPercentage])

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        const chartData = {
            labels: [],
            datasets: [
                {
                    label: "Percentage",
                    data: [chartDataPercentage <= 0 ? 0 : Math.abs(chartDataPercentage - 100), Math.abs(chartDataPercentage)],
                    borderRadius: [0, 100],
                    spacing: 0,
                    backgroundColor: ["rgba(115, 136, 169, 0.353283)", getChartColor],
                    borderColor: ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 0)"],
                    borderWidth: 1,
                    weight: 1,
                    cutout: 26,
                    rotation: 270,
                },
            ],
        };

        setChartData(chartData);
    }, []);

    return (
        <>
            <div className="perc-graph-mini">
                <div className="perc-number-mini">{chartDataPercentage?.toFixed()}%</div>
                <Doughnut ref={chartRef} data={chartData} />
            </div>
        </>
    );
};
