import { CSSProperties, useEffect, useMemo } from "react";
import { ClassTimeList } from "../Components/Dashboard/ClassTimeList";
import { LayoutContainer } from "../Layout/layout.container";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { fetchAbsentsBySchoolID, getOverallStudentEfficiencyAllClassesBySemester } from "../Services/AttendanceService";
import { CssBaseline, Box } from "@mui/material";

import moment from "moment";
import { fetchStudentsBySchoolId } from "../Services/UsersService";

const buildDesktopStyles = () => {
  return {
    containerBox: {
      borderRadius: "5px 5px 0 0",
    } as CSSProperties,
    header: {
      height: "auto",
      marginBottom: 12,
      zIndex: 3,
    } as CSSProperties,
    listContainer: {
      overflow: "auto",
      minHeight: "60vh",
      maxHeight: "60vh",
      textAlign: "center",
      background: "#3C404E",
      zIndex: 3,
      borderRadius: "8px 8px 0px 0px ",
    } as CSSProperties,
    tabsContainer: {
      background: "rgb(70, 75, 91)",
      padding: "5px 10px",
      maxWidth: "89vw",
      height: 55,
      width: "100%",
      borderRadius: 5,
    } as CSSProperties,
    tab: {
      textTransform: "none",
    } as CSSProperties,
    tabPanel: {
      padding: 0,
    } as CSSProperties,
  };
};

const StudentsEfficiencyPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const { currentSchool } = useSelector((state: RootState) => state.main);
//   const { absents, loadingIndicator, errorMessage } = useSelector(
//     (state: RootState) => state.attendance
//   );

  const { students, loadingIndicator, errorMessage } = useSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
    if (currentSchool && currentSchool?.id) {
      dispatch(
        fetchAbsentsBySchoolID({
          school_id: currentSchool.id,
          absent_date: moment().format("YYYY-MM-DD"),
        })
      );
    }
  }, [currentSchool]);

  useEffect(() => {
    if (currentSchool && currentSchool?.id) {
        dispatch(
            fetchStudentsBySchoolId(currentSchool.id)
        );
    }
    }
    , [currentSchool]);

    

  const styles = useMemo(() => {
    let style = buildDesktopStyles();
    if (isMobile) {
      style.containerBox = {
        // width: "100%",
        width: "100vw",
        maxWidth: "100vw",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
      };
      style.header.width = "100%";
      style.listContainer.width = "100%";
      style.tabsContainer = {
        background: "rgb(70, 75, 91)",
        height: 155,
        marginTop: -120,
        zIndex: 1,
        display: "flex",
        alignItems: "flex-end",
        width: "100%",
      };
    }
    return style;
  }, [isMobile]);

  return (
    <>
      <CssBaseline />
      <LayoutContainer isRaised={true} sx={{ padding: 0 }}>
        <Box style={styles.containerBox}>
         
          <ClassTimeList
         
            loadingUtilization={loadingIndicator}
            students={students}
            isMobile={isMobile} 
          />
        </Box>
      </LayoutContainer>
    </>
  );
};

export default StudentsEfficiencyPage;