import { createAsyncThunk } from "@reduxjs/toolkit";
import { Calendar } from "../Interfaces/CalendarInterface";
import ApiService from "./apiService";

import moment from "moment";

// const baseURL =
//   process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local";
const baseURL = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE === "local"
    ? true
    : false
  : true;
const CalendarURL = `${baseURL ? "calendar" : ""}/calendar/`;

export const fetchCalendarDaysBySchoolId = createAsyncThunk(
  "calendar/fetchCalendarDays",
  async (school_id: number) => {
    const result: any = await ApiService.get<Calendar[]>(
      `${CalendarURL}school/${school_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const createCalendarDay = createAsyncThunk(
  "calendar/createCalendarDay",
  async (calendar: Calendar) => {
    const result: any = await ApiService.post<Calendar>(CalendarURL, calendar);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const updateCalendarDay = createAsyncThunk(
  "calendar/updateCalendarDay",
  async (calendar: Calendar) => {
    const result: any = await ApiService.put<Calendar>(
      `${CalendarURL}${calendar.id}`,
      calendar
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const deleteCalendarDay = createAsyncThunk(
  "calendar/deleteCalendarDay",
  async (calendar_id: number) => {
    const result: any = await ApiService.delete<Calendar>(
      `${CalendarURL}${calendar_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

//school/:school_id/:string_date
export async function findBySchoolAndDate(school_id: number, date: Date) {
  const selectedDate = moment(date).format("YYYY-MM-DD");
  const result: any = await ApiService.get<Calendar>(
    `${CalendarURL}school/${school_id}/${selectedDate}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}
