import {
  Box,
} from "@mui/material";
import { FC, ReactElement, useEffect } from 'react';
import { CSSProperties } from "@mui/styles";
import { DateRangeOutlined } from "@mui/icons-material";
import React from "react";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
interface ListProps {
  isMobile: boolean;
}
const styles = {
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    zIndex: 3,
    fontWeight: "bold",
    color: "rgba(196,198,202,255)",
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "56px",
  } as CSSProperties,
  divider: {
    borderColor: "#3C404E",
    background: "#3C404E",
    borderBottomWidth: 2,
  },
  miniBox: {
    m: 2,
    width: "40%",
    border: "2px solid rgba(122,193,65,255)",
  }
};

const PreviousClassesList: FC<ListProps> = ({
  isMobile,
}): ReactElement => {
  styles.listContainer = {
    ...styles.listContainer,
    maxHeight: isMobile ? "60vh" : "50vh",
    minHeight: isMobile ? "60vh" : "50vh",
  };
  const [expand, setExpand] = React.useState<boolean>(false);
  const click = () => {
    if (expand) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  }
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);

  return (
    <>
      <Box
        style={styles.listContainer}>
        <Box
          onClick={click}
          mt={2} ml={2} sx={
            {
              display: "flex",
              justifyContent: "left",
            }
          }>
          <DateRangeOutlined sx={{
            color: "rgba(122,193,65,255)",
            mr: 2,
          }} />
          Filter by date
        </Box>
        {
          expand ? (
            <>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center"
                }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    inputFormat="dd MMM, EEE"
                    startText=""
                    endText=""
                    value={value}
                    onChange={(newValue :any) => {
                      setValue(newValue);
                    }}
                    renderInput={(startProps :any, endProps:any) => (
                      <React.Fragment>
                        <TextField sx={styles.miniBox} {...startProps} />
                        <Box sx={{ mx: 1 }}> * </Box>
                        <TextField sx={styles.miniBox} {...endProps} />
                      </React.Fragment>
                    )}
                  />
                </LocalizationProvider>
              </Box>

            </>
          ) : (
            <Box>

            </Box>
          )
        }
      </Box>
    </>
  );
};

export default PreviousClassesList; 
