const local_secure = {
  api: {
    ENDPOINT: "https://localhost:3000/local",
    WEB: "https://localhost:3001",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_U9TAaVQ9D",
    APP_CLIENT_ID: "5cv08l8bmrafcfsn8emee3o3bj",
  },
  classlink: {
    URL: "https://launchpad.classlink.com/oauth2/v2/auth?scope=profile",
    REDIRECT_URI: "https%3A%2F%2Fdev.app.seedclassroom.com%2FProcessClassLink",
    CLIEND_ID: "c1691674072990d27a0aa70454f5314b87519e7c38c0"
  },
};

const local = {
  api: {
    ENDPOINT: "http://localhost:3000/local",
    WEB: "http://localhost:3001",
    WEBSOCKET: "ws://localhost:3001",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_U9TAaVQ9D",
    APP_CLIENT_ID: "5cv08l8bmrafcfsn8emee3o3bj",
  },
  classlink: {
    URL: "https://launchpad.classlink.com/oauth2/v2/auth?scope=profile",
    REDIRECT_URI: "https%3A%2F%2Fdev.app.seedclassroom.com%2FProcessClassLink",
    CLIEND_ID: "c1691674072990d27a0aa70454f5314b87519e7c38c0"
  },
};

const dev = {
  api: {
    ENDPOINT: "https://dev.api.seedclassroom.com",
    WEB: "https://dev.app.seedclassroom.com",
    WEBSOCKET: "wss://ra2dqu3unh.execute-api.us-east-1.amazonaws.com/dev/",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_U9TAaVQ9D",
    APP_CLIENT_ID: "5cv08l8bmrafcfsn8emee3o3bj",
  },
  classlink: {
    URL: "https://launchpad.classlink.com/oauth2/v2/auth?scope=profile",
    REDIRECT_URI: "https%3A%2F%2Fdev.app.seedclassroom.com%2FProcessClassLink",
    CLIEND_ID: "c1691674072990d27a0aa70454f5314b87519e7c38c0"
  },
};

const test = {
  api: {
    ENDPOINT: "https://test.api.seedclassroom.com",
    WEB: "https://test.app.seedclassroom.com",
    WEBSOCKET: "wss://p01u3207j7.execute-api.us-east-1.amazonaws.com/dev/",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_xIEzfsrAE",
    APP_CLIENT_ID: "4i2l8mp2g7jgq3te58kml2l2q6",
  },
  classlink: {
    URL: "https://launchpad.classlink.com/oauth2/v2/auth?scope=profile",
    REDIRECT_URI: "https%3A%2F%2Ftest.app.seedclassroom.com%2FProcessClassLink",
    CLIEND_ID: "c1691674072990d27a0aa70454f5314b87519e7c38c0"
  },
};

const prod = {
  api: {
    ENDPOINT: "https://api.seedclassroom.com",
    WEB: "https://app.seedclassroom.com",
    WEBSOCKET: "wss://1bh7mk7ena.execute-api.us-east-1.amazonaws.com/dev/",
  },
  cognito: {
    REGION: "us-east-1",  
    USER_POOL_ID: "us-east-1_unuNiIs6H",
    APP_CLIENT_ID: "1hq2dgecrj2sk5eph9igsfmqul",
  },
  classlink: {
    URL: "https://launchpad.classlink.com/oauth2/v2/auth?scope=profile",
    REDIRECT_URI: "https%3A%2F%2Fapp.seedclassroom.com%2FProcessClassLink",
    CLIEND_ID: "c1691674072990876aa4fac2c6a37e177d3582132fcc"
  },
};

const config =
  process.env.REACT_APP_STAGE === "prod"
    ? prod
    : process.env.REACT_APP_STAGE === "test"
    ? test
    : process.env.REACT_APP_STAGE === "dev"
    ? dev
    : local;

const sharedObj = {
  ...config,
};
export default sharedObj;
