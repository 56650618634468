import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FC, ReactElement } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { PopButtonMenu, ConfirmationDialog } from "../../Components";
import { Search } from "@mui/icons-material";
import { HallPassLocation } from "../../Interfaces/SchoolInterface";
import {
  deleteHallPassLocation,
  fetchHallPasses,
} from "../../Services/HallPassService";
import { Class } from "../../Interfaces/ClassInterface";
import appConfig from "../../Config/appConfig";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const pdfMake = require("pdfmake");
interface ListProps {
  defaultPassLocation: HallPassLocation;
  styles: any;
  loadingClasses: boolean;
  hallPassLocations: HallPassLocation[];
  selectedHallPass?: HallPassLocation;
  isMobile: boolean;
  setSelectedHallPass: (hallPassLocation_: HallPassLocation) => void;
  handleOpenModal: (state: boolean, origin: string) => void;
  school_id: number;
}
const confirmationDialogData: any = [
  {
    title: "Edit Hall pass location",
    message: "Are you sure you want to edit this hall pass location?",
  },
  {
    title: "Delete Hall pass location",
    message: "Are you sure you want to delete this hall pass location?",
  },
];
const HallPassList: FC<ListProps> = ({
  defaultPassLocation,
  styles,
  loadingClasses,
  hallPassLocations,
  selectedHallPass,
  isMobile,
  setSelectedHallPass,
  handleOpenModal,
  school_id,
}): ReactElement => {
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
  React.useState<boolean>(false);
  const buttonMenuOptions: string[] = ["Edit", "Delete", "Print QR Code", "Cancel"];
  const [confirmationDialog, setConfirmationDialog] = React.useState<any>(
    confirmationDialogData[0]
    );
    const dispatch = useDispatch();
    

    function colDefinition(url: string, room: string, className: string) {
      return [
        { qr: url, fit: 120 }, // Increase the fit value to make the QR code larger
        { text: `Room: ${room}` },
      ];
    }

    function docDefinition(url: string, room: string, className: string) {
      const columnsPerPage = 2;
      const rowsPerPage = 2;
    
      const content = [];
    
      for (let row = 0; row < rowsPerPage; row++) {
        const rowContent = [];
    
        for (let col = 0; col < columnsPerPage; col++) {
          rowContent.push({
            alignment: "left",
            margin: [0, 15],
            columns: [colDefinition(url, room, className)],
          });
        }
    
        content.push(rowContent);
      }
    
      return {
        pageSize: "A4",
        pageMargins: [40, 40, 10, 10],
        alignment: "justify",
        content: content,
      };
    }

    

    function handleClickItem({schoolId, schoolName, roomId, roomName}: {
      schoolId: number,
      schoolName: string,
      roomId: number,
      roomName: string
    }) {
      console.log({
        url: `${appConfig.api.WEB}/school/${schoolId}/location/${roomId}`,
      });
      
      const url = `${appConfig.api.WEB}/school/${schoolId}/location/${roomId}`;
      const title = `Room: ${roomName}`;
      
      pdfMake
      .createPdf(docDefinition(url, `${schoolName}`, `${roomName}`))
      .download(`${title}.pdf`);
    }
    
    const popUpHandlerClick = (
      event: React.MouseEvent<HTMLElement>,
      selectedOption: string | null,
      selectedHallPass?: HallPassLocation
      ) => {
        event.preventDefault();        
        if (!selectedOption) return;
        
        if (selectedHallPass?.id) {
          setSelectedHallPass(selectedHallPass);
        }
        
        if (selectedOption === "Cancel") {
      setSelectedHallPass(defaultPassLocation);
      setConfirmationDialogOpen(false);
      return;
    } else if (selectedOption === "Edit") {
      handleOpenModal(true, "update");
      return;
    } else if (selectedOption === "Delete") {
      setConfirmationDialogOpen(true);
      return;
    } else if (selectedOption === "Print QR Code") {
      handleClickItem({
        schoolId: selectedHallPass?.school_id || 0,
        schoolName: selectedHallPass?.name || "",
        roomId: selectedHallPass?.id || 0,
        roomName: selectedHallPass?.name || ""
      })    
      
      return;
    }
    setConfirmationDialog(
      confirmationDialogData[buttonMenuOptions.indexOf(selectedOption)]
    );
    setConfirmationDialogOpen(true);
  };
  const confirmationDialogHandler = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    try {
      event.preventDefault();
      if (selectedHallPass?.id) {
        setDeleting(true);
        await dispatch(
          deleteHallPassLocation(parseInt(`${selectedHallPass.id}`))
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSelectedHallPass(defaultPassLocation);
      setDeleting(false);
      setConfirmationDialogOpen(false);
      await dispatch(fetchHallPasses(school_id));
    }
  };
  
  const ClassListItems = useMemo(
    () =>
      hallPassLocations?.map((item: HallPassLocation, index) => {
        let managerLabel: any = "";
        if (item?.managers?.length) {
          managerLabel = `${item?.managers[0].first_name} ${item?.managers[0].last_name}`;
          managerLabel =
            item?.managers?.length > 1 ? `${managerLabel} +more` : managerLabel;
        }
        const tempMenuOptions = [...buttonMenuOptions]
        if(!item?.scan_at_arrival){          
          tempMenuOptions.splice(2, 1)
        }
        return (
          <React.Fragment key={item.id}>
            <ListItem style={styles.listItem}>
              <ListItemText
                primaryTypographyProps={styles.primaryTextList}
                primary={<React.Fragment>{item.name}</React.Fragment>}
                secondary={managerLabel}
                secondaryTypographyProps={styles.secondaryTextList}
              />
              <PopButtonMenu
                menuOptions={tempMenuOptions}
                onClick={popUpHandlerClick}
                data={item}
              />
              <ConfirmationDialog
                open={confirmationDialogOpen}
                title={confirmationDialog.title}
                message={confirmationDialog.message}
                onConfirm={confirmationDialogHandler}
                onCancel={() => {
                  setSelectedHallPass(defaultPassLocation);
                  setConfirmationDialogOpen(false);
                }}
                loading={deleting}
              />
            </ListItem>
            <Divider key={`${item.id}${index}`} style={styles.divider} />
          </React.Fragment>
        );
      }),
    [hallPassLocations, popUpHandlerClick, confirmationDialogHandler]
  );
  return (
    <>
      <Box style={styles.listContainer}>
        {loadingClasses ? (
          <Box style={styles.loading}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <Box pl={2}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sm={6} md={6} marginTop="1rem">
                  <Box>
                    <TextField
                      placeholder="Search..."
                      variant="standard"
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  {!isMobile && (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      style={styles.newButton}
                      onClick={() => handleOpenModal(true, "create")}
                    >
                      + Add new
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
            <List style={styles.list}>{ClassListItems}</List>
          </>
        )}
      </Box>
    </>
  );
};
export default HallPassList;