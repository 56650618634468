import { createAsyncThunk } from "@reduxjs/toolkit";
import { Attendance } from "../Interfaces/AttendanceInterface";
import ApiService from "./apiService";

// const baseURL =
//   process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local";
const baseURL = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE === "local"
    ? true
    : false
  : true;
const attendanceURL = `${baseURL ? "attendance" : ""}/attendance/`;

export const createAttendance = createAsyncThunk(
  "attendance/createAttendance",
  async (attendance: Attendance) => {
    const result: any = await ApiService.post<Attendance>(
      attendanceURL,
      attendance
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export async function createAttendanceKioskPage(
  attendance: Attendance
): Promise<any> {
  const result: any = await ApiService.post<Attendance>(
    attendanceURL + "byTeacher",
    attendance
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result.response?.response?.data?.error);
}

export const fetchAttendanceByClassID = createAsyncThunk(
  "attendance/fetchAttendanceByClassID",
  async (classID: number) => {
    const result: any = await ApiService.get<Attendance[]>(
      attendanceURL + "attendance/class/" + classID
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export const findAttendanceByDateAndPeriod = createAsyncThunk(
  "attendance/findAttendanceByDateAndPeriod",
  async ({ school_id, attendance_date, period_id, day_type_id }: any) => {
    const result: any = await ApiService.get<Attendance[]>(
      attendanceURL +
        `attendance/school/${school_id}/attendance_date/${attendance_date}/period_id/${period_id}/day_type_id/${day_type_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export const fetchAbsentsBySchoolID = createAsyncThunk(
  "attendance/findStudentsAbsentsBySchoolID",
  async ({ school_id, absent_date = "" }: any) => {
    const result: any = await ApiService.get<Attendance[]>(
      `${attendanceURL}attendance/absents/${school_id}/${absent_date}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export async function getAbsentsBySchoolId(
  school_id: number,
  absent_date = ""
): Promise<Attendance[]> {
  const result: any = await ApiService.get<Attendance[]>(
    `${attendanceURL}attendance/absents/${school_id}/${absent_date}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result.response?.response?.data?.error);
}

export async function getAttendanceClassByUserId(
  date: string,
  user_id?: number
): Promise<any> {
  const result: any = await ApiService.get<any>(
    attendanceURL + `attendance/attendance_date/${date}/user/${user_id}/`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getAttendanceByClass(
  classId?: number | null
): Promise<any> {
  const result: any = await ApiService.get<any>(
    attendanceURL + `attendance/classId/${classId}/`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getPercentageTimeSpentAllClasses(
  user_id?: number
): Promise<any> {
  const result: any = await ApiService.get<any>(
    attendanceURL + `attendance/spent_time/user/${user_id}/`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getAttendanceByStudentAndClass(
  username?: string,
  classID?: number
): Promise<any> {
  const result: any = await ApiService.get<any>(
    attendanceURL + `attendance/student/today/${username}/class/${classID}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getOverallStudentEfficiencyAllClasses(
  user_id?: number,
  school_id?: number
): Promise<any> {
  const result: any = await ApiService.get<any>(
    attendanceURL + `attendance/overall_student_efficiency/user/${user_id}/school/${school_id}/`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getOverallStudentEfficiencyAllClassesBySemester(
  user_id?: number,
  school_id?: number,
  semester_id?: number
): Promise<any> {
  const result: any = await ApiService.get<any>(
    attendanceURL + `attendance/overall_student_efficiency/user/${user_id}/school/${school_id}/semester/${semester_id}/`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export const updateAttendanceStatus = createAsyncThunk(
  "attendance/updateAttendanceStatus",
  async (new_attendance: any) => {
    const result: any = await ApiService.put<Attendance>(
      `${attendanceURL}${new_attendance.id}`,
      new_attendance
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);
