import { CSSProperties, FC, ReactElement, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Grid,
  Button,
  CardActions,
  Alert,
} from "@mui/material";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { LightModal } from "../LightModal/LightModal";
import { useCognitoAwsV2 } from "../../Hooks/useCognitoAwsv2";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailRegex } from "../../Helpers/Constants";

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
    lineHeight: 1,
  } as CSSProperties,
};

interface ListProps {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
}

interface IFormInputs {
  email: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, "Invalid email format")
    .required("This field is required"),
});

export const AttemptExceedModal: FC<ListProps> = ({
  openModal,
  handleOpenModal,
}): ReactElement => {
  const { forgotPassword } = useCognitoAwsV2();
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<boolean>(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState<string>("");

  const [initialValues, setInitialValues] = useState<IFormInputs>({
    email: "",
  });

  const formSubmitHandler: SubmitHandler<any> = async (data: IFormInputs) => {
    try {
      const { email } = data;

      setErrorAlert(false);
      setErrorAlertMsg("");

      // await forgotPassword(email, { accountStatusEvent: 'ACCOUNT_UNBLOCKED' })
      await forgotPassword(email);
      setIsEmailSent(true);
    } catch (err: any) {
      console.log(err);
      setErrorAlertMsg(err?.message);
      setErrorAlert(true);
    }
  };

  return (
    <>
      <LightModal isOpenModal={openModal} handleOpenModal={handleOpenModal}>
        <Card>
          <CardHeader
            title="Recovery password"
            sx={{ mx: 1, textAlign: "center" }}
          />
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <CardContent sx={{ mx: 1 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <span style={{ marginLeft: 20, marginRight: 20 }}>
                  {!isEmailSent ? (
                    <>
                      We will send you a message with the recovery instructions
                      to your email
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleOutlineOutlined color="primary" />
                      We have sent your recovery email
                    </div>
                  )}
                </span>
              </div>
              {!isEmailSent && (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      marginLeft: 15,
                      marginRight: 15,
                      marginTop: 40,
                      marginBottom: 40,
                    }}
                  >
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={initialValues.email}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="email"
                          type="email"
                          label="Email"
                          variant="standard"
                          disabled={false}
                          error={!!errors.email}
                          helperText={errors.email ? errors.email?.message : ""}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              {errorAlert && <Alert severity="error">{errorAlertMsg}</Alert>}
            </CardContent>
            <CardActions style={{ padding: 0, margin: 0, marginTop: 10 }}>
              <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                {!isEmailSent ? (
                  <>
                    <Grid item xs={6}>
                      <Button
                        onClick={() => handleOpenModal(false)}
                        style={styles.formButton}
                        size="large"
                        color="secondary"
                        variant="contained"
                        fullWidth
                      >
                        Go to login
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        style={styles.formButton}
                        size="large"
                        variant="contained"
                        type="submit"
                        fullWidth
                      >
                        Send email
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        reset();
                        setInitialValues({ email: "" });
                        setIsEmailSent(false);
                        handleOpenModal(false);
                      }}
                      style={styles.formButton}
                      size="large"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      Ok
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardActions>
          </form>
        </Card>
      </LightModal>
    </>
  );
};
