import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Button, MenuItem, Radio, alpha, styled as muStyled, CircularProgress } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import Menu, { MenuProps } from "@mui/material/Menu";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel, {
    FormControlLabelProps
} from "@mui/material/FormControlLabel";
import { withDarkTheme } from '../../Hocs/withDarkTheme';

interface StyledFormControlLabelProps extends FormControlLabelProps {
    checked: boolean;
}

const StyledFormControlLabel = muStyled((props: StyledFormControlLabelProps) => (
    <FormControlLabel {...props} />
))(({ theme, checked }) => ({
    textTransform: 'none',
    "&.MuiFormControlLabel-root .MuiFormControlLabel-label": {
        fontSize: '14px',
        minWidth: '120px',
    },
    ".MuiFormControlLabel-label": checked && {
        color: theme.palette.primary.main,
    }
}));

function MyFormControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}

const StyledButtonBlack = muStyled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    textTransform: 'none',
    '&:hover, &:focus': {
        backgroundColor: theme.palette.background.default,
    },
    '&.Mui-selected': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    '&.Mui-selected:hover, &.Mui-selected:focus': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    '&.MuiPickersDay-root:not(.Mui-selected)': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary
    },
}));

const StyledMenu = muStyled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
        {...props}
    />))(({ theme }) => ({
        "& .MuiPaper-root": {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color: theme.palette.text.primary,
            boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            "& .MuiMenu-list": {
                padding: "1px 0"
            },
            "& .MuiMenuItem-root": {
                paddingTop: 0,
                paddingBottom: 0,
                minHeight: 10,
                "& .MuiSvgIcon-root": {
                    fontSize: 23, // font size radio button icon
                    padding: 0,
                },
                "&:active": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity
                    )
                }
            }
        }
    }));

const StyledProgress = muStyled(CircularProgress)(({ theme }) => ({
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '40%',
    left: '50%',
    marginTop: '15px',
    marginLeft: '-12px',
}));

interface RadioSelectorProps {
    options: any | undefined;
    isLoading: boolean;
    onChange: (value: any) => void;
    defaultPeriodId: any;
}

const RadioSelectorComponent: FC<RadioSelectorProps> = ({ options, defaultPeriodId, isLoading, onChange }): ReactElement => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [radioGroupValue, setRadioGroupValue] = useState("Select Period");
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (options.length && defaultPeriodId) {
            const defaultPeriodID = options.find((option: any) => option?.periodID === defaultPeriodId);
            if(defaultPeriodID){
                setRadioGroupValue(defaultPeriodID.value);
            }
        } else{
            setRadioGroupValue("Select Period");
        }
    }, [options, defaultPeriodId]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setRadioGroupValue(ev.target.value);
        const periodID = options && options.find((option: any) => option.value === ev.target.value)?.periodID;
        onChange(periodID);
    };

    return (
        <div>
            <StyledButtonBlack
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDown />}>
                {radioGroupValue}
            </StyledButtonBlack>
            {isLoading && <StyledProgress size={24} />}

            {!isLoading &&
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button"
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>
                    <RadioGroup
                        name="use-radio-group"
                        value={radioGroupValue}
                        onChange={handleChange}>
                        {
                            options && options?.length && options?.map((option: any, index: number) => {
                                return (
                                    <MenuItem key={index} onClick={handleClose} disableRipple>
                                        <MyFormControlLabel
                                            value={option.value}
                                            label={option.label}
                                            control={<Radio />}
                                        />
                                    </MenuItem>
                                )
                            })
                        }
                    </RadioGroup>
                </StyledMenu>}
        </div>
    )
}

export const RadioSelector = withDarkTheme(RadioSelectorComponent);
