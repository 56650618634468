import { AlertColor, Button, CssBaseline, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateSchoolModal,
  SchoolList,
  DistrictList,
  ToggleHeader,
  AddFloatButton,
  CreateDistrictModal,
  AdvertisementShoolModal,
} from "../Components";
import { ToggleSchoolOptionEnum } from "../Interfaces/SchoolInterface";
import { LayoutContainer } from "../Layout/layout.container";
import { fetchSchools, GetDistricts } from "../Services/SchoolsService";
import { RootState } from "../store";
import {
  AlertState,
  setCustomAlertDetails,
  setPageTitle,
  setShowCustomAlert,
} from "../store/slices/main";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles";
import CustomAlert from "../Components/CustomAlert/CustomAlert";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../store/slices/school.slice";

const styles = {
  newButton: {
    textTransform: "none",
    color: "white",
    height: 48,
    width: 180,
  } as CSSProperties,
};
const Schools = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const { loadingIndicator } = useSelector((state: RootState) => state.main);
  const {
    districts,
    schools,
    successMessage,
    errorMessage,
    loadingIndicator: loadingSchools,
  } = useSelector((state: RootState) => state.school);
  const [selectedToggleOption, setSelectedToggleOption] = useState(
    ToggleSchoolOptionEnum.Schools
  );
  const [openModal, setOpenModal] = useState(false);
  const [openAdvertisementModal, setOpenAdvertisementModal] = useState(false);
  const [openDistrictModal, setOpenDistrictModal] = useState(false);

  useEffect(() => {
    dispatch(setPageTitle("List of schools"));
    dispatch(GetDistricts());
    dispatch(fetchSchools());
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showAlert(errorMessage, "error");
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      showAlert(successMessage, "success");
    }
  }, [successMessage]);

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleOpenModal(value: boolean) {
    if (districts && districts.length > 0) setOpenModal(value);
    else setOpenAdvertisementModal(true);
  }

  function handleOpenDistrictModal(value: boolean) {
    setOpenDistrictModal(value);
  }

  function handleOpenWarningModal(value: ToggleSchoolOptionEnum) {
    setSelectedToggleOption(value);
    setOpenAdvertisementModal(false);
    setOpenDistrictModal(true);
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  return (
    <>
      <CssBaseline />

      <LayoutContainer isRaised={true} sx={{ marginBottom: 8 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={8}>
            <ToggleHeader
              selectedTabOption={selectedToggleOption}
              selectedToggleOption={setSelectedToggleOption}
            />
          </Grid>
          {!isMobile && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={styles.newButton}
                onClick={() =>
                  selectedToggleOption === ToggleSchoolOptionEnum.Schools
                    ? handleOpenModal(true)
                    : handleOpenDistrictModal(true)
                }
              >
                {selectedToggleOption === ToggleSchoolOptionEnum.Schools
                  ? "+ Add new School"
                  : "+ Add new District"}
              </Button>
            </Grid>
          )}
        </Grid>
      </LayoutContainer>

      <LayoutContainer isRaised={true}>
        {selectedToggleOption === ToggleSchoolOptionEnum.Schools ? (
          <>
            <SchoolList
              loadingSchools={loadingSchools}
              schools={schools}
              isMobile={isMobile}
            />
            {isMobile && <AddFloatButton handleOpenModal={handleOpenModal} />}
            <CreateSchoolModal
              // addNewSchool={addNewSchool}
              handleOpenModal={handleOpenModal}
              openModal={openModal}
              schools={schools}
              districts={districts}
            />
            <AdvertisementShoolModal
              handleOpenModal={handleOpenWarningModal}
              openModal={openAdvertisementModal}
            />
          </>
        ) : (
          <>
            <DistrictList
              loadingIndicator={loadingIndicator}
              districts={districts}
              isMobile={isMobile}
            />
            {isMobile && (
              <AddFloatButton handleOpenModal={handleOpenDistrictModal} />
            )}
            <CreateDistrictModal
              handleOpenModal={handleOpenDistrictModal}
              openModal={openDistrictModal}
            />
          </>
        )}
      </LayoutContainer>
      <CustomAlert handleClose={handleCloseAlert} />
    </>
  );
};

export default Schools;
