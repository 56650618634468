import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import UserPool from "../Services/UserPool";
import { LayoutSign } from "../Layout/layout-sign/layout-sign";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";

export default function ChangePassword() {
  const { changePassword, error } = useCognitoAwsV2();
  const [succeedAlert, setSucceedAlert] = React.useState<boolean>(false);
  const [errorAlert, setErrorAlert] = React.useState<boolean>(false);
  const [errorAlertMsg, setErrorAlertMsg] = React.useState<string>("");
  const [cognitoUser, setCognitoUser] = React.useState<any>();

  React.useEffect(() => {
    const cognitoUserData = UserPool.getCurrentUser();
    console.log(cognitoUser);
    setCognitoUser(cognitoUserData);
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data);
    const formData = {
      email: data.get("email")?.toString() || "",
      password: data.get("password")?.toString() || "",
      newPassword: data.get("newPassword")?.toString() || "",
    };

    try {
      await changePassword(formData.password, formData.newPassword);
      setSucceedAlert(true);
      setErrorAlert(false);
    } catch (e: any) {
      console.log(e);
      setErrorAlertMsg(e?.message);
      setErrorAlert(true);
    }
  };

  return (
    <>
      <LayoutSign
        children={
          <Container>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 22,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Change Password
              </Typography>

              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Change your password
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="div">
                      UserName: {cognitoUser?.username}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="email"
                      label="Email"
                      type="text"
                      id="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Current Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="newPassword"
                      label="New Password"
                      type="password"
                      id="newPassword"
                      autoComplete="new-password"
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{ width: 200, marginTop: 40 }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  {"Change Password"}
                </Button>

                <Stack sx={{ width: "100%" }} spacing={2}>
                  {errorAlert && (
                    <Alert severity="error">
                      {errorAlertMsg + " " + error}
                    </Alert>
                  )}
                  {succeedAlert && (
                    <Alert severity="success">
                      Password changed successfully!
                    </Alert>
                  )}
                </Stack>
              </Box>
            </Box>
          </Container>
        }
      ></LayoutSign>
    </>
  );
}
