import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  AlertColor,
  Box,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import isEqual from "date-fns/isEqual";
import { CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CalendarModal, CustomAlert } from "../Components";
import { CustomCalendar } from "../Components/Calendar/CustomCalendar";
import { DesktopCalendar } from "../Components/Calendar/DesktopCalendar";
import { toLocalDateWithoutTime } from "../Helpers/DateFormats";
import { Calendar, DateTypeEnum } from "../Interfaces/CalendarInterface";
import { LayoutContainer } from "../Layout/layout.container";
import { fetchCalendarDaysBySchoolId } from "../Services/CalendarService";
import { fetchDayTypesBySchoolId } from "../Services/ScheduleService";
import { RootState } from "../store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../store/slices/calendar.slice";
import {
  AlertState,
  setCustomAlertDetails,
  setPageTitle,
  setShowCustomAlert,
} from "../store/slices/main";

const styles = {
  listContainer: {
    background: "#282C36",
    borderRadius: "0px 0px 0 0",
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    minHeight: "79vh",
    maxHeight: "79vh",
    zIndex: 3,
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  list: { borderRadius: "5px", padding: 0 } as CSSProperties,
  listItem: {
    background: "#464B5B",
    height: "56px",
  } as CSSProperties,
  divider: { borderColor: "#3C404E", background: "#3C404E" },
};

const CalendarPage = () => {
  const dispatch = useDispatch();
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { calendarDays, loadingIndicator, errorMessage, successMessage } =
    useSelector((state: RootState) => state.calendar);

  const [currentCalendarDay, setCurrentCalendarDay] = useState<
    Calendar | null | undefined
  >(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedDateType, setSelectedDateType] = useState<DateTypeEnum>(
    DateTypeEnum.Holiday
  );
  const [openModal, setOpenModal] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const downMd = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (currentSchool.id) {
      dispatch(fetchCalendarDaysBySchoolId(currentSchool.id));
    }
  }, [currentSchool]);

  useEffect(() => {
    dispatch(setPageTitle("Calendar"));
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showAlert(errorMessage, "error");
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      showAlert(successMessage, "success");
    }
  }, [successMessage]);

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleOpenModal(value: boolean) {
    setOpenModal(value);
  }

  function handleSelectedDate(newDate: any) {
    setSelectedDate(newDate);

    const calendarDay = calendarDays?.find((calendarDay: any) =>
      isEqual(
        toLocalDateWithoutTime(calendarDay.selected_date),
        toLocalDateWithoutTime(newDate)
      )
    );

    if (calendarDay) {
      setSelectedDateType(calendarDay.day_type_enum);
    }

    setCurrentCalendarDay(calendarDay);
    handleOpenModal(true);
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  useEffect(() => {
    if (currentSchool?.id) {
      dispatch(fetchDayTypesBySchoolId(currentSchool.id));
    }
  }, [currentSchool]);

  return (
    <>
      {!downMd ? <div style={{ marginTop: "-80px" }}></div> : <div></div>}
      {isMobile ? (
        <LayoutContainer isRaised={true}>
          {loadingIndicator && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <Box style={styles.listContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomCalendar
                selectedDate={selectedDate}
                calendarDays={calendarDays}
                onChange={handleSelectedDate}
              />
              <CalendarModal
                selectedDate={selectedDate}
                openModal={openModal}
                currentCalendarDay={currentCalendarDay}
                handleOpenModal={handleOpenModal}
                selectedDateType={selectedDateType}
                setSelectedDateType={setSelectedDateType}
                school_id={currentSchool.id as number}
              />
            </LocalizationProvider>
          </Box>
        </LayoutContainer>
      ) : (
        <LayoutContainer isRaised={true}>
          {loadingIndicator ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : (
            <DesktopCalendar
              handleSelectedDate={handleSelectedDate}
              selectedDate={selectedDate}
              openModal={openModal}
              currentCalendarDay={currentCalendarDay}
              handleOpenModal={handleOpenModal}
              selectedDateType={selectedDateType}
              setSelectedDateType={setSelectedDateType}
              calendarDays={calendarDays}
              school_id={currentSchool.id as number}
              onChange={handleSelectedDate}
            />
          )}
        </LayoutContainer>
      )}
      <CustomAlert handleClose={handleCloseAlert} />
    </>
  );
};

export default CalendarPage;
