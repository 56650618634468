import { FC, ReactElement, useEffect, useState } from "react";
import {
    Box,
    LinearProgress,
    Grid,
    TextField,
    InputAdornment,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import "./AttendanceListLocationComponent.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { getStudentsByLocation } from "../../Services/HallPassService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedUser } from "../../store/slices/main";

interface IAttendanceListClass {
    dataFiltered?: ReportAttendanceByCurrentClass[];
    loading?: boolean;
}

const styles = {
    searchContainer: {
        background: "#464B5B",
        padding: "0px",
        height: 50,
        width: "100%",
        borderRadius: "8px 8px 0px 0px ",
    },
};

/**
 *This component is used to display the list
 * of students which that are registered in a certain location (Kiosk Mode)
 * @param param0
 * @returns
 */
export const AttendanceListStaff: FC<IAttendanceListClass> = ({
    dataFiltered,
    loading = false,
}): ReactElement => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    function selectStudent(user: any) {
        dispatch(setSelectedUser(user));
        navigate("/StudentProfile");
    }
    
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    {loading ? (
                        <LinearProgress />
                    ) : (
                        <table className="attendance-list-studentclass2">
                            <tbody>
                                {dataFiltered
                                    ?.filter((x) => {
                                        return x != null;
                                    })
                                    .map((x) => (
                                        <tr key={x.id}>
                                            {/* <td className="studentName"> */}
                                            <td className="studentName" onClick={() => selectStudent(x.user)}>
                                                {x.first_name} {x.last_name} {console.log("student", x)}
                                            </td>
                                            <td className="class-status">
                                                <AccessTimeIcon
                                                    fontSize="small"
                                                    sx={{ color: "#7AC142" }}
                                                />{" "}
                                                {x.timeToNow}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export interface ReportAttendanceByCurrentClass {
    user(user: any): void;
    id: number;
    started_at: string;
    timeToNow: string;
    first_name: string;
    last_name: string;
}
function dispatch(arg0: any) {
    throw new Error("Function not implemented.");
}

