import {
  Alert,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import { LayoutSign } from "../Layout/layout-sign/layout-sign";

function CompleteRegistration() {
  const { email, firstName, lastName } = useParams();
  const { error, isLoading, completeNewPasswordChallenge, setError } =
    useCognitoAwsV2();
  const [formEmail, setFormEmail] = useState<string>("");
  const [formFirstName, setFormFirstName] = useState<string>("");
  const [formLastName, setFormLastName] = useState<string>("");
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [temporaryPassword, setTemporaryPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");

  useEffect(() => {
    try {
      const emailUrlParam = window.atob(`${email}`);
      setFormEmail(emailUrlParam);

      if (firstName || lastName) {
        const firstNameUrlParam = window.atob(`${firstName}`);
        const lastNameUrlParam = window.atob(`${lastName}`);
        setFormFirstName(firstNameUrlParam);
        setFormLastName(lastNameUrlParam);
        setDisableInput(true);
      }

    } catch (error) {
      setError("Invalid URL parameters");
    }
  }, []);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (
      !temporaryPassword ||
      !newPassword ||
      !confirmNewPassword ||
      !formFirstName ||
      !formLastName
    ) {
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match");
      return;
    }

    await completeNewPasswordChallenge(
      formEmail,
      temporaryPassword,
      newPassword,
      formFirstName,
      formLastName,
    );
  }

  return (
    <>
      <LayoutSign
        children={
          <Container>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                User registration
              </Typography>

              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      disabled={disableInput}
                      value={formFirstName}
                      name="firstName"
                      label="First name"
                      type="text"
                      onChange={(event) =>
                        setFormFirstName(event.target.value.trim())
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      value={formLastName}
                      disabled={disableInput}
                      name="oldPassword"
                      label="Last name"
                      type="text"
                      onChange={(event) =>
                        setFormLastName(event.target.value.trim())
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="oldPassword"
                      label="Temporary password"
                      type="password"
                      onChange={(event) =>
                        setTemporaryPassword(event.target.value.trim())
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="New password"
                      type="password"
                      onChange={(event) =>
                        setNewPassword(event.target.value.trim())
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="confirmPassword"
                      label="confirm new password"
                      type="password"
                      onChange={(event) =>
                        setConfirmNewPassword(event.target.value.trim())
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                  <Grid item xs={6}>
                    <Button
                      disabled={
                        isLoading ||
                        !temporaryPassword ||
                        !newPassword ||
                        !confirmNewPassword
                      }
                      type="submit"
                      fullWidth
                      variant="contained"
                    >
                      {"Submit"}
                    </Button>
                  </Grid>
                  <Grid item xs={6} alignContent="flex-end">
                    <Box display="flex" justifyContent="flex-end">
                      <Link href="/" variant="body2">
                        Back to Sign in
                      </Link>
                    </Box>
                  </Grid>
                </Grid>

                <Stack sx={{ width: "100%", marginTop: 3 }} spacing={2}>
                  {error && <Alert severity="error">{error}</Alert>}
                </Stack>
              </Box>
            </Box>
          </Container>
        }
      ></LayoutSign>
    </>
  );
}

export default CompleteRegistration;
