import { FC, ReactElement, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createClass,
  createClassWithUser,
  fetchAllBySchoolIdAndTeacherUsername,
  updateClass,
} from "../../Services/ClassService";
import { fetchPeriodsByDayTypeId } from "../../Services/ScheduleService";
import { Class } from "../../Interfaces/ClassInterface";
import { DayType, Period } from "../../Interfaces/ScheduleInterface";
import { Room } from "../../Interfaces/SchoolInterface";
import { LightModal } from "../LightModal/LightModal";
interface ListProps {
  title: string;
  school_id: number;
  openModal: boolean;
  handleOpenModal: (state: boolean, origin: string) => void;
  dayTypes: DayType[];
  periods?: Period[];
  rooms: Room[];
  classes: Class[];
  selectedClass?: Class;
  styles: any;
}
interface IForm {
  name: string;
  day_type: string;
  period: string;
  room: string;
  description: string;
}

const TeacherModal: FC<ListProps> = ({
  styles,
  title,
  school_id,
  openModal,
  handleOpenModal,
  dayTypes,
  periods,
  rooms,
  selectedClass,
}): ReactElement => {
  const dispatch = useDispatch();
  const { successMessage, savingIndicator } = useSelector(
    (state: RootState) => state.class_
  );
  const { selectedSemester } = useSelector(
    (state: RootState) => state.semester
  );
  const { currentSchool, currentUser } = useSelector((state: RootState) => state.main);

  const schema = yup.object().shape({
    name: yup.string().required("this field is required"),
    day_type: yup.string().required("this field is required"),
    period: yup.string().required("this field is required"),
    room: yup.string().required("this field is required"),
    description: yup.string(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      day_type: "",
      period: "",
      room: "",
      description: "",
    },
  });

  useEffect(() => {
    successMessage !== "" && handleCloseModal();
    reset();
  }, [successMessage]);

  useEffect(() => {
    if (selectedClass?.id) {
      title = "Update Class";
      setValue("name", selectedClass.name);
      setValue("day_type", selectedClass.day_type_id);
      setValue("period", selectedClass.period_id);
      setValue("room", selectedClass.room_id);
      setValue("description", selectedClass.description);
    } else {
      reset();
    }
  }, [selectedClass]);

  useEffect(() => {
    if (watch("day_type")) {
      dispatch(fetchPeriodsByDayTypeId(watch("day_type")));
    }
  }, [watch("day_type")]);

  const formSubmitHandler: SubmitHandler<any> = async (data: IForm) => {
    if (selectedClass?.id) {
      const newForm: Class = {
        id: selectedClass.id,
        name: data.name,
        description: data.description,
        day_type_id: parseInt(`${data.day_type}`),
        period_id: parseInt(`${data.period}`),
        room_id: parseInt(`${data.room}`),
        school_id: school_id,
      };
      await dispatch(updateClass(newForm));
    } else {
      if (!selectedSemester) return;

      const newForm: Class = {
        name: data.name,
        description: data.description,
        day_type_id: parseInt(`${data.day_type}`),
        period_id: parseInt(`${data.period}`),
        room_id: parseInt(`${data.room}`),
        school_id: school_id,
        semester_id: selectedSemester.id,
      };
      await dispatch(createClassWithUser(newForm));
    }
    dispatch(fetchAllBySchoolIdAndTeacherUsername({school_id: currentSchool.id, user_name: currentUser.user_name} as any));
  };

  function handleCloseModal() {
    handleOpenModal(false, "");
  }

  function onBlurDayType() {
    setValue("period", undefined);
  }

  return (
    <>
      <LightModal
        width="650px"
        isOpenModal={openModal}
        handleOpenModal={handleOpenModal}
      >
        <Card>
          <CardHeader title={title} sx={{ mx: 1 }} />
          <form autoComplete="off" onSubmit={handleSubmit(formSubmitHandler)}>
            <CardContent sx={{ mx: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        id="name"
                        label="Enter Class Name"
                        variant="standard"
                        error={!!errors.name}
                        helperText={errors.name ? errors.name?.message : ""}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controller
                    name="day_type"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="day_type"
                        select
                        label="Day Type"
                        error={!!errors.day_type}
                        helperText={
                          errors.day_type ? errors.day_type.message : ""
                        }
                        variant="standard"
                        onBlur={onBlurDayType}
                        fullWidth
                      >
                        {dayTypes.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controller
                    name="period"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="period"
                        select
                        label="Period"
                        error={!!errors.period}
                        helperText={errors.period ? errors.period?.message : ""}
                        variant="standard"
                        fullWidth
                      >
                        {periods?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controller
                    name="room"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="room"
                        select
                        label="Room"
                        error={!!errors.room}
                        helperText={errors.room ? errors.room?.message : ""}
                        variant="standard"
                        fullWidth
                      >
                        {rooms.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="text"
                        id="description"
                        label="Enter Description"
                        variant="standard"
                        error={!!errors.description}
                        helperText={
                          errors.description ? errors.description?.message : ""
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions style={{ padding: 0, margin: 0 }}>
              <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => handleOpenModal(false, "")}
                    disabled={savingIndicator}
                    style={styles.formButton}
                    size="large"
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={styles.formButton}
                    fullWidth
                    size="large"
                  >
                    {selectedClass?.id ? (
                      <div>Update Class</div>
                    ) : (
                      <div>Add Class</div>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            {savingIndicator && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </form>
        </Card>
      </LightModal>
    </>
  );
};

export default TeacherModal;
