import { FC, ReactElement, useState } from "react";
import {
  Box,
  LinearProgress,
  Grid,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { CSSProperties } from "@mui/styles";
import "./RoomManagementComponent.css";
import { useEffect } from "react";
import { deleteRoom, fetchRoomsBySchoolId } from "../../Services/RoomService";
import { PopButtonMenu } from "../PopButtonMenu/PopButtonMenu";
import {
  AddBoxOutlined,
  LibraryAddOutlined,
} from "@mui/icons-material";
import {
  NewRoomPopupButtonMenu,
  PopupMenuOption,
} from "./NewRoomPopupButtonMenu";
import { Room } from "../../Interfaces/SchoolInterface";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";

const buttonMenuOptions: string[] = ["Edit", "Delete", "Cancel"];

const styles = {
  container: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    marginTop: "-50px",
    textAlign: "center",
    minHeight: "79vh",
    maxHeight: "79vh",
    zIndex: 3,
    overflow: "hidden",
  } as CSSProperties,
  headerContainer: {
    color: "white",
    width: "100%",
    padding: "20px",
    textAlign: "left",
  } as CSSProperties,
  listContainer: {
    overflow: "auto",
    minHeight: "70vh",
    maxHeight: "70vh",
    textAlign: "center",
    background: "#3C404E",
    zIndex: 3,
    width: "100%",
  } as CSSProperties,
  list: {
    borderRadius: "5px",
    padding: 0,
  } as CSSProperties,
  listItem: {
    width: "100%",
    background: "#464B5B",
  } as CSSProperties,
  loading: { width: "100%" } as CSSProperties,
  newButton: {
    textTransform: "none",
    color: "white",
    height: 48,
    width: 120,
    marginRight: 15,
  } as CSSProperties,
};

const newButtonmenuProps: PopupMenuOption[] = [
  {
    value: "Massive",
    text: "Multiple Classrooms",
    icon: <LibraryAddOutlined color="primary" />,
  },
  {
    value: "Single",
    text: "Single Classroom",
    icon: <AddBoxOutlined color="primary" />,
  }
];

interface Props {
  isMobile: boolean;
  handleOpenSingleModal: (state: boolean, room?: Room) => void;
  handleOpenMultipleModal: (state: boolean) => void;
}

const RoomManagementComponent: FC<Props> = ({
  isMobile,
  handleOpenSingleModal,
  handleOpenMultipleModal,
}): ReactElement => {
  const dispatch = useDispatch();
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const { rooms, loadingIndicator } = useSelector(
    (state: RootState) => state.room
  );

  const [deleting, setDeleting] = useState<boolean>(false);
  const [roomToDelete, setRoomToDelete] = useState<Room>();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (currentSchool.id) {
      dispatch(fetchRoomsBySchoolId(currentSchool.id));
    }
  }, [currentSchool]);

  function handleNewButtonMenuOptionClick(option: string | null) {
    switch (option) {
      case "Massive":
        handleOpenMultipleModal(true);
        break;
      case "Single":
        handleOpenSingleModal(true);
        break;
    }
  }

  function popUpHandlerClick(
    _: React.MouseEvent<HTMLElement>,
    selectedOption: string | null,
    selectedRoom?: Room
  ) {
    switch (selectedOption) {
      case "Edit":
        handleOpenSingleModal(true, selectedRoom as Room);
        break;
      case "Delete":
        setRoomToDelete(selectedRoom);
        setConfirmDialogOpen(true);
        break;
    }
  }

  async function confirmDialogHandler(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    setDeleting(true);
    await dispatch(deleteRoom(parseInt(`${roomToDelete?.id}`)));
    setDeleting(false);
    setConfirmDialogOpen(false);
  }

  return (
    <Box style={styles.container}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={8}>
          <Typography style={styles.headerContainer}>
            List of the Rooms
          </Typography>
        </Grid>
        {!isMobile && (
          <Grid item>
            <NewRoomPopupButtonMenu
              menuOptions={newButtonmenuProps}
              onClick={(e, option) => handleNewButtonMenuOptionClick(option)}
            ></NewRoomPopupButtonMenu>
          </Grid>
        )}
      </Grid>
      <Grid container>
        {loadingIndicator ? (
          <Box style={styles.loading}>
            <LinearProgress />
          </Box>
        ) : (
          <Box style={styles.listContainer}>
            <List component="nav" style={styles.list}>
              {rooms?.map((item, index) => (
                <div key={index.toString()}>
                  <ListItem style={styles.listItem}>
                    <div className="item-container">
                      <div className="room-name">Room {item.name}</div>
                    </div>
                    <PopButtonMenu
                      menuOptions={buttonMenuOptions}
                      data={item}
                      onClick={popUpHandlerClick}
                    />
                    <ConfirmationDialog
                      open={confirmDialogOpen}
                      title="Delete Room"
                      message="Are you sure you want to delete this room?"
                      onConfirm={confirmDialogHandler}
                      onCancel={() => setConfirmDialogOpen(false)}
                      loading={deleting}
                    />
                  </ListItem>
                </div>
              ))}
            </List>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default RoomManagementComponent;
