import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Popover,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import { WatchLaterOutlined, InfoOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { SchoolSettings } from "../../Interfaces/SchoolInterface";
import { updateSchoolSettingsBySchoolId } from "../../Services/SchoolsService";

const styles = {
  listContainer: {
    backgroundColor: "#3C404E",
    borderRadius: "5px",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    margin: "1rem 0",
    textAlign: "start",
    zIndex: 3,
  } as CSSProperties,
};

interface Props {
  schoolId?: number;
  schoolSettings: SchoolSettings;
  isSaving: boolean;
  isLoading: boolean;
}

const LateBufferForm = ({
  schoolId,
  schoolSettings,
  isSaving,
  isLoading,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>();
  const [lateBufferValue, setLateBufferValue] = useState<number>(0);

  useEffect(() => {
    setLateBufferValue(schoolSettings.late_buffer ?? 0);
  }, [schoolSettings]);

  const handleHover = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (value < 0) return;

    setLateBufferValue(value);
  };

  const handleSubmit = () => {
    if (!schoolId) return;

    const newSettings = {
      ...schoolSettings,
      late_buffer: lateBufferValue,
    };

    dispatch(
      updateSchoolSettingsBySchoolId({
        schoolId,
        settings: newSettings,
      })
    );
  };

  return (
    <Box style={styles.listContainer} flexDirection="column">
      <Grid container p={2}>
        <Grid item xs={1}>
          <WatchLaterOutlined sx={{ fill: "#7AC142" }} />
        </Grid>
        <Grid item xs={10} paddingLeft={1}>
          <Typography fontWeight={500}>Late Buffer</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          textAlign="end"
          aria-owns={anchorElement ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handleHover}
          onMouseLeave={handleClose}
        >
          <InfoOutlined />
        </Grid>

        <Grid item xs={12} mt={1}>
          <Typography fontSize=".8em">Seconds</Typography>
        </Grid>
        <Grid item xs={12} mb={3}>
          <TextField
            type="number"
            variant="standard"
            fullWidth
            placeholder="E.g.: 40"
            value={lateBufferValue}
            onChange={handleChange}
            disabled={isSaving || isLoading}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={{
              textTransform: "capitalize",
              color: "white",
              fontWeight: 400,
            }}
            disabled={isSaving || isLoading}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        disableRestoreFocus
      >
        <Typography
          fontSize=".8em"
          p={2}
          sx={{
            maxWidth: isMobile ? "60vw" : "30vw",
            backgroundColor: "white",
            color: "black",
          }}
        >
          Please enter the amount of time in seconds that you would like to give
          your students to scan into class after the bell rings before they are
          marked late.
        </Typography>
      </Popover>
      {isSaving && <LinearProgress />}
    </Box>
  );
};

export default LateBufferForm;
