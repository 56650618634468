import { AlertColor } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";
import { CURRENT_CLASS, REGISTER_STUDENT } from "../../Helpers/Constants";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { Class } from "../../Interfaces/ClassInterface";
import { School } from "../../Interfaces/SchoolInterface";
import { User } from "../../Interfaces/UserInterface";
import { fetchCurrentClassByRoomId } from "../../Services/ClassService";

export interface AlertState {
  message: string;
  severity: AlertColor;
}

export interface Location {
  id: number
  name: string;
  max_students_allowed: number;
  scan_at_arrival: boolean;
}

interface MainState {
  pageTitle: string;
  pageHeaderChildren: React.ReactNode;
  pageSubtitle: string;
  backwardNavigationRoute: string;
  raiseHeader: boolean;
  loadingIndicator: boolean;
  currentUser: User;
  selectedUser: User;
  currentSchool: School;
  showCustomAlert: boolean;
  customAlertDetails: AlertState;
  currentClass: Class;
  isStaffLocationPage: boolean;
  currentLocation: Location;
}

const initialState: MainState = {
  pageTitle: "",
  pageHeaderChildren: null,
  pageSubtitle: "",
  backwardNavigationRoute: "",
  raiseHeader: false,
  loadingIndicator: false,
  currentUser: {} as User,
  selectedUser: {} as User,
  currentSchool: {} as School,
  showCustomAlert: false,
  customAlertDetails: { message: "", severity: "error" } as AlertState,
  currentClass: {} as Class,
  isStaffLocationPage: false,
  currentLocation: {} as Location,
};

export const mainSlice = createSlice({
  name: "main",
  initialState: initialState,
  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
    setPageHeaderChildren: (state, action) => {
      state.pageHeaderChildren = action.payload;
    },
    setPageSubtitle: (state, action) => {
      state.pageSubtitle = action.payload;
    },
    setBackwardNavigationRoute: (state, { payload }) => {
      state.backwardNavigationRoute = payload;
    },
    setRaiseHeader: (state, { payload }) => {
      state.raiseHeader = payload;
    },
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
    setCurrentSchool: (state, { payload }) => {
      state.currentSchool = payload;
    },
    setCustomAlertDetails: (state, { payload }) => {
      state.customAlertDetails = payload;
    },
    setShowCustomAlert: (state, { payload }) => {
      state.showCustomAlert = payload;
    },
    setCurrentClass: (state, { payload }) => {
      state.currentClass = payload;
    },
    setIsStaffLocationPage: (state, action) => {
      state.isStaffLocationPage = action.payload;
    },
    setCurrentLocation: (state, action) => {
      state.currentLocation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentClassByRoomId.fulfilled, (state, { payload }) => {
      state.currentClass = payload;
      window.localStorage.setItem(CURRENT_CLASS, JSON.stringify(payload));
    }), builder.addCase(fetchCurrentClassByRoomId.rejected, () => {
      window.localStorage.setItem(REGISTER_STUDENT, "TRUE");
    });
  },
});

export default mainSlice.reducer;
export const {
  setPageTitle,
  setPageHeaderChildren,
  setPageSubtitle,
  setBackwardNavigationRoute,
  setRaiseHeader,
  setCurrentUser,
  setSelectedUser,
  setCurrentSchool,
  setCustomAlertDetails,
  setShowCustomAlert,
  setCurrentClass,
  setIsStaffLocationPage,
  setCurrentLocation,
} = mainSlice.actions;
