import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react'
import { Box, Button, Divider, Grid, InputAdornment, LinearProgress, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { NativeSelect, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from '@material-ui/core/styles'
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../store';
import { getOverallStudentEfficiencyAllClassesBySemester } from '../../Services/AttendanceService';
import { setPageTitle, setSelectedUser } from "../../store/slices/main";
import { getUserByStudentIdNumber } from '../../Services/UsersService';
import { User } from '../../Interfaces/UserInterface';
import { Search } from '@mui/icons-material';
import { setSelectedSemester, setEfficiencyDataSemesterAllSemester } from "../../store/slices/semester.slice";
import { Semester } from "../../Interfaces/SemesterInterface";
import {
    fetchActiveSemestersBySchoolId,
    fetchAllSemestersBySchoolId,
} from '../../Services/SemestersService';



interface ListProps {
    loadingUtilization: boolean;
    isMobile: boolean;
    students: User[];
}


const CustomSelect = styled(NativeSelect)`
  border: none;
  padding: 2px 7px;
  font-weight: 500;
  color: black;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 2px;

  svg {
    color: #7ac142;
  }

  ::before,
  ::after {
    display: none;
  }

  & option {
    background-color: #fff !important;
  }
`;



export const ClassTimeList: FC<ListProps> = ({ loadingUtilization, students, isMobile }) => {
    const Loading = () => <>Loading ...</>;
    const styles = {
        listContainer: {
            background: "#3C404E",
            borderRadius: "5px 5px 0 0",
            boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
            overflow: "auto",
            marginTop: "-50px",
            textAlign: "center",
            minHeight: "79vh",
            maxHeight: "79vh",
            zIndex: 3,
            // width: "90%",
            width: isMobile ? "98%" : "90%",
            maxWidth: isMobile ? "98%" : "90%",
        } as CSSProperties,
        loading: { width: "100%" } as CSSProperties,
        list: { borderRadius: "5px", padding: 0, margin: 0 } as CSSProperties,
        listItem: {
            background: "#464B5B",
            height: "auto",
            padding: "8px",
            borderBottom: "1px solid #3C404E",
    
    
        } as CSSProperties,
        divider: { borderColor: "#3C404E", background: "#3C404E" },
        clickableText: {
            cursor: "pointer",
            color: "#7AC142",
            fontSize: "15px",
            lineHeight: "50px",
        } as CSSProperties,
        searchContainer: {
            background: "#3C404E",
            padding: "10px",
            height: 50,
            width: "100%",
            borderRadius: "8px 8px 0px 0px ",
        } as CSSProperties,
        selectContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        } as CSSProperties,
    
    };


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentSchool } = useSelector((state: RootState) => state.main);
    const [studentUser, setStudentUser] = useState<User | null>(null);
    const [efficiencyData, setEfficiencyData] = useState<Record<number, number>>({});
    const [loadingEfficiency, setLoadingEfficiency] = useState(false);
    const { selectedSemester, allSemesters, semesters, loadingIndicator } = useSelector((state: RootState) => state.semester);
    const [selectedSemesterId, setSelectedSemesterId] = useState<number | undefined>(selectedSemester?.id);
    const [searchInput, setSearchInput] = useState("");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
    const { efficiencyDataSemester, efficiencyDataSemesterAllSemester } = useSelector((state: RootState) => state.semester);
    const [hasFetchedEfficiencyData, setHasFetchedEfficiencyData] = useState(false);
    const [semesterEfficiencyData, setSemesterEfficiencyData] = useState<Record<number, Record<number, number>>>({});

    React.useEffect(() => {
        dispatch(setPageTitle("Efficiency Report"));
    }, []);


    useEffect(() => {
        if (currentSchool.id) {
            dispatch(fetchActiveSemestersBySchoolId(currentSchool.id));
            dispatch(fetchAllSemestersBySchoolId(currentSchool.id));
        }
    }, [currentSchool]);


    useEffect(() => {
        if (allSemesters.length > 0) {
            setSelectedSemesterId(allSemesters[0].id);
        }
    }, [allSemesters]);

    useEffect(() => {
        setEfficiencyData(efficiencyDataSemester)
    }, [efficiencyDataSemester]);

    function getUserByStudentId(singleStudentId: string) {
        const fetchSelectedUser = async () => {
            try {
                const user = await getUserByStudentIdNumber(singleStudentId);
                // console.log('***result***', user);
                if (user) {
                    dispatch(setSelectedUser(user));
                    navigate("/AdminStudentProfile");
                } else {
                    setStudentUser(null);
                }
            } catch (err) {
                console.error(err);
                setStudentUser(null);
            }
        }

        fetchSelectedUser();
    }



    const filterStudents = (input: string) => {
        return students.filter((student) => {
            const fullName = `${student.first_name} ${student.last_name}`;
            return fullName.toLowerCase().includes(input.toLowerCase());
        });
    };

    let filteredStudents = useMemo(() => filterStudents(searchInput), [searchInput, students]);

    useEffect(() => {
        if (filteredStudents.length > 0) {
            const user = filteredStudents[0];
            if (user && user.id) {
                setSelectedUser(user);
            }
        }
    }, [filteredStudents]);


    // const sortStudentsByEfficiency = (students: User[], sortOrder: "asc" | "desc") => {
    //     const sortedStudents = [...students];
    //     sortedStudents.sort((a, b) => {
    //         const efficiencyA = efficiencyData[a.id!] || 0;
    //         const efficiencyB = efficiencyData[b.id!] || 0;
    //         if (sortOrder === "asc") {
    //             return efficiencyA - efficiencyB;
    //         } else {
    //             return efficiencyB - efficiencyA;
    //         }
    //     });
    //     return sortedStudents;
    // };

    const sortStudentsByEfficiency = (students: User[], sortOrder: "asc" | "desc") => {
        const sortedStudents = [...students];
        
        sortedStudents.sort((a, b) => {
            // Check if both student IDs are defined
            if (a.id !== undefined && b.id !== undefined) {
                const efficiencyA = efficiencyData[a.id] || 0;
                const efficiencyB = efficiencyData[b.id] || 0;
                
                if (sortOrder === "asc") {
                    return efficiencyA - efficiencyB;
                } else {
                    return efficiencyB - efficiencyA;
                }
            }
            
            // Default sort condition when IDs are not defined.
            // Adjust as needed; currently, it will keep the original order for these cases.
            return 0;
        });
    
        return sortedStudents;
    };



    const handleEfficiencyClick = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };


    useEffect(() => {
        const fetchEfficiencyDataForSemester = async (semesterId: any) => {
          try {
            setLoadingEfficiency(true);
            const studentsWithEfficiency = students.filter((student) => student.id);
            const efficiencies = await Promise.all(
              studentsWithEfficiency.map(async (student) => {
                let efficiency = await getOverallStudentEfficiencyAllClassesBySemester(
                  student.id,
                  currentSchool.id,
                  semesterId
                );
                if (
                  efficiency === null ||
                  efficiency === undefined ||
                  isNaN(efficiency) ||
                  efficiency == 'NaN'
                ) {
                  efficiency = 0;
                }
                return [student.id, efficiency];
              })
            );
      
            const efficiencyMap : any = {};
            efficiencies.forEach(([studentId, efficiency]) => {
              efficiencyMap[studentId] = efficiency;
            });
      
            setSemesterEfficiencyData((prev) => ({
              ...prev,
              [semesterId]: efficiencyMap,
            }));
          } catch (error) {
            console.error(error);
          } finally {
            setLoadingEfficiency(false);
          }
        };
        if (
            !hasFetchedEfficiencyData &&
            allSemesters &&
            currentSchool.id &&
            students &&
            sortOrder &&
            Object.keys(efficiencyDataSemesterAllSemester).length == 0
          ) {
            const fetchPromises = allSemesters.map((semester) => {
              return fetchEfficiencyDataForSemester(semester.id);
            });
          
            Promise.all(fetchPromises)
              .then(() => {
                setHasFetchedEfficiencyData(true)
              })
              .catch((error) => {
                console.error("Error", error);
              });
          }
      }, [allSemesters, currentSchool.id, students, sortOrder, efficiencyDataSemesterAllSemester]);

    useEffect(() => {
        if(Object.keys(efficiencyDataSemesterAllSemester).length > 0)
            setSemesterEfficiencyData(efficiencyDataSemesterAllSemester)
    }, [efficiencyDataSemesterAllSemester]);
    
    useEffect(() => {
        if(hasFetchedEfficiencyData){
            dispatch(setEfficiencyDataSemesterAllSemester(semesterEfficiencyData))
        }
    }, [hasFetchedEfficiencyData]);

    const sortedStudents = useMemo(() => sortStudentsByEfficiency(filteredStudents, sortOrder), [
        filteredStudents,
        sortOrder,
        efficiencyData,
    ]);


    const SelectOptions = ({ semesters }: { semesters: Semester[] }) => (
        <>
            {semesters.map((semester) => (
                <option key={semester.id} value={semester.id}>
                    {semester.name}
                </option>
            ))}
        </>
    );



    useEffect(() => {
        if (selectedSemester && Object.keys(selectedSemester).length > 0) {
            setSelectedSemesterId(selectedSemester.id);
        } else setSelectedSemesterId(allSemesters[0]?.id);
    }, [selectedSemester]);

    const handleSemesterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const semesterId = parseInt(event.target.value);
        const semester = allSemesters.find((semester) => semester.id === semesterId);
        setSelectedSemesterId(semesterId);
        dispatch(setSelectedSemester(semester));
        setEfficiencyData(semesterEfficiencyData[semesterId] || {});
    };




    const EfficiencyItems = useMemo(
        () =>
            sortedStudents.map((item: User, index) => (
                <React.Fragment key={item.id}>
                    {/* {console.log("item", item)} */}
                    <ListItem style={styles.listItem}>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: "subtitle2",
                                fontWeight: "500",
                                fontSize: "1rem",
                                marginBottom: "0",
                                color: "textPrimary",
                            }}
                            primary={

                                <React.Fragment>
                                    <Grid container spacing={0} mt={0}
                                        pt={0} pb={0}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item xs={5} md={5} pt={0}>
                                            <Typography
                                                onClick={() => item.student_id_number && getUserByStudentId(item.student_id_number)}
                                                component="div"
                                                align="left"
                                                // variant="subtitle2"
                                                style={styles.clickableText}
                                            >
                                                {item.first_name + " " + item.last_name}
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={3} md={3} pt={0}>
                                            <Typography
                                                component="div"
                                                align="left"
                                                variant="subtitle2"
                                                style={{ fontSize: "15px", marginLeft: "15px" }}>
                                                {item.grade || '6'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} md={4} pt={0}
                                            sx={{
                                                paddingTop: "0",
                                            }}>
                                            
                                            {loadingUtilization ? (
                                                <LinearProgress />
                                            ) : (
                                                <Typography
                                                    component="div"
                                                    align="left"
                                                    variant="subtitle2"
                                                    style={{ fontSize: "15px", marginLeft: "15px" }}
                                                >
                                                    {item.id !== undefined && !isNaN(Math.round(efficiencyData[item.id])) ? (
                                                    Math.round(efficiencyData[item.id]) + ' %'
                                                    ) : (
                                                    <Loading />
                                                    )}
                                                </Typography>
                                            )}
                                        </Grid>

                                    </Grid>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider key={`${item.id}${index}`} style={styles.divider} />
                </React.Fragment>
            )),
        // [filterStudents]
        [sortedStudents]
    );


    return (
        <>
            <Box pl={isMobile ? 0 : 2}>
                <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={12}>
                        {isMobile && (
                            <IconButton
                                component="span"
                                size="small"
                                sx={{
                                    background: (theme) => theme.palette.primary.main,
                                    color: 'white',
                                    width: '30px',
                                    height: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingLeft: '13px',
                                }}
                                onClick={() => navigate(-1)}
                                disableRipple={true}
                            >
                                <ArrowBackIosIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </Box>

            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Typography
                        component="div"
                        align="center"
                        variant="subtitle2"
                        style={{ fontSize: '18px', lineHeight: '48px', marginRight: 'auto' }}
                    ></Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box style={styles.selectContainer}>
                        <CustomSelect value={selectedSemesterId || ''} IconComponent={ExpandMoreIcon} onChange={handleSemesterChange}>
                            {loadingIndicator || !allSemesters?.length ? (
                                <option value="">Loading...</option>
                            ) : (
                                <SelectOptions semesters={allSemesters} />
                            )}
                        </CustomSelect>
                    </Box>
                </Grid>
            </Grid>

            <Grid container style={{ marginTop: '2px' }}>
                <Grid item xs={12} sm={12}>
                    <Box style={styles.searchContainer}>
                        <TextField
                            placeholder="Search by name"
                            variant="standard"
                            fullWidth
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Box pl={2} style={{ width: '100%' }}>
                <Grid
                    container
                    spacing={1}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <Grid item xs={5} sm={5}>
                        <Typography
                            component="div"
                            align="left"
                            variant="subtitle2"
                            style={{ fontSize: '15px', lineHeight: '50px' }}
                        >
                            Name
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Typography
                            component="div"
                            align="left"
                            variant="subtitle2"
                            style={{ fontSize: '15px', lineHeight: '50px' }}
                        >
                            Grade
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <Typography
                            component="div"
                            align="left"
                            variant="subtitle2"
                            style={styles.clickableText}
                            onClick={handleEfficiencyClick}
                        >
                            Efficiency
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Grid
                container
                spacing={1}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Grid item xs={12} style={{ width: '100%' }}>
                    <List sx={{ padding: 2 }} style={styles.list}>
                        {EfficiencyItems}
                    </List>
                </Grid>
            </Grid>
        </>
    );
}