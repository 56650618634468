import { FC, ReactElement, useEffect } from "react";
import { CssBaseline, Box, AlertColor } from "@mui/material";
import { LayoutContainer } from "../Layout/layout.container";
import RoomManagementComponent from "../Components/RoomManagement/RoomManagementComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  setPageTitle,
  setShowCustomAlert,
  setCustomAlertDetails,
  AlertState,
} from "../store/slices/main";
import { AddFloatButton } from "../Components";
import RoomManagementModal from "../Components/RoomManagement/RoomManagementModal";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomAlert from "../Components/CustomAlert/CustomAlert";
import { RootState } from "../store";
import { setErrorMessage, setSuccessMessage } from "../store/slices/room.slice";
import { BaseMenuDialog } from "../Components/BaseMenuDialog/BaseMenuDialog";
import CreateMultipleRoomsModal from "../Components/RoomManagement/CreateMultipleRoomsModal";
import { Room } from "../Interfaces/SchoolInterface";

enum menuDialogItemsEnum {
  Massive = "Add multiple classrooms",
  Single = "Add single classroom",
}

const RoomManagementPage: FC = (): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const [openRoomManagementModal, setOpenRoomManagementModal] = useState(false);
  const [openCreateMultipleRoomsModal, setOpenCreateMultipleRoomsModal] =
    useState(false);
  const { successMessage, errorMessage } = useSelector(
    (state: RootState) => state.room
  );

  const [openMenuDialog, setOpenMenuDialog] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<Room>();

  useEffect(() => {
    dispatch(setPageTitle("Room Management"));
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showAlert(errorMessage, "error");
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      showAlert(successMessage, "success");
    }
  }, [successMessage]);

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleOpenSingleModal(value: boolean, room?: Room) {
    setSelectedRoom(room);
    setOpenRoomManagementModal(value);
  }

  function handleOpenMultipleModal(value: boolean) {
    setOpenCreateMultipleRoomsModal(value);
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setCustomAlertDetails({}));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  const handleCloseMenuDialog = (value: string) => {
    const enumValue = Object.values(menuDialogItemsEnum).find(
      (s) => s === value
    ) as menuDialogItemsEnum;
    setOpenMenuDialog(false);

    switch (enumValue) {
      case menuDialogItemsEnum.Massive:
        setOpenCreateMultipleRoomsModal(true);
        break;
      case menuDialogItemsEnum.Single:
        handleOpenSingleModal(true);
        break;
    }
  };

  return (
    <>
      <CssBaseline />
      <LayoutContainer isRaised={true}>
        <Box>
          <RoomManagementComponent
            isMobile={isMobile}
            handleOpenSingleModal={handleOpenSingleModal}
            handleOpenMultipleModal={handleOpenMultipleModal}
          ></RoomManagementComponent>
          {isMobile && (
            <AddFloatButton handleOpenModal={() => setOpenMenuDialog(true)} />
          )}
        </Box>
      </LayoutContainer>
      <BaseMenuDialog
        items={Object.values(menuDialogItemsEnum)}
        onClose={handleCloseMenuDialog}
        open={openMenuDialog}
      />
      <RoomManagementModal
        selectedRoom={selectedRoom}
        openModal={openRoomManagementModal}
        handleOpenModal={handleOpenSingleModal}
      ></RoomManagementModal>
      <CreateMultipleRoomsModal
        openModal={openCreateMultipleRoomsModal}
        handleOpenModal={handleOpenMultipleModal}
      ></CreateMultipleRoomsModal>
      <CustomAlert handleClose={handleCloseAlert} />
    </>
  );
};

export default RoomManagementPage;
