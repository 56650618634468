import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { School } from "../../Interfaces/SchoolInterface";
import { GroupEnum, User } from "../../Interfaces/UserInterface";
import { deleteUser, fetchProfilePicture } from "../../Services/UsersService";
import { RootState } from "../../store";
import {
  setErrorMessage,
  setSelectedUserStore,
} from "../../store/slices/user.slice";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { PopButtonMenu } from "../PopButtonMenu/PopButtonMenu";

const buttonMenuOptions: string[] = ["Edit", "Delete"];

const confirmationDialogData: any = [
  {
    title: "Edit User",
    message: "Are you sure you want to edit this user?",
  },
  {
    title: "Delete User",
    message: "Are you sure you want to delete this user?",
  },
];

const defaultUser: User = {
  first_name: "",
  last_name: "",
  email: "",
  user_name: "",
  group: GroupEnum.None,
  schools: [] as School[],
};

const UserDataText = ({
  user,
  showEmail,
}: {
  user: User;
  showEmail: boolean;
}) => (
  <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
    <span style={{ flex: 1 }}>{`${user.first_name} ${user.last_name}`}</span>

    {showEmail && (
      <span
        style={{
          fontSize: ".9rem",
          color: "#ccc",
          marginLeft: "2rem",
          flex: 4,
        }}
      >
        {user.email}
      </span>
    )}
  </div>
);

const UserGroupText = ({ group }: { group: string }) => (
  <span
    style={{
      fontSize: ".9rem",
      color: "#7AC142",
    }}
  >
    {group}
  </span>
);

interface ListProps {
  user: User;
  isMobile: boolean;
  group?: string;
  handleOpenModal?: (state: boolean) => void;
}

const UserListItem: FC<ListProps> = ({
  user,
  isMobile,
  group,
  handleOpenModal,
}: ListProps) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [confirmationDialog, setConfirmationDialog] = useState<any>(
    confirmationDialogData[0]
  );

  const { errorMessage } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (!user.profile_picture_encoded && user.profile_picture) {
      dispatch(fetchProfilePicture(user));
    }
  }, []);

  const popUpHandlerClick = async (
    event: React.MouseEvent<HTMLElement>,
    selectedOption: string | null,
    selectedUser?: User
  ) => {
    event.preventDefault();

    if (!selectedOption) return;

    if (selectedUser) {
      setSelectedUser(selectedUser);
    }

    if (selectedOption === "Cancel") {
      setConfirmationDialogOpen(false);
      return;
    } else if (selectedOption === "Edit") {
      await dispatch(setSelectedUserStore(selectedUser));
      handleOpenModal && handleOpenModal(true);
      return;
    }

    setConfirmationDialog(
      confirmationDialogData[buttonMenuOptions.indexOf(selectedOption)]
    );
    setConfirmationDialogOpen(true);
  };

  const confirmationDialogHandler = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    try {
      event.preventDefault();

      if (selectedUser) {
        setDeleting(true);
        await dispatch(deleteUser(selectedUser.user_name));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSelectedUser(defaultUser);
      setDeleting(false);
      setConfirmationDialogOpen(false);
    }
  };

  function handleCloseAlert() {
    dispatch(setErrorMessage(""));
  }

  return (
    <ListItem
      style={{
        background: "#464B5B",
        height: "80px",
      }}
    >
      <ListItemAvatar>
        <Avatar alt={user.first_name} src={user.profile_picture_encoded} />
      </ListItemAvatar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <ListItemText
          sx={{ margin: 0, padding: 0 }}
          primary={<UserDataText user={user} showEmail={!isMobile} />}
        />
        {group && <ListItemText secondary={<UserGroupText group={group} />} />}
      </Box>
      <PopButtonMenu
        menuOptions={buttonMenuOptions}
        onClick={popUpHandlerClick}
        data={user}
      />
      {/* Confirm Delete dialog */}
      <ConfirmationDialog
        open={confirmationDialogOpen}
        title={confirmationDialog.title}
        message={confirmationDialog.message}
        onConfirm={confirmationDialogHandler}
        onCancel={() => setConfirmationDialogOpen(false)}
        loading={deleting}
      />
      {/* Deletion error alert dialog */}
      <ConfirmationDialog
        open={Boolean(errorMessage && errorMessage.length)}
        title="Warning"
        message={errorMessage}
        onConfirm={handleCloseAlert}
        loading={deleting}
      />
    </ListItem>
  );
};

UserListItem.propTypes = {};

export default UserListItem;
