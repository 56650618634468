import {
  EmailOutlined,
  LockOutlined,
  PersonOutlineOutlined,
  SchoolOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { CustomButton } from "../Components";
import { LayoutSign } from "../Layout/layout-sign/layout-sign";
import { LayoutContainer } from "../Layout/layout.container";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import { updateUser } from "../Services/UsersService";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { User } from "../Interfaces/UserInterface";
import { emailRegex } from "../Helpers/Constants";

interface IFormInputs {
  firstName: string;
  lastName: string;
  email: string;
  studentFacingName: string;
  school: string;
  currentPass: string;
  pass: string;
  confirm: string;
}

const schema = yup.object().shape({
  firstName: yup.string().required("This field is required"),
  lastName: yup.string().required("This field is required"),
  email: yup
    .string()
    .matches(emailRegex, "Invalid email format")
    .required("This field is required"),
  studentFacingName: yup.string().required("This field is required"),
  school: yup.string().required("This field is required"),
  currentPass: yup.string().required("This field is required"),
  pass: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/,
      "The password must be 8 digits, some capital letters and a special character"
    )
    .required("This field is required"),
  confirm: yup
    .string()
    .oneOf([yup.ref("pass"), null], "Passwords must match")
    .required("This field is required"),
});

export const TeachersRegistration: FC<any> = (): ReactElement => {
  const dispatch = useDispatch();
  const { cipher_params } = useParams();
  const { error, completeNewPasswordChallenge } = useCognitoAwsV2();
  const [userName, setUserName] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordNew, setShowPasswordNew] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] =
    useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IFormInputs>({
    firstName: "",
    lastName: "",
    email: "",
    studentFacingName: "",
    school: "",
    currentPass: "",
    pass: "",
    confirm: "",
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (cipher_params) {
      let decryptParams = atob(cipher_params);
      decryptParams = decryptParams.replace(/\+/g, "®"); // TODO: Character plus is avoided by params.get function

      const params = new URLSearchParams(decryptParams);

      const firstName = params.get("fn")?.replace(/®/g, "+") || ""; // TODO: Character plus is avoided
      const lastName = params.get("ln")?.replace(/®/g, "+") || "";
      const email = params.get("em")?.replace(/®/g, "+") || "";
      const schoolName = params.get("sch")?.replace(/®/g, "+") || "";
      const userName = params.get("us")?.replace(/®/g, "+") || "";

      const formNewData = {
        firstName,
        lastName,
        email,
        studentFacingName: "",
        school: schoolName,
        currentPass: "",
        pass: "",
        confirm: "",
      };

      setInitialValues(formNewData);
      setUserName(userName);
      reset(formNewData);
    }
  }, [cipher_params]);

  const formSubmitHandler: SubmitHandler<any> = async (data: IFormInputs) => {
    try {
      const { email, currentPass, pass } = data;

      await completeNewPasswordChallenge(email, currentPass, pass);

      if (error) return;

      const updateUserObj = {
        first_name: data.firstName,
        last_name: data.lastName,
        nickname: data.studentFacingName,
        user_name: userName,
      };

      dispatch(updateUser(updateUserObj as User));
    } catch (err) {
      console.log(err);
    }
  };

  const styles = {
    boxStyle: {
      display: "flex",
      alignItems: "flex-end",
      mt: 3,
    },
    iconStyle: {
      color: "rgba(185,187,190,255)",
      mr: 1,
      my: 0.5,
    },
  };

  return (
    <>
      <LayoutSign
        children={
          <LayoutContainer>
            <Typography component="h2" variant="h5" sx={{ mt: -10 }}>
              Teachers
            </Typography>
            <Typography component="h2" variant="h5">
              Registration
            </Typography>
            <form onSubmit={handleSubmit(formSubmitHandler)}>
              <Box sx={styles.boxStyle}>
                <PersonOutlineOutlined sx={styles.iconStyle} />
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={initialValues.firstName}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="firstName"
                      label="First Name"
                      variant="standard"
                      error={!!errors.firstName}
                      helperText={
                        errors.firstName ? errors.firstName?.message : ""
                      }
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box sx={styles.boxStyle}>
                <PersonOutlineOutlined sx={styles.iconStyle} />
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={initialValues.lastName}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="lastName"
                      label="Last Name"
                      variant="standard"
                      error={!!errors.lastName}
                      helperText={
                        errors.lastName ? errors.lastName?.message : ""
                      }
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box sx={styles.boxStyle}>
                <EmailOutlined sx={styles.iconStyle} />
                <Controller
                  name="email"
                  control={control}
                  defaultValue={initialValues.email}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="email"
                      type="email"
                      label="Email"
                      variant="standard"
                      disabled={true}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email?.message : ""}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box sx={styles.boxStyle}>
                <PersonOutlineOutlined sx={styles.iconStyle} />
                <Controller
                  name="studentFacingName"
                  control={control}
                  defaultValue={initialValues.studentFacingName} //"(e.g. Ms. Smith)"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="studentFacingName"
                      label="Student Facing Name"
                      variant="standard"
                      placeholder="(e.g. Ms. Smith)"
                      error={!!errors.studentFacingName}
                      helperText={
                        errors.studentFacingName
                          ? errors.studentFacingName?.message
                          : ""
                      }
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box sx={styles.boxStyle}>
                <SchoolOutlined sx={styles.iconStyle} />
                <Controller
                  name="school"
                  control={control}
                  defaultValue={initialValues.school}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="school"
                      label="School"
                      disabled={true}
                      variant="standard"
                      error={!!errors.school}
                      helperText={errors.school ? errors.pass?.school : ""}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box sx={styles.boxStyle}>
                <LockOutlined sx={styles.iconStyle} />
                <Controller
                  name="currentPass"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="currentPass"
                      type={showPassword ? "text" : "password"}
                      variant="standard"
                      label="Current Password"
                      error={!!errors.currentPass}
                      helperText={
                        errors.currentPass ? errors.currentPass?.message : ""
                      }
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ cursor: "pointer" }}
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined color="disabled" />
                            ) : (
                              <VisibilityOutlined color="disabled" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={styles.boxStyle}>
                <LockOutlined sx={styles.iconStyle} />
                <Controller
                  name="pass"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="pass"
                      type={showPasswordNew ? "text" : "password"}
                      label="New Password"
                      variant="standard"
                      error={!!errors.pass}
                      helperText={errors.pass ? errors.pass?.message : ""}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => setShowPasswordNew(!showPasswordNew)}
                            style={{ cursor: "pointer" }}
                          >
                            {showPasswordNew ? (
                              <VisibilityOffOutlined color="disabled" />
                            ) : (
                              <VisibilityOutlined color="disabled" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={styles.boxStyle}>
                <LockOutlined sx={styles.iconStyle} />
                <Controller
                  name="confirm"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="confirm"
                      label="Confirm New Password"
                      type={showPasswordConfirm ? "text" : "password"}
                      variant="standard"
                      error={!!errors.confirm}
                      helperText={errors.confirm ? errors.confirm?.message : ""}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() =>
                              setShowPasswordConfirm(!showPasswordConfirm)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {showPasswordConfirm ? (
                              <VisibilityOffOutlined color="disabled" />
                            ) : (
                              <VisibilityOutlined color="disabled" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>

              {error && (
                <Stack sx={{ width: "100%", mt: 2, mb: 0 }} spacing={2}>
                  <Alert severity="error" variant="outlined">
                    {error}
                  </Alert>
                </Stack>
              )}

              <CustomButton
                text="Register"
                type="submit"
                sx={{ mt: 5, mb: 1 }}
              />
            </form>
          </LayoutContainer>
        }
      ></LayoutSign>
    </>
  );
};

export default TeachersRegistration;
