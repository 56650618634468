import { createSlice } from "@reduxjs/toolkit";
import { DayType, Period } from "../../Interfaces/ScheduleInterface";
import {
  createDayType,
  createPeriod,
  editDayType,
  fetchDayTypesBySchoolId,
  fetchPeriodsByDayTypeId,
  fetchPeriodsBySchoolID,
  fetchPeriodsBySelectedDateChar,
} from "../../Services/ScheduleService";

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState: {
    dayTypes: [] as DayType[],
    periods: [] as Period[],
    dayTypesLoadingIndicator: false,
    dayTypesSavingIndicator: false,
    periodsLoadingIndicator: false,
    periodsSavingIndicator: false,
    periodsForAttendance: [] as Period[],
    errorMessage: "",
    successMessage: "",
  },
  reducers: {
    setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    cleanPeriods: (state) => {
      state.periods = [];
    },
  },
  extraReducers: (builder) => {
    /* #region  DayTypes */
    builder.addCase(fetchDayTypesBySchoolId.pending, (state) => {
      state.successMessage = state.errorMessage = "";
      state.dayTypesLoadingIndicator = true;
    }),
      builder.addCase(
        fetchDayTypesBySchoolId.fulfilled,
        (state, { payload }) => {
          state.dayTypes = payload;
          state.dayTypesLoadingIndicator = false;
        }
      ),
      builder.addCase(fetchDayTypesBySchoolId.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.dayTypesLoadingIndicator = false;
      });

    builder.addCase(createDayType.pending, (state) => {
      state.successMessage = state.errorMessage = "";
      state.dayTypesSavingIndicator = true;
    }),
      builder.addCase(createDayType.fulfilled, (state, { payload }) => {
        state.dayTypes.push(payload);
        state.successMessage = "Day Type created successfully";
        state.dayTypesSavingIndicator = false;
      }),
      builder.addCase(createDayType.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.dayTypesSavingIndicator = false;
      });

      builder.addCase(editDayType.pending, (state) => {
        state.successMessage = state.errorMessage = "";
        state.dayTypesSavingIndicator = true;
      }),
        builder.addCase(editDayType.fulfilled, (state, { payload }) => {
          const index = state.dayTypes.findIndex(
            (dayType) => dayType.id === payload.id
          );
          state.dayTypes[index] = payload;
          state.successMessage = "Day Type updated successfully";
          state.dayTypesSavingIndicator = false;
        }),
        builder.addCase(editDayType.rejected, (state, { error }) => {
          state.errorMessage = `${error.message}`;
          state.dayTypesSavingIndicator = false;
        });
    /* #endregion */

    /* #region  Periods */
    builder.addCase(fetchPeriodsByDayTypeId.pending, (state) => {
      state.successMessage = state.errorMessage = "";
      state.periodsLoadingIndicator = true;
    }),
      builder.addCase(
        fetchPeriodsByDayTypeId.fulfilled,
        (state, { payload }) => {
          state.periods = payload;
          state.periodsLoadingIndicator = false;
        }
      ),
      builder.addCase(fetchPeriodsByDayTypeId.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.periodsLoadingIndicator = false;
      });

    builder.addCase(createPeriod.pending, (state) => {
      state.successMessage = state.errorMessage = "";
      state.periodsSavingIndicator = true;
    }),
      builder.addCase(createPeriod.fulfilled, (state, { payload }) => {
        state.periods.push(payload);
        state.successMessage = "Period created successfully";
        state.periodsSavingIndicator = false;
      }),
      builder.addCase(createPeriod.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.periodsSavingIndicator = false;
      });

    builder.addCase(fetchPeriodsBySchoolID.pending, (state) => {
      state.successMessage = state.errorMessage = "";
      state.periodsLoadingIndicator = true;
    }),
      builder.addCase(
        fetchPeriodsBySchoolID.fulfilled,
        (state, { payload }) => {
          state.periods = payload;
          state.periodsLoadingIndicator = false;
        }
      ),
      /* #region Attendance getPeriodsBySelectedDateChar */
      builder.addCase(
        fetchPeriodsBySchoolID.rejected,
        (state, { error }) => {
          state.errorMessage = `${error.message}`;
          state.periodsLoadingIndicator = false;
        }
      );

    builder.addCase(fetchPeriodsBySelectedDateChar.pending, (state) => {
      state.periodsForAttendance = [];
      state.successMessage = state.errorMessage = "";
      state.periodsLoadingIndicator = true;
    }),
      builder.addCase(
        fetchPeriodsBySelectedDateChar.fulfilled,
        (state, { payload }) => {
          state.periodsForAttendance = payload;
          state.periodsLoadingIndicator = false;
        }
      ),
      builder.addCase(
        fetchPeriodsBySelectedDateChar.rejected,
        (state, { error }) => {
          state.periodsForAttendance = [];
          console.log(error.message);
          
          state.errorMessage = `${error.message}`;
          state.periodsLoadingIndicator = false;
        }
      );
    /* #endregion */

    /* #endregion */
  },
});

export default scheduleSlice.reducer;
export const { setSuccessMessage, setErrorMessage, cleanPeriods } =
  scheduleSlice.actions;
