import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Select,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MaxCharCountField,
  RequiredStringField,
} from "../../Helpers/FormValidations";
import useForm, { FormField } from "../../Hooks/useForm";
import { District, School } from "../../Interfaces/SchoolInterface";
import { createSchool } from "../../Services/SchoolsService";
import { RootState } from "../../store";
import { LightModal } from "../LightModal/LightModal";

interface ListProps {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
  schools: School[];
  districts: District[];
}

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

const newSchoolForm: School = {
  name: "",
  district_id: 0,
};

const CreateSchoolModal: FC<ListProps> = ({
  openModal,
  handleOpenModal,
  schools,
  districts,
}): ReactElement => {
  const {
    form,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleCompleted,
  } = useForm<School>(newSchoolForm, validateformField, submitForm);
  const dispach = useDispatch();
  const { savingIndicator, successMessage } = useSelector(
    (state: RootState) => state.school
  );

  async function submitForm(form: School) {
    if (!readyToSubmit()) return;
    dispach(createSchool(form));
  }

  useEffect(() => {
    successMessage !== "" && handleCloseModal();
  }, [successMessage]);

  function handleCloseModal() {
    handleCompleted(true);
    handleOpenModal(false);
  }

  function checkIfAlreadyExist(value: string): string {
    const result = schools?.find((s) => s.name === value);
    return result === undefined
      ? ""
      : "A school with the same name already exists";
  }

  function validateformField(fieldName: string, fieldValue: string): FormField {
    let value: string = "";
    switch (fieldName) {
      case "name": {
        value =
          RequiredStringField(fieldName, fieldValue) ||
          MaxCharCountField(fieldName, fieldValue, 40) ||
          checkIfAlreadyExist(fieldValue);
        break;
      }
      case "district_id": {
        value = fieldValue ? "" : "No district selected";
      }
    }
    return {
      name: fieldName,
      value: value,
    } as FormField;
  }

  function readyToSubmit(): boolean {
    return !(
      !form.name ||
      !form.district_id ||
      !!errors.name ||
      !!errors.district ||
      savingIndicator
    );
  }

  return (
    <>
      <LightModal
        width="500px"
        isOpenModal={openModal}
        handleOpenModal={handleOpenModal}
      >
        <Card>
          <CardHeader title="Create a new school" sx={{ mx: 1 }} />
          <form onSubmit={handleSubmit} autoComplete="off">
            <CardContent sx={{ mx: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    required
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 0.5 }}
                    error={!!errors.district_id}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      District name
                    </InputLabel>
                    <Select
                      label="District name"
                      labelId="demo-simple-select-standard-label"
                      name="district_id"
                      value={form.district_id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value={0} disabled>
                        Select a district
                      </MenuItem>
                      {districts?.map((district) => (
                        <MenuItem key={district.id} value={district.id}>
                          {district.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.district_id && errors.district_id}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    type="text"
                    name="name"
                    fullWidth
                    id="modal-name"
                    label="Name"
                    variant="outlined"
                    value={form.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.name}
                    helperText={errors.name && errors.name}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions style={{ padding: 0, margin: 0 }}>
              <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => handleOpenModal(false)}
                    disabled={savingIndicator}
                    style={styles.formButton}
                    size="large"
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={styles.formButton}
                    fullWidth
                    size="large"
                    disabled={!readyToSubmit()}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            {savingIndicator && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </form>
        </Card>
      </LightModal>
    </>
  );
};

export default CreateSchoolModal;
