import React, { CSSProperties, FC, useMemo, useState } from 'react'
import { Box, Divider, Grid, LinearProgress, List, ListItem, ListItemText, Typography, TextField } from '@mui/material';
import { StudentOnLocation } from '../../Interfaces/SchoolInterface';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { CalendarSelector } from '../Calendar/CalendarSelector';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../store';
import { fetchStudentsWithCompletePassBySchoolId } from "../../Services/HallPassService";
import { Button } from '@mui/material';
import moment from 'moment';
import { format } from 'date-fns';

interface ListProps {
    loadingHallwayCompleted: boolean;
    hallwayCompleted: any[];
    isMobile: boolean;
}

const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: 'firstName',
      header: 'First Name',
      size: 10,
    },
    {
      accessorKey: 'lastName',
      header: 'Last Name',
      size: 50,
    },
    {
      accessorKey: 'location',
      header: 'Location',
      size: 50,
    },
    {
    accessorKey: 'duration',
    header: 'Duration',
    size: 50,
    muiTableBodyCellProps: ({
        cell
      }) => ({
        sx: {
          color: cell.getValue<string>().split(' ')[0] as unknown as number < 9 ? '#78BF39' : '#FF634D'
        }
      })
    }
    ,
    {
      accessorFn: (row) => new Date(row.startTime),
      accessorKey: 'startTime',
      header: 'Start Time',
      size: 50,
      filterFn: 'greaterThanOrEqualTo',
      sortingFn: 'datetime',
      Cell: ({ cell }) => format(cell.getValue<Date>(), 'MM/dd/yyyy hh:mm:ss a'), 
      Header: ({ column }) => <em>{column.columnDef.header}</em>, 
      Filter: ({ column }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                onChange={(newValue) => {
                column.setFilterValue(newValue);
                }}
                value={column.getFilterValue()}
                renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Select Date"
                />
                )}
            />
            </LocalizationProvider>
        ),
    },
    {
      accessorFn: (row) => new Date(row.endTime),
      accessorKey: 'endTime',
      header: 'End Time',
      size: 50,
      filterFn: 'lessThanOrEqualTo',
      sortingFn: 'datetime',
      Cell: ({ cell }) => format(cell.getValue<Date>(), 'MM/dd/yyyy hh:mm:ss a'), 
      Header: ({ column }) => <em>{column.columnDef.header}</em>, 
      Filter: ({ column }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                onChange={(newValue) => {
                column.setFilterValue(newValue);
                }}
                value={column.getFilterValue()}
                renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Select Date"
                />
                )}
            />
            </LocalizationProvider>
        ),
    }
  ];
const styles = {
    listContainer: {
        background: "#3C404E",
        borderRadius: "5px 5px 0 0",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
        overflow: "auto",
        marginTop: "-50px",
        textAlign: "center",
        minHeight: "79vh",
        maxHeight: "79vh",
        zIndex: 3,
        width: "90%",
    } as CSSProperties,
    loading: { width: "100%" } as CSSProperties,
    list: { borderRadius: "5px", padding: 0 } as CSSProperties,
    listItem: {
        background: "#464B5B",
        height: "auto",
    } as CSSProperties,
    divider: { borderColor: "#3C404E", background: "#3C404E" },
};

export const HallCompleteList: FC<ListProps> = ({ loadingHallwayCompleted, hallwayCompleted, isMobile }) => {
    console.log(hallwayCompleted)
    const formattedData = hallwayCompleted.map(item => {
        const startTime = new Date(item.started_at);
        const endTime = new Date(item.ended_at);
        const durationInMinutes = Math.max(1, Math.ceil((new Date(item.ended_at as Date).getTime() - new Date(item.started_at as Date).getTime()) / 60000)); 
        console.log(startTime)
        const formattedStartTime = format(startTime, 'MM/dd/yyyy hh:mm:ss a');
        const formattedEndTime = format(endTime, 'MM/dd/yyyy hh:mm:ss a');
        return {
          "firstName": item.student.first_name,
          "lastName": item.student.last_name,
          "location": item.location.name,
          "duration": durationInMinutes <= 1 ? '1 minute' : `${durationInMinutes} minutes`,
          "startTime": formattedStartTime,
          "endTime": formattedEndTime,
        };
      });
    console.log(formattedData)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentSchool } = useSelector((state: RootState) => state.main);
    const [selectedHallwayCompletedDate, setSelectedHallwayCompletedDate] = useState<Date>(new Date());

    const getMinutesDifferenceText = (startedAt: number, endedAt: number) => {
        if (!isNaN(startedAt) && !isNaN(endedAt)) {
            const timeDifference = endedAt - startedAt;
            const minutesDifference = Math.ceil(timeDifference / (1000 * 60));
            return minutesDifference <= 1 ? '1 minute' : `${minutesDifference} minutes`;
        } else {
            return 'Invalid Date';
        }
    };

    const getColor = (minutesDifferenceText: string) => {
        const minutesDifference = parseInt(minutesDifferenceText, 10);
        return minutesDifference <= 10 ? '#78BF39' : '#FF634D';
    };

    const HallwayCompletedListItems = useMemo(
        () =>
            hallwayCompleted?.map((item: StudentOnLocation, index) => (
                <React.Fragment key={item.id}>
                    <ListItem style={styles.listItem}>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: "subtitle2",
                                fontWeight: "500",
                                fontSize: "1rem",
                                marginBottom: "0",
                                color: "textPrimary",
                            }}
                            primary={
                                <React.Fragment>
                                    <Grid container spacing={0} mt={0}
                                        pt={0} pb={0}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item xs={5} md={5} pt={0}>
                                            <Typography
                                                component="div"
                                                align="left"
                                                variant="subtitle2"
                                                style={{ fontSize: "15px" }}>
                                                {item.student?.first_name + " " + item.student?.last_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} md={3} pt={0}>
                                            <Typography
                                                component="div"
                                                align="left"
                                                variant="subtitle2"
                                                style={{ fontSize: "15px", marginLeft: "15px" }}>
                                                {item.location?.name || '6'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} md={4} pt={0}
                                            sx={{
                                                paddingTop: "0",
                                            }}>
                                            <Box
                                                component="div"
                                                ml={3}
                                                pt={0}
                                                sx={{
                                                    display: 'flex',
                                                    pt: 0.5,
                                                    pb: 0.5,
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                    verticalAlign: 'middle',
                                                    color: getColor(
                                                        getMinutesDifferenceText(
                                                        new Date(item.started_at as Date).getTime(),
                                                        new Date(item.ended_at as Date).getTime()
                                                        )
                                                    ),
                                                    fontSize: '0.875rem',
                                                    fontWeight: '700',
                                                }}
                                            >
                                               {getMinutesDifferenceText(
                                                new Date(item.started_at as Date).getTime(),
                                                new Date(item.ended_at as Date).getTime()
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider key={`${item.id}${index}`} style={styles.divider} />
                </React.Fragment>
            )),
        [hallwayCompleted]
    );

    const handleCalendarChange = (date: Date) => {
        console.log(date);
        
        setSelectedHallwayCompletedDate(date);
        
        if (currentSchool && currentSchool?.id) {
            dispatch(fetchStudentsWithCompletePassBySchoolId({
                school_id: currentSchool.id,
                hall_date: moment(date).format("YYYY-MM-DD")
            }));
        }
    };

    return (
        <>
            <Box style={styles.listContainer}>
                <Box pl={2}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={12} mt={2}>
                            {isMobile && (
                                <IconButton
                                    component="span"
                                    size="small"
                                    sx={{
                                        background: (theme) => theme.palette.primary.main,
                                        color: "white",
                                        width: '30px', height: '30px',
                                        display: "flex", justifyContent: "center", alignItems: "center",
                                        paddingLeft: '13px'
                                    }}
                                    onClick={() => navigate('/Dashboard')}
                                    disableRipple={true}
                                >
                                    <ArrowBackIosIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                component="div"
                                align="left"
                                variant="subtitle2"
                                style={{ fontSize: "18px", lineHeight: "48px" }}
                            >
                                Historical Hall Pass Report
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {loadingHallwayCompleted ? (
                    <Box style={styles.loading}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <>
                        {isMobile ? (
                        <>
                        <Box pl={2}>
                            <CalendarSelector
                                style={{ marginRight: 10 }}
                                selectedDate={selectedHallwayCompletedDate}
                                onChange={handleCalendarChange}
                            />
                        </Box>
                        <Box pl={2}>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={5} sm={5}>
                                    <Typography
                                        component="div"
                                        align="left"
                                        variant="subtitle2"
                                        style={{ fontSize: "15px", lineHeight: "50px" }}
                                    >
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <Typography
                                        component="div"
                                        align="left"
                                        variant="subtitle2"
                                        style={{ fontSize: "15px", lineHeight: "50px" }}
                                    >
                                        Location
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <Typography
                                        component="div"
                                        align="left"
                                        variant="subtitle2"
                                        style={{ fontSize: "15px", lineHeight: "50px" }}
                                    >
                                        Duration
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <List sx={{ padding: 0 }} style={styles.list}>{HallwayCompletedListItems}</List>
                        </>
                        ) : (
                            <Box pl={2}>
                                <MaterialReactTable
                                columns={columns}
                                data={formattedData}
                                enableFullScreenToggle={false}
                                enableDensityToggle={false}
                                enableHiding={false}
                                initialState={{ density: 'compact' }}
                                isMultiSortEvent={() => true} 
                                maxMultiSortColCount={3}                              
                                renderTopToolbarCustomActions={({ table }) => (
                                    <Button onClick={() => table.resetSorting(true)}>
                                    Clear All Sorting
                                    </Button>
                                )}
                                />
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </>
    )
}
