import { Box, Typography, Button } from "@mui/material";
import SemesterSelect from "../Semester/SemesterSelect";

interface Props {
  areClassesLoaded: boolean;
  isMobile: boolean;
  styles: any;
  onAddNewClicked: () => void;
}

const SchedulePageHeader = ({
  areClassesLoaded,
  isMobile,
  onAddNewClicked,
  styles,
}: Props) => {
  return (
    <Box
      flexDirection={isMobile ? "column" : "row"}
      sx={{
        backgroundColor: "#2a2d38",
        display: "flex",
        alignItems: "flex-start",
        paddingLeft: "20%",
        marginLeft: "-20%",

        justifyContent: "space-between",
      }}
    >
      <Box
        flexDirection="column"
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="500"
          fontSize="1.5rem"
          marginBottom="0.1rem"
          color="textPrimary"
        >
          Schedule
        </Typography>
        <Typography
          variant="caption"
          fontSize="1rem"
          marginBottom="1.5rem"
          color="textPrimary"
        >
          View and update your schedule
        </Typography>
      </Box>
      <Box
        flexDirection="column"
        sx={{
          display: "flex",
          marginTop: isMobile ? 0 : "8px",
        }}
      >
        <SemesterSelect />
        {areClassesLoaded && !isMobile && (
          <Button
            sx={{ marginTop: "8px" }}
            variant="contained"
            color="primary"
            style={styles.newButton}
            onClick={onAddNewClicked}
          >
            + Add new
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SchedulePageHeader;
