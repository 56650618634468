import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { FC, ReactElement, useEffect, useState } from "react";
import {
  MaxCharCountField,
  RequiredStringField,
} from "../../Helpers/FormValidations";
import useForm, { FormField } from "../../Hooks/useForm";
import { Semester } from "../../Interfaces/SemesterInterface";
import {
  createSemester,
  updateSemester,
} from "../../Services/SemestersService";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { LightModal } from "../LightModal/LightModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

interface ListProps {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
  school_id: number;
  semesters: Semester[];
  setSelectedSemester: (semester: Semester) => void;
  selectedSemester?: Semester;
}

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

const newSemesterForm: Semester = {
  name: "",
  start_date: new Date(),
  end_date: new Date(),
};

const SemesterModal: FC<ListProps> = ({
  openModal,
  handleOpenModal,
  school_id,
  semesters,
  setSelectedSemester,
  selectedSemester,
}): ReactElement => {
  const dispatch = useDispatch();
  const {
    form,
    setForm,
    errors,
    handleChangeByAttrName,
    handleChange,
    handleBlur,
    handleSubmit,
    handleCompleted,
  } = useForm<Semester>(newSemesterForm, validateformField, submitForm);

  const { successMessage, savingIndicator: savingIndicator } = useSelector(
    (state: RootState) => state.semester
  );

  const [openStartDate, setOpenStartDate] = useState<boolean>(false);
  const [openEndDate, setOpenEndDate] = useState<boolean>(false);

  useEffect(() => {
    setForm(selectedSemester ?? newSemesterForm);
  }, [setForm, selectedSemester]);

  useEffect(() => {
    successMessage !== "" && handleCloseModal();
  }, [successMessage]);

  async function submitForm(form: Semester) {
    if (!readyToSubmit()) return;

    const newForm: Semester = {
      ...form,
      start_date: new Date(form.start_date),
      end_date: new Date(form.end_date),
      id: selectedSemester?.id,
      school_id: school_id,
    };
    selectedSemester?.id
      ? await dispatch(updateSemester(newForm))
      : await dispatch(createSemester(newForm));
  }

  function handleCloseModal() {
    handleCompleted(true);
    handleOpenModal(false);
    setSelectedSemester(newSemesterForm);
  }

  function checkIfAlreadyExist(value: string): string {
    if (selectedSemester?.id && selectedSemester?.name === value) return "";

    const result = semesters?.find((s) => s.name === value);
    return result === undefined
      ? ""
      : "A semester with the same name already exists";
  }

  function validateformField(fieldName: string, fieldValue: string): FormField {
    let value: string = "";

    switch (fieldName) {
      case "name": {
        value =
          RequiredStringField(fieldName, fieldValue) ||
          MaxCharCountField(fieldName, fieldValue, 40) ||
          checkIfAlreadyExist(fieldValue);
        break;
      }
    }
    return {
      name: fieldName,
      value: value,
    } as FormField;
  }

  const DatePickerItem = (
    labelName: string,
    attributeName: string,
    formDate: Date,
    errorsDate: any
  ): ReactElement => (
    <MobileDatePicker
      open={attributeName === "start_date" ? openStartDate : openEndDate}
      onOpen={() =>
        attributeName === "start_date"
          ? setOpenStartDate(true)
          : setOpenEndDate(true)
      }
      onClose={() =>
        attributeName === "start_date"
          ? setOpenStartDate(false)
          : setOpenEndDate(false)
      }
      label={labelName}
      inputFormat="MMM dd, yyyy"
      value={formDate}
      onChange={(date) => handleChangeByAttrName(attributeName, date)}
      renderInput={(params) => (
        <TextField
          onClick={() =>
            attributeName === "start_date"
              ? setOpenStartDate(true)
              : setOpenEndDate(true)
          }
          {...params}
          fullWidth
          id="modal-startDate"
          name={attributeName}
          variant="outlined"
          error={!!errorsDate}
          onBlur={handleBlur}
          helperText={errorsDate && errorsDate}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <DateRangeIcon
                  style={{ cursor: "pointer" }}
                  color="primary"
                  fontSize="small"
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );

  function readyToSubmit(): boolean {
    return !(
      !form.name ||
      !!errors.name ||
      !!errors.start_date ||
      !!errors.end_date ||
      savingIndicator
    );
  }

  return (
    <>
      <LightModal
        width="650px"
        isOpenModal={openModal}
        handleOpenModal={handleOpenModal}
      >
        <Card>
          <CardHeader title="Add semester" sx={{ mx: 1 }} />
          <form onSubmit={handleSubmit} autoComplete="off">
            <CardContent sx={{ mx: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="name"
                    id="modal-name"
                    label="Semester Name"
                    variant="outlined"
                    value={form.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeByAttrName("name", e.target.value || "");
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    error={!!errors.name}
                    helperText={errors.name && errors.name}
                  />
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={12} sm={6}>
                    {DatePickerItem(
                      "Start Date",
                      "start_date",
                      form.start_date,
                      errors.start_date
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {DatePickerItem(
                      "End Date",
                      "end_date",
                      form.end_date,
                      errors.end_date
                    )}
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </CardContent>
            <CardActions style={{ padding: 0, margin: 0 }}>
              <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      setForm(newSemesterForm);
                      setSelectedSemester(newSemesterForm);
                      handleOpenModal(false);
                    }}
                    disabled={savingIndicator}
                    style={styles.formButton}
                    size="large"
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={styles.formButton}
                    fullWidth
                    size="large"
                    disabled={!readyToSubmit()}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            {savingIndicator && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </form>
        </Card>
      </LightModal>
    </>
  );
};

export default SemesterModal;
