import { FC, useCallback, useEffect, useState } from 'react'
import FullCalendar, { DateSelectArg, EventApi, EventClickArg, EventContentArg, EventInput } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { createEventId } from '../../Config/event-utils'
import { withDarkTheme } from '../../Hocs/withDarkTheme'
import './main.css'
import CalendarModal from './CalendarModal'
import { Calendar, DateTypeEnum } from '../../Interfaces/CalendarInterface'
import { useTheme } from "@mui/material/styles";
import moment from 'moment'
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { fetchDayTypesBySchoolId } from '../../Services/ScheduleService'

interface FullCalendarProps {
    openModal: boolean;
    handleOpenModal: (state: boolean) => void;
    handleSelectedDate: (newDate: any) => void;
    selectedDate: Date | null;
    selectedDateType: DateTypeEnum;
    setSelectedDateType: Function;
    currentCalendarDay: Calendar | null | undefined;
    school_id: number;
    calendarDays: Calendar[];
    defaultEvents?: EventApi[];
    onChange: (date: Date | null) => void;
    // weekendsVisible: boolean
}


const DesktopCalendarComponent: FC<FullCalendarProps> = ({ calendarDays, defaultEvents, handleSelectedDate, selectedDate,
    handleOpenModal, openModal, onChange,
    selectedDateType, setSelectedDateType,
    currentCalendarDay, school_id }) => {

    const [currentEvents, setCurrentEvents] = useState<EventApi[]>(defaultEvents || []);
    const [selectedEvent, setSelectedEvent] = useState<DateSelectArg>();

    const { dayTypes } = useSelector((state: RootState) => state.schedule);
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleDateSelect = (selectInfo: DateSelectArg) => {

        // Disable the multi-selection days
        
        if (selectInfo?.end?.getDay() - selectInfo?.start?.getDay() !== 1) {
            selectInfo?.view?.calendar?.unselect();
            return;
        }

        let dayOfWeek = selectInfo.start.getDay();
        let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0); // 6 = Saturday, 0 = Sunday

        if (isWeekend) return;

        handleSelectedDate(selectInfo.start);
        setSelectedEvent(selectInfo);
        handleOpenModal(!openModal);
    }

    const handleEventClick = (clickInfo: EventClickArg) => {
        // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        //     clickInfo.event.remove()
        // }
        //clickInfo.event.remove();

        const dateSelectEvent: DateSelectArg =
        {
            jsEvent: clickInfo.jsEvent,
            view: clickInfo.view,
            start: clickInfo.event.start ?? new Date(),
            end: clickInfo.event.end ?? new Date(),
            allDay: clickInfo.event.allDay,
            startStr: clickInfo.event.startStr,
            endStr: clickInfo.event.endStr,
        }

        setSelectedEvent(dateSelectEvent);
        handleSelectedDate(clickInfo.event.start);
        onChange(clickInfo.event.start);
    }

    const handleEvents = (events: EventApi[]) => {
        setCurrentEvents(events);
    }

    function renderEventContent(eventContent: EventContentArg) {
        return (
            <>
                <b>{eventContent.timeText}</b>
                <i>{eventContent.event.title}</i>
            </>
        )
    }

    function onSave(eventTitle: string, selectedDateTypeValue: DateTypeEnum) {
        let title = eventTitle || 'New Event';
        let calendarApi = selectedEvent?.view.calendar
        const newEventId = createEventId();

        if (selectedEvent) {
            const isPreviousEvent: any = currentEvents.find((event: EventApi) => {
                const isSameDate = moment(event?.start).isSame(selectedEvent?.start);
                return isSameDate ?? event;
            });

            if (!isPreviousEvent) {
                console.log('is not previous event')
               /*calendarApi?.addEvent({
                    id: newEventId,
                    title,
                    start: selectedEvent?.startStr,
                    end: selectedEvent?.endStr,
                    allDay: selectedEvent?.allDay,
                    backgroundColor: selectedDateTypeValue === DateTypeEnum.Holiday ? theme.palette.secondary.main : 'var(--fc-event-bg-color, #3788d8)',
                    borderColor: selectedDateTypeValue === DateTypeEnum.Holiday ? theme.palette.secondary.main : '#3788d8',
                });*/
            }
            else if (isPreviousEvent instanceof EventApi) {
                isPreviousEvent.setProp('title', title);
                isPreviousEvent?.setProp('backgroundColor', selectedDateTypeValue === DateTypeEnum.Holiday ? theme.palette.secondary.main : 'var(--fc-event-bg-color, #3788d8)');
                isPreviousEvent?.setProp('borderColor', selectedDateTypeValue === DateTypeEnum.Holiday ? theme.palette.secondary.main : '#3788d8');
            }
        }

        calendarApi?.unselect() // clear date selection
        handleOpenModal(false);
    }

    const INITIAL_EVENTS = useMemo(() => {

        return calendarDays?.map(day => {

            const event: EventInput = {
                id: day?.id?.toString() || createEventId(),
                title: dayTypes?.find(type => type.id === day?.day_type_id)?.name || "Day Off",
                start: moment(day?.selected_date).toDate(),
                end: moment(day?.selected_date).toDate(),
                allDay: true,
                backgroundColor: day?.day_type_enum === DateTypeEnum.Holiday ? theme.palette.secondary.main : 'var(--fc-event-bg-color, #3788d8)',
                borderColor: day?.day_type_enum === DateTypeEnum.Holiday ? theme.palette.secondary.main : '#3788d8'
            };

            return event;
        });

    }, [calendarDays, theme, dayTypes, school_id]);

    return (
        <div className='desktop-calendar'>
            <div className='desktop-calendar-main'>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    initialView='dayGridMonth'
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    events={INITIAL_EVENTS}    // alternatively, use the `events` setting to fetch from a feed
                    select={handleDateSelect}
                    eventContent={renderEventContent} // custom render function
                    eventClick={handleEventClick}
                    eventsSet={handleEvents}          // called after events are initialized/added/changed/removed
                    eventStartEditable={false}
                /* you can update a remote database when these fire:
                eventAdd={function(){}}
                eventChange={function(){}}
                eventRemove={function(){}}
                */
                />
                <CalendarModal
                    selectedDate={selectedDate}
                    openModal={openModal}
                    currentCalendarDay={currentCalendarDay}
                    handleOpenModal={handleOpenModal}
                    selectedDateType={selectedDateType}
                    setSelectedDateType={setSelectedDateType}
                    school_id={school_id as number}
                    onSave={onSave}
                />
            </div>
        </div>
    )
}

export const DesktopCalendar = withDarkTheme(DesktopCalendarComponent);