import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CssBaseline, LinearProgress, Alert, Grid } from "@mui/material";
import { LayoutContainer } from "../Layout/layout.container";
import { setPageTitle, setPageSubtitle } from "../store/slices/main";
import { createAttendance } from "../Services/AttendanceService";
import { RootState } from "../store";
import { Attendance } from "../Interfaces/AttendanceInterface";
import { CSSProperties } from "@mui/styles";
import planningSvg from "../Assets/planning.svg";
import { CustomButton } from "../Components";
import { useNavigate } from "react-router-dom";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../store/slices/attendance.slice";

import moment from "moment";

const styles = {
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    minHeight: "79vh",
    maxHeight: "79vh",
    zIndex: 3,
  } as CSSProperties,
};

export const AttendanceRegisterPage: FC<any> = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { savingIndicator, errorMessage, successMessage } = useSelector(
    (state: RootState) => state.attendance
  );
  const { currentClass } = useSelector((state: RootState) => state.main);
  const [showMessageSuccess, setShowMessageSuccess] = useState<boolean>(false);
  const [showMessageError, setShowMessageError] = useState<boolean>(false);
  const [showMessageWarning, setShowMessageWarning] = useState<boolean>(false);
  const [messageWarning, setMessageWarning] = useState<string>("");

  const [customErrorMessage, setCustomErrorMessage] = useState<string>();

  useEffect(() => {
    if (currentClass.id) {
      dispatch(setPageTitle(currentClass.name));
      dispatch(setPageSubtitle(`Room ${currentClass.room?.name}`));
      let attendance: Attendance = {
        class_id: Number(currentClass.id),
      };
      dispatch(createAttendance(attendance));
    } else {
      setErrorMessage("Invalid class");
      setShowMessageError(true);
    }
  }, [currentClass]);

  useEffect(() => {
    if (errorMessage && errorMessage !== "") {
      setShowMessageError(true);
      setShowMessageSuccess(false);
      setCustomErrorMessage(errorMessage);
    } else {
      setShowMessageError(false);
      setCustomErrorMessage("");
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage && successMessage !== "") {
      setShowMessageSuccess(true);
      setShowMessageError(false);
      setShowMessageWarning(false);
    } else {
      setShowMessageSuccess(false);
    }
  }, [successMessage]);

  useEffect(() => {
    if (messageWarning && messageWarning !== "") {
      setShowMessageWarning(true);
      setShowMessageError(false);
      setShowMessageSuccess(false);
    } else {
      setShowMessageWarning(false);
    }
  }, [messageWarning]);

  const acceptButtonLogic = () => {
    if (currentClass.id) {
      const currDate = moment();
      const classStartTime = moment(currentClass.period?.start_time)
        .set("date", currDate.get("date"))
        .set("month", currDate.get("month"))
        .set("year", currDate.get("year"));

      if (currDate.isBefore(classStartTime)) {
        setMessageWarning("Please wait until class starts to request a pass");
        setShowMessageWarning(true);
      } else {
        navigate("/StudentHallPassLocations");
      }
    } else {
      setCustomErrorMessage("Error");
      setShowMessageError(true);
    }
  };

  return (
    <>
      <CssBaseline />
      {/* <Header title="List of schools" /> */}

      <LayoutContainer isRaised={true}>
        <Box style={styles.listContainer} flexDirection="column">
          {savingIndicator ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : (
            <Box className="default-loading">
              <Grid
                sx={{ height: "72vh" }}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid>
                  <img
                    style={{ height: 250, marginBottom: 50 }}
                    src={planningSvg}
                    alt="logo"
                  />
                  <Box style={{ marginLeft: 15, marginRight: 15 }}>
                    {showMessageError && customErrorMessage ? (
                      <Alert
                        severity="warning"
                        variant="outlined"
                        color="error"
                      >
                        {customErrorMessage}
                      </Alert>
                    ) : (
                      <></>
                    )}
                    {showMessageWarning ? (
                      <Alert
                        severity="warning"
                        variant="outlined"
                        color="warning"
                      >
                        {messageWarning}
                      </Alert>
                    ) : (
                      <></>
                    )}
                    {showMessageSuccess && successMessage ? (
                      <>
                        <Alert severity="success" variant="outlined">
                          {successMessage}
                        </Alert>
                      </>
                    ) : (
                      <></>
                    )}
                    <CustomButton
                      text="Accept"
                      onClick={acceptButtonLogic}
                      sx={{ mt: 5, mb: 1 }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </LayoutContainer>
    </>
  );
};

export default AttendanceRegisterPage;
