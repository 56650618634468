import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Radio,
  RadioGroup
} from '@mui/material'
import DashboardSchoolAdmin from './DashboardSchoolAdmin'
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CSSProperties } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./DashboardStaff.css";
import { getLocationsByUserManager, getStudentsByLocation, getStudentsByLocationOnTheWay } from '../Services/HallPassService';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { AttendanceListStaff, ReportAttendanceByCurrentClass } from '../Components/AttendanceList/AttendanceListStaff';
import { useWebSocket } from '../Hooks/useWebSocket';

import { AttendanceListLocationComponent } from "../Components/AttendanceList/AttendanceListLocationComponent";

const styles = {
  card: {
    height: "56px",
    background: "linear-gradient(0deg, #3C404E, #3C404E), #009CDE",
    boxShadow: "0px 7px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  formButton: {
    width: "100%",
    height: "100%",
    textTransform: "unset",
    // color: "#FFFFFF",
    fontSize: 16,
    padding: "0",
  } as CSSProperties,
  radioLabel: {
    color: "#7AC142",
  } as CSSProperties,
};

const DashboardStaff = () => {

  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const [pathUrl, setPathUrl] = useState('/Dashboard');
  const { currentSchool, currentUser } = useSelector((state: RootState) => state.main);
  // const { currentUser } = useSelector((state: RootState) => state.main);
  const [locations, setLocations] = useState<any>([]);
  const [value, setValue] = useState<string>();
  const [showTab, setShowTab] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [arrivedCounter, setArrivedCounter] = useState<number>(0);
  const [onTheWayCounter, setOnTheWayCounter] = useState<number>(0);
  const [selectedLocationId, setSelectedLocationId] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);
  const [dataArrived, setDataArrived] = useState<ReportAttendanceByCurrentClass[]>([]);
  const [dataOnTheWay, setDataOnTheWay] = useState<ReportAttendanceByCurrentClass[]>([]);

  const open = Boolean(anchorEl);

  const [tabValue, setTabValue] = useState('1');

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useWebSocket([
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        if (selectedLocationId && selectedLocationId > 0) {
          loadStudentByLocationIdArrived(selectedLocationId);
          loadStudentByLocationOnTheWay(selectedLocationId);
        }
      },
    },
  ]);

  useEffect(() => {
    setPathUrl(location.pathname)
  }, []);

  useEffect(() => {
    loadLocationsByStaffUser();
  }, [currentUser.user_name]);

  useEffect(() => {
    if (locations && locations.length) {
      setSelectedLocationId(locations[0]?.location_id);
    }
  }, [locations]);

  function loadLocationsByStaffUser() {
    if (currentUser.user_name) {
      const fetchData = async () => {
        return await getLocationsByUserManager(currentUser.user_name);
      };
      fetchData()
        .then((result: any) => {
          setLocations(result);
          if (result !== null && result.length) {
            setValue(result ? result[0].location.name : null);
            setShowTab(true)

          } else {
            setValue("");
            setShowTab(false)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  /***
 * Reload students list every x mil-seconds
 */
  useEffect(() => {
    if (selectedLocationId && selectedLocationId > 0) {
      const interval = setInterval(() => {
        loadStudentByLocationIdArrived(selectedLocationId);
        loadStudentByLocationOnTheWay(selectedLocationId);
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [selectedLocationId]);

  /***
   * Load students list for the fist time
   */
  useEffect(() => {
    if (selectedLocationId && selectedLocationId > 0) {
      loadStudentByLocationIdArrived(selectedLocationId);
      loadStudentByLocationOnTheWay(selectedLocationId);
    }
  }, [selectedLocationId]);

  function loadStudentByLocationIdArrived(locationId: number | null) {
    setLoading(true);

    const fetchData = async () => {
      return await getStudentsByLocation(locationId);
    };

    fetchData()
      .then((result: any[]) => {
        console.log("getStudentsByLocation response ", result);
        
        const filteredResult: any[] = result.filter((item) => !(item.user.sentToHallPass && item.user.sentToNextClass));
        setDataArrived(filteredResult);
        setArrivedCounter(filteredResult.length);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDataArrived([]);
        setArrivedCounter(0);
        setLoading(false);
      });
  }

  function loadStudentByLocationOnTheWay(locationId: number | null) {
    setLoading(true);

    const fetchData = async () => {
      return await getStudentsByLocationOnTheWay(locationId);
    };

    fetchData()
      .then((result: any[]) => {
        console.log("getStudentsByLocation on the Way response ", result);
        
        const filteredResult: any[] = result.filter((item) => !(item.user.sentToHallPass && item.user.sentToNextClass));
        setDataOnTheWay(filteredResult);
        setOnTheWayCounter(filteredResult.length);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDataOnTheWay([]);
        setOnTheWayCounter(0);
        setLoading(false);
      });
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, path: string) => {
    path !== '/Dashboard' && setAnchorEl(event.currentTarget);
    setPathUrl(path);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const locationId = locations?.filter((x: any) => x.location.name === event.target.value)[0]?.location_id;

    setSelectedLocationId(locationId || 0);
    setValue(event.target.value);
  };

  return (
    <>
      {showTab
        ? (
          <div>
            {downMd ? <div style={{ marginTop: "-48px" }}></div> : <div style={{ marginTop: "-50px" }}></div>}
            <Card style={styles.card}>
              <CardContent className="cardContent">
                <Grid container spacing={1} className="tabsGrid">
                  <Grid
                    item xs={6}
                    className={`${pathUrl == '/Dashboard' ? ' tabDashboard tabDashboardActive' : 'tabDashboard'}`}
                  >
                    <Button
                      style={{ textTransform: 'capitalize', fontSize: '16px', width: "100%"}}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClick(event, '/Dashboard')}>
                      School
                    </Button>
                  </Grid>
                  <Grid
                    item xs={6}
                    className={`${pathUrl !== '/Dashboard' ? ' tabDashboard tabDashboardActive' : 'tabDashboard'}`}>
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClick(event, '/Dashboard/Staff')}
                      color="inherit"
                      style={styles.formButton}
                      endIcon={<KeyboardArrowDownIcon fontSize="large" />}
                    >
                      {value}
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <RadioGroup
                          name="use-radio-group"
                          defaultValue="first"
                          value={value}
                          onChange={handleChange}
                        >
                          {locations?.map((loc: any) => (
                            <FormControlLabel
                              key={loc.location.id}
                              value={loc.location.name}
                              label={loc.location.name}
                              control={<Radio />}
                              style={{ color: `${pathUrl === '/Dashboard' ? styles.radioLabel : 'primary'}` }}
                            />
                          ))}
                        </RadioGroup>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {pathUrl === '/Dashboard' ? (
              <div id="tab_school">
                {downMd ? <div style={{ marginTop: "15px" }}></div> : <div style={{ marginTop: "85px" }}></div>}
                <DashboardSchoolAdmin />
              </div>
            ) : (
              <div id="tab_location">
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab}>
                      <Tab label="Arrived"
                        style={{ textTransform: 'capitalize', minWidth: '50%', maxWidth: '50%' }}
                        value="1"
                        iconPosition="end"
                        icon={<Box sx={{
                          display: 'flex',
                          pt: 0.5,
                          pb: 0.5,
                          width: '25px',
                          textAlign: 'center',
                          justifyContent: 'center',
                          verticalAlign: 'middle',
                          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF'),
                          color: '#000',
                          border: '1px solid',
                          borderColor: (theme) =>
                            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                          borderRadius: '5px',
                          fontSize: '0.775rem',
                          fontWeight: '700',
                        }}>{arrivedCounter}</Box>} />
                      <Tab label="On The Way" value="2"
                        style={{ textTransform: 'capitalize', minWidth: '50%', maxWidth: '50%' }}
                        iconPosition="end"
                        icon={<Box sx={{
                          display: 'flex',
                          pt: 0.4,
                          pb: 0.4,
                          width: '25px',
                          textAlign: 'center',
                          justifyContent: 'center',
                          verticalAlign: 'middle',
                          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#F8931C' : '#F8931C'),
                          color: '#fff',
                          border: '1px solid',
                          borderColor: (theme) =>
                            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                          borderRadius: '5px',
                          fontSize: '0.775rem',
                          fontWeight: '700',
                        }}>{onTheWayCounter}</Box>} />
                    </TabList>
                  </Box>
                  <TabPanel value="1" style={{ padding: 0 }}>
                    <AttendanceListStaff dataFiltered={dataArrived} loading={loading} />
                    {/* <AttendanceListLocationComponent dataFiltered={dataArrived} loading={loading} /> */}

                  </TabPanel>
                  <TabPanel value="2" style={{ padding: 0 }}>
                    <AttendanceListStaff dataFiltered={dataOnTheWay} loading={loading} />
                  </TabPanel>
                </TabContext>
              </div>
            )}
          </div>
        ) : (
          <div>
            {downMd ? <div style={{ marginTop: "15px" }}></div> : <div style={{ marginTop: "85px" }}></div>}
            <DashboardSchoolAdmin />
          </div>
        )
      }
    </>
  );
}

export default DashboardStaff
