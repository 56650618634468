import { SVGProps } from "react"

const GroupSvgMobile = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={277}
        height={109}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M238.553 108.687s28.693-30.113 4.847-62.728c-21.143-28.939-45.8-26.95-63.89-15.22-7.7 5.003-35.596 27.725-54.508-1.713-25.79-40.144-109.503-42.907-93.206 18.871C39.682 77.786.224 78.874 16.47 108.75l222.082-.063Z"
            fill="#DDE8FD"
            fillOpacity={0.1}
        />
        <path
            d="M47.68 68.156s-8.048 3.527-9.505 3.452c-1.458-.063-10.466-2.751-10.466-2.751l.224 1.55s6.952 4.015 9.382 4.24c2.392.225 10.565-2.151 11.362-2.651.785-.5 1.309-3.34-.996-3.84Z"
            fill="#FC9796"
        />
        <path
            d="M48.902 55.2s2.965-.562 4.448.513c2.678 1.963 2.828 8.742 3.825 9.742 1.009 1.013 3.426.575 3.638 5.803.211 5.215-.96 5.652.436 7.99 1.407 2.327-.598 4.666-.598 4.666s.822-1.288.473-2.214c0 0-.336 2.051-1.632 2.526-1.296.475-1.483.838-1.694 1.538 0 0-11.064-5.69-11.077-9.73-.012-4.038-2.18-8.028-1.108-9.779 0 0-.474.75-.536 1.576 0 0-.636-1.225.473-2.363 1.11-1.138-1.295-3.502-.947-6.24.237-1.89 1.558-3.953 4.299-4.028Z"
            fill="#0D0D38"
        />
        <path
            d="M46.709 71.996c.71.488 1.595.9 2.703 1.15 4.174.963 5.769-2.576 6.367-5.002a3.67 3.67 0 0 0-1.408-.438 12.069 12.069 0 0 0-.884-.075c-.474-.362-1.172-1.838-1.321-4.127l-.2.05-3.289.9s.15 1.051.225 2.04c.062.775.062 1.513-.075 1.7-.262.05-.411.088-.411.088-.038.1-.997 1.675-1.707 3.714Z"
            fill="#FDB5B4"
        />
        <path
            d="M48.69 64.48s.15 1.05.224 2.038c1.869-.188 2.729-1.963 3.065-2.939l-3.29.9Z"
            fill="#FC9796"
        />
        <path
            d="M48.13 65.455s4.422-.25 4.223-3.002c-.2-2.763.25-4.652-2.592-4.614-2.828.037-3.301 1.138-3.463 2.076-.15.938.673 5.54 1.831 5.54Z"
            fill="#FDB5B4"
        />
        <path
            d="M46.335 58.364c.623-.875 1.146-.737 1.458-.5 2.317-1.5 4.012.35 4.012.35.436 1.964 1.943 2.99 1.943 2.99s-.772.562-2.654.212c-.573-.1-1.108-.475-1.582-.938a10.501 10.501 0 0 0 1.931 2.05c-2.292-.862-3.401-3.9-3.513-4.213-.25.262-.748.825-.872 1.363-.175.712-.897 1.838-1.32 1.825-.013-.012-.35-1.813.597-3.139Z"
            fill="#0D0D38"
        />
        <path
            d="M57.05 81.025c.399-2.051 1.707-10.23.399-12.869-.474-.95-2.305-1.025-4.311-.95-.623.4-3.015 1.938-4 1.75-.012 0-.024-.012-.05-.012-.46-.112-.635-.438-.249-.725 0 0-.996-.1-2.23.137-.698.138-2.591 1.113-2.591 1.113s.947 1.726 2.355 2.314c-.237 2.138.112 3.076 2.33 8.016 1.868 4.177-.25 7.929-.25 7.929s10.99 8.879 11.051 4.352c.038-3.39-2.965-8.442-2.454-11.055Z"
            fill="#A83F3F"
        />
        <path
            d="M47.892 97.382a2.039 2.039 0 0 1-1.47-.9c-.05-.075-.112-.163-.174-.263-1.246-1.9-1.744-7.728-4.548-13.931-.685-1.513-3.152-1.789-3.812-3.49 0 0-1.657-5.54 3.165-5.702 4.809-.163 12.895 15.395 13.02 17.033.111 1.6 3.164 8.316-6.18 7.253Z"
            fill="#FA7970"
        />
        <path
            d="M28.755 97.257h6.853s.262-1.1-.2-1.838c-.398-.663-.249-1.038.05-1.588-.311.125-.71.237-1.158.212-.337-.025-.698-.175-1.022-.362-.299.337-1.557 1.776-1.956 2.063-.436.325-2.43.213-2.567 1.513ZM56.327 70.295s.897 8.141-4.896 9.855c-5.781 1.7-7.426-7.492-2.753-8.154 4.672-.663 7.65-1.701 7.65-1.701Z"
            fill="#9E332E"
        />
        <path
            d="M37.726 74.71c-.411 3.1-2.018 12.905-4.871 17.683-.922 1.538 2.304 2.4 2.33 2.4.435-.112 1.158-.8 1.482-1.1 2.305-2.088 6.99-7.991 6.99-12.73 0-2.314.037-6.729-1.633-7.842-1.034-.7-4.136.363-4.298 1.588Z"
            fill="#FB8A82"
        />
        <path
            d="M51.98 97.257s-.25-.35-.699-1.025c-.523-.8-1.108-2.376-1.819-4.402-.747-2.126-1.62-4.727-2.728-7.441a62.117 62.117 0 0 0-1.084-2.539c-.685-1.513-2.567-1.3-3.24-3.014 0 0-2.616-5.915 2.193-6.077 4.81-.163 13.954 15.832 14.49 17.382.573 1.701 3.377 8.742-7.114 7.116Z"
            fill="#FC9796"
        />
        <path
            d="M33.789 97.257h6.852s.262-1.1-.199-1.838c-.399-.663-.25-1.038.05-1.588-.312.125-.71.237-1.159.212-.336-.025-.697-.175-1.021-.362-.3.337-1.558 1.776-1.956 2.063-.437.325-2.43.213-2.567 1.513Z"
            fill="#A83F3F"
        />
        <path
            d="M49.462 91.83c-.747-2.126-1.62-4.728-2.728-7.441.286-2.064.91-3.852 1.956-4.578 0-.012 2.529 7.867.772 12.019Z"
            fill="#FB8A82"
        />
        <path
            d="M41.725 74.497c-.41 3.101-.996 13.118-3.837 17.908-.922 1.538 2.305 2.401 2.33 2.401.436-.113 1.158-.8 1.482-1.1 2.305-2.089 6.99-7.992 6.99-12.731 0-2.314-2.704-6.54-4.373-7.654-1.034-.713-2.43-.062-2.592 1.176Z"
            fill="#FC9796"
        />
        <path
            d="m26.887 64.855 7.014-.75c.2-.026.399.1.473.287l2.505 6.615c.112.288-.1.6-.411.613l-6.965.288a.447.447 0 0 1-.436-.275L26.5 65.48c-.1-.275.088-.6.387-.625Z"
            fill="#9E332E"
        />
        <path
            d="m26.276 64.917 7.015-.75c.199-.025.398.1.473.287l2.504 6.616c.112.288-.1.6-.41.613l-6.965.287a.447.447 0 0 1-.437-.275l-2.541-6.153a.449.449 0 0 1 .361-.625Z"
            fill="#A83F3F"
        />
        <path
            d="M30.163 68.006c-.087-.237.038-.425.274-.425.237 0 .499.188.586.425.087.238-.037.425-.274.425-.237 0-.498-.187-.586-.425ZM26.488 65.543c-.037-.113.012-.213.137-.213.112 0 .25.1.287.213.037.112-.013.212-.138.212-.112 0-.236-.1-.286-.212Z"
            fill="#9E332E"
        />
        <path
            d="M55.791 68.156s-9.718 3.59-11.188 3.527c-.947-.05-8.796-2.814-8.796-2.814s-.585-.9-1.22-1.088c0 0-1.122-.513-1.434-.613-.323-.1-.249 0-.161.3.05.163.224.313.386.438-.536.15-1.072.35-1.309.4-.41.075-1.594.463-1.245 1.038.348.576 1.98.7 2.678.576.698-.125 1.595.287 1.595.287s7.637 4.928 9.743 4.903c4.149-.05 11.138-2.602 11.936-3.102.785-.513 1.308-3.351-.985-3.852ZM26.999 68.281s.697-1.113.934-1.163c.698-.137.698.788.548 1.388-.162.638-.697 1.25-.847 1.2-.15-.062-.797-.837-.635-1.425Z"
            fill="#FDB5B4"
        />
        <path d="M26.974 68.506s-.224 1.25.96 1.901l-.96-1.9Z" fill="#FDB5B4" />
        <path
            d="M47.307 63.867s.424-.162 1.595-.075c0 0-.063.463-.935.5-.498.038-.66-.425-.66-.425Z"
            fill="#fff"
        />
        <path
            d="M50.77 69.92s.873 2.763 3.863 3.389c0 0 4.934-1.526 2.48-4.903 0 .013-2.43-1.8-6.342 1.513Z"
            fill="#A83F3F"
        />
        <path
            d="M48.416 34.116c0-.275 0-.563.012-.838a23.466 23.466 0 0 1 1.047-6.103c-.523.013-1.022.05-1.508.075a23.259 23.259 0 0 0-.91 5.165c-.037.525-.049 1.038-.062 1.576v.137c0 .526.013 1.05.05 1.576.474.225.96.438 1.483.65-.05-.5-.087-1-.1-1.5 0-.263-.012-.5-.012-.738Zm45.376-9.517a24.064 24.064 0 0 0-5.22-7.766 24.234 24.234 0 0 0-7.738-5.24 24.104 24.104 0 0 0-9.481-1.913c-3.29 0-6.479.65-9.481 1.913-.362.15-.723.313-1.06.488a19.68 19.68 0 0 0-1.681.9 23.604 23.604 0 0 0-4.984 3.865c-.311.312-.623.637-.91.962-.373.413-.722.838-1.059 1.276-.548.7-1.046 1.426-1.507 2.163a24.675 24.675 0 0 0-1.732 3.364 25.656 25.656 0 0 0-.623 1.626c-.112.338-.224.676-.324 1.013a23.247 23.247 0 0 0-.91 5.165c-.037.525-.05 1.038-.062 1.576v.137c0 .526.013 1.05.05 1.576a24.282 24.282 0 0 0 1.856 7.941 24.252 24.252 0 0 0 5.221 7.779 24.647 24.647 0 0 0 7.737 5.24 24.104 24.104 0 0 0 9.481 1.913c3.277 0 6.467-.65 9.482-1.913a24.366 24.366 0 0 0 7.737-5.24 24.405 24.405 0 0 0 5.12-7.541c.025-.075.063-.15.1-.225.137-.325.262-.65.386-.963a24.26 24.26 0 0 0 1.533-8.542c-.013-3.339-.648-6.54-1.931-9.554Zm-1.284 18.446c0 .012 0 .012-.012.025-.15.363-.311.725-.486 1.075a22.826 22.826 0 0 1-4.435 6.253 22.715 22.715 0 0 1-7.289 4.928 22.712 22.712 0 0 1-8.92 1.813c-3.103 0-6.106-.613-8.934-1.813a22.919 22.919 0 0 1-7.288-4.928 23.05 23.05 0 0 1-4.922-7.316 22.21 22.21 0 0 1-1.532-5.49 20.105 20.105 0 0 1-.15-1.25c-.05-.5-.087-1-.1-1.5 0-.238-.012-.476-.012-.726 0-.275 0-.563.013-.838a23.461 23.461 0 0 1 1.046-6.103c.1-.337.212-.663.337-.988.124-.337.249-.688.398-1.013.2-.475.412-.938.648-1.388.212-.438.449-.85.686-1.275a22.097 22.097 0 0 1 2.317-3.264 26.103 26.103 0 0 1 1.283-1.389 22.844 22.844 0 0 1 5.906-4.289c.448-.225.91-.438 1.383-.638l.373-.15a22.67 22.67 0 0 1 8.56-1.65c3.102 0 6.092.612 8.92 1.813a22.919 22.919 0 0 1 7.29 4.927 23.05 23.05 0 0 1 4.92 7.316 22.933 22.933 0 0 1 1.794 8.954c0 3.064-.61 6.078-1.794 8.904Z"
            fill="#93B3F4"
        />
        <path
            d="m98.327 39.043-1.882-.037c.61.788.748 1.513.362 2.126-.374.625-1.284 1.138-2.63 1.538-.498.15-1.046.275-1.669.388-1.644.3-3.712.475-6.117.5-6.08.075-13.643-.763-21.268-2.326-5.133-1.063-9.88-2.376-13.854-3.814a40.133 40.133 0 0 1-1.757-.663c-.336-.125-.66-.263-.984-.4-.51-.213-.997-.425-1.483-.65-.336-.15-.66-.3-.971-.463-4.386-2.151-6.392-4.215-5.495-5.69.723-1.189 3.352-2.001 7.388-2.302.486-.024.985-.062 1.508-.075.1-.337.212-.662.336-.988-.523.013-1.034.038-1.52.063-5.083.3-7.8 1.2-8.796 2.826-1.146 1.889.424 4.427 5.445 6.904.698.337 1.433.675 2.218 1.013.485.2.996.412 1.52.612.236.088.485.188.722.275.598.225 1.196.45 1.82.663 3.911 1.363 8.459 2.589 13.343 3.602 6.827 1.4 13.617 2.238 19.423 2.376.848.025 1.682.025 2.48.012 2.08-.025 3.937-.15 5.532-.375a19.534 19.534 0 0 0 1.681-.287c2.48-.513 4.112-1.326 4.76-2.389.473-.75.411-1.576-.112-2.439Z"
            fill="#93B3F4"
        />
        <path
            d="M87.923 38.43c-.025.05-.05.088-.074.126-.748 1.188-2.417 1.8-4.747 1.8-1.122 0-2.392-.137-3.788-.425-4.61-.938-10.042-3.289-15.324-6.628-5.183-3.276-9.594-7.116-12.422-10.817l-.15-.188a16.737 16.737 0 0 1-.747-1.063c-2.068-3.076-2.691-5.69-1.695-7.278.748-1.188 2.418-1.801 4.747-1.801 1.122 0 2.392.137 3.788.425.536.113 1.071.238 1.632.375.635.163 1.27.35 1.919.563.448-.225.91-.438 1.383-.638l.373-.15a33.819 33.819 0 0 0-1.993-.663 31.389 31.389 0 0 0-3.053-.788c-4.97-1.012-8.496-.312-9.93 1.964-1.357 2.176-.622 5.44 2.081 9.254.137.2.287.388.424.588.162.225.336.438.51.663 2.929 3.676 7.302 7.44 12.41 10.667 5.407 3.427 11.014 5.84 15.76 6.803 1.483.3 2.841.45 4.05.45 2.84 0 4.871-.825 5.868-2.426.162-.262.3-.538.399-.838h-1.42v.025Z"
            fill="#93B3F4"
        />
        <path
            d="M62.769 56.526c-.287 0-.561-.05-.823-.137-4.173-1.526-2.242-13.932 1.695-24.824 3.638-10.092 9.518-20.297 13.717-20.297.287 0 .56.05.822.138 2.38.862 2.53 5.14 2.23 8.566-.398 4.59-1.793 10.355-3.924 16.258-3.638 10.08-9.519 20.296-13.717 20.296Zm14.59-43.97c-2.642 0-8.186 7.429-12.522 19.434-2.093 5.79-3.464 11.443-3.85 15.92-.411 4.815.436 6.903 1.383 7.24.125.05.25.063.386.063 2.641 0 8.186-7.428 12.522-19.434 2.093-5.79 3.463-11.442 3.85-15.92.423-4.814-.437-6.903-1.384-7.24a1.315 1.315 0 0 0-.386-.063Z"
            fill="#93B3F4"
        />
        <path
            d="M87.687 29.677c-4.66 0-8.909-.438-12.696-1.326-4.535-1.063-8.447-2.751-11.6-5.015-5.432-3.914-6.765-8.154-6.827-8.341l1.233-.375c.013.037 1.309 4.077 6.43 7.728 4.771 3.39 13.904 7.14 30.4 5.728l.112 1.288c-2.442.2-4.797.313-7.052.313ZM83.102 55.1c-9.02-6.74-17.929-9.754-23.822-11.104-6.367-1.451-10.627-1.314-10.665-1.314l-.05-1.288c.175-.012 4.473-.15 11.002 1.351 6.005 1.376 15.1 4.452 24.307 11.33l-.772 1.026Z"
            fill="#93B3F4"
        />
        <path
            d="M254.488 103.185a5.103 5.103 0 0 1-1.495 3.614 5.064 5.064 0 0 1-3.6 1.501h-41.477v-9.917h41.788c.262 0 .523.025.772.062a4.796 4.796 0 0 1 4.012 4.74Z"
            fill="#FC9796"
        />
        <path
            d="M215.616 103.285a4.98 4.98 0 0 1-1.445 3.526l-.013.013a4.951 4.951 0 0 1-3.526 1.463H163.5V98.37h47.245c1.37 0 2.616.563 3.501 1.488.847.901 1.37 2.101 1.37 3.427Z"
            fill="#C0CCEA"
        />
        <path
            d="M219.528 107.562c0 .2-.087.388-.212.525a.731.731 0 0 1-.523.213h-56.975a.738.738 0 0 1-.735-.738c0-.2.087-.388.212-.525a.731.731 0 0 1 .523-.213h56.975a.73.73 0 0 1 .735.738ZM219.528 99.133c0 .2-.087.388-.212.526a.733.733 0 0 1-.523.212h-56.975a.738.738 0 0 1-.735-.738c0-.2.087-.387.212-.525a.733.733 0 0 1 .523-.213h56.975a.73.73 0 0 1 .735.738Z"
            fill="#FB8A82"
        />
        <path
            d="m163.5 103.347 6.317.163-6.317.1v-.263ZM163.5 104.898l15.026.175-15.026.1v-.275Z"
            fill="#6D89CF"
        />
        <path
            d="m250.476 98.458-13.642 9.842h-28.93v-9.917h41.788c.274.012.535.025.784.075Z"
            fill="#FB8A82"
        />
        <path
            d="M86.44 108.3H58.82V96.644h27.994c3.103 0 5.62 2.527 5.62 5.641 0 3.326-2.68 6.015-5.993 6.015Z"
            fill="#3662C1"
        />
        <path
            d="M58.508 108.3H26.749V96.644h31.883c3.165 0 5.744 2.577 5.744 5.766 0 3.251-2.63 5.89-5.868 5.89Z"
            fill="#C0CCEA"
        />
        <path
            d="M66.332 108.3H25.877a.876.876 0 0 1 0-1.751h40.455a.877.877 0 0 1 0 1.751ZM66.332 98.395H25.877a.876.876 0 0 1 0-1.75h40.455a.876.876 0 0 1 0 1.75Z"
            fill="#3662C1"
        />
        <path
            d="m26.75 102.472 4.56.2-4.56.113v-.313ZM26.75 104.298l10.851.2-10.851.113v-.313Z"
            fill="#6D89CF"
        />
        <path
            d="m103.423 54.688-4.237 50.548s-.448 3.064 1.832 3.064c3.738.013 18.576 0 18.576 0s-2.815-.638-2.778-2.076c.037-1.688 4.946-52.024 4.946-52.024l-18.339.488Z"
            fill="#FA7269"
        />
        <path
            d="M120.803 107.374c-.735 0-14.914.05-18.552 0-1.582-.025-1.856-1.413-1.881-2.313-.012-.438.05-.75.05-.75l.112-1.314.287-3.351.249-2.964.324-3.802.274-3.214 2.99-35.904 18.352-.475s-2.068 21.06-3.526 36.392c-.162 1.713-.324 3.351-.461 4.877-.05.488-.087.963-.137 1.426-.137 1.45-.261 2.788-.361 3.964-.062.625-.112 1.201-.162 1.726-.187 2.063-.299 3.389-.299 3.639 0 .15.012.288.037.413.287 1.6 2.43 1.65 2.704 1.65Z"
            fill="#FC9492"
        />
        <path
            d="M58.857 27.175 69.173 68.77l1.42 5.703 4.81 19.371h89.256l-17.705-66.668H58.857Z"
            fill="#FC9F9D"
        />
        <path
            d="m58.856 27.175-1.42.476 16.06 67.63 1.906-1.438-16.546-66.668Z"
            fill="#FC9492"
        />
        <path
            d="M73.496 95.281h88.721l2.442-1.438H75.402l-1.906 1.438Z"
            fill="#FA7269"
        />
        <path
            d="M104.652 108.317h19.212v-.913h-19.212v.913ZM160.335 81.55l-14.477-53.487H58.719v-.888h88.235l14.428 54.263-1.047.112Z"
            fill="#FC9F9D"
        />
        <path
            d="m118.971 95.282-.099.7-18.066 3.664.249-2.951.125-1.413h17.791Z"
            fill="#FA7269"
        />
        <path
            d="M120.803 107.374c-.735 0-14.914.051-18.552 0-1.582-.025-1.856-1.413-1.881-2.313l17.978-3.389c-.186 2.063-.299 3.389-.299 3.639 0 .15.013.288.038.413.299 1.6 2.442 1.65 2.716 1.65ZM114.611 80.35 75.402 93.844l-4.809-19.372 44.018 5.878Z"
            fill="#FB928B"
        />
        <path
            d="M58.857 27.175h88.097l14.291 53.575H71.702L58.857 27.175Z"
            fill="#fff"
        />
        <path
            d="M122.398 87.528c-.25-1.313-1.52-2.376-2.829-2.376-1.308 0-2.167 1.063-1.906 2.376.249 1.313 1.52 2.376 2.828 2.376 1.309 0 2.156-1.063 1.907-2.376Z"
            fill="#FA7269"
        />
        <path
            d="M157.084 78.436H74.617l-9.344-37.354-.723-2.864-.61-2.439-1.433-5.727h81.295l1.57 5.727.673 2.439.785 2.864 10.254 37.354Z"
            fill="#DDE8FD"
        />
        <path
            d="M145.372 35.78H63.94l-1.433-5.728h81.295l1.57 5.727Z"
            fill="#97B6F4"
        />
        <path
            d="M65.871 32.64c-.1-.4.137-.713.536-.713.398 0 .797.325.897.713.1.4-.137.713-.536.713s-.797-.325-.897-.713ZM68.25 32.64c-.099-.4.138-.713.536-.713.4 0 .798.325.897.713.1.4-.137.713-.535.713a.961.961 0 0 1-.897-.713ZM70.792 32.64c-.1-.4.137-.713.536-.713s.797.325.897.713c.1.4-.137.713-.536.713-.398 0-.797-.325-.897-.713Z"
            fill="#DDE8FD"
        />
        <path
            d="M76.386 72.72c0-1.65 1.383-2.988 3.078-2.988 1.694 0 3.077 1.338 3.077 2.989 0 1.65-1.383 2.989-3.077 2.989-1.707.012-3.078-1.338-3.078-2.99Z"
            fill="#fff"
        />
        <path
            d="m79.215 70.958 1.769 1.538a.326.326 0 0 1 0 .5l-1.744 1.476c-.225.187-.574.037-.574-.25l-.025-3.014c-.012-.288.35-.438.574-.25Z"
            fill="#DDE8FD"
        />
        <path
            d="M148.873 73.071a.555.555 0 0 1-.561.563h-62.83a.56.56 0 0 1-.561-.563.553.553 0 0 1 .56-.563h62.831a.56.56 0 0 1 .561.563Z"
            fill="#97B6F4"
        />
        <path
            d="M92.484 72.508v1.126h-6.99a.56.56 0 0 1-.56-.563.553.553 0 0 1 .56-.563h6.99Z"
            fill="#fff"
        />
        <path
            d="M141.111 67.868H86.503l-6.192-22.435-.473-1.726-.4-1.463-.958-3.439h53.835l1.047 3.44.436 1.462.523 1.726 6.79 22.435Z"
            fill="#97B6F4"
        />
        <path
            d="M132.764 51.249H114.81l-2.031-7.379-.162-.562-.137-.476-.311-1.138h17.704l.336 1.138.15.476.174.562 2.231 7.379Z"
            fill="#DDE8FD"
        />
        <path
            opacity={0.6}
            d="M122.198 49.423h-6.229l-.698-2.551-.062-.2-.05-.163-.1-.4h6.13l.125.4.049.163.063.2.772 2.55ZM130.122 49.423h-6.229l-.698-2.551-.062-.2-.038-.163-.112-.4h6.142l.113.4.049.163.063.2.772 2.55Z"
            fill="#97B6F4"
        />
        <path
            d="M125.662 43.095h-11.375a.165.165 0 0 1-.162-.162c0-.088.075-.163.162-.163h11.375c.087 0 .162.075.162.163 0 .1-.075.162-.162.162ZM126.085 43.983H114.71a.165.165 0 0 1-.162-.163c0-.087.075-.162.162-.162h11.375c.088 0 .162.075.162.162a.165.165 0 0 1-.162.163ZM128.054 45.258h-12.82a.192.192 0 0 1-.187-.187c0-.1.087-.188.187-.188h12.808c.099 0 .186.088.186.188s-.074.188-.174.188Z"
            fill="#97B6F4"
        />
        <path
            d="M136.115 62.791h-17.953l-2.031-7.378-.162-.563-.125-.488-.311-1.125h17.691l.349 1.125.137.488.175.563 2.23 7.378Z"
            fill="#DDE8FD"
        />
        <path
            opacity={0.6}
            d="M125.55 60.965h-6.23l-.698-2.563-.049-.2-.05-.163-.112-.388h6.142l.112.388.05.163.062.2.773 2.563ZM133.474 60.965h-6.217l-.711-2.563-.049-.2-.05-.163-.112-.388h6.142l.112.388.062.163.05.2.773 2.563Z"
            fill="#97B6F4"
        />
        <path
            d="M129.013 54.638h-11.375a.165.165 0 0 1-.162-.163c0-.087.075-.163.162-.163h11.375c.088 0 .162.076.162.163a.157.157 0 0 1-.162.163ZM129.437 55.513h-11.375a.165.165 0 0 1-.162-.162c0-.088.075-.163.162-.163h11.375c.087 0 .162.075.162.163a.165.165 0 0 1-.162.162ZM129.748 56.4h-11.375a.165.165 0 0 1-.162-.162c0-.087.075-.162.162-.162h11.375c.088 0 .162.075.162.162a.165.165 0 0 1-.162.163Z"
            fill="#97B6F4"
        />
        <path
            d="M41.065 102.06s-.997.475-1.32 1.263c-.063.137-.1.287-.1.437-.05 1.051 0 3.402 0 3.402l-2.928-1.176-2.916 1.176v-3.202c0-.6.25-1.2.71-1.575.225-.188.499-.325.81-.325h5.744Z"
            fill="#FC9796"
        />
        <path
            d="M41.065 102.06s-.997.475-1.32 1.263l-5.869.05s.224-1.126 1.445-1.313h5.744Z"
            fill="#FB8A82"
        />
        <path
            d="M185.291 98.483h33.789V83.4h-34.263c-4.011 0-7.276 3.264-7.276 7.303 0 4.29 3.476 7.779 7.75 7.779Z"
            fill="#A83F3F"
        />
        <path
            d="M219.802 98.483h38.885V83.4h-39.059c-4.099 0-7.426 3.339-7.426 7.453.013 4.215 3.414 7.629 7.6 7.629Z"
            fill="#C0CCEA"
        />
        <path
            d="M209.848 98.483h49.886c.622 0 1.121-.5 1.121-1.126a1.12 1.12 0 0 0-1.121-1.125h-49.886c-.623 0-1.122.5-1.122 1.125a1.12 1.12 0 0 0 1.122 1.126ZM209.848 85.652h49.886c.622 0 1.121-.5 1.121-1.126a1.12 1.12 0 0 0-1.121-1.125h-49.886c-.623 0-1.122.5-1.122 1.125a1.112 1.112 0 0 0 1.122 1.126Z"
            fill="#A83F3F"
        />
        <path
            d="m258.699 90.942-5.644.25 5.644.15v-.4ZM258.699 87.465l-13.418.263 13.418.15v-.413Z"
            fill="#6D89CF"
        />
        <path
            d="M245.431 87.665s.884.476 1.183 1.264a1.3 1.3 0 0 1 .087.437c.05 1.038 0 3.402 0 3.402l2.617-1.176 2.604 1.176v-3.202c0-.6-.225-1.2-.636-1.575-.199-.188-.448-.326-.722-.326h-5.133Z"
            fill="#A83F3F"
        />
        <path
            d="M245.431 87.665s.884.476 1.183 1.264l5.233.05s-.199-1.126-1.283-1.314h-5.133Z"
            fill="#A03732"
        />
        <path
            d="M173.118 66.543s-1.869 1.063-2.392 1.063c-.523 0-2.566-.4-3.139-.1-.574.288-1.608 1.476-1.795 1.864-.348.688 1.408.387 2.231-.2.124-.088.274-.113.411-.088.423.1 1.32.263 1.756.1.574-.212 3.626-.45 3.626-.45l-.698-2.189Z"
            fill="#FC9593"
        />
        <path
            d="M171.013 105.011s-.2 3.251-.823 3.302h-3.177l-3.949-.025s.025-.951 1.184-1.164c1.158-.2 3.089-1.012 3.787-2.551.723-1.6 2.978.438 2.978.438Z"
            fill="#060632"
        />
        <path
            d="M187.322 52.049s-1.932 13.794-5.133 15.032c-1.583.613-8.56 1.988-10.628 2.126 0 0-.511-.413-.386-2.088 0 0 7.313-2.702 8.36-4.165 1.545-2.188-.96-8.916.984-10.968 1.931-2.05 5.195-2.926 6.803.063Z"
            fill="#ED6966"
        />
        <path
            d="M186.674 81.112c-.561.963-1.221 1.864-1.894 2.714-2.081 2.639-4.336 4.802-4.734 6.853-.075.413-.2.85-.362 1.314-.162.475-.374.962-.598 1.475-.884 1.926-2.205 4.077-3.513 6.04-2.28 3.39-4.423 6.216-4.423 6.216l-3.925-.163c.162-.4 1.869-5.077 4.162-10.78.934-2.326 1.918-4.79 2.878-7.166.124-.312.249-.637.386-.95.286-.725.573-1.45.859-2.138 2.156-5.365 3.863-9.642 3.863-9.642s8.597-3.264 9.494-1.15c.71 1.737.112 3.45-2.193 7.377Z"
            fill="#93B3F4"
        />
        <path
            d="M180.108 95.72s2.728 1.763 2.467 2.338c-.038.075-1.545 2.789-1.545 2.789l-1.944 3.451s-.822-.488-.436-1.601c.386-1.113.623-3.201-.374-4.564-1.046-1.426 1.832-2.414 1.832-2.414Z"
            fill="#060632"
        />
        <path
            d="M178.775 98.82s1.707-1.512 2.006-2.613c0 0-7.04-8.454-8.56-10.355-.074-.087-.05-.187.038-.287 1.146-1.3 13.954-3.952 15.623-6.566.573-.9.897-1.75 1.084-2.501.374-1.588.075-2.689.075-2.689l-12.783-.375s-10.64 6.653-11.849 11.18c-1.196 4.527 14.366 14.207 14.366 14.207Z"
            fill="#C7D8FB"
        />
        <path
            d="M172.259 85.552c1.146-1.3 13.954-3.952 15.623-6.566.573-.9.897-1.75 1.084-2.5v-1.627l-5.432-.862s-1.545 4.752-6.105 6.54c-4.273 1.688-5.083 4.652-5.17 5.015Z"
            fill="#BED2FA"
        />
        <path
            d="M179.759 51.174c2.591.675 6.877-1.739 6.877-1.739s-1.669-1.125-2.28-3.589a3.639 3.639 0 0 1-.087-.387c-.274-.063-.785-.163-1.333-.288l-1.981 1.776s.05.738.287 2c.037.226.087.463.149.713-1.046.676-1.632 1.514-1.632 1.514Z"
            fill="#FC9593"
        />
        <path
            d="M189.365 61.928c-.374 5.003.112 12.606-.299 13.92-.361 1.137-4.498.462-6.142.087a29.466 29.466 0 0 1-2.118-.575c-2.131-.663-4.448-1.614-4.697-2.314-.013-.025-.013-.05-.025-.075a.797.797 0 0 1 0-.3c.087-.363.398-.75.436-1.626.037-.988.037-2.15.074-3.401 0-.138 0-.3.025-.438v-.125c.038-1.4.112-2.864.187-4.315.05-.913.112-1.825.187-2.688.187-2.239.436-4.227.773-5.49.535-2.051 1.731-3.39 2.815-4.377l.785-.676c.872.188 1.919.063 2.853-.375 1.01-.488 1.371-.825 1.545-.7 2.355 1.8 4.037 7.353 3.601 13.468Z"
            fill="#FA7269"
        />
        <path
            d="M180.806 75.36c-2.131-.663-4.448-1.613-4.698-2.314-.012-.025-.012-.05-.024-.075v-.3c.087-.362.398-.75.436-1.626.037-.987.037-2.15.074-3.401 0-.138 0-.3.025-.438v-.125l8.61-11.005c.012-.012 5.793 12.08-4.423 19.284Z"
            fill="#ED6966"
        />
        <path
            d="M180.955 46.96s.05.737.287 2c1.931-.625 2.803-2.376 3.089-3.114a3.639 3.639 0 0 1-.087-.387c-.274-.063-.785-.163-1.333-.288l-1.956 1.788Z"
            fill="#EA8888"
        />
        <path
            d="M180.706 48.085s4.211-.7 3.725-3.327c-.473-2.626-.262-4.477-2.965-4.14-2.704.338-3.04 1.439-3.09 2.352-.062.9 1.221 5.227 2.33 5.115Z"
            fill="#FC9593"
        />
        <path
            d="m160.993 60.771-.368.12 3.156 9.753.367-.12-3.155-9.753Z"
            fill="#3A49CD"
        />
        <path
            d="m174.486 69.77-10.713.229.014.638 10.712-.23-.013-.638Z"
            fill="#3A49CD"
        />
        <path
            d="M174.277 70.92s-3.439.913-4.734.813c-1.296-.1-2.368-.713-2.53-1.038-.162-.325-.498-.888.374-.787.885.1 2.081.287 2.118.124.038-.175-.722-.15-.722-.15s-.225-.337.062-.35c.286-.012 1.121-.237 1.433-.087.311.15.598.738.822.713.224-.025 2.878-1.201 2.878-1.201l.299 1.963Z"
            fill="#FC9593"
        />
        <path
            d="M186.462 52.612s-.411 13.931-3.451 15.507c-1.508.775-8.298 2.926-10.329 3.29 0 0-.548-.351-.61-2.04 0 0 6.977-3.489 7.849-5.064 1.296-2.339-1.931-8.754-.224-11.005 1.694-2.239 4.846-3.477 6.765-.688Z"
            fill="#FA7269"
        />
        <path
            d="M184.344 45.846s.05-.9-.511-1.413c0 0 .187-.9-.162-1.05-.361-.15-.423.612-.598.487-.162-.125-.885-.688-1.196-1.513-.311-.838-3.152.175-3.489.325-.336.15-1.395-.4-1.607-.85 0 0 .598-.475.86-.838s2.13-2.764 4.734-2.076c2.604.688 2.966 1.938 2.816 3.002-.137 1.075.224 3.576-.847 3.926Z"
            fill="#120E59"
        />
        <path
            d="M212.613 57.414s1.334 1.989 2.094 6.153c.672 3.652 1.245 5.002 2.217 5.753.96.75 6.329 2.963 6.329 2.963l-.398.938s-7.862-1.075-8.746-2.388c-.885-1.313-4.249-9.117-3.987-10.905.249-1.814 2.491-2.514 2.491-2.514Z"
            fill="#FCA2A1"
        />
        <path
            d="M231.875 100.959c-.075.775.997.963 2.23 1.013 1.234.062.86.312 2.554.625 1.695.313 2.38-.162 2.492-.388.112-.2-.673-.525-1.52-.887-.099-.051-.212-.088-.311-.138-.947-.413-1.47-1.263-2.218-2.051-.698-.75-1.358-3.139-1.358-3.139l-2.305-.488s.648 2.151.872 3.077c.137.538-.062 1.15-.237 1.6-.087.251-.174.501-.199.776Z"
            fill="#FDB5B4"
        />
        <path
            d="M234.105 101.972c1.234.063.86.313 2.554.625 1.695.313 2.38-.162 2.492-.387.112-.2-.673-.526-1.52-.888l-.012.012s-1.196.4-2.567.025c-1.121-.3-2.205-.975-2.978-1.2-.087.25-.186.5-.211.775-.063.788 1.009.975 2.242 1.038Z"
            fill="#B15252"
        />
        <path
            d="M208.938 73.546s15.3-1.15 18.19.663c2.878 1.826 7.501 23.085 7.501 23.085s-.287.226-.922.3c-.598.063-1.894-.287-1.894-.287s-6.641-9.517-7.114-11.905c-.474-2.401-.349-4.54-1.421-4.59-1.059-.063-14.34 1.626-16.346-1.926-2.006-3.552-.349-7.066-.349-7.066l2.355 1.726Z"
            fill="#060632"
        />
        <path
            d="M226.019 103.423c-.074.775.997.963 2.231 1.013 1.233.062.859.312 2.554.625 1.694.313 2.379-.163 2.491-.388.113-.2-.672-.525-1.52-.888-.099-.05-.211-.087-.311-.137-.947-.413-1.47-1.263-2.218-2.051-.697-.75-1.358-3.14-1.358-3.14l-2.305-.487s.648 2.151.872 3.077c.137.537-.062 1.15-.236 1.6-.087.25-.175.501-.2.776Z"
            fill="#FDB5B4"
        />
        <path
            d="M228.25 104.435c1.233.063.859.313 2.554.626 1.694.312 2.379-.163 2.491-.388.113-.2-.672-.525-1.52-.888l-.012.013s-1.196.4-2.567.025c-1.121-.301-2.205-.976-2.977-1.201-.087.25-.187.5-.212.775-.062.801 1.009.988 2.243 1.038Z"
            fill="#B15252"
        />
        <path
            d="M205.935 74.497s13.593.65 16.409 2.576c2.803 1.938 6.603 23.36 6.603 23.36s-.299.213-.934.263c-.598.038-1.881-.363-1.881-.363s-6.267-9.767-6.641-12.18c-.374-2.414-.175-4.54-1.246-4.64-1.059-.1-14.39 1.063-16.259-2.564-1.869-3.626 3.949-6.452 3.949-6.452Z"
            fill="#0B0754"
        />
        <path
            d="M210.782 44.47c-.598-.4-1.358-.487-1.881-.487.149-.125.411-.163.411-.163-.324-.075-1.072.2-1.072.2-4.423-2.05-5.569 4.602-5.581 6.153-.013 1.563-.112 3.352-2.467 4.602-4.635 2.451-1.196 7.491-1.196 7.491.037.025 3.09 4.09 3.09 4.09-.025-1.263 4.946-7.98 8.098-9.755.81-1.038 1.196-2.939 1.221-3.089-.224 1.513-.548 2.351-.81 2.814 2.716-2.201 1.794-10.805.187-11.855Z"
            fill="#A83F3F"
        />
        <path
            d="M205.375 55.125s1.271-.125 2.529 0c.86.088 1.707.35 2.193.55 1.196.5 1.071 2.627-1.446 2.24-2.504-.389-3.463-2.152-3.276-2.79Z"
            fill="#FDB5B4"
        />
        <path
            d="M209.997 56.676c.013.012.361 1.826-1.283 1.763-1.084-.05-3.414-1.363-3.202-2.288.099-.438.909-1.589 1.084-4.202l.199.05 3.302.888s-.15 1.05-.212 2.05c-.075.839-.075 1.639.112 1.739Z"
            fill="#FDB5B4"
        />
        <path
            d="M210.084 52.887s-.149 1.05-.212 2.05c-1.868-.174-2.753-1.962-3.089-2.938l3.301.888Z"
            fill="#FCA2A1"
        />
        <path
            d="M206.446 51.611s-.884-1.626-1.021-2.976c-.1-.963.61-4.465 4.111-3.715 0 0 1.022.175 1.944.888.735.576 1.769 1.764.324 4.765l-.387 1.013-4.971.025Z"
            fill="#A83F3F"
        />
        <path
            d="M210.645 53.875s-4.448-.225-4.261-3.001c.187-2.777-.274-4.678 2.579-4.653 2.841.025 3.327 1.126 3.489 2.064.161.95-.648 5.577-1.807 5.59Z"
            fill="#FDB5B4"
        />
        <path
            d="M210.981 46.296s-1.432 3.177-3.326 3.527c-1.894.363-2.666-.2-2.666-.2s1.507-1.05 1.931-3.014c0 0 3.251-1.926 4.061-.313Z"
            fill="#A83F3F"
        />
        <path
            d="M210.67 46.546s.86.788 1.034 1.514c.174.725.174 2.188.598 2.163 0 0 1.059-2.151.112-3.477-.997-1.4-1.744-.2-1.744-.2Z"
            fill="#A83F3F"
        />
        <path
            d="M215.093 73.772c-.361.437-1.483.562-2.679.762-.947.163-1.931.35-2.641.763-1.607.938-6.84 4.14-8.373 3.99-.598-.05-.486-1.201-.124-2.702.56-2.388 1.756-5.702 1.756-7.14 0-.788.262-2.652.611-4.778.025-.175.062-.35.087-.525.685-4.14 1.645-9.016 1.645-9.016s1.059 2.326 3.139 2.326c2.069 0 1.595-1.776 1.595-1.776s1.894.225 2.342.713c.449.487 1.583 2.576 1.807 3.139 0 0 .05.725-1.744 1.075 0 0 .436 1.963.486 3.276.037.963-.661 4.002-.86 5.89-.075.663-.075 1.189.025 1.426.137.3.586.613 1.096.9.997.601 2.255 1.164 1.832 1.677Z"
            fill="#0B0754"
        />
        <path
            d="M213.249 72.108c1.146 1.964-3.115 2.551-3.813 2.977-1.607.938-6.503 4.364-8.036 4.227-.598-.05-.486-1.201-.124-2.702.56-2.388 1.756-5.702 1.756-7.14 0-.788.262-2.652.611-4.778l1.844-1.775s1.981 5.127 3.862 5.977c.984.438 2.019.726 2.779.913-.075.663-.075 1.188.025 1.426.137.262.585.575 1.096.875Z"
            fill="#060632"
        />
        <path d="M219.852 73.56h-4.286v.512h4.286v-.513Z" fill="#9E332E" />
        <path
            d="M218.943 73.997c.037-.163 3.937-6.303 3.937-6.303h8.659l-4.348 6.14-8.248.163Z"
            fill="#9E332E"
        />
        <path
            d="M219.304 74.072c.037-.163 3.949-6.378 3.949-6.378h8.286l-4.137 6.378h-8.098Z"
            fill="#A83F3F"
        />
        <path
            d="M226.605 70.895c-.087.25-.361.45-.598.45-.249 0-.374-.2-.299-.45.087-.25.361-.45.598-.45s.386.2.299.45Z"
            fill="#9E332E"
        />
        <path
            d="M216.837 71.383c-.249-.1-2.118.238-2.654.338-1.694-.338-7.288-1.451-7.562-1.801-.324-.413-1.022-13.031-1.819-13.244 0 0-1.583-.963-3.103.863-1.52 1.826 1.084 14.87 3.352 15.545 2.517.75 9.768-.125 9.768-.125.598.2 1.632.325 2.193.5.735.25 1.382.037 1.544-.25.15-.288-1.432-1.714-1.719-1.826Z"
            fill="#FDB5B4"
        />
        <path
            d="M205.375 55.126s-3.24.287-4.186 1.363c-.947 1.075-1.221 3.964-1.221 3.964s2.853 2.313 6.291 1.413l-.884-6.74Z"
            fill="#0B0754"
        />
        <path
            d="M275.694 109H.361a.362.362 0 0 1 0-.725h275.32c.199 0 .361.162.361.363a.343.343 0 0 1-.348.362Z"
            fill="#0D0D38"
        />
    </svg>
)

export default GroupSvgMobile;