import { FC, ReactElement, useState, useEffect } from "react";
import  Html5QrcodePlugin  from "../Services/Html5QrcodePlugin"
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { LayoutContainer } from "../Layout/layout.container";
import { CSSProperties } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { setPageTitle, setPageSubtitle } from "../store/slices/main";

import "./ReadQRPage.css";

const styles = {
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    marginTop: "-50px",
    textAlign: "center",
    minHeight: "70vh",
    maxHeight: "70vh",
    zIndex: 3,
  } as CSSProperties,
};

export const ReadQRPage: FC<any> = (): ReactElement => {
  const dispatch = useDispatch();
  const [data, setData] = useState("No result");
  const navigate = useNavigate();

  const onNewScanResult = (decodedText : any, decodedResult : any) => {
    setData(decodedText);
    const url = new URL(decodedText);
    navigate(url.pathname);
  };

  useEffect(() => {
    dispatch(setPageTitle("Scan QR code"));
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <LayoutContainer isRaised={true}>
        <Box style={styles.listContainer} flexDirection="column">
          <Grid
            sx={{ height: "41vh" }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <div className="camera-container-ui">
              <div className="camera-container">
                <Html5QrcodePlugin
                    style={{color: '#000'}}
                    fps={10}
                    qrbox={{ width: 400, height: 400 }}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                />
              </div>
            </div>
          </Grid>

          <Box className="default-loading"></Box>
        </Box>
      </LayoutContainer>
    </>
  );
};
export default ReadQRPage;
