import { FC, ReactElement, useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import type { ChartArea } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

ChartJS.register(ArcElement, Tooltip, Legend);

import "./Percentage.css";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { getOverallStudentEfficiencyAllClasses, getPercentageTimeSpentAllClasses } from "../../Services/AttendanceService";

import { useWebSocket } from "../../Hooks/useWebSocket";
import CreatePassComponent from "../CreatePass/CreatePass";
import { CSSProperties } from "@mui/styles";
import { GroupEnum } from "../../Interfaces/UserInterface";
interface IClassPercentage {
  studentId: number | null;
  userSchool?: any; 
}

export const PercentageUtilizationComponent: FC<IClassPercentage> = ({
  studentId,
  userSchool
}): ReactElement => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("sm"));

  const chartRef = useRef<ChartJSOrUndefined<"doughnut">>(null);
  const [chartData, setChartData] = useState<ChartData<"doughnut">>({
    datasets: [],
  });
  const { currentUser, currentSchool } = useSelector((state: RootState) => state.main);

  const [dataResult, setDataResult] = useState<number | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  useWebSocket([
    {
      entity: "attendance",
      method: "UPDATE",
      onMessageReceived: () => {
        loadData(studentId);
      },
    },
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        loadData(studentId);
      },
    },
  ]);

  function createGradient(ctx: CanvasRenderingContext2D, doughnut: ChartArea) {
    const colorStart = "#f76b1c";
    const colorMid = "#ffd94d";
    const colorEnd = "#fecc47";
    const gradient = ctx.createLinearGradient(
      doughnut.bottom - 20,
      0,
      doughnut.top - 20,
      0
    );

    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(0.5, colorMid);
    gradient.addColorStop(1, colorEnd);

    return gradient;
  }

  const data = {
    labels: [],
    datasets: [
      {
        label: "Percentage",
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    loadData(studentId);
  }, [studentId]);

  function loadData(studentId: number | null) {
    if (studentId) {
      setLoading(true);
      const fetchData = async () => {
        return await getOverallStudentEfficiencyAllClasses(studentId, currentSchool?.id);
      };

      fetchData()
        .then((result: number) => {
          console.log(result);
          let percentage = 0;

          if (result < 0) {
            percentage = result * -1;
          } else if (result === null || result === undefined || isNaN(result)) {
            percentage = 0;
          } else {
            percentage = result;
          }
          setDataResult(Math.round(percentage));

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    if (dataResult == null) {
      return;
    }

    let spentTimePercentage = Math.round(100 - dataResult);
    let totalTimePercentage = Math.round(dataResult);

    console.log(spentTimePercentage);
    console.log(totalTimePercentage);

    const chartData = {
      labels: [],
      datasets: [
        {
          label: "Percentage",
          data: [spentTimePercentage, totalTimePercentage],
          borderRadius: [0, 100],
          spacing: -6,
          backgroundColor: [
            "rgba(115, 136, 169, 0.353283)",
            createGradient(chart.ctx, chart.chartArea),
          ],
          borderColor: ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 0)"],
          borderWidth: 1,
          weight: 1,
          cutout: 40,
          rotation: 270,
        },
      ],
    };

    setChartData(chartData);
  }, [dataResult, loading]);

  const styles = {
    container: {
      height: downMd && studentId ? 160 : 145,
    } as CSSProperties,
  };

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <div>
          <div
            className={downMd ? "perc-container" : "perc-container-desk"}
            style={styles.container}
          >
            <div className="perc-graph">
              <div className="perc-number">{dataResult} % </div>
              <Doughnut ref={chartRef} data={chartData} />
            </div>
            <div className="perc-sub-title">
              Percentage of time spent in all classes
            </div>
            <div style={{ marginTop: 10 }}>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

