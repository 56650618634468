import { Container } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Outlet } from "react-router-dom";
import appConfig from "../../Config/appConfig";
import { menuItemList } from "../../Config/menuItemList";
import { useWebSocket } from "../../Hooks/useWebSocket";
import MenuItem from "../../Interfaces/MenuItem";
import { MenuDrawer } from "./components/menu.drawer";
import { UserToolbar } from "./components/user.toolbar";
import { UserToolbarDesktop } from "./components/user.toolbar.desktop";
import { SocketProvider } from "../../Providers/SocketProvider";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface LayoutAdaptiveProps {
  title?: string;
  subTitle?: string;
  imagePath?: string;
  menuList?: MenuItem[];
  children?: JSX.Element | JSX.Element[];
}

const fullWidthScreens = [
  {
    name: "Calendar",
  },
];

export const LayoutAdaptive: FC<LayoutAdaptiveProps> = (): ReactElement => {
  const drawerWidth = 285;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("sm"));

  const { pageTitle } = useSelector((state: RootState) => state.main);

  const isFullScreen = useMemo(
    () => fullWidthScreens.some((x) => x.name === pageTitle),
    [pageTitle]
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <MenuDrawer
        menuList={menuItemList}
        onMenuItemSelected={() => setMobileOpen(false)}
      ></MenuDrawer>
    </div>
  );
  return (
    <SocketProvider>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          {!downMd ? <UserToolbarDesktop /> : <div></div>}
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#353535",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,

            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          {downMd ? (
            <UserToolbar handleDrawerToggle={handleDrawerToggle}></UserToolbar>
          ) : (
            <div></div>
          )}

          {!downMd ? (
            <Container
              maxWidth={isFullScreen ? "lg" : "md"}
              style={{ paddingTop: "120px", paddingRight: 0, paddingLeft: 0 }}
            >
              <Outlet />
            </Container>
          ) : (
            <Container
              maxWidth={isFullScreen ? "lg" : "md"}
              style={{
                paddingTop: downMd ? 0 : 5,
                paddingRight: 0,
                paddingLeft: 0,
              }}
            >
              <Outlet />
            </Container>
          )}
        </Box>
      </Box>
    </SocketProvider>
  );
};
