import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";

const UserProfile = () => {
  const navigate = useNavigate();
  const { signOut, isAuthenticated } = useCognitoAwsV2();

  const handleLogOut = () => {
    signOut();
    console.log("Logout result: ", isAuthenticated);

    if (isAuthenticated === false) {
      console.log("Log out succeed");
      navigate("/");
    }
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{
        background: "#2A2D38",
        padding: 20,
        paddingBottom: 60,
        marginTop: 60,
        height: 720,
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: 30, marginTop: 40 }}>
          <Typography component="h1" variant="h5">
            Login Success
          </Typography>
        </div>
        <Box sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                className="form__custom-button"
                style={{ width: 180, marginTop: 50 }}
                onClick={handleLogOut}
                sx={{ mt: 3, mb: 2 }}
              >
                Logout
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                component={Link}
                to="/ChangePassword"
                variant="contained"
                color="primary"
                type="button"
                fullWidth
                className="form__custom-button"
                style={{ width: 180, marginTop: 50 }}
                sx={{ mt: 3, mb: 2 }}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default UserProfile;
