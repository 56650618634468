import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { FC, ReactElement, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { PostDistricts } from "../../Services/SchoolsService";
import { LightModal } from "../LightModal/LightModal";

interface ListProps {
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
}

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

const CreateDistrictModal: FC<ListProps> = ({
  openModal,
  handleOpenModal,
}): ReactElement => {
  const dispatch = useDispatch();
  const { districts, savingIndicator, successMessage } = useSelector(
    (state: RootState) => state.school
  );

  const required = (value: string) =>
    value
      ? checkIfAlreadyExist(value)
        ? "District already exist"
        : undefined
      : "Required";

  const handleSubmitClick = (values: any) => {
    dispatch(PostDistricts(values));
  };

  useEffect(() => {
    successMessage !== "" && handleOpenModal(false);
  }, [successMessage]);

  const checkIfAlreadyExist = (value: string): boolean => {
    const result = districts?.find(
      (s) => s.name.toLowerCase() === value.toLowerCase()
    );
    return result !== undefined;
  };

  return (
    <LightModal
      width="500px"
      isOpenModal={openModal}
      handleOpenModal={handleOpenModal}
    >
      <Card>
        <CardHeader title="Add a District" sx={{ mx: 1 }} />
        <Form
          onSubmit={handleSubmitClick}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <CardContent sx={{ mx: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Field name="name" validate={required}>
                      {({ input, meta }) => (
                        <div>
                          <TextField
                            style={{ marginBottom: "5px" }}
                            type="text"
                            fullWidth
                            id="modal-district"
                            label="Name"
                            variant="outlined"
                            error={meta.error && meta.touched}
                            helperText={meta.error}
                            {...input}
                          />
                        </div>
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions style={{ padding: 0, margin: 0 }}>
                <Grid
                  container
                  spacing="0"
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: "1rem" }}
                >
                  <Grid item xs={6}>
                    <Button
                      onClick={() => handleOpenModal(false)}
                      disabled={savingIndicator}
                      style={styles.formButton}
                      size="large"
                      color="secondary"
                      variant="contained"
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={styles.formButton}
                      fullWidth
                      size="large"
                      disabled={savingIndicator}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </form>
          )}
        />
        {savingIndicator && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Card>
    </LightModal>
  );
};

export default CreateDistrictModal;
