import { Badge } from "@mui/material";
import { CSSProperties } from "react";

interface Props {
  title: string;
  isActive: boolean;
  badgeCssBackground?: string;
  badgeColor?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  quantity?: number;
}

const TabPanelTitle = ({
  title,
  isActive,
  badgeCssBackground,
  badgeColor,
  quantity = 0,
}: Props) => {
  const style = {
    alignItems: "center",
    color: isActive ? "white" : "#fffa",
    textTransform: "none",
    "& span:last-child": {
      position: "static",
      transform: "scale(1) translate(50%)",
      marginRight: "5px",
    } as CSSProperties,
  };

  const shouldShow = quantity > 0;
  if (badgeCssBackground && shouldShow) {
    style["& span:last-child"].backgroundColor = badgeCssBackground;
  }

  return (
    <>
      {shouldShow ? (
        <Badge
          color={badgeColor ?? "secondary"}
          badgeContent={quantity}
          sx={style as CSSProperties}
        >
          <span>{title}</span>
        </Badge>
      ) : (
        <span style={style as CSSProperties}>{title}</span>
      )}
    </>
  );
};

export default TabPanelTitle;
