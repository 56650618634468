import React, { useMemo, useState } from "react";
import { FC, ReactElement } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteClass } from "../../Services/ClassService";
import { Class } from "../../Interfaces/ClassInterface";
import { PopButtonMenu, ConfirmationDialog } from "../../Components";
import { setCurrentClass } from "../../store/slices/class.slice";
import { setBackwardNavigationRoute } from "../../store/slices/main";
import { useNavigate } from "react-router-dom";

interface ListProps {
  defaultClass: Class;
  styles: any;
  loadingClasses: boolean;
  classes: Class[];
  selectedClass?: Class;
  isMobile: boolean;
  setSelectedClass: (class_: Class) => void;
  handleOpenModal: (state: boolean, origin: string) => void;
}

const EDIT_CLASS_OPT = "Edit Class";
const VIEW_ATTENDANCE_OPT = "View Attendance";
const EDIT_ROSTER_OPT = "Edit Roster";
const DELETE_OPT = "Delete";

const buttonMenuOptions: string[] = [
  EDIT_CLASS_OPT,
  VIEW_ATTENDANCE_OPT,
  EDIT_ROSTER_OPT,
  DELETE_OPT,
];

const confirmationDialogData: any = [
  {
    title: "Edit Class",
    message: "Are you sure you want to edit this class?",
  },
  {
    title: "Delete Class",
    message: "Are you sure you want to delete this class?",
  },
];

const BulletSeparator = ({
  isMobile,
  style,
}: {
  isMobile: boolean;
  style: any;
}) => (
  <Grid sx={style}>
    <span style={{ display: isMobile ? "inline" : "none" }}>&bull;</span>
  </Grid>
);

const ClassList: FC<ListProps> = ({
  defaultClass,
  styles,
  loadingClasses,
  classes,
  selectedClass,
  isMobile,
  handleOpenModal,
}): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [confirmationDialog, setConfirmationDialog] = useState<any>(
    confirmationDialogData[0]
  );

  const buildContainerStyle = () => {
    return loadingClasses
      ? styles.listContainer
      : {
          ...styles.listContainer,
          backgroundColor: "transparent",
          boxShadow: "none",
        };
  };

  const popUpHandlerClick = (
    event: React.MouseEvent<HTMLElement>,
    selectedOption: string | null,
    selectedClass?: Class
  ) => {
    event.preventDefault();

    if (!selectedOption) return;

    if (selectedClass?.id) {
      dispatch(setCurrentClass(selectedClass));
    }

    setConfirmationDialogOpen(false);
    switch (selectedOption) {
      case EDIT_CLASS_OPT:
        handleOpenModal(true, "update");
        break;
      case VIEW_ATTENDANCE_OPT:
        navigate("/AttendanceList", {
          state: { navigationClass: selectedClass },
        });
        break;
      case EDIT_ROSTER_OPT:
        dispatch(setBackwardNavigationRoute("/TeacherSchedule"));
        navigate("/ClassRoster");
        return;
      case DELETE_OPT:
        setConfirmationDialog(confirmationDialogData[1]);
        setConfirmationDialogOpen(true);
        return;
    }
  };

  const confirmationDialogHandler = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    try {
      event.preventDefault();

      if (selectedClass?.id) {
        setDeleting(true);
        await dispatch(deleteClass(parseInt(`${selectedClass.id}`)));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setCurrentClass(defaultClass));
      setDeleting(false);
      setConfirmationDialogOpen(false);
    }
  };

  const ClassListItems = useMemo(
    () =>
      classes.map((item: Class) => (
        <React.Fragment key={item.id}>
          <ListItem style={styles.listItem}>
            <ListItemText
              primaryTypographyProps={styles.primaryTextList}
              primary={
                <React.Fragment>
                  <Grid container mt={0}>
                    <Grid item md={2} pt={0} sx={styles.listGridContainer}>
                      Room{" "}
                      <Grid sx={styles.listGridText}>{item.room?.name}</Grid>
                      <BulletSeparator
                        isMobile={isMobile}
                        style={styles.listGridText}
                      />
                    </Grid>
                    <Grid item md={2} pt={0} sx={styles.listGridContainer}>
                      Day Type{" "}
                      <Grid sx={styles.listGridText}>
                        {item.day_type?.name}
                      </Grid>
                      <BulletSeparator
                        isMobile={isMobile}
                        style={styles.listGridText}
                      />
                    </Grid>
                    <Grid item md={2} pt={0} sx={styles.listGridContainer}>
                      Period{" "}
                      <Grid sx={styles.listGridText}>{item.period?.name}</Grid>
                      <BulletSeparator
                        isMobile={isMobile}
                        style={styles.listGridText}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              secondary={item.name}
              secondaryTypographyProps={styles.secondaryTextList}
            />
            <PopButtonMenu
              menuOptions={buttonMenuOptions}
              onClick={popUpHandlerClick}
              data={item}
            />
            <ConfirmationDialog
              open={confirmationDialogOpen}
              title={confirmationDialog.title}
              message={confirmationDialog.message}
              onConfirm={confirmationDialogHandler}
              onCancel={() => {
                dispatch(setCurrentClass(defaultClass));
                setConfirmationDialogOpen(false);
              }}
              loading={deleting}
            />
          </ListItem>
        </React.Fragment>
      )),
    [classes, selectedClass, popUpHandlerClick, confirmationDialogHandler]
  );

  return (
    <>
      <Box style={buildContainerStyle()}>
        {loadingClasses ? (
          <Box style={styles.loading}>
            <LinearProgress />
          </Box>
        ) : (
          <List style={styles.list}>{ClassListItems}</List>
        )}
      </Box>
    </>
  );
};

export default ClassList;
