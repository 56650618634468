import { WatchLaterOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { StudentOnLocation } from "../../Interfaces/SchoolInterface";

const HallPassListItem = ({
  studentOnLocation,
}: {
  studentOnLocation: StudentOnLocation;
}) => {
  const { student, location, started_at, current_class } = studentOnLocation;
  const startedAtDate = started_at ? new Date(started_at) : new Date();
  const now = moment(new Date());
  const durationInMinutes = moment
    .duration(now.diff(moment(startedAtDate)))
    .asMinutes()
    .toFixed(0);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      padding="12px 16px"
      sx={{
        backgroundColor: "#3c404e",
        marginBottom: "2px",
        borderRadius: "5px",
        boxShadow: 2,
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="start">
        <Typography fontWeight={500} lineHeight={1.28}>
          {`${student?.first_name} ${student?.last_name}`}
        </Typography>
        <Typography fontSize=".9em" lineHeight={1.28}>
          Room {current_class?.room?.name}
        </Typography>
        <Typography fontSize=".9em" color="#009cde" lineHeight={1.28}>
          {location?.name}
        </Typography>
      </Box>
      <Box display="flex" alignItems="start">
        <WatchLaterOutlined />
        <Typography
          style={{
            color: "white",
          }}
          marginLeft={1}
          fontSize=".9em"
        >
          {durationInMinutes} Min
        </Typography>
      </Box>
    </Box>
  );
};

export default HallPassListItem;
