import { createAsyncThunk } from "@reduxjs/toolkit";
import { MassCreateRoom, Room } from "../Interfaces/SchoolInterface";
import ApiService from "./apiService";

// const basicUrl =
//   process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local";
const basicUrl = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE === "local"
    ? true
    : false
  : true;
const roomUrl = `${basicUrl ? "/schools" : ""}/schools/rooms`;

export const fetchRoomsBySchoolId = createAsyncThunk(
  "rooms/fetchRoomsBySchoolId",
  async (school_id: number) => {
    const result: any = await ApiService.get<Room[]>(
      `${roomUrl}/school/${school_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const createRoom = createAsyncThunk(
  "rooms/createRoom",
  async (room: Room) => {
    const result: any = await ApiService.post<Room>(`${roomUrl}`, room);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const updateRoom = createAsyncThunk(
  "rooms/updateRoom",
  async (room: Room) => {
    const result: any = await ApiService.put<Room>(
      `${roomUrl}/${room.id}`,
      room
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const massCreateRoom = createAsyncThunk(
  "rooms/massCreateRoom",
  async (massCreateRoom: MassCreateRoom) => {
    const result: any = await ApiService.post<Room[]>(
      `${roomUrl}/massCreate`,
      massCreateRoom
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const deleteRoom = createAsyncThunk(
  "rooms/deleteRoom",
  async (room_id: number) => {
    const result: any = await ApiService.delete<Room>(`${roomUrl}/${room_id}`);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);
