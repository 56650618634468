import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { FC, ReactElement, useEffect, useState } from "react";
import {
  MaxCharCountField,
  RequiredStringField,
} from "../../Helpers/FormValidations";
import useForm, { FormField } from "../../Hooks/useForm";
import { LightModal } from "../LightModal/LightModal";
import { DayType, FrecuencyType } from "../../Interfaces/ScheduleInterface";
import { createDayType, editDayType } from "../../Services/ScheduleService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

interface ListProps {
  schoolId: number;
  openModal: boolean;
  selectedDayType?: DayType;
  handleOpenModal: (state: boolean) => void;
}

// const daysButtons = [
//   { value: "Mo", text: "M" },
//   { value: "Tu", text: "T" },
//   { value: "We", text: "W" },
//   { value: "Th", text: "T" },
//   { value: "Fr", text: "F" },
// ];

const styles = {
  formButton: {
    borderRadius: 0,
    textTransform: "unset",
    fontWeight: 500,
    height: 46,
  } as CSSProperties,
};

const newDayTypeForm: DayType = {
  name: "",
  // frecuency_type: FrecuencyType.EveryXDays,
  // details: "",
  school_id: 0,
};

const NewDayTypeModal: FC<ListProps> = ({
  schoolId,
  openModal,
  selectedDayType,
  handleOpenModal,
}): ReactElement => {
  const {
    form,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleCompleted,
  } = useForm<DayType>(newDayTypeForm, validateformField, submitForm);
  const dispatch = useDispatch();
  const { dayTypes, successMessage, dayTypesSavingIndicator } = useSelector(
    (state: RootState) => state.schedule
  );
  // const [frecuency, setFrecuency] = useState(FrecuencyType.EveryXDays);
  // const [daysOfWeek, setDaysOfWeek] = useState<string[]>(["Mo"]);
  // const [schoolDays, setSchoolDays] = useState<number>(0);

  useEffect(() => {
    successMessage !== "" && handleCloseModal();
  }, [successMessage]);


  useEffect(() => {
    form.name = selectedDayType?.name || "";
  }, [selectedDayType]);


  function handleCloseModal() {
    handleCompleted(true);
    handleOpenModal(false);
  }

  // const handleDaysOfWeek = (
  //   e: React.MouseEvent<HTMLElement>,
  //   newDays: string[]
  // ) => {
  //   setDaysOfWeek(newDays);
  // };

  async function submitForm(form: DayType) {
    if (!readyToSubmit()) return;

    // form.frecuency_type = frecuency;
    form.school_id = schoolId;
    // switch (frecuency) {
    //   case FrecuencyType.DaysOFWeek:
    //     form.details = JSON.stringify(daysOfWeek);
    //     break;
    //   case FrecuencyType.EveryXDays:
    //     form.details = JSON.stringify(schoolDays);
    //     break;
    // }

    if (selectedDayType) {
      form.id = selectedDayType.id;

      dispatch(editDayType(form));
    }
    else {
      dispatch(createDayType(form));
    }
  }

  function checkIfAlreadyExist(value: string): string {
    const result = dayTypes.find((s) => s.name === value);
    return result === undefined
      ? ""
      : "A day type with the same name already exists";
  }

  function validateformField(fieldName: string, fieldValue: string): FormField {
    let value: string = "";

    switch (fieldName) {
      case "name": {
        value =
          RequiredStringField(fieldName, fieldValue) ||
          MaxCharCountField(fieldName, fieldValue, 40) ||
          checkIfAlreadyExist(fieldValue);
        break;
      }
    }
    return {
      name: fieldName,
      value: value,
    } as FormField;
  }

  // function handleFrecuencyChange({
  //   target,
  // }: React.ChangeEvent<HTMLInputElement>) {
  //   const frec: FrecuencyType = FrecuencyType[target.value as FrecuencyType];
  //   setFrecuency(frec);
  // }

  function handleSubmitForm(e: React.FormEvent<HTMLFormElement>) {
    handleSubmit(e);
  }

  // function handleSchoolDaysChange({
  //   target,
  // }: React.ChangeEvent<HTMLInputElement>) {
  //   setSchoolDays(parseInt(target.value));
  // }

  // const FrecuencyLabel = (
  //   <Box>
  //     <label>Every </label>
  //     <TextField
  //       style={{ width: 40 }}
  //       inputProps={{ min: 0, style: { padding: 0, textAlign: "center" } }}
  //       type="number"
  //       name="details"
  //       variant="standard"
  //       onChange={handleSchoolDaysChange}
  //       value={schoolDays}
  //       error={
  //         frecuency === FrecuencyType.EveryXDays &&
  //         (!schoolDays || schoolDays === 0)
  //       }
  //       disabled={frecuency !== FrecuencyType.EveryXDays}
  //     />
  //     <label> School days</label>
  //   </Box>
  // );

  function readyToSubmit(): boolean {
    return !(
      !form.name ||
      !!errors.name ||
      // (frecuency === FrecuencyType.DaysOFWeek && daysOfWeek.length === 0) ||
      // (frecuency === FrecuencyType.EveryXDays &&
      //   (!schoolDays || schoolDays === 0)) ||
      dayTypesSavingIndicator
    );
  }

  return (
    <>
      <LightModal isOpenModal={openModal} handleOpenModal={handleOpenModal}>
        <Card>
          <CardHeader title={selectedDayType ? 'Edit Day Type' : 'Add new day type'} sx={{ mx: 1 }} />
          <form onSubmit={handleSubmitForm} autoComplete="off">
            <CardContent sx={{ mx: 1 }}>
              <TextField
                style={{ marginBottom: "5px" }}
                type="text"
                name="name"
                fullWidth
                label="Type name"
                variant="standard"
                value={form.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.name}
                helperText={errors.name && errors.name}
              />
              {/* <FormControl sx={{ my: 2 }}>
                <FormLabel id="radio-button-frecuency">Frecuency</FormLabel>
                <RadioGroup
                  aria-labelledby="radio-button-frecuency"
                  value={frecuency}
                  onChange={handleFrecuencyChange}
                >
                  <FormControlLabel
                    value={FrecuencyType.EveryXDays}
                    control={<Radio />}
                    label={FrecuencyLabel}
                  />
                  <FormControlLabel
                    value={FrecuencyType.DaysOFWeek}
                    control={<Radio />}
                    label="Choose day of week"
                  />
                </RadioGroup>
              </FormControl> */}
              {/* <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={daysOfWeek}
                  onChange={handleDaysOfWeek}
                  size="large"
                  style={{ textAlign: "center" }}
                  disabled={frecuency === FrecuencyType.EveryXDays}
                >
                  {daysButtons.map((day, index) => (
                    <ToggleButton
                      key={index}
                      value={day.value}
                      style={{ width: 47 }}
                    >
                      {day.text}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid> */}
            </CardContent>
            <CardActions style={{ padding: 0, margin: 0 }}>
              <Grid
                container
                spacing="0"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleCompleted(true);
                      handleOpenModal(false);
                    }}
                    disabled={dayTypesSavingIndicator}
                    style={styles.formButton}
                    size="large"
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={styles.formButton}
                    fullWidth
                    size="large"
                    disabled={!readyToSubmit()}
                  >
                    {selectedDayType ? "Save" : "Add type"}
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            {dayTypesSavingIndicator && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </form>
        </Card>
      </LightModal>
    </>
  );
};

export default NewDayTypeModal;
