import { FC, ReactElement } from "react";
import { Button, CssBaseline } from "@mui/material";
import { LayoutContainer } from "../../Layout/layout.container";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { margin } from "@mui/system";

const CreatePassComponent: FC = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <>
      <CssBaseline />
      <Button
        variant="contained"
        startIcon={<AddCircleOutlineOutlined />}
        style={{
          background: "#FFFFFF",
          color: "#7AC142",
          textTransform: "unset",
          margin: 0,
          zIndex: 2,
        }}
        onClick={() => {
          console.log("Button clicked");

          navigate(`/TeacherHallPassLocations`);
        }}
      >
        Create Pass
      </Button>
    </>
  );
};

export default CreatePassComponent;
